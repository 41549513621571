import styled from '@emotion/styled';
import React, {FC} from 'react';

import {color} from '@uc/cx-design-tokens';

const Photo = styled.div<{listingPhotoUrl?: string}>`
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-position: 50%;
  ${({listingPhotoUrl}) =>
    listingPhotoUrl ? `background-image: url(${listingPhotoUrl});` : ''}
`;

const Placeholder = styled.div`
  position: absolute;
  width: 50%;
  max-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PlaceholderSvg = styled.svg`
  fill: ${color.grey70};
`;

type OwnProps = {
  className?: string;
  listingPhotoUrl?: string;
  listingPhotoAriaLabel?: string;
};

export const ListingPhoto: FC<OwnProps> = ({
  listingPhotoUrl,
  listingPhotoAriaLabel,
  className,
}) => {
  return (
    <Photo
      className={className}
      listingPhotoUrl={listingPhotoUrl}
      role="img"
      aria-label={listingPhotoAriaLabel ?? 'listing thumbnail photo'}
    >
      {!listingPhotoUrl ? (
        <Placeholder>
          <PlaceholderSvg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            {/* eslint-disable-next-line max-len */}
            <path d="M21.854,12.146l-2-2h0l-7.5-7.5a.5.5,0,0,0-.708,0l-7.5,7.5h0l-2,2a.5.5,0,0,0,.708.708L4,11.707V20.5a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5V11.707l1.146,1.147a.5.5,0,0,0,.708-.708ZM14,20H10V15a2,2,0,0,1,4,0Zm5,0H15V15a3,3,0,0,0-6,0v5H5V10.707l7-7,7,7Z"></path>
          </PlaceholderSvg>
        </Placeholder>
      ) : null}
    </Photo>
  );
};
