import styled from '@emotion/styled';
import React, {FC} from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';
import {NoSsr} from '@uc/cx.react';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {propertyDetailFieldsForCmaLp} from '../../helpers/display-helpers';
import {getValueForPropertyDetailCmaLp} from '../../helpers/listingDataFields';
import {PropertyDetailCell} from '@/components/PropertiesDetailCell';

const Container = styled.div`
  margin: 10px ${spacing['2x']};
`;

const Title = styled.div<{isForPropertyModal?: boolean}>`
  font-weight: ${font.weightMedium};
  margin-bottom: ${spacing['1x']};
  font-size: ${({isForPropertyModal}) =>
    isForPropertyModal ? font.sizeBody2 : 'inherit'};
`;

const PropertyDetailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${mq.maxWidthMobileLandscape} {
    margin: 0;
  }
`;

const PropertyDetailFormColumn = styled.div<{columns?: number}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${spacing['1x']};
  margin-bottom: ${spacing['1x']};
  width: ${({columns = 1}) => {
    if (columns === 1) {
      return '100%';
    }
    return `calc(${100 / columns + '%'} - ${spacing['1x']})`;
  }};
  border-bottom: 1px solid ${color.border};
  @media ${mq.maxWidthMobileLandscape} {
    padding-bottom: ${spacing['1x']};
    margin-bottom: ${spacing['1x']};
    width: 100%;
  }
`;

type Props = {
  columns?: number;
  hiddenTransactionInformation?: string[];
  listing?: ProcessedListing;
  isForPropertyModal?: boolean;
};

export const PropertyDetailForCmaLp: FC<Props> = ({
  listing,
  columns,
  hiddenTransactionInformation = [],
  isForPropertyModal = false,
}) => {
  if (!listing) {
    return null;
  }

  const filteredPropertyDetailFieldsForCmaLp =
    propertyDetailFieldsForCmaLp.filter(
      ([, path]) => !hiddenTransactionInformation.includes(path),
    );

  return (
    <NoSsr>
      <Container data-tn="transactionInformation">
        <Title isForPropertyModal={isForPropertyModal}>
          Transaction Information
        </Title>
        <PropertyDetailWrapper data-tn="PropertyDetailWrapper-view">
          {filteredPropertyDetailFieldsForCmaLp.map(([label, path], index) => {
            const value = getValueForPropertyDetailCmaLp(path, listing);
            return (
              <PropertyDetailFormColumn
                data-tn="PropertyDetailFormColumn-view"
                key={index}
                columns={columns}
              >
                <PropertyDetailCell
                  data-tn="PropertyDetailCell-view"
                  caption={label}
                  value={value}
                />
              </PropertyDetailFormColumn>
            );
          })}
        </PropertyDetailWrapper>
      </Container>
    </NoSsr>
  );
};
