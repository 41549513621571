import React, {useRef, useEffect, SyntheticEvent} from 'react';
import fitty from 'fitty';
import styled from '@emotion/styled';

interface FittyOptions {
  minSize?: number;
  maxSize?: number;
  multiLine?: boolean;
  observeMutations?: MutationObserverInit;
}

interface Props {
  value?: string;
  editable?: boolean;
  onTextChange?: (text: string) => void;
  lengthLimit?: number;
  href?: string;
  isMultiLine?: boolean;
  shouldRestoreInitialValueWhenCleared?: boolean;
  className?: string;
  autoScale?: FittyOptions;
  dataTn?: string;
}

const EdibleLink = styled.a<Props>`
  transition: font-size 0.2s;

  ${({editable}) =>
    editable &&
    ` 
    &:hover {
      outline: 1px solid #0064e5;
    }
    &:focus {
      outline: 1px solid #003375;
    }`}
`;

export const EditableText: React.FC<Props> = ({
  value,
  editable,
  onTextChange = () => {},
  lengthLimit = Infinity,
  href,
  isMultiLine,
  shouldRestoreInitialValueWhenCleared,
  className,
  autoScale,
  dataTn,
}: Props) => {
  const ref = useRef<HTMLAnchorElement>(null);
  let lastHTML = value;

  const shouldLimitInput = ({
    target: {textContent = ''},
    key = '',
  }: SyntheticEvent & {
    target: {textContent?: string};
    key?: string;
  }): boolean =>
    (textContent?.length || 0) >= lengthLimit &&
    ![
      'Backspace',
      'Mata',
      'Control',
      'ArrowLeft',
      'ArrowDown',
      'ArrowUp',
      'ArrowRight',
      'Shift',
      'Alt',
    ].includes(key);

  useEffect(() => {
    if (
      autoScale &&
      !isMultiLine &&
      typeof MutationObserver !== 'undefined' &&
      ref?.current
    ) {
      fitty(ref.current, autoScale);
    }
  }, []);

  return (
    <EdibleLink
      data-tn={dataTn}
      ref={ref}
      dangerouslySetInnerHTML={{
        __html: (editable ? value : value?.replace(/\n/g, '<br>')) || '',
      }}
      className={className}
      contentEditable={
        editable ? ('plaintext-only' as unknown as boolean) : false
      }
      suppressContentEditableWarning={true}
      href={editable ? undefined : href}
      onKeyDown={e => {
        if (shouldLimitInput(e)) {
          e.preventDefault();
        }

        if (e.key === 'Enter' && !isMultiLine) {
          e.preventDefault();
          e.currentTarget.blur();
        }
      }}
      onPaste={e => {
        if (!shouldLimitInput(e)) {
          e.preventDefault();
          // Resolve the problem that when copying and pasting from a web to text, the style is also copied over.
          // Converting text in the pasteboard to normal text
          const text = e.clipboardData?.getData('text/plain');
          document.execCommand('insertHTML', false, text);
        }
      }}
      onBlur={({target: {innerHTML, textContent}}) => {
        if (ref?.current) {
          ref.current.scrollTop = 0;
          ref.current.scrollLeft = 0;

          if (
            shouldRestoreInitialValueWhenCleared &&
            textContent?.trim() === ''
          ) {
            ref.current.innerHTML = value || '';
          }

          ref.current.normalize();
        }

        if (innerHTML !== lastHTML) {
          onTextChange(innerHTML);
          lastHTML = innerHTML;
        }
      }}
    />
  );
};
