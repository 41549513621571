import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';

import {fillValue} from './fillValue';
import {NetSheetsLineItemNumber} from './types';

export function convertPercentToAmount(
  netSheetsLineItems: NetSheetsLineItem[],
  salesPrice?: NetSheetsLineItem,
): NetSheetsLineItemNumber[] {
  const salesPriceFillValue = fillValue(salesPrice);

  return netSheetsLineItems
    .map(fillValue)
    .map(item => {
      if (item.isPercentage) {
        return {
          ...item,
          minPercentage: item.minValue,
          maxPercentage: item.maxValue,
          minValue: Math.round(
            (salesPriceFillValue.minValue * item.minValue) / 100,
          ),
          maxValue: Math.round(
            (salesPriceFillValue.maxValue * item.maxValue) / 100,
          ),
        };
      }

      return item;
    })
    .filter(v => !!v.name);
}
