import styled from '@emotion/styled';
import React, {FC} from 'react';

import {font} from '@uc/cx-design-tokens';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {getCityStateZip} from '@/utils/getCityStateZip';

type Props = {
  isSubject?: boolean;
  listing: ProcessedListing | null;
};

export const PropertyTitleBlock: FC<Props> = ({isSubject, listing}) => {
  if (!listing) {
    return null;
  }

  const prettyAddress = listing.location?.prettyAddress ?? '-';
  const cityStateZip = getCityStateZip(listing);

  return (
    <>
      <Address isSubject={isSubject}>{prettyAddress}</Address>
      {!isSubject && (
        <CityStateZip cityStateZip={cityStateZip}>{cityStateZip}</CityStateZip>
      )}
    </>
  );
};

const noCityStateZipMargin = `
  text-align: center;
  margin-bottom: 18px;
`;

const Address = styled.div<{isSubject?: boolean}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${font.sizeBody2};
  font-weight: ${font.weightBold};
  font-family: ${font.familySans};
  letter-spacing: 0.44px;
  ${(props: {isSubject?: boolean}) => props.isSubject && noCityStateZipMargin}
`;

const CityStateZip = styled.div<{cityStateZip: string | null}>`
  font-size: ${font.sizeCaption1};
  letter-spacing: 0.37px;
  ${(props: {cityStateZip?: string | null}) =>
    !props.cityStateZip && noCityStateZipMargin}
`;
