import produce from 'immer';

import {CompsOrder} from '@uc/thrift2npme/dist/cma/cma_models';
import {TranslationLoadListingsResponse} from '@uc/thrift2npme/dist/listing_translation/listing_translation_service';
import {
  loadListings,
  OptionalField,
} from '@uc/thrift2npme/dist/listing_translation/listing_translation_service.ucfetch';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {CUSTOM_LISTING_PREFIX} from '@/constants';
import {setCompsSortOrder, setDisplayListings} from '@/context/ducks/cma';
import {clearTableEdits} from '@/context/ducks/propertyFields';
import {orderCompsBySortOrder} from '@/context/utils';
import {ApiError} from '@/types/ApiError';
import {createReducer} from '../createReducer';
import IStoreState, {ThunkFunction} from '../types/Store';
import IComps from './types/Comps';

export const compsInitalState: IComps = {
  compListings: [],
  isFetching: false,
  error: null,
};

export const COMPS_ACTION_TYPES = {
  FETCH_COMPS: 'FETCH_COMPS',
  FETCH_COMPS_SUCCESS: 'FETCH_COMPS_SUCCESS',
  FETCH_COMPS_FAILURE: 'FETCH_COMPS_FAILURE',
  SET_COMPS: 'SET_COMPS',
} as const;

// ACTION HELPERS
const shouldFetchComps = (
  state: IStoreState,
  currentCompListingIdSHAs: string[],
  listingIdSHAs: string[],
) => {
  if (state.comps.isFetching || !listingIdSHAs || listingIdSHAs.length === 0) {
    return false;
  }

  return (
    new Set(currentCompListingIdSHAs.concat(listingIdSHAs)).size >
    currentCompListingIdSHAs.length
  );
};

// ACTION CREATORS
const fetchComps = () => ({
  type: COMPS_ACTION_TYPES.FETCH_COMPS,
});

const fetchCompsSuccess = (compListings: ProcessedListing[]) => ({
  type: COMPS_ACTION_TYPES.FETCH_COMPS_SUCCESS,
  payload: {compListings},
});

const fetchCompsFailure = (error: ApiError | null) => ({
  type: COMPS_ACTION_TYPES.FETCH_COMPS_FAILURE,
  payload: {error},
});

export const setCompListings = (compListings: ProcessedListing[]) => ({
  type: COMPS_ACTION_TYPES.SET_COMPS,
  payload: {compListings},
});

// THUNKS
export const resetAllComps =
  (): ThunkFunction => async (dispatch, state, getState) => {
    const latestState = getState();
    const compListingIds = latestState.cma.cma.agentInputs?.compIds?.filter(
      (id: string) => !id.startsWith(CUSTOM_LISTING_PREFIX),
    );
    let loadListingsResp: null | TranslationLoadListingsResponse = null;

    if (compListingIds?.length) {
      loadListingsResp = await loadListings({
        listingIdSHAs: compListingIds,
        optionalFieldsToInclude: [
          OptionalField.CONTACT_INFOS,
          OptionalField.LISTING_DETAILS,
        ],
      });
    }

    if (loadListingsResp?.listings?.length === compListingIds?.length) {
      const listings = latestState.cma.cma.agentInputs?.compIds?.map(
        (compId: string) => {
          if (compId.startsWith(CUSTOM_LISTING_PREFIX)) {
            return latestState.cma.cma.agentInputs?.customListings?.find(
              (customListing: ProcessedListing) =>
                customListing.listingIdSHA === compId,
            );
          }
          return loadListingsResp?.listings?.find(
            (listing: ProcessedListing) => listing.listingIdSHA === compId,
          );
        },
      );
      dispatch(setCompListings(listings as ProcessedListing[]));
      dispatch(clearTableEdits());
    }
  };

export const selectCompListing =
  (compListing?: ProcessedListing): ThunkFunction =>
  async (dispatch, state, getState) => {
    const latestState = getState();
    const compListings: ProcessedListing[] = latestState.comps.compListings;
    const compIds = compListings.map(listing => listing?.listingIdSHA);
    if (compIds.indexOf(compListing?.listingIdSHA) > -1) {
      return;
    }
    const fullCompListing = await loadListings({
      listingIdSHAs: [compListing?.listingIdSHA as string],
      optionalFieldsToInclude: [
        OptionalField.CONTACT_INFOS,
        OptionalField.LISTING_DETAILS,
      ],
    });

    if (fullCompListing?.listings?.[0]) {
      const newListings = [...compListings, fullCompListing?.listings?.[0]];
      const compsSortOrder = latestState.cma.cma?.agentInputs?.compsOrder;
      const sortedListings = orderCompsBySortOrder(newListings, compsSortOrder);
      dispatch(setCompListings(sortedListings));
    }
  };

export const deselectCompListing =
  (compListingIdSHA: string): ThunkFunction =>
  (dispatch, state, getState) => {
    const latestState = getState();
    const compListings: ProcessedListing[] = latestState.comps.compListings;
    const {editedDisplayListings, origDisplayListings} =
      latestState.cma.cma?.agentInputs || {};
    if (
      editedDisplayListings?.[compListingIdSHA] ||
      origDisplayListings?.[compListingIdSHA]
    ) {
      const {[compListingIdSHA]: editCompDLToBeRemoved, ...strippedEditedDL} =
        editedDisplayListings || {};
      const {[compListingIdSHA]: origCompDLToBeRemoved, ...strippedOrigDL} =
        origDisplayListings || {};
      dispatch(
        setDisplayListings(
          Object.keys(strippedEditedDL).length ? strippedEditedDL : undefined,
          Object.keys(strippedOrigDL).length ? strippedOrigDL : undefined,
        ),
      );
    }
    const compIds = compListings.map(listing => listing?.listingIdSHA);
    const hasSelectedListing = compIds.indexOf(compListingIdSHA) > -1;
    if (!hasSelectedListing) {
      return;
    }
    const filteredComps = compListings.filter(
      listing => listing?.listingIdSHA !== compListingIdSHA,
    );
    const compsSortOrder = latestState.cma.cma?.agentInputs?.compsOrder;
    const sortedListings = orderCompsBySortOrder(filteredComps, compsSortOrder);
    dispatch(setCompListings(sortedListings));
  };

export const getCompListings =
  (listingIdSHAs: string[]): ThunkFunction =>
  async (dispatch, state) => {
    const currentCompListingIdSHAs = state.comps.compListings.map(
      (listing: ProcessedListing) => listing.listingIdSHA,
    );
    if (
      shouldFetchComps(
        state,
        currentCompListingIdSHAs as string[],
        listingIdSHAs,
      )
    ) {
      dispatch(fetchComps());
      const currentCompListingIdSHAsSet = new Set(currentCompListingIdSHAs);
      const filteredListingIdSHAs = listingIdSHAs.filter(
        listingIdSHA => !currentCompListingIdSHAsSet.has(listingIdSHA),
      );
      try {
        const resp = await loadListings({
          listingIdSHAs: filteredListingIdSHAs,
        });
        dispatch(fetchCompsSuccess(resp.listings ?? []));
      } catch (error) {
        dispatch(fetchCompsFailure(error));
      }
    }
  };

export const insertCompAtPosition =
  (
    compListing: ProcessedListing | undefined,
    insertIdx: number,
  ): ThunkFunction =>
  (dispatch, state, getState) => {
    const latestState = getState();
    const currentComps = latestState.comps.compListings.slice();
    if (
      !compListing ||
      isNaN(insertIdx) ||
      insertIdx < 0 ||
      insertIdx > currentComps.length ||
      currentComps[insertIdx]?.listingIdSHA === compListing?.listingIdSHA
    ) {
      return;
    }

    currentComps.splice(insertIdx, 0, compListing);

    dispatch(setCompListings(currentComps));
  };

export const moveComp =
  (sourceIdx: number, destinationIdx: number): ThunkFunction =>
  (dispatch, state, getState) => {
    const latestState = getState();
    const currentComps = latestState.comps.compListings.slice();
    if (
      isNaN(sourceIdx) ||
      isNaN(destinationIdx) ||
      sourceIdx === destinationIdx ||
      sourceIdx < 0 ||
      sourceIdx > currentComps.length - 1 ||
      destinationIdx < 0 ||
      destinationIdx > currentComps.length - 1
    ) {
      return;
    }
    const currentSortPreference = latestState.cma.cma.agentInputs?.compsOrder;
    if (currentSortPreference !== CompsOrder.MANUAL) {
      dispatch(setCompsSortOrder(CompsOrder.MANUAL));
    }
    // Insert the listing at the new position and shift the comps in-between accordingly
    const [listingToMove] = currentComps.splice(sourceIdx, 1);
    currentComps.splice(destinationIdx, 0, listingToMove);
    dispatch(setCompListings(currentComps));
  };

export const moveCompLeft =
  (compPosition: number): ThunkFunction =>
  dispatch => {
    if (isNaN(compPosition) || compPosition === 0) {
      return;
    }
    dispatch(moveComp(compPosition, compPosition - 1));
  };

export const moveCompRight =
  (compPosition: number): ThunkFunction =>
  (dispatch, state, getState) => {
    const latestState = getState();
    const currentComps = latestState.comps.compListings;
    if (isNaN(compPosition) || compPosition === currentComps.length - 1) {
      return;
    }
    dispatch(moveComp(compPosition, compPosition + 1));
  };

// REDUCERS
const fetchCompsReducer = (state: IComps) =>
  produce(state, draftState => {
    draftState.isFetching = true;
    draftState.error = null;
  });

const fetchCompsSuccessReducer = (
  state: IComps,
  payload: {compListings: ProcessedListing[]},
) =>
  produce(state, draftState => {
    draftState.compListings = state.compListings.concat(payload.compListings);
    draftState.isFetching = false;
    draftState.error = null;
  });

const fetchCompsFailureReducer = (
  state: IComps,
  payload: {error: ApiError | null},
) =>
  produce(state, draftState => {
    draftState.isFetching = false;
    draftState.error = payload.error;
  });

const setCompsReducer = (
  state: IComps,
  payload: {compListings: ProcessedListing[]},
) =>
  produce(state, draftState => {
    draftState.compListings = payload.compListings;
  });

export default createReducer<IComps>({
  [COMPS_ACTION_TYPES.FETCH_COMPS]: fetchCompsReducer,
  [COMPS_ACTION_TYPES.FETCH_COMPS_SUCCESS]: fetchCompsSuccessReducer,
  [COMPS_ACTION_TYPES.FETCH_COMPS_FAILURE]: fetchCompsFailureReducer,
  [COMPS_ACTION_TYPES.SET_COMPS]: setCompsReducer,
});
