import produce from 'immer';

import {createReducer} from '../createReducer';

export type IAllDealIds = string[] | null;

export const ALL_DEAL_IDS_ACTION_TYPES = {
  SET_ALL_DEAL_IDS: 'SET_ALL_DEAL_IDS',
} as const;

export const allDealIdsInitialState: IAllDealIds = null;

// ACTIONS
export const setAllDealIds = (allDealIds: IAllDealIds) => ({
  type: ALL_DEAL_IDS_ACTION_TYPES.SET_ALL_DEAL_IDS,
  payload: allDealIds !== null ? allDealIds : undefined,
});

// REDUCER
export const allDealIdsReducer = createReducer<IAllDealIds>({
  [ALL_DEAL_IDS_ACTION_TYPES.SET_ALL_DEAL_IDS]: (state, allDealIds: string[]) =>
    produce(state, () => allDealIds),
});
