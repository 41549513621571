import styled from '@emotion/styled';
import React from 'react';
import dayjs from 'dayjs';

import {
  StatsName,
  XYChartItem,
} from '@uc/thrift2npme/dist/trend_chart/trend_chart_service.ucfetch';
import {Table, Td, Th, Tr} from '@uc/cx.react';
import {formatPrice} from '@uc/format';

import {IMetric, Metrics} from './constants';
import {StatsValue} from './StatsValue';

interface IMetricTableProps {
  stats: {[key in StatsName]: XYChartItem[]};
  visibleMetrics: StatsName[];
}

export const MetricTable = ({stats, visibleMetrics}: IMetricTableProps) => {
  const metrics =
    Metrics.filter(
      metricObj =>
        stats[metricObj.metric] && visibleMetrics.includes(metricObj.metric),
    ) ?? [];
  return (
    <TableWrapper>
      <Table
        borderStyle="cells"
        borderedHeader={true}
        caption="market report metrics table"
        density="sm"
        layout="auto"
        isLoading={false}
        stripe={false}
      >
        {Object.keys(stats).length && <TableHead />}
        <tbody>
          {metrics.map(metricObj => (
            <TableRow
              key={metricObj.title}
              stats={stats[metricObj.metric]}
              metric={metricObj}
            />
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

const TableHead = () => {
  const currentMonth = dayjs().subtract(1, 'month');
  const previousMonth = dayjs().subtract(2, 'month');
  const previousYear = dayjs().subtract(1, 'year').subtract(1, 'month');
  return (
    <thead>
      <Tr>
        <Th></Th>
        <Th scope="col" align="center">
          {currentMonth.format('MMM YYYY')}
        </Th>
        <Th
          scope="col"
          align="center"
        >{`change from ${previousMonth.format('MMM YYYY')}`}</Th>
        <Th
          scope="col"
          align="center"
        >{`change from ${previousYear.format('MMM YYYY')}`}</Th>
      </Tr>
    </thead>
  );
};

const TableRow = ({stats, metric}: {stats: XYChartItem[]; metric: IMetric}) => {
  const newStats = [...stats];
  const currentMonthValue = newStats?.pop()?.yItem;
  const previousMonthValue = newStats?.pop()?.yItem;
  const previousYearValue = newStats?.pop()?.yItem;

  return (
    <Tr>
      <Th scope="row">{metric.title}</Th>
      <Td align="center">
        {currentMonthValue !== undefined
          ? getCurrentMonthValue(currentMonthValue, metric)
          : '-'}
      </Td>
      <Td align="center">
        {currentMonthValue !== undefined && previousMonthValue !== undefined ? (
          <StatsValue curVal={currentMonthValue} prevVal={previousMonthValue} />
        ) : (
          '-'
        )}
      </Td>
      <Td align="center">
        {currentMonthValue !== undefined && previousYearValue !== undefined ? (
          <StatsValue curVal={currentMonthValue} prevVal={previousYearValue} />
        ) : (
          '-'
        )}
      </Td>
    </Tr>
  );
};

const getCurrentMonthValue = (
  currentMonthValue: number,
  metric: IMetric,
): string | number => {
  const {isMonthsOfInventory, isPrice} = metric;

  if (isMonthsOfInventory) {
    if (currentMonthValue < 10) {
      return currentMonthValue.toFixed(1);
    } else {
      return Math.round(currentMonthValue);
    }
  }

  if (isPrice) {
    return formatPrice(currentMonthValue);
  } else {
    return Math.round(currentMonthValue);
  }
};

const TableWrapper = styled.div`
  margin: 0 0 40px 0;
  text-align: center;
  flex-grow: 1;

  .cx-react-truncatedWrapper,
  .cx-react-td {
    font-weight: 100;
  }

  thead {
    border-top: 0;
  }

  tbody .cx-react-td.cx-react-td.cx-react-td {
    border-left: none;
    border-right: none;
    border-top: none;
  }

  tbody .cx-react-td.cx-react-td:nth-child(2) {
    padding-right: 45px;
  }

  tbody .cx-react-th.cx-react-th {
    border-left: 0;
    border-right: 0;
    border-top: none;
  }

  .cx-react-th.cx-react-th:first-child {
    padding-right: 50px;
  }

  thead {
    .cx-react-th::after {
      display: none;
    }
    .cx-react-th.cx-react-th.cx-react-th {
      text-transform: uppercase;
      border-left: 0;
      border-right: 0;
      border-top: 0;
    }
    .cx-react-th.cx-react-th.cx-react-th:first-child {
      padding-right: 50px;
    }
    .cx-react-th .cx-react-truncatedWrapper {
      overflow: auto;
      text-overflow: initial;
      white-space: initial;
    }
  }
`;
