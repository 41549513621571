import {getDealV2} from '@uc/thrift2npme/dist/deals/deals_service.ucfetch';
import {DealType} from '@uc/thrift2npme/dist/deals/deals_model';

export const getCmaInitialParentDealData = async (dealId: string) => {
  try {
    const {deal} = await getDealV2(dealId, {dealId});
    const dealType =
      deal?.type !== undefined ? dealTypeMap[deal?.type] : undefined;
    const dealStage = deal?.stage?.name;
    return {dealType, dealStage};
  } catch (error) {
    console.error('Error fetching deal data:', error);
    return {dealType: undefined, dealStage: undefined};
  }
};

type DealTypeLabel =
  | 'Seller'
  | 'Buyer'
  | 'Landlord'
  | 'Renter'
  | 'Other'
  | 'Referred Out';
export const dealTypeMap: Record<DealType, DealTypeLabel> = {
  [DealType.BUY]: 'Buyer',
  [DealType.LEASE_RENTAL]: 'Renter',
  [DealType.SELL]: 'Seller',
  [DealType.LEASE_LISTING]: 'Landlord',
  [DealType.REFERRED_OUT]: 'Referred Out',
  [DealType.OTHER]: 'Other',
};
