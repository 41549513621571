import {Filelink, PickerFileMetadata} from 'filestack-js';

import {
  Asset,
  AssetOrigin,
  AssetType,
  FoldersSortableFields,
  FolderType,
} from '@uc/thrift2npme/dist/asset_library/asset_library_models';
import {
  createAsset,
  CreateAssetResponse,
  createFolder,
  getFolders,
  updateAsset,
} from '@uc/thrift2npme/dist/asset_library/asset_library_service.ucfetch';

import {createReducer} from '../createReducer';
import {ActionWithGenericPayload} from './types/ActionWithGenericPayload';
import ISubjectAssets from './types/SubjectAssets';

const PdfMimetype = 'application/pdf';
const GifMimeType = 'image/gif';
const baseFilestackUrl = 'https://cdn.filestackcontent.com';
const sizeOptions = {
  minHeight: 80,
  maxHeight: 280,
  minWidth: 100,
  maxWidth: 320,
};

const getFilestackUrl = (
  filestackHandle: string,
  mimeType: string,
  shouldScaleImageDown = false,
) => {
  const filelink = new Filelink(filestackHandle);

  if (mimeType === PdfMimetype) {
    filelink.output({
      format: 'png',
      density: 96,
    });
  }

  if (shouldScaleImageDown) {
    filelink
      .resize({
        width: sizeOptions?.maxWidth,
      })
      .autoImage()
      .compress();
  }

  return filelink.toString();
};

const getImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (event, source, lineno, colno, error) => reject(error);
    img.src = src;
  });
};

const getAssetDetails = async (file: PickerFileMetadata) => {
  const url = getFilestackUrl(file?.handle, file?.mimetype);

  const {width, height} = await getImage(url);
  const assetDetails = {
    url,
    width,
    height,
    size: file?.size,
  };

  return assetDetails;
};

const convertFilestackFileToAsset = async (
  file: PickerFileMetadata,
  folderId: string,
): Promise<{asset: Asset}> => {
  let origin = AssetOrigin.LOCAL;

  if (file.source === 'googledrive') {
    origin = AssetOrigin.GOOGLE_DRIVE;
  } else if (file.source === 'dropbox') {
    origin = AssetOrigin.DROPBOX;
  }
  const assetDetails = await getAssetDetails(file);
  return {
    asset: {
      name: file.filename,
      origin,
      originalDetails: assetDetails,
      thumbnailDetails: assetDetails,
      mimeType: file.mimetype,
      folderId,
      type: AssetType.INDIVIDUAL,
    },
  };
};

const getFolderId = async () => {
  const request = {
    baseFolder: true,
    filterQuery: {
      folderTypes: [FolderType.INDIVIDUAL],
    },
    sort: [
      {
        orderAsc: false,
        orderBy: FoldersSortableFields.CREATED_AT,
      },
    ],
    paginationFolders: {
      skip: 0,
      limit: 1,
    },
  };
  const response = await getFolders(request);
  let baseFolder = response?.folders?.folders?.[0];
  if (!baseFolder) {
    const createRequest = {
      folder: {
        name: 'baseFolder',
        type: FolderType.INDIVIDUAL,
      },
    };
    const createResponse = await createFolder(createRequest);
    baseFolder = createResponse?.folder;
  }

  return baseFolder;
};

const getFilestackHandleFromUrl = (url: string | undefined) => {
  if (!url) {
    return;
  }

  let parsedUrl;
  try {
    parsedUrl = new URL(url);
  } catch {
    return;
  }

  const filestackHandle = parsedUrl.pathname.slice(1); // remove the leading /
  if (
    parsedUrl.origin !== baseFilestackUrl ||
    filestackHandle.match(/^[a-z0-9]+$/i) === null
  ) {
    return;
  }
  return filestackHandle;
};

const generateThumbnail = async (asset: Asset) => {
  const thumbnailDetails = asset.thumbnailDetails;
  const originalUrl = asset.originalDetails?.url;

  const filestackHandle = getFilestackHandleFromUrl(originalUrl);
  if (!filestackHandle) return;

  const newThumbnailUrl = getFilestackUrl(
    filestackHandle,
    asset.mimeType as string,
    true,
  );
  const {width, height} = await getImage(newThumbnailUrl);

  const newAsset = {
    ...asset,
    thumbnailDetails: {
      ...thumbnailDetails,
      url: newThumbnailUrl,
      width,
      height,
    },
  };
  const updateResponse = await updateAsset(asset.id as string, {
    asset: newAsset,
  });
  return updateResponse?.asset;
};

// Temporary standalone function to create an asset from the Filestack upload
// TODO: Move this into the createSubjectAssets thunk after GQL is removed
export const createSubjectAssetsTemp = async (
  fileUploads: PickerFileMetadata[],
) => {
  const baseFolder = await getFolderId();
  const userFolderId = baseFolder?.id;
  if (!userFolderId) {
    return;
  }
  const assetPromises = fileUploads.map(file => {
    return convertFilestackFileToAsset(file, userFolderId);
  });
  const assetPromisesRes = await Promise.allSettled(assetPromises);

  const assetDrafts = (
    assetPromisesRes.filter(
      response => response.status === 'fulfilled',
    ) as PromiseFulfilledResult<{asset: Asset}>[]
  ).map(response => response.value);

  if (assetDrafts.length <= 0) {
    return;
  }

  const createdAssetPromises = assetDrafts.map(draft => createAsset(draft));
  const settledResults: PromiseSettledResult<CreateAssetResponse>[] =
    await Promise.allSettled(createdAssetPromises);

  const assetValues = (
    settledResults.filter(
      res => res.status === 'fulfilled',
    ) as PromiseFulfilledResult<CreateAssetResponse>[]
  ).map(response => response.value);

  if (assetValues.length === 0) {
    return;
  }

  const assets = assetValues.map(value => value.asset);
  const assetsToGenerateThumbnails = assets.filter(asset => {
    const thumbnailDetails = asset?.thumbnailDetails;
    const originalUrl = asset?.originalDetails?.url;
    if (
      asset?.mimeType === GifMimeType ||
      thumbnailDetails?.url !== originalUrl
    ) {
      return false;
    }

    return true;
  });

  const updatedAssetPromises = await Promise.allSettled(
    assetsToGenerateThumbnails.map(asset => generateThumbnail(asset as Asset)),
  );
  const updatedAssetValues = (
    updatedAssetPromises.filter(
      res => res.status === 'fulfilled',
    ) as PromiseFulfilledResult<Asset | undefined>[]
  ).map(response => response.value);

  const createdAssets: CreateAssetResponse[] = updatedAssetValues.map(asset => {
    return {asset};
  });

  return createdAssets;
};

// DEFAULT STATE
export const subjectAssetsInitialState: ISubjectAssets = {
  assets: [],
  isUploading: false,
  error: null,
};

// ACTION TYPES
export const SUBJECT_ASSETS_ACTION_TYPES = {
  SET_SUBJECT_ASSETS: 'SET_SUBJECT_ASSETS',
  SET_SUBJECT_ASSETS_SUCCESS: 'SET_SUBJECT_ASSETS_SUCCESS',
  SET_SUBJECT_ASSETS_FAIL: 'SET_SUBJECT_ASSETS_FAIL',
  SET_SUBJECT_PHOTO_DEFAULT: 'SET_SUBJECT_PHOTO_DEFAULT',
} as const;

export const setSubjectAssets = () => ({
  type: SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_ASSETS,
});

const setSubjectAssetsSuccess = (assets: CreateAssetResponse[]) => ({
  type: SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_ASSETS_SUCCESS,
  payload: {
    assets,
  },
});

const setSubjectAssetsFail = (error: {error: string}) => ({
  type: SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_ASSETS_FAIL,
  payload: {error},
});

export const setSubjectPhotoDefault = () => ({
  type: SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_PHOTO_DEFAULT,
});

// ACTION CREATORS
export const createSubjectAssets =
  (assetValues: CreateAssetResponse[]) =>
  (dispatch: (action: ActionWithGenericPayload) => void) => {
    if (assetValues.length === 0) {
      dispatch(
        setSubjectAssetsFail({
          error: 'Error uploading. Please try again later',
        }),
      );
      return;
    }
    dispatch(setSubjectAssetsSuccess(assetValues));
  };

// REDUCERS
const setSubjectAssetsReducer = (state: ISubjectAssets) => ({
  ...state,
  isUploading: true,
});

const setSubjectAssetsSuccessReducer = (
  state: ISubjectAssets,
  payload: {assets: CreateAssetResponse[]},
) => {
  return {
    ...state,
    assets: payload.assets
      .map(({asset}) => asset)
      .filter((asset): asset is Asset => !!asset),
    error: null,
    isUploading: false,
  };
};

const setSubjectAssetsFailReducer = (
  state: ISubjectAssets,
  payload: {error: string},
) => ({
  ...state,
  error: payload,
  isUploading: false,
});

const setSubjectPhotoDefaultReducer = (state: ISubjectAssets) => ({
  ...state,
  assets: [],
  error: null,
  isUploading: false,
});

// COMBINED REDUCER
export default createReducer<ISubjectAssets>({
  [SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_ASSETS]: setSubjectAssetsReducer,
  [SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_ASSETS_SUCCESS]:
    setSubjectAssetsSuccessReducer,
  [SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_ASSETS_FAIL]:
    setSubjectAssetsFailReducer,
  [SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_PHOTO_DEFAULT]:
    setSubjectPhotoDefaultReducer,
});
