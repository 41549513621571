import produce from 'immer';

import {
  SearchQuery as ThriftSearchQuery,
  SORT_ORDER,
} from '@uc/thrift2npme/dist/search/search';

import {setSearchQuerySource} from '@/context/ducks/searchQuerySource';
import {setShouldQueryCompsBuilder} from '@/context/ducks/shouldQueryCompsBuilder';
import {createReducer} from '../createReducer';
import {ActionWithGenericPayload} from './types/ActionWithGenericPayload';
import ActionWithPayload from './types/ActionWithPayload';
import ISearchQueryState from './types/SearchQuery';
import {SearchQuerySourceValues} from './types/SearchQuerySource';

export const searchQueryInitialState: ISearchQueryState = {};

// ACTION TYPES
export const SEARCH_QUERY_ACTION_TYPES = {
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SET_SEARCH_QUERY_SORT_ORDER: 'SET_SEARCH_QUERY_SORT_ORDER',
  SET_COMPS_BUILDER_INPUTS: 'SET_COMPS_BUILDER_INPUTS',
} as const;

// ACTION CREATORS
export const setSearchQuery = (
  searchQuery: ISearchQueryState,
): ActionWithPayload<{
  searchQuery: ISearchQueryState;
}> => ({
  type: SEARCH_QUERY_ACTION_TYPES.SET_SEARCH_QUERY,
  payload: {searchQuery},
});

export const setSearchQuerySortOrder = (
  sortOrder: SORT_ORDER,
): ActionWithPayload<{sortOrder: number}> => ({
  type: SEARCH_QUERY_ACTION_TYPES.SET_SEARCH_QUERY_SORT_ORDER,
  payload: {sortOrder},
});

export const setCompsBuilderInputs = (
  cqbId: string,
): ActionWithPayload<{cqbId: string}> => ({
  type: SEARCH_QUERY_ACTION_TYPES.SET_COMPS_BUILDER_INPUTS,
  payload: {
    cqbId,
  },
});

// REDUCERS
const setSearchQueryReducer = (
  _state: ISearchQueryState,
  payload: {searchQuery: ThriftSearchQuery},
) => payload.searchQuery;

const setSearchQuerySortOrderReducer = (
  state: ISearchQueryState,
  payload: {sortOrder: SORT_ORDER},
) =>
  produce(state, draftState => {
    draftState.sortOrder = payload.sortOrder;
  });

// THUNKS
export const updateSearchQuery =
  (
    compsQueryBuilderSearchQuery: ThriftSearchQuery,
    sortOrder: SORT_ORDER | undefined,
    querySource: SearchQuerySourceValues,
  ) =>
  (dispatch: (action: ActionWithGenericPayload) => void) => {
    if (compsQueryBuilderSearchQuery) {
      dispatch(setSearchQuery(compsQueryBuilderSearchQuery));
      if (sortOrder) {
        dispatch(setSearchQuerySortOrder(sortOrder));
      }
      dispatch(setShouldQueryCompsBuilder(false));
      dispatch(setSearchQuerySource(querySource));
    }
  };

// COMBINED REDUCER
export default createReducer<ISearchQueryState>({
  [SEARCH_QUERY_ACTION_TYPES.SET_SEARCH_QUERY]: setSearchQueryReducer,
  [SEARCH_QUERY_ACTION_TYPES.SET_SEARCH_QUERY_SORT_ORDER]:
    setSearchQuerySortOrderReducer,
});
