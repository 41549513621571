import {NetSheetsGeneric} from '@uc/thrift2npme/dist/cma/cma_models';

import {convertPercentToAmount} from './convertPercentToAmount';
import {fillValue} from './fillValue';
import {getNetEquity} from './getNetEquity';
import {getSubtotal} from './getSubtotal';
import {DataResult} from './types';
import {merge} from './utils';
import {valueToString} from './valueToString';

export function genericNetSheetsDataFilter({
  salesPrice = {},
  mortgage = {},
  predefinedFees,
  additionalFees,
}: NetSheetsGeneric): DataResult {
  const salesPriceAndMortgage = merge([salesPrice, mortgage])
    .map(fillValue)
    .filter(item => Boolean(item.minValue))
    .map(valueToString);
  const netEquity = getNetEquity(fillValue(salesPrice), fillValue(mortgage));
  const estimateAndAdditionalFees = convertPercentToAmount(
    merge([predefinedFees, additionalFees]),
    salesPrice,
  ).filter(item => Boolean(item.minValue));
  const subTotal = getSubtotal(estimateAndAdditionalFees);
  const total = {
    minValue: netEquity.minValue - subTotal.minValue,
    maxValue: netEquity.maxValue - subTotal.maxValue,
  };

  return {
    estimateAndAdditionalFees: estimateAndAdditionalFees.map(valueToString),
    salesPriceAndMortgage,
    netEquity: {
      minValue: netEquity.minValue.toString(),
      maxValue: netEquity.maxValue.toString(),
    },
    subTotal: {
      minValue: subTotal.minValue.toString(),
      maxValue: subTotal.maxValue.toString(),
    },
    total: {
      minValue: total.minValue.toString(),
      maxValue: total.maxValue.toString(),
    },
  };
}
