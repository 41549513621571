import React from 'react';
import dayjs from 'dayjs';

import {MarketReportChart} from '@uc/thrift2npme/dist/cma/cma_models';
import {
  ChartName,
  XYChartItem,
} from '@uc/thrift2npme/dist/trend_chart/trend_chart_service.ucfetch';

import {ChartConfigs, IChartConfig, IChartConfigInternal} from './constants';
import {ColumnChart} from './ColumnChart';

interface IMetricChartsProps {
  chartItems: {[key in ChartName]: XYChartItem[]};
  visibleCharts: MarketReportChart[];
}

export const MetricCharts = ({
  chartItems,
  visibleCharts,
}: IMetricChartsProps) => {
  const metricSet = new Set(visibleCharts.map(chart => chart.chartName));
  const labelSet = new Set(visibleCharts.map(chart => chart.label || ''));
  const chartConfigs =
    ChartConfigs.filter(
      config => chartItems[config.metric] && metricSet.has(config.metric),
    ) ?? [];

  return (
    <>
      {chartConfigs.map(config =>
        renderCharts({
          xyChartResponse: chartItems[config.metric],
          chartConfig: config,
          labelSet,
        }),
      )}
    </>
  );
};

const renderCharts = ({
  xyChartResponse,
  chartConfig,
  labelSet,
}: {
  xyChartResponse: XYChartItem[];
  chartConfig: IChartConfig;
  labelSet: Set<string>;
}) => {
  if (chartConfig.internals) {
    const internals = chartConfig.internals.filter(config =>
      labelSet.has(config.label || ''),
    );
    return internals.map(config => {
      const filteredChartResponse = xyChartResponse.filter(
        response => response.label === config.label,
      );
      return renderChart(filteredChartResponse, config);
    });
  }
  return renderChart(xyChartResponse, chartConfig);
};

const renderChart = (
  chartItems: XYChartItem[],
  chartConfig: IChartConfigInternal,
) => {
  return (
    <ColumnChart
      title={chartConfig.title || ''}
      subtitle={chartConfig.description || ''}
      data={chartItems.map(chartData => ({
        xAxis: dayjs(chartData.xItem).format('MMM YY'),
        yAxis: getChartYItem({
          n: chartData.yItem || 0,
          isMonthsOfInventory: !!chartConfig.isMonthsOfInventory,
          multiplier: chartConfig.multiplier,
        }),
      }))}
    />
  );
};

const getChartYItem = ({
  n,
  isMonthsOfInventory,
  multiplier,
}: {
  n: number;
  isMonthsOfInventory: boolean;
  multiplier?: number;
}): number => {
  const num = multiplier ? n * multiplier : n;
  if (isMonthsOfInventory && num < 10) {
    return Number(num.toFixed(1));
  } else {
    return Math.round(num);
  }
};
