import {cloneDeep} from 'lodash';

import {parseInputPriceRange} from '@uc/cma-components';
import {
  AgentType,
  CommissionType,
  EquityInfo,
  LineItem,
  NetSheetsInputs,
  ResponseResult,
} from '@uc/thrift2npme/dist/apex/apex_service';
import {NetSheets, PriceEstimate} from '@uc/thrift2npme/dist/cma/cma_models';

import {getFlatFeeForPercent} from './getFlatFeeForPercent';

type CommissionsResult = {min: LineItem[]; max: LineItem[]};

type CalculationsResult = {
  minCalculations: ResponseResult;
  maxCalculations: ResponseResult;
};

export const calculateCommissions = ({
  netSheetInputs,
  minPrice,
  maxPrice,
}: {
  netSheetInputs: NetSheetsInputs;
  minPrice: number;
  maxPrice: number;
}): CommissionsResult => {
  const {agentCommissions} = netSheetInputs;
  const result: CommissionsResult = {
    min: [],
    max: [],
  };

  agentCommissions?.forEach(agentCommission => {
    const name =
      agentCommission.type === AgentType.BUYER
        ? 'Buyer Commissions'
        : 'Seller Commissions';
    agentCommission.commissions?.forEach(commission => {
      const {min, type, value = '0'} = commission;
      const basisPrice = min ? minPrice : maxPrice;
      result[min ? 'min' : 'max'].push({
        name,
        amount:
          type === CommissionType.PERCENTAGE
            ? getFlatFeeForPercent({
                basisPrice,
                percentageValue: isNaN(Number(value))
                  ? undefined
                  : Number(value),
              })
            : value,
      });
    });
  });

  return result;
};

export const calculateEquityInfo = (
  input: NetSheetsInputs,
  price: number,
): EquityInfo => {
  const {sellerMortgageBalance = '0'} = input;
  return {
    salePrice: String(price),
    mortgageBalance: sellerMortgageBalance,
    netEquity: String(price - Number(sellerMortgageBalance)),
  };
};

export const calculateTotalNetProceeds = (
  calculations: ResponseResult,
): string => {
  const {
    commissions = [],
    fees = [],
    additionalFees = [],
    equityInfo = {},
  } = calculations;
  const allFees = [...commissions, ...fees, ...additionalFees];
  return String(
    Number(equityInfo.netEquity ?? '0') -
      allFees.reduce((sum, next) => sum + Number(next.amount ?? '0'), 0),
  );
};

/**
 * When the input changes, some costs need to be recalculated,
 * including [commissions, equityInfo, totalNetProceeds, additionalFees]
 * @param {NetSheets} netsheet  netsheet object in the cma
 * @param {PriceEstimate} price  listing price, it is a range
 * @returns {CalculationsResult} Costs calculated with reference to minimum and maximum prices
 */
export const calculateNetsheetCosts = (
  netsheet: NetSheets,
  price: PriceEstimate,
): CalculationsResult => {
  // parseInputPriceRange returns `string[]` type
  const [minPrice = '0', maxPrice = '0'] = parseInputPriceRange(price.value);
  const {
    input = {},
    minResults = {},
    maxResults = {},
  } = cloneDeep(netsheet) ?? {};
  const minCalculations = minResults.calculations ?? {};
  const maxCalculations = maxResults.calculations ?? {};

  // calculate `commissions`
  const {min: minCommissions, max: maxCommissions} = calculateCommissions({
    netSheetInputs: input,
    minPrice: Number(minPrice),
    maxPrice: Number(maxPrice),
  });

  // calculate `equityInfo`
  const [minEquity, maxEquity] = [
    calculateEquityInfo(input, Number(minPrice)),
    calculateEquityInfo(input, Number(maxPrice)),
  ];

  // calculate `addtionalFees`
  const additionalFees: LineItem[] = [];
  input.closingFees?.forEach(
    ({name, value}) =>
      name && value && additionalFees.push({name, amount: value}),
  );

  minCalculations.commissions = minCommissions;
  minCalculations.equityInfo = minEquity;
  minCalculations.additionalFees = additionalFees;
  minCalculations.totalNetProceeds = calculateTotalNetProceeds(minCalculations);

  maxCalculations.commissions = maxCommissions;
  maxCalculations.equityInfo = maxEquity;
  maxCalculations.additionalFees = additionalFees;
  maxCalculations.totalNetProceeds = calculateTotalNetProceeds(maxCalculations);

  return {
    minCalculations,
    maxCalculations,
  };
};
