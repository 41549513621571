import styled from '@emotion/styled';
import groupBy from 'lodash.groupby';
import React, {FC} from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';

import {displayStatuses, statusOrder} from '@/utils/listingStatusMaps';

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: ${spacing['8x']};
  padding-left: 50px;
`;

const LegendKey = styled.div<{isFirst: boolean}>`
  display: flex;
  align-items: center;
  padding-left: ${({isFirst}) => (isFirst ? 0 : spacing['2x'])};
  color: ${color.textSubtle};
`;

const Label = styled.span`
  font-size: ${font.sizeCaption1};
  font-family: ${font.familyLegible};
  font-weight: ${font.weightSemiBold};
  color: ${color.text};
  margin-right: ${spacing.half};
`;

const Circle = styled.div<{color?: string}>`
  background: ${props => props.color || color.black};
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin-right: ${spacing['1x']};

  @media ${mq.maxWidthMobileLandscape} {
    height: 16px;
    width: 16px;
    margin-right: ${spacing.half};
  }
`;

type Props = {
  data: Array<{status: {label: string}}>;
};

export const ChartLegend: FC<Props> = ({data}) => {
  const listingsByStatus = groupBy(data, l => l.status.label);
  return (
    <Legend>
      {statusOrder.map(
        (key, idx) =>
          listingsByStatus[key]?.length && (
            <LegendKey key={`legend-${key}`} isFirst={idx === 0}>
              <Circle color={displayStatuses[key.toLowerCase()].color} />
              <Label>{key.toUpperCase()}</Label>(
              {listingsByStatus[key].length})
            </LegendKey>
          ),
      )}
    </Legend>
  );
};
