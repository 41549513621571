import {color} from '@uc/cx-design-tokens';
import {getListingPrice, formatPrice} from '@uc/format';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

const ASSETS_PREFIX =
  'https://www.compass.com/ucfe-assets/listing-presentation/' +
  'eks-2020-03-03-16-30-869bc8f/';

export const getSubjectMarkerIcon = (withPulse: boolean) => ({
  url: `${ASSETS_PREFIX}${withPulse ? '' : 'no'}pulse.svg`,
  anchor: new window.google.maps.Point(220, 220),
  scaledSize: new window.google.maps.Size(440, 440),
});

export const getCompMarkerIcon = (listing: ProcessedListing) => {
  const listingStatusBubbleIndexMap: Record<string, number> = {
    Active: 1,
    Sold: 2,
    Pending: 3,
  };
  // make red bubble svg default for all other property status
  const index =
    (listing.localizedStatus &&
      listingStatusBubbleIndexMap[listing.localizedStatus]) ||
    2;
  return {
    url: `${ASSETS_PREFIX}bubble${index}.svg`,
    scaledSize: new window.google.maps.Size(100, 50),
  };
};

export const getListingCenter = (listing?: ProcessedListing) => ({
  lat: listing?.location?.latitude ?? 0,
  lng: listing?.location?.longitude ?? 0,
});

export const getCompMarkerIconUrl = (listing: ProcessedListing) => {
  const listingStatusBubbleIndexMap: Record<string, number> = {
    Active: 1,
    Sold: 2,
    Pending: 3,
  };
  const index =
    (listing.localizedStatus &&
      listingStatusBubbleIndexMap[listing.localizedStatus]) ||
    2;
  return `${ASSETS_PREFIX}bubble${index}.svg`;
};

export const computeBbox = (
  compListings: ProcessedListing[],
  subjectProperty: ProcessedListing,
) => {
  let maxDiffLat = 0;
  let maxDiffLng = 0;
  const latLngMap: string[] = [];
  const subjectPosition = getListingCenter(subjectProperty);
  compListings
    .filter(({location}) => location?.latitude && location.longitude)
    .forEach((listing, i) => {
      const position = getListingCenter(listing);
      let latLngKey = `${position.lat}-${position.lng}`;
      // Offset the lat/lng for comps of the same position slightly
      // Display comps in a circular fashion around a small radius for gmaps
      if (latLngMap.includes(latLngKey)) {
        const angle = 360.0 / compListings.length;
        position.lat =
          position.lat + -0.00004 * Math.cos(((+angle * i) / 180) * Math.PI);
        position.lng =
          position.lng + -0.00004 * Math.sin(((+angle * i) / 180) * Math.PI);
        latLngKey = `${position.lat}-${position.lng}`;
      }
      latLngMap.push(latLngKey);

      const diffLat = Math.abs(position.lat - subjectPosition.lat);
      const diffLng = Math.abs(position.lng - subjectPosition.lng);

      maxDiffLat = diffLat > maxDiffLat ? diffLat : maxDiffLat;
      maxDiffLng = diffLng > maxDiffLng ? diffLng : maxDiffLng;
    });

  maxDiffLat = maxDiffLat < 0.005 ? 0.005 : maxDiffLat;
  maxDiffLng = maxDiffLng < 0.005 ? 0.005 : maxDiffLng;

  return [
    subjectPosition.lng - maxDiffLng,
    subjectPosition.lat - maxDiffLat,
    subjectPosition.lng + maxDiffLng,
    subjectPosition.lat + maxDiffLat,
  ];
};

export const getCompLabel = (listing: ProcessedListing) => {
  const listingStatusTextColorMap: Record<string, string> = {
    Active: color.onDarkText,
    Sold: color.onDarkText,
    Pending: color.orange90,
  };
  const newColor =
    (listing.localizedStatus &&
      listingStatusTextColorMap[listing.localizedStatus]) ||
    color.onDarkText;
  const listingPrice = getListingPrice(listing);
  return {
    text:
      (typeof listingPrice !== 'undefined' &&
        formatPrice(listingPrice, {
          abbreviateMillions: true,
          abbreviateThousands: true,
          round: true,
          currencySymbol: '',
        })) ||
      'N/A',
    color: newColor,
    fontFamily: 'Compass Sans, Helvetica, Arial, sans-serif',
    fontWeight: '700',
  };
};
