import get from 'lodash.get';

import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

export const getPricePerSqft = (listing?: ProcessedListing): string => {
  const path = 'price.perSquareFoot';
  const value = Number(get(listing, path, '-'));
  if (isNaN(value)) {
    return '-';
  }
  return formatPriceNumber(value);
};

export const formatPriceNumber = (price: number) => {
  const formattedPrice = Math.round(price).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formattedPrice;
};
