import {NetSheetsGeneric} from '@uc/thrift2npme/dist/cma/cma_models';

import {getError} from './utils';

export function getValidateFieldsError(
  generic: NetSheetsGeneric,
): string | undefined {
  const {salesPrice, mortgage, predefinedFees, additionalFees} = generic;
  const list = [salesPrice, mortgage, predefinedFees, additionalFees].flat();
  const errors = list.map(getError).filter(Boolean);

  return errors[0];
}
