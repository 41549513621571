import {RefObject} from 'react';

export const scrollContainerToElement = (
  containerRef?: RefObject<HTMLElement>,
  elementRef?: RefObject<HTMLElement | null>,
) => {
  if (elementRef?.current) {
    containerRef?.current?.scrollTo({
      top: elementRef.current.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  }
};
