const placeholder = encodeURIComponent(`
<svg width="40" height="40" viewBox="0 0 40 40" fill="white" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#171717" stroke-width="1" >
        <circle cx="20" cy="20" r="19.5" vector-effect="non-scaling-stroke" />
        <circle cx="20" cy="14" r="6" vector-effect="non-scaling-stroke" />
        <path d="M 10 30 A 10 10 0 0 1 30 30" vector-effect="non-scaling-stroke" />
    </g>
</svg>
`);

export const agentAvatarSrc = `data:image/svg+xml;charset=UTF-8,${placeholder}`;
