import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import type {Media} from '@uc/thrift2npme/dist/translation_common/media';

import type {State} from './store';

export const SET_MODAL_LISTING_INDEX = 'SET_MODAL_LISTING_INDEX';
type SET_MODAL_LISTING_INDEX = typeof SET_MODAL_LISTING_INDEX;

export const SET_PHOTO_VIEWER_MODAL_LISTING = 'SET_PHOTO_VIEWER_MODAL_LISTING';
type SET_PHOTO_VIEWER_MODAL_LISTING = typeof SET_PHOTO_VIEWER_MODAL_LISTING;

export const SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX =
  'SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX';
type SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX =
  typeof SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX;

export const SET_PHOTO_VIEWER_MODAL_PHOTOS = 'SET_PHOTO_VIEWER_MODAL_PHOTOS';
type SET_PHOTO_VIEWER_MODAL_PHOTOS = typeof SET_PHOTO_VIEWER_MODAL_PHOTOS;

export const setModalListingIndex = (
  modalListingIndex: number | null,
): {
  type: SET_MODAL_LISTING_INDEX;
  payload: {modalListingIndex: number | null};
} => ({
  type: SET_MODAL_LISTING_INDEX,
  payload: {modalListingIndex},
});

export const setPhotoViewerModalListing = (
  photoViewerModalListing: ProcessedListing | null,
): {
  type: SET_PHOTO_VIEWER_MODAL_LISTING;
  payload: {photoViewerModalListing: ProcessedListing | null};
} => ({
  type: SET_PHOTO_VIEWER_MODAL_LISTING,
  payload: {photoViewerModalListing},
});

export const setPhotoViewerModalPhotoIndex = (
  photoViewerModalPhotoIndex: number | null,
): {
  type: SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX;
  payload: {photoViewerModalPhotoIndex: number | null};
} => ({
  type: SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX,
  payload: {photoViewerModalPhotoIndex},
});

export const setPhotoViewerModalPhotos = (
  photoViewerModalPhotos: Media[] | null,
): {
  type: SET_PHOTO_VIEWER_MODAL_PHOTOS;
  payload: {photoViewerModalPhotos: Media[] | null};
} => ({
  type: SET_PHOTO_VIEWER_MODAL_PHOTOS,
  payload: {photoViewerModalPhotos},
});

export type Action =
  | ReturnType<typeof setPhotoViewerModalListing>
  | ReturnType<typeof setModalListingIndex>
  | ReturnType<typeof setPhotoViewerModalPhotoIndex>
  | ReturnType<typeof setPhotoViewerModalPhotos>;

export const modalListingIndexReducer = (state: State, action: Action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_MODAL_LISTING_INDEX: {
      const {modalListingIndex} = payload;
      return {
        ...state,
        modalListingIndex,
      };
    }
    case SET_PHOTO_VIEWER_MODAL_LISTING: {
      const {photoViewerModalListing} = payload;
      return {
        ...state,
        photoViewerModalListing,
      };
    }
    case SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX: {
      const {photoViewerModalPhotoIndex} = payload;
      return {
        ...state,
        photoViewerModalPhotoIndex,
      };
    }
    case SET_PHOTO_VIEWER_MODAL_PHOTOS: {
      const {photoViewerModalPhotos} = payload;
      return {
        ...state,
        photoViewerModalPhotos,
      };
    }
    default:
      console.log('action type not found: ', type);
      return state;
  }
};
