import {PageZoomLevel} from '../utils/';

export const PAGE_CONTAINER_WIDTH_PADDING = 20;
export const FIRST_PAGE_ACTION_HEIGHT = 40;
export const CANVAS_PADDING_HEIGHT = 20;

const containerMargin = 15;
const widthPadding = PAGE_CONTAINER_WIDTH_PADDING * 2;

const PREVIEW_CONTAINER_HEIGHT = 908;
const PREVIEW_CONTAINER_WIDTH = 620;

export function calculateFitZoomLevel(
  pageHeight: number | undefined,
  pageWidth: number | undefined,
  knownContainerHeight?: number,
  knownContainerWidth?: number,
) {
  if (!pageHeight || !pageWidth) {
    return 1;
  }

  // adjustment for absolutely positioned validation poptips which have not been rendered yet
  let containerHeight = PREVIEW_CONTAINER_HEIGHT;
  let containerWidth = PREVIEW_CONTAINER_WIDTH;

  if (knownContainerHeight && knownContainerWidth) {
    containerHeight = knownContainerHeight;
    containerWidth = knownContainerWidth;
  }

  const verticalScale =
    (containerHeight -
      CANVAS_PADDING_HEIGHT -
      FIRST_PAGE_ACTION_HEIGHT -
      containerMargin * 2) /
    pageHeight;
  const horizontalScale = (containerWidth - widthPadding) / pageWidth;
  const zoomFactor = Math.min(horizontalScale, verticalScale);
  return Math.min(Math.max(zoomFactor, 0), 3);
}

export function calculateFillZoomLevel(
  pageWidth: number | undefined,
  knownContainerWidth?: number,
) {
  let containerWidth;

  if (knownContainerWidth) {
    containerWidth = knownContainerWidth;
  }

  if (!pageWidth || !containerWidth) {
    return 1;
  }

  const zoomFactor = (containerWidth - widthPadding) / pageWidth;
  return Math.min(Math.max(zoomFactor, 0), 3);
}

export function getScalingFactor(
  zoomLevel: PageZoomLevel,
  calculatedZoomLevel: number,
) {
  if (
    zoomLevel &&
    zoomLevel !== PageZoomLevel.Fit &&
    zoomLevel !== PageZoomLevel.Fill
  ) {
    return zoomLevel;
  }

  return calculatedZoomLevel;
}
