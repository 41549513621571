/**
 * Utility to assist setting fields in the store with convenient type checking.
 * This works around problems with properties being defined everywhere as optional
 * in thrift.
 * @param {object|array} object containing object to be modifed
 * @param {string} property the property to set
 * @param {*} value the value to assign
 */
const setStoreField = <T, U extends keyof Required<NonNullable<T>>>(
  object: T,
  property: U,
  value: Required<NonNullable<T>>[U] | undefined,
) => {
  if (object) {
    (object as NonNullable<typeof object>)[property] = value as NonNullable<
      typeof value
    >;
  }
};

export default setStoreField;
