import styled from '@emotion/styled';
import React from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';
import {CmaOutputSection} from '@uc/thrift2npme/dist/cma/cma_models';

import {PropertyAgentRemarks} from '@/pages/Web/components/propertyAgentRemarks/propertyAgentRemarks';
import {PropertyDetailForCmaLp} from '@/pages/Web/components/propertyDetailForCmaLp/propertyDetailForCmaLp';
import {PropertyGeneralInfoForCmaLp} from '@/pages/Web/components/propertyGeneralInfoForCmaLp/propertyGeneralInfoForCmaLp';
import {PropertyHeadline} from '@/pages/Web/components/propertyHeadline/propertyHeadline';
import {useStateValue} from '../../../context/state';

const SubjectFlexContainer = styled.div`
  display: flex;
  background-color: ${color.background};

  @media ${mq.maxWidthTabletLandscape} {
    flex-direction: column;
  }

  @media ${mq.maxWidthMobileLandscape} {
    height: auto;
    width: 100%;
    padding-bottom: ${spacing['3x']};
  }
`;

const ListingPhoto = styled.img`
  display: none;

  @media ${mq.maxWidthMobileLandscape} {
    display: flex;
    width: 100%;
    margin-bottom: ${spacing['3x']};
  }
`;

const Subject = styled.div`
  width: calc(100vw - 350px);
  padding: 40px;

  @media ${mq.maxWidthTabletLandscape} {
    width: 100vw;
  }

  @media ${mq.maxWidthMobileLandscape} {
    padding: 0;
  }
`;

const Title = styled.div`
  font-size: ${font.sizeBody2};
  font-family: ${font.familySans};
  font-weight: ${font.weightBold};
  text-transform: uppercase;

  @media ${mq.maxWidthMobileLandscape} {
    text-align: center;
  }
`;

const StyledPropertyHeadLine = styled(PropertyHeadline)`
  margin-bottom: 20px;

  @media ${mq.maxWidthMobileLandscape} {
    margin-bottom: 0;
  }
`;

const StyledPropertyGeneralInfoForCmaLp = styled(PropertyGeneralInfoForCmaLp)`
  padding-left: 0;
  @media ${mq.maxWidthMobileLandscape} {
    margin-bottom: ${spacing['1x']};
    padding-left: ${spacing['2x']};
  }
`;

const SubjectDetail = styled.div`
  width: 350px;
  padding: 20px;

  @media ${mq.maxWidthTabletLandscape} {
    width: 100vw;
  }

  @media ${mq.maxWidthMobileLandscape} {
    padding: 0 ${spacing['2x']};
  }
`;

export const SubjectSection = () => {
  const [{subjectProperty, agentInputs = {}, allDisplayFields}] =
    useStateValue();

  const {
    presentationalSettings = {},
    assets,
    selectListingAttributes,
  } = agentInputs;
  const {visibleSections = []} = presentationalSettings;
  const section =
    visibleSections.find(
      visibleSection =>
        visibleSection.sectionType === CmaOutputSection.SUBJECT_DETAILS,
    ) || {};
  const subjectPropertyDetails = section.subjectPropertyDetailsSection || {};

  const {
    hiddenPropertyInformation = [],
    hiddenTransactionInformation = [],
    hideListingDescription,
  } = subjectPropertyDetails;

  const asset = assets?.[0];
  const subjectCoverPhotoUrl =
    asset?.originalDetails?.url || subjectProperty?.media?.[0]?.originalUrl;

  return (
    <SubjectFlexContainer>
      {subjectCoverPhotoUrl ? (
        <ListingPhoto src={subjectCoverPhotoUrl} />
      ) : null}
      <Subject>
        <Title>Subject Property</Title>
        <StyledPropertyHeadLine listingDetail={subjectProperty} />
        <StyledPropertyGeneralInfoForCmaLp
          hiddenPropertyInformation={hiddenPropertyInformation}
          listing={subjectProperty}
          selectListingAttributes={selectListingAttributes}
          allDisplayFields={allDisplayFields}
          topBorder
        />
        {!hideListingDescription && (
          <PropertyAgentRemarks remarks={subjectProperty?.description} />
        )}
      </Subject>
      <SubjectDetail>
        <PropertyDetailForCmaLp
          hiddenTransactionInformation={hiddenTransactionInformation}
          listing={subjectProperty}
          columns={1}
        />
      </SubjectDetail>
    </SubjectFlexContainer>
  );
};
