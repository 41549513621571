import styled from '@emotion/styled';
import React from 'react';

import {font, mq, spacing} from '@uc/cx-design-tokens';
import {getListingPrice, formatPrice} from '@uc/format';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

const PropertyDetailTitle = styled.div<{showPrice: boolean}>`
  font-size: ${font.sizeHeader3};
  font-weight: ${font.weightMedium};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeSubheader1};
    text-align: ${({showPrice}) => !showPrice && 'center'};
    padding-bottom: ${spacing['1x']};
  }
`;

const SubtitleWrapper = styled.div<{showPrice: boolean}>`
  display: flex;
  justify-content: space-between;

  @media ${mq.maxWidthMobileLandscape} {
    justify-content: ${({showPrice}) => !showPrice && 'center'};
    padding-bottom: ${spacing['2x']};
  }
`;

const PropertyDetailSubTitle = styled.div<{showPrice: boolean}>`
  display: flex;
  font-size: ${font.sizeBody1};

  @media ${mq.maxWidthMobileLandscape} {
    display: block;
    font-size: ${font.sizeBody2};
    text-align: ${({showPrice}) => !showPrice && 'center'};
    font-size: ${({showPrice}) => showPrice && font.sizeBody2};
  }
`;

const PropertyPrice = styled.div`
  font-size: ${font.sizeBody1};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeBody2};
  }
`;

type Props = {
  className?: string;
  isSubject?: boolean;
  listingDetail?: ProcessedListing;
  showPrice?: boolean;
};

export const PropertyHeadline = ({
  listingDetail,
  isSubject,
  showPrice = false,
  className,
}: Props) => (
  <div className={className}>
    <PropertyDetailTitle showPrice={showPrice}>
      {listingDetail?.location?.prettyAddress}
    </PropertyDetailTitle>
    <SubtitleWrapper showPrice={showPrice}>
      <PropertyDetailSubTitle showPrice={showPrice}>
        {listingDetail?.location?.city},&nbsp;
        {listingDetail?.location?.state}&nbsp;
        {listingDetail?.location?.zipCode}
      </PropertyDetailSubTitle>
      {showPrice && !isSubject && (
        <PropertyPrice>
          {formatPrice((listingDetail && getListingPrice(listingDetail)) || '')}
        </PropertyPrice>
      )}
    </SubtitleWrapper>
  </div>
);
