import type {Person} from '@uc/thrift2npme/dist/people/people_models';
import type {
  Metadata,
  AgentInputs,
  Cma,
} from '@uc/thrift2npme/dist/cma/cma_models';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import type {Media} from '@uc/thrift2npme/dist/translation_common/media';
import type {OptyState} from '@uc/opty';

import {type AllDisplayFieldsType} from '@/types/types';
import {agentReducer, Action as AgentAction, SET_AGENT} from './agent';
import {
  agentInputsReducer,
  SET_AGENT_INPUTS,
  Action as AgentInputsAction,
} from './agentInputs';
import {
  allDisplayFieldsReducer,
  SET_ALL_DISPLAY_FIELDS,
  Action as AllDisplayFieldsAction,
} from './allDisplayFields';
import {
  compListingsReducer,
  SET_COMP_LISTINGS,
  Action as CompListingsAction,
} from './compListings';
import {
  SET_LAYOUT_KEY,
  Action as CompSectionAction,
  compSectionReducer,
} from './compSection';
import dataFetchingReducer, {
  Action as DataFetchingStatesAction,
  SET_ERROR,
  SET_LOADING,
} from './ducks/data-fetching-states';
import {
  modalListingIndexReducer,
  SET_MODAL_LISTING_INDEX,
  SET_PHOTO_VIEWER_MODAL_LISTING,
  SET_PHOTO_VIEWER_MODAL_PHOTOS,
  SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX,
  Action as ModalsAction,
} from './modals';
import {
  SET_NAV_EXPANDED,
  Action as NavigationAction,
  navigationReducer,
} from './navigation';
import {
  SET_SUBJECT_PROPERTY,
  Action as SubjectPropertyAction,
  subjectPropertyReducer,
} from './subjectProperty';

export type State = {
  loading: boolean;
  error: boolean;
  agent: Person;
  CMAMetaData: Metadata;
  comparablesLayoutKey: string;
  modalListingIndex: number | null;
  photoViewerModalListingIndex: number | null;
  photoViewerModalPhotoIndex: number | null;
  photoViewerModalPhotos: Media[] | null;
  photoViewerModalListing: ProcessedListing | null;
  compListings: ProcessedListing[];
  isNavExpanded: boolean;
  subjectProperty: ProcessedListing;
  agentInputs: AgentInputs;
  opty: OptyState;
  cma: Cma;
  allDisplayFields: AllDisplayFieldsType;
  staticDir: string;
};

export const defaultState: State = {
  loading: false,
  error: false,
  agent: {},
  CMAMetaData: {},
  comparablesLayoutKey: 'columns',
  modalListingIndex: null,
  photoViewerModalListingIndex: null,
  photoViewerModalPhotoIndex: null,
  photoViewerModalPhotos: null,
  photoViewerModalListing: null,
  compListings: [],
  isNavExpanded: false,
  subjectProperty: {},
  agentInputs: {},
  opty: {},
  cma: {},
  allDisplayFields: {},
  staticDir: '',
};

export type Action =
  | DataFetchingStatesAction
  | AgentAction
  | AgentInputsAction
  | AllDisplayFieldsAction
  | CompListingsAction
  | CompSectionAction
  | ModalsAction
  | NavigationAction
  | SubjectPropertyAction;

export const reducer = (state: State, action: Action) => {
  const {type} = action;
  if (type === SET_ERROR || type === SET_LOADING) {
    return dataFetchingReducer(state, action);
  }
  if (type === SET_AGENT) {
    return agentReducer(state, action);
  }
  if (type === SET_AGENT_INPUTS) {
    return agentInputsReducer(state, action);
  }
  if (type === SET_SUBJECT_PROPERTY) {
    return subjectPropertyReducer(state, action);
  }
  if (
    type === SET_MODAL_LISTING_INDEX ||
    type === SET_PHOTO_VIEWER_MODAL_LISTING ||
    type === SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX ||
    type === SET_PHOTO_VIEWER_MODAL_PHOTOS
  ) {
    return modalListingIndexReducer(state, action);
  }
  if (type === SET_COMP_LISTINGS) {
    return compListingsReducer(state, action);
  }
  if (type === SET_LAYOUT_KEY) {
    return compSectionReducer(state, action);
  }
  if (type === SET_NAV_EXPANDED) {
    return navigationReducer(state, action);
  }
  if (type === SET_ALL_DISPLAY_FIELDS) {
    return allDisplayFieldsReducer(state, action);
  }
  throw `action type not found: ${type}`;
};
