import {NetSheetsLineItemNumber} from './types';

export function getSubtotal(
  netSheetsLineItems: NetSheetsLineItemNumber[],
): NetSheetsLineItemNumber {
  return netSheetsLineItems.reduce(
    ({minValue, maxValue}, item) => ({
      minValue: minValue + item.minValue,
      maxValue: maxValue + item.maxValue,
    }),
    {
      minValue: 0,
      maxValue: 0,
    },
  );
}
