import {Adjustment} from '@uc/thrift2npme/dist/valuation/ai_valuation/adjustments';
import {Person} from '@uc/thrift2npme/dist/people/api/person';
import {AgentInfo} from '@uc/thrift2npme/dist/cma/cma_models';

export const getDisplayImageFromThumbnailUrl = (thumbailUrl?: string) => {
  return thumbailUrl?.replace('165x165', '640x480');
};

export const getAdjustedPrice = (price = 0, adjustments: Adjustment[] = []) => {
  if (!adjustments?.length) {
    return price;
  }
  return adjustments.reduce((totalPrice, adjustment) => {
    return totalPrice + (adjustment.value || 0);
  }, price);
};

export const formatAgentInfoPhone = (phone: string): string => {
  const cleanedPhone = phone.replace(/\D/g, '');
  const match = cleanedPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match.slice(1, 4).join('.');
  }
  return phone;
};

export const getAgentInfoFromPerson = (person: Person): AgentInfo => {
  const {
    personId,
    displayName,
    avatarUrl,
    email,
    profile = {},
    phone = '',
  } = person;
  const phoneList = phone.split('||').map(item => formatAgentInfoPhone(item));
  const agentTitle = profile.agent?.businessTitle || profile.agent?.title;
  return {
    personId,
    name: displayName,
    imageUrl: avatarUrl,
    info: [agentTitle, email, ...phoneList].filter(Boolean).join('\n'),
  };
};
