import {State} from './store';
import {AllDisplayFieldsType} from '@/types/types';

export const SET_ALL_DISPLAY_FIELDS = 'SET_ALL_DISPLAY_FIELDS';
type SET_ALL_DISPLAY_FIELDS = typeof SET_ALL_DISPLAY_FIELDS;

export const setAllDisplayFields = (
  allDisplayFields: AllDisplayFieldsType,
): {
  type: SET_ALL_DISPLAY_FIELDS;
  payload: {allDisplayFields: AllDisplayFieldsType};
} => ({
  type: SET_ALL_DISPLAY_FIELDS,
  payload: {allDisplayFields},
});

export type Action = ReturnType<typeof setAllDisplayFields>;

export const allDisplayFieldsReducer = (state: State, action: Action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_ALL_DISPLAY_FIELDS: {
      const {allDisplayFields} = payload;
      return {
        ...state,
        allDisplayFields,
      };
    }
    default:
      console.log('display fields not found: ', type);
      return state;
  }
};
