import styled from '@emotion/styled';
import React, {useRef, useState, useEffect} from 'react';

import {mq} from '@uc/cx-design-tokens';

import {SectionTitle} from '@/pages/Web/components/sectionTitle/sectionTitle';
import {useStateValue} from '../../../context/state';
import {MapLockToggleButton} from './mapLockToggleButton';
import {ComparableMap} from './ComparableMap';

const MapContainer = styled.div`
  height: calc(100vh - 360px);
  overflow: auto;
  position: relative;

  @media ${mq.maxWidthMobileLandscape} {
    height: calc(100vh - 240px);
    width: 100%;
  }
`;

export const MapSection = () => {
  const [{subjectProperty = {}, compListings}] = useStateValue();
  const [mapIsLocked, setMapIsLocked] = useState(true);
  const mapContainer = useRef<HTMLDivElement>(null);
  const comparableMapRef = useRef<{
    resetMap: (size: {height: number; width: number}) => void;
    setOptions: (options: google.maps.MapOptions) => void;
  }>(null);

  const toggleMapLock = () => {
    comparableMapRef.current?.setOptions({
      gestureHandling: !mapIsLocked ? 'none' : 'auto',
    });
    setMapIsLocked(!mapIsLocked);
  };

  useEffect(() => {
    const handleResize = () => {
      if (mapContainer.current) {
        const {height, width} = mapContainer.current.getBoundingClientRect();
        comparableMapRef.current?.resetMap({height, width});
      }
    };
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <>
      <SectionTitle
        title="Comparables"
        subtitle={`(${compListings.length} listings)`}
      />

      <MapContainer ref={mapContainer}>
        <ComparableMap
          compListings={compListings}
          subjectProperty={subjectProperty}
          ref={comparableMapRef}
        />
        <MapLockToggleButton
          mapIsLocked={mapIsLocked}
          onClick={toggleMapLock}
        />
      </MapContainer>
    </>
  );
};
