import produce from 'immer';

import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import {RelationCluster} from '@uc/thrift2npme/dist/user_listing_relation/user_listing_relation_service';

import {createReducer} from '../createReducer';
import ISearchResults from './types/SearchResults';

// DEFAULT STATE
export const searchResultsInitialState: ISearchResults = {
  listings: [],
  mapClusters: [],
  totalNumResults: 0,
};

// ACTION TYPES
export const SEARCH_RESULTS_ACTION_TYPES = {
  SEARCH_RESULTS_SET: 'SEARCH_RESULTS_SET',
  SEARCH_RESULTS_SET_CLUSTERS: 'SEARCH_RESULTS_SET_CLUSTERS',
} as const;

// ACTION CREATORS
export const setSearchResults = (
  listings: ProcessedListing[],
  totalNumResults: number,
) => ({
  type: SEARCH_RESULTS_ACTION_TYPES.SEARCH_RESULTS_SET,
  payload: {listings, totalNumResults},
});

export const setSearchResultsClusters = (mapClusters: RelationCluster[]) => ({
  type: SEARCH_RESULTS_ACTION_TYPES.SEARCH_RESULTS_SET_CLUSTERS,
  payload: {mapClusters},
});

// REDUCERS
const setSearchResultsReducer = (
  state: ISearchResults,
  payload: {
    listings: ProcessedListing[];
    totalNumResults: number;
  },
) =>
  produce(state, draftState => {
    draftState.listings = payload.listings;
    draftState.totalNumResults = payload.totalNumResults;
  });

const setSearchResultsClustersReducer = (
  state: ISearchResults,
  payload: {mapClusters: RelationCluster[]},
) =>
  produce(state, draftState => {
    draftState.mapClusters = payload.mapClusters;
  });

// COMBINED REDUCER
export default createReducer<ISearchResults>({
  [SEARCH_RESULTS_ACTION_TYPES.SEARCH_RESULTS_SET]: setSearchResultsReducer,
  [SEARCH_RESULTS_ACTION_TYPES.SEARCH_RESULTS_SET_CLUSTERS]:
    setSearchResultsClustersReducer,
});
