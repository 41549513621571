import React from 'react';
import styled from '@emotion/styled';

import {color, font, spacing} from '@uc/cx-design-tokens';

import {NetsheetLineItem} from '@/components/Netsheet/NetsheetLineItem';
import {getAmount} from '@/utils/netsheet-utils';
import {GroupProps} from '@/components/Netsheet/NetsheetSection';

const Section = styled.div`
  margin-top: ${spacing['4x']};
  &:nth-of-type(1) {
    margin-top: 20px;
  }
`;

const SubHeader = styled.div`
  color: ${color.text};
  font-size: ${font.sizeSubheader2};
  font-weight: ${font.weightMedium};
  line-height: ${font.lineHeightBody};
  margin-bottom: ${spacing['1x']};
`;

export const NetsheetGroup: React.FC<GroupProps> = ({
  header,
  detail,
  includeSubtotal,
  subtotalName,
  subTotalDescriptions,
}: GroupProps) => {
  let minSubtotal = 0;
  let maxSubtotal = 0;
  let minAllUndefined = true;
  let maxAllUndefined = true;
  const renderLineItems = detail.map(d => {
    minSubtotal += d.minValue ? parseFloat(d.minValue) : 0;
    maxSubtotal += d.maxValue ? parseFloat(d.maxValue) : 0;
    if (d.minValue !== undefined) {
      minAllUndefined = false;
    }
    if (d.maxValue !== undefined) {
      maxAllUndefined = false;
    }

    return (
      <NetsheetLineItem
        key={d.title}
        title={d.title}
        value={getAmount({
          amount1: d.minValue,
          amount2: d.maxValue,
          percent1: d.minPercent,
          percent2: d.maxPercent,
        })}
        description={d.description}
      />
    );
  });
  if (includeSubtotal) {
    renderLineItems.push(
      <NetsheetLineItem
        key={subtotalName}
        title={subtotalName}
        value={getAmount({
          amount1: minAllUndefined ? undefined : minSubtotal.toString(),
          amount2: maxAllUndefined ? undefined : maxSubtotal.toString(),
        })}
        description={subTotalDescriptions}
      />,
    );
  }
  return (
    <Section key={header}>
      {header && <SubHeader data-tn={header}>{header}</SubHeader>}
      {renderLineItems}
    </Section>
  );
};
