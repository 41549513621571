import styled from '@emotion/styled';
import React, {ReactNode} from 'react';

const Wrapper = styled.div`
  margin-bottom: var(--cx-spacing-3x);
`;

const Title = styled.div`
  color: var(--cx-color-text);
  font-weight: var(--cx-font-weightMedium);
  font-size: var(--cx-font-sizeBody2);
  line-height: var(--cx-font-lineHeightCompact);
  border-bottom: 1px solid var(--cx-color-grey40);
  padding-bottom: var(--cx-spacing-1x);
`;
Title.displayName = 'Title';

export type Props = {
  title?: string;
  children: ReactNode;
};

export const FeesSectionLayout: React.FC<Props> = ({
  title,
  children,
}: Props) => {
  return (
    <Wrapper key={title}>
      {title && <Title data-tn={title}>{title}</Title>}
      <div>{children}</div>
    </Wrapper>
  );
};
