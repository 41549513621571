import styled from '@emotion/styled';
import React from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';

const Title = styled.div`
  color: ${color.black};
  font-weight: ${font.weightMedium};
  font-size: ${font.sizeHeader3};
  padding: ${spacing['3x']};
`;

const Subtitle = styled.span`
  font-weight: ${font.weightRegular};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeSubheader2};
  }
`;

type Props = {
  subtitle?: React.ReactNode;
  title: React.ReactNode;
};

export const SectionTitle = ({title, subtitle}: Props) => {
  if (!title) {
    return null;
  }
  return (
    <Title data-tn="sectionTitle">
      {title}
      {subtitle ? <Subtitle> {subtitle}</Subtitle> : null}
    </Title>
  );
};
