import {RefObject, useEffect} from 'react';

// Custom hook to alert if a click is made outside a given ref
export const useClickAlert = (
  ref: RefObject<HTMLDivElement>,
  onClick: (val: boolean) => void,
) => {
  useEffect(() => {
    const onOutsideClick = (e: MouseEvent) => {
      if (e.target instanceof Element) {
        if (ref?.current && !ref?.current?.contains(e.target)) {
          onClick(false);
        }
      }
    };

    document.addEventListener('mousedown', onOutsideClick);
    return () => {
      document.removeEventListener('mousedown', onOutsideClick);
    };
  }, [ref]);
};
