import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import {Adjustment} from '@uc/thrift2npme/dist/valuation/ai_valuation/adjustments';
import {SORT_ORDER} from '@uc/thrift2npme/dist/search/search';
import * as track from '@uc/analytics-definitions';
import {Product, Toggle} from '@uc/analytics-definitions';

import {BaseStep} from '@/flows/type';
import {ApiError} from '@/types/ApiError';
import {getPropertyId} from '@/utils/listing-data-helper';
import {NetSheetsType} from './types';
import {CMA_COVER_EDIT_PANEL_ACTIONS} from '@/types/cmaCoverEditPanelActions.enum';

interface AgentNote {
  cmaId: string;
  listing: ProcessedListing;
  noteLength: number;
}

interface CompsSelection {
  cmaId: string;
  subjectProperty: ProcessedListing;
  comps: ProcessedListing[];
}

interface CompSelect {
  cmaId: string;
  subjectProperty: ProcessedListing;
  compListing: ProcessedListing;
}

interface CommonError {
  cmaId: string;
  subjectProperty: ProcessedListing;
  error: ApiError;
}

interface ListingError {
  cmaId: string;
  subjectListingId: string;
  error: ApiError;
}

const classifyToggle = (isToggledOn: boolean) => {
  return isToggledOn ? 'on' : 'off';
};

const getAnalyticListingProps = (listing: ProcessedListing, cmaId: string) => ({
  property_id: listing?.compassPropertyId || 0,
  listing_id: listing?.listingIdSHA || '',
  cma_id: cmaId,
});

const getAnalyticProductProps = (
  cmaId?: string,
  subjectListingId?: string,
) => ({
  product: 'cma' as Product,
  cma_id: cmaId || '',
  subject_listing_id: subjectListingId || '',
});

export const getAnalyticSubjectProps = (
  cmaId: string,
  subjectProperty?: ProcessedListing,
) => ({
  cma_id: cmaId,
  subject_listing_id: subjectProperty?.listingIdSHA || '',
  subject_property_id:
    (subjectProperty && getPropertyId(subjectProperty)) || '',
});

const getAnalyticGeoProps = (cmaId: string, isToggledOn: boolean = false) => ({
  cma_id: cmaId,
  geo: window.uc.geoId,
  is_toggled_on: classifyToggle(isToggledOn) as Toggle,
});

const getAnalyticErrorProps = (error: ApiError) => ({
  status: error?.response?.status,
  status_text: error?.response?.statusText,
  url: error?.response?.url,
  message: error?.message,
});

export const trackCmaCreateInitialized = (productPath?: string) =>
  track.cmaCreateInitialized({
    product: 'cma',
    product_path: productPath || '',
  });

export const trackCmaEditInitialized = (cmaId: string) =>
  track.cmaEditInitialized({
    cma_id: cmaId,
  });

export const trackCmaLensAddSuggestionButtonClicked = ({
  cmaId,
  subjectProperty,
  isLensFeatureOn,
}: {
  cmaId: string;
  subjectProperty: ProcessedListing;
  isLensFeatureOn: boolean;
}) =>
  track.cmaLensAddSuggestionButtonClicked({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    lens_experiment: isLensFeatureOn,
  });

export const trackAddAgentNoteInitiated = (
  cmaId: string,
  listing: ProcessedListing,
) => track.cmaAddNoteInitiated(getAnalyticListingProps(listing, cmaId));
export const trackAgentNoteAdded = ({cmaId, listing, noteLength}: AgentNote) =>
  track.cmaNoteAdded({
    ...getAnalyticListingProps(listing, cmaId),
    length: noteLength,
  });

export const trackAgentNoteEdited = ({cmaId, listing, noteLength}: AgentNote) =>
  track.cmaNoteEdited({
    ...getAnalyticListingProps(listing, cmaId),
    length: noteLength,
  });

export const trackAgentNoteDeleted = ({cmaId, listing}: AgentNote) =>
  track.cmaNoteDeleted(getAnalyticListingProps(listing, cmaId));

export const trackSubjectListingAdded = (
  cmaId: string,
  subjectProperty: ProcessedListing,
  edit: string[],
) =>
  track.cmaSubjectListingAdded({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    edit,
  });

export const trackAnalyzeResultUpdate = (cmaId: string, edit: string[]) =>
  track.cmaSidebysideCompleted({
    cma_id: cmaId,
    edit,
  });

export const trackCompsSearchQuickAddPanelOpened = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaCompsSearchMlsIdPanelOpened(
    getAnalyticSubjectProps(cmaId, subjectProperty),
  );

export const trackCompsSearchQuickAddPanelClosed = ({
  cmaId,
  subjectProperty,
  source,
  comps,
}: CompsSelection & {source: string}) =>
  track.cmaCompsSearchMlsIdPanelClosed({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    source,
    comps: comps?.map(({listingIdSHA}) => listingIdSHA || ''),
  });

export const trackCompsSelectionCompleted = ({
  cmaId,
  subjectProperty,
  comps,
}: CompsSelection) => {
  const compListingIds = comps?.map(({listingIdSHA}) => listingIdSHA || '');
  return track.cmaCompsSelectionCompleted({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    comp_listing_ids: compListingIds,
  });
};

export const trackCQBError = ({cmaId, error, subjectProperty}: CommonError) =>
  track.cmaGetCompsQueryError({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    ...getAnalyticErrorProps(error),
  });

export const trackCmaCreated = ({
  cmaId,
  comps,
  subjectProperty,
  adjustments,
  isLensFeatureOn,
  netSheetsType,
}: CompsSelection & {
  adjustments: Adjustment[][];
  isLensFeatureOn: boolean;
  netSheetsType: NetSheetsType;
}) => {
  const compListingIds = comps?.map(({listingIdSHA}) => listingIdSHA || '');
  const adjustmentsIds = adjustments.map(adjustment => ({
    adjustments_ids: adjustment.map(({adjustmentId}) => adjustmentId || ''),
  }));
  return track.cmaCreated({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    comp_listing_ids: compListingIds,
    adjustments: adjustmentsIds,
    lens_experiment: isLensFeatureOn,
    net_sheets_type: netSheetsType,
  });
};

export const trackListingCardCompSelect = ({
  compListing,
  cmaId,
  subjectProperty,
  location,
  selectedView,
  sortOrder,
}: CompSelect & {
  selectedView: string;
  sortOrder: SORT_ORDER;
  location: number;
}) =>
  track.cmaListingCardSelectCompListing({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    location,
    sort_type: sortOrder,
    view: selectedView,
    comp_listing_id: compListing?.listingIdSHA || '',
  });

export const trackListingCardCompDeselect = ({
  compListing,
  cmaId,
  subjectProperty,
}: CompSelect) =>
  track.cmaListingCardDeselectCompListing({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    comp_listing_id: compListing?.listingIdSHA || '',
  });

export const trackMapClusterClicked = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaMapClusterClicked(getAnalyticSubjectProps(cmaId, subjectProperty));

export const trackMapCompSelect = ({
  compListing,
  cmaId,
  subjectProperty,
}: CompSelect) =>
  track.cmaMapSelectCompListing({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    comp_listing_id: compListing?.listingIdSHA || '',
  });

export const trackMapCompDeselect = ({
  compListing,
  cmaId,
  subjectProperty,
}: CompSelect) =>
  track.cmaMapDeselectCompListing({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    comp_listing_id: compListing?.listingIdSHA || '',
  });

export const trackTableCompSelect = ({
  compListing,
  cmaId,
  subjectProperty,
}: CompSelect) =>
  track.cmaListingTableSelectCompListing({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    comp_listing_id: compListing?.listingIdSHA || '',
  });

export const trackTableCompDeselect = ({
  compListing,
  cmaId,
  subjectProperty,
}: CompSelect) =>
  track.cmaListingCardDeselectCompListing({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    comp_listing_id: compListing?.listingIdSHA || '',
  });

export const trackDeselectAllComps = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) => track.cmaCompsDeselectAll(getAnalyticSubjectProps(cmaId, subjectProperty));

export const trackShoppingCartOpened = ({
  cmaId,
  subjectProperty,
  source,
}: {
  cmaId: string;
  subjectProperty: ProcessedListing;
  source: string;
}) =>
  track.cmaOpenShoppingCart({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    source,
  });

export const trackCompRearrangedFromAnalyze = ({
  compAction,
  compListing,
  cmaId,
  subjectProperty,
}: CompSelect & {compAction: string}) =>
  track.cmaCompArrangedFromAnalyse({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    comp_listing_id: compListing?.listingIdSHA || '',
    action: compAction,
  });

export const trackLensLearnMore = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaLensLearnMoreConciergeClicked(
    getAnalyticSubjectProps(cmaId, subjectProperty),
  );

export const trackLensCancelUpgrade = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaLensCancelUpgradeButtonClicked(
    getAnalyticSubjectProps(cmaId, subjectProperty),
  );

export const trackLensDontAddUpgrade = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaLensDontAddUpgradeButtonClicked(
    getAnalyticSubjectProps(cmaId, subjectProperty),
  );

export const trackLensSaveUpgrade = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaLensSaveUpgradeButtonClicked(
    getAnalyticSubjectProps(cmaId, subjectProperty),
  );

export const trackLensEditUpgrade = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaLensEditUpgradeButtonClicked(
    getAnalyticSubjectProps(cmaId, subjectProperty),
  );

export const trackLensDeleteUpgrade = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaLensDeleteUpgradeButtonClicked(
    getAnalyticSubjectProps(cmaId, subjectProperty),
  );

export const trackAddLensUpgradeButtonConfirmed = ({
  cmaId,
  subjectProperty,
  imageCount,
}: {
  cmaId: string;
  subjectProperty: ProcessedListing;
  imageCount: number;
}) =>
  track.cmaLensConfirmAddUpgradeButtonClicked({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    image_count: imageCount,
  });

export const trackAddLensUpgradeButtonClicked = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaLensAddUpgradeButtonClicked({
    ...getAnalyticSubjectProps(cmaId, subjectProperty),
    lens_experiment: true,
  });

export const trackResetCompEdits = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaAnalyzeResultsResetAllComps(
    getAnalyticSubjectProps(cmaId, subjectProperty),
  );

export const trackCmaMarketReportGenerated = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaMarketReportGenerated(
    getAnalyticSubjectProps(cmaId, subjectProperty),
  );

export const trackCmaMarketReportPublished = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaMarketReportPublished(
    getAnalyticSubjectProps(cmaId, subjectProperty),
  );

export const trackCmaFlowNextButtonClicked = (cmaId: string, step: BaseStep) =>
  track.cmaFlowNextButtonClicked({
    ...getAnalyticGeoProps(cmaId),
    step: step.name || '',
  });

export const trackCmaFlowQuitButtonClicked = ({
  cmaId,
  step,
  confirmation,
}: {
  cmaId: string;
  step: BaseStep;
  confirmation: string;
}) =>
  track.cmaFlowQuitButtonClicked({
    ...getAnalyticGeoProps(cmaId),
    step: step.name || '',
    confirmation,
  });

export const trackCmaFlowBackButtonClicked = (cmaId: string, step: BaseStep) =>
  track.cmaFlowBackButtonClicked({
    ...getAnalyticGeoProps(cmaId),
    step: step.name || '',
  });

export const trackCmaFlowPublishButtonClicked = (cmaId: string) =>
  track.cmaFlowPublishButtonClicked(getAnalyticGeoProps(cmaId));

export const trackCmaCustomizeView = (cmaId: string, format: string) =>
  track.cmaCustomizeView({
    ...getAnalyticGeoProps(cmaId),
    format,
  });

export const trackCmaCustomizeZoom = (
  cmaId: string,
  format: string,
  percentage?: string,
) =>
  track.cmaCustomizeZoom({
    ...getAnalyticGeoProps(cmaId),
    format,
    ...(percentage && {percentage}),
  });

export const trackCmaCustomizeSectionListClicked = (
  cmaId: string,
  section: string,
) =>
  track.cmaCustomizeSectionListClicked({
    ...getAnalyticGeoProps(cmaId),
    section,
  });

export const trackCustomizeSectionListHide = (
  cmaId: string,
  currentSection: string,
) =>
  track.cmaCustomizeSectionListHide({
    ...getAnalyticGeoProps(cmaId),
    current_section: currentSection,
  });

export const trackCustomizeSectionListShow = (
  cmaId: string,
  currentSection: string,
) =>
  track.cmaCustomizeSectionListShow({
    ...getAnalyticGeoProps(cmaId),
    current_section: currentSection,
  });

export const trackCustomizeSectionListAdd = (cmaId: string, section: string) =>
  track.cmaCustomizeSectionAdd({
    ...getAnalyticGeoProps(cmaId),
    section,
  });

export const trackCustomizeSectionListUploadPdf = (cmaId: string) =>
  track.cmaCustomizeSectionUploadPdf(getAnalyticGeoProps(cmaId));

export const trackCustomizeSectionDelete = (cmaId: string, section: string) =>
  track.cmaCustomizeSectionDelete({
    ...getAnalyticGeoProps(cmaId),
    section,
  });

export const trackCustomizeSectionRearrange = ({
  cmaId,
  section,
  sourceIndex,
  destIndex,
}: {
  cmaId: string;
  section: string;
  sourceIndex: number;
  destIndex: number;
}) =>
  track.cmaCustomizeSectionRearrange({
    ...getAnalyticGeoProps(cmaId),
    section,
    source_index: sourceIndex,
    dest_index: destIndex,
  });

export const trackCustomizeSectionUndoDelete = (
  cmaId: string,
  section: string,
) =>
  track.cmaCustomizeSectionUndoDelete({
    ...getAnalyticGeoProps(cmaId),
    section,
  });

export const trackCustomizeEditPanelShow = (cmaId: string, section: string) =>
  track.cmaCustomizeEditPanelShow({
    ...getAnalyticGeoProps(cmaId),
    section,
  });

export const trackCustomizeEditPanelHide = (cmaId: string, section: string) =>
  track.cmaCustomizeEditPanelHide({
    ...getAnalyticGeoProps(cmaId),
    section,
  });

export const trackCustomizeEditPanelCoverEditCoverTitle = (cmaId: string) =>
  track.cmaCustomizeEditPanelCoverEditCoverTitle(getAnalyticGeoProps(cmaId));

export const trackCustomizeEditPanelCoverEditLogo = (
  cmaId: string,
  action: CMA_COVER_EDIT_PANEL_ACTIONS,
) =>
  track.cmaCustomizeEditPanelCoverLogo({
    ...getAnalyticGeoProps(cmaId),
    action,
  });

export const trackCustomizeEditPanelCoverEditAgentCards = (
  cmaId: string,
  action: CMA_COVER_EDIT_PANEL_ACTIONS,
) =>
  track.cmaCustomizeEditPanelCoverAgentCards({
    ...getAnalyticGeoProps(cmaId),
    action,
  });

export const trackCustomizeEditPanelCoverShowLogo = (
  cmaId: string,
  isToggledOn: boolean,
) =>
  track.cmaCustomizeEditPanelCoverShowLogo(
    getAnalyticGeoProps(cmaId, isToggledOn),
  );

export const trackCustomizeEditPanelSubjectPropertyShowDescription = (
  cmaId: string,
  isToggledOn: boolean,
) =>
  track.cmaCustomizeEditPanelSubjectPropertyShowDescription(
    getAnalyticGeoProps(cmaId, isToggledOn),
  );

export const trackCustomizeEditPanelSubjectPropertyPhotos = (
  cmaId: string,
  isToggledOn: boolean,
) =>
  track.cmaCustomizeEditPanelSubjectPropertyPhotos(
    getAnalyticGeoProps(cmaId, isToggledOn),
  );

export const trackCustomizeEditPanelSubjectPropertyEditDescription = (
  cmaId: string,
) =>
  track.cmaCustomizeEditPanelSubjectPropertyEditDescription(
    getAnalyticGeoProps(cmaId),
  );

export const trackCustomizeEditPanelSubjectPropertyPropertyInformation = ({
  cmaId,
  isToggledOn,
  data,
}: {
  cmaId: string;
  isToggledOn: boolean;
  data: string;
}) =>
  track.cmaCustomizeEditPanelSubjectPropertyPropertyInformation({
    ...getAnalyticGeoProps(cmaId, isToggledOn),
    data,
  });

export const trackCustomizeEditPanelSideBySide = ({
  cmaId,
  isToggledOn,
  data,
}: {
  cmaId: string;
  isToggledOn: boolean;
  data: string;
}) =>
  track.cmaCustomizeEditPanelSideBySide({
    ...getAnalyticGeoProps(cmaId, isToggledOn),
    data,
  });

export const trackCustomizeEditPanelComparablePropertyInformation = ({
  cmaId,
  isToggledOn,
  data,
}: {
  cmaId: string;
  isToggledOn: boolean;
  data: string;
}) =>
  track.cmaCustomizeEditPanelComparablePropertyInformation({
    ...getAnalyticGeoProps(cmaId, isToggledOn),
    data,
  });

export const trackCustomizeEditPanelComparableAgentNotes = (
  cmaId: string,
  isToggledOn: boolean,
) =>
  track.cmaCustomizeEditPanelComparableAgentNotes(
    getAnalyticGeoProps(cmaId, isToggledOn),
  );

export const trackCustomizeEditPanelComparablePhotos = (
  cmaId: string,
  isToggledOn: boolean,
) =>
  track.cmaCustomizeEditPanelComparablePhotos(
    getAnalyticGeoProps(cmaId, isToggledOn),
  );

export const trackCustomizeEditPanelThankYouDisplayTitle = (
  cmaId: string,
  isToggledOn: boolean,
) =>
  track.cmaCustomizeEditPanelThankYouDisplayTitle(
    getAnalyticGeoProps(cmaId, isToggledOn),
  );

export const trackCustomizeEditPanelThankYouDisplayNote = (
  cmaId: string,
  isToggledOn: boolean,
) =>
  track.cmaCustomizeEditPanelThankYouDisplayNote(
    getAnalyticGeoProps(cmaId, isToggledOn),
  );

export const trackCustomizeEditPanelPriceEstimationDisplayAgentNotes = (
  cmaId: string,
  isToggledOn: boolean,
) =>
  track.cmaCustomizeEditPanelPriceEstimationDisplayAgentNotes(
    getAnalyticGeoProps(cmaId, isToggledOn),
  );

export const trackCustomizeEditPanelSuggestedUpgradesDisplayTotalExpectedCost =
  (cmaId: string, isToggledOn: boolean) =>
    track.cmaCustomizeEditPanelSuggestedUpgradesDisplayTotalExpectedCost(
      getAnalyticGeoProps(cmaId, isToggledOn),
    );

export const trackCustomizeEditPanelSuggestedUpgradesDisplayExpectedResaleImpact =
  (cmaId: string, isToggledOn: boolean) =>
    track.cmaCustomizeEditPanelSuggestedUpgradesDisplayExpectedResaleImpact(
      getAnalyticGeoProps(cmaId, isToggledOn),
    );

export const trackCustomizeEditPanelThankYouEditTitle = (cmaId: string) =>
  track.cmaCustomizeEditPanelThankYouEditTitle(getAnalyticGeoProps(cmaId));

export const trackCustomizeEditPanelThankYouEditNote = (cmaId: string) =>
  track.cmaCustomizeEditPanelThankYouEditNote(getAnalyticGeoProps(cmaId));

export const trackNetsheetsCancel = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) => track.cmaNetsheetsCancel(getAnalyticSubjectProps(cmaId, subjectProperty));

export const trackNetsheetsPriceRecalc = (
  cmaId: string,
  subjectProperty: ProcessedListing,
) =>
  track.cmaNetsheetsPriceRecalc(
    getAnalyticSubjectProps(cmaId, subjectProperty),
  );

export const trackCmaViewed = (cmaId: string) =>
  track.cmaViewed({
    ...getAnalyticProductProps(cmaId),
    cma_type: 'digital',
  });

export const trackEstimateLensSearchByImageError = ({
  cmaId,
  error,
  subjectProperty,
}: CommonError) =>
  track.cmaEstimateLensSearchByImageError({
    ...getAnalyticProductProps(cmaId, subjectProperty?.listingIdSHA),
    ...getAnalyticErrorProps(error),
  });

export const trackEstimateLensImageUploadToS3Error = ({
  cmaId,
  error,
  subjectProperty,
}: CommonError) =>
  track.cmaEstimateLensImageUploadToS3Error({
    ...getAnalyticProductProps(cmaId, subjectProperty?.listingIdSHA),
    ...getAnalyticErrorProps(error),
  });

export const trackEstimateLensImageUploadError = ({
  cmaId,
  error,
  subjectProperty,
}: CommonError) =>
  track.cmaEstimateLensImageUploadError({
    ...getAnalyticProductProps(cmaId, subjectProperty?.listingIdSHA),
    ...getAnalyticErrorProps(error),
  });

export const trackGetEstimatePriceError = ({
  cmaId,
  error,
  subjectProperty,
}: CommonError) =>
  track.cmaGetEstimatePriceError({
    ...getAnalyticProductProps(cmaId, subjectProperty?.listingIdSHA),
    ...getAnalyticErrorProps(error),
  });

export const trackCustomizeFieldsInitiated = (cmaId: string) =>
  track.customizeFieldsInitiated(getAnalyticProductProps(cmaId));

export const trackCustomizeFieldsClosed = ({
  cmaId,
  error,
  fields,
}: {
  cmaId: string;
  fields: string[];
  error: ApiError;
}) =>
  track.customizeFieldsClosed({
    ...getAnalyticProductProps(cmaId),
    ...getAnalyticErrorProps(error),
    fields,
  });

export const trackCmaUpdateFailure = (
  cmaId: string,
  lastUpdateTimeMs: number,
) =>
  track.cmaUpdateFailure({
    ...getAnalyticProductProps(cmaId),
    last_update_time_ms: lastUpdateTimeMs,
  });

export const trackSubjectPropertyAlternateListingSelected = (
  subjectListingId: string,
) =>
  track.cmaSubjectPropertyAlternateListingSelected(
    getAnalyticProductProps('', subjectListingId),
  );

export const trackCmaChangeCompsSortOrder = (
  cmaId: string,
  sortOrder: string,
) =>
  track.cmaChangeCompsSortOrder({
    ...getAnalyticProductProps(cmaId),
    sort_order: sortOrder,
  });

export const trackGeocodingFailure = ({
  agentAddress,
  postalCode,
  error,
}: {
  agentAddress: string;
  postalCode: string;
  error: string;
}) =>
  track.geocodingFailure({
    product: 'cma',
    agent_address: agentAddress,
    postal_code: postalCode,
    error,
  });

export const trackGeocodingSecondFailure = ({
  agentAddress,
  postalCode,
  error,
}: {
  agentAddress: string;
  postalCode: string;
  error: string;
}) =>
  track.geocodingSecondFailure({
    product: 'cma',
    agent_address: agentAddress,
    postal_code: postalCode,
    error,
  });

export const trackRemoveSectionPreview = (section: string) =>
  track.cmaRemoveSectionPreview({
    product: 'cma',
    section,
  });

export const trackAddAdjustmentInitiated = (
  cmaId: string,
  compListingId: string,
) =>
  track.addAdjustmentInitiated({
    ...getAnalyticProductProps(cmaId),
    comp_listing_id: compListingId,
  });

export const trackCmaMapShapeUpdated = ({
  cmaId,
  searchQuery,
  subjectListingId,
}: {
  cmaId: string;
  subjectListingId: string;
  searchQuery: string;
}) =>
  track.cmaMapShapeUpdated({
    ...getAnalyticProductProps(cmaId, subjectListingId),
    search_query: searchQuery,
  });

export const trackMlsModalSearchPerformed = ({
  cmaId,
  searchQuery,
  subjectListingId,
}: {
  cmaId: string;
  subjectListingId: string;
  searchQuery: string;
}) =>
  track.cmaMlsModalSearchPerformed({
    ...getAnalyticProductProps(cmaId, subjectListingId),
    search_query: searchQuery,
  });

export const trackCmaGetCompsQuery = ({
  cmaId,
  searchQuery,
  subjectListingId,
}: {
  cmaId: string;
  subjectListingId: string;
  searchQuery: string;
}) =>
  track.cmaGetCompsQuery({
    ...getAnalyticProductProps(cmaId, subjectListingId),
    search_query: searchQuery,
  });

export const trackCmaSearchTokenShowAllClicked = (
  cmaId: string,
  subjectListingId: string,
) =>
  track.cmaSearchTokenShowAllClicked(
    getAnalyticProductProps(cmaId, subjectListingId),
  );

export const trackCmaMlsModalRegionChanged = (
  cmaId: string,
  subjectListingId: string,
) =>
  track.cmaMlsModalRegionChanged(
    getAnalyticProductProps(cmaId, subjectListingId),
  );

export const trackCmaMlsModalError = ({
  cmaId,
  error,
  subjectListingId,
}: ListingError) =>
  track.cmaMlsModalError({
    ...getAnalyticProductProps(cmaId, subjectListingId),
    ...getAnalyticErrorProps(error),
  });

export const trackCmaEditCriteriaClicked = (
  cmaId: string,
  subjectListingId: string,
) =>
  track.cmaEditCriteriaClicked({
    ...getAnalyticProductProps(cmaId, subjectListingId),
  });

export const trackCmaSubjectLoadListingError = ({
  cmaId,
  subjectListingId,
  error,
}: Omit<ListingError, 'error'> & {error: Partial<ApiError>}) =>
  track.cmaSubjectLoadListingError({
    ...getAnalyticProductProps(cmaId, subjectListingId),
    ...getAnalyticErrorProps(error as ApiError),
  });

export const trackCmaSubjectPropertyGeoNotFound = ({
  cmaId,
  subjectListingId,
  address,
}: {
  cmaId: string;
  subjectListingId: string;
  address: string;
}) =>
  track.cmaSubjectPropertyGeoNotFound({
    ...getAnalyticProductProps(cmaId, subjectListingId),
    address,
  });

export const trackCmaSubjectGetGoogleGeoError = ({
  cmaId,
  subjectListingId,
  errorMessage,
  address,
}: {
  cmaId: string;
  subjectListingId: string;
  address: string;
  errorMessage: string;
}) =>
  track.cmaSubjectGetGoogleGeoError({
    ...getAnalyticProductProps(cmaId, subjectListingId),
    address,
    error: errorMessage,
  });

export const trackCmaAddSectionPreview = (section: string) =>
  track.cmaAddSectionPreview({
    product: 'cma',
    section,
  });

export const trackCmaUploadPdfPreview = (numpages: number) =>
  track.cmaUploadPdfPreview({
    product: 'cma',
    numpages,
  });

export const trackCmaAnalyzeResultsClearedAllAdjustments = (
  cmaId: string,
  subjectListingId: string,
) =>
  track.cmaAnalyzeResultsClearedAllAdjustments({
    ...getAnalyticProductProps(cmaId, subjectListingId),
  });

export const trackCmaAdjustmentsModalClosed = ({
  cmaId,
  cmaAdjustmentsModalClosed,
  modalVersion,
  millisSpentInModal,
  numberOfAppliedAdjustmentsAfter,
  numberOfAppliedAdjustmentsBefore,
  numberOfSuggestedAdjustments,
  comparableId,
  subjectPropertyId,
}: {
  cmaId: string;
  subjectPropertyId: string;
  comparableId: string;
  modalVersion: string;
  numberOfSuggestedAdjustments: number;
  numberOfAppliedAdjustmentsBefore: number;
  numberOfAppliedAdjustmentsAfter: number;
  millisSpentInModal: number;
  cmaAdjustmentsModalClosed: string;
}) =>
  track.cmaAdjustmentsModalClosed({
    ...getAnalyticProductProps(cmaId),
    subject_property_id: subjectPropertyId,
    comparable_id: comparableId,
    modal_version: modalVersion,
    number_of_suggested_adjustments: numberOfSuggestedAdjustments,
    number_of_applied_adjustments_before: numberOfAppliedAdjustmentsBefore,
    number_of_applied_adjustments_after: numberOfAppliedAdjustmentsAfter,
    millis_spent_in_modal: millisSpentInModal,
    action_on_close: cmaAdjustmentsModalClosed,
  });

export const trackCmaAnalyzeResultsGetAdjustmentError = ({
  subjectListingId,
  cmaId,
  error,
}: ListingError) =>
  track.cmaAnalyzeResultsGetAdjustmentError({
    ...getAnalyticProductProps(cmaId),
    ...getAnalyticErrorProps(error),
    subject_listing_id: subjectListingId,
  });

export const trackLoadUnknownLocation = (
  locationName: string,
  geographyId: string,
) =>
  track.loadUnknownLocation({
    product: 'cma',
    location_name: locationName,
    geography: geographyId,
  });

export const trackLoadLocationFailure = (
  locationName: string,
  geographyId: string,
) =>
  track.loadLocationFailure({
    product: 'cma',
    location_name: locationName,
    geography: geographyId,
  });

export const trackCmaPublishError = (cmaId: string, error: ApiError) =>
  track.cmaPublishError({
    ...getAnalyticProductProps(cmaId),
    ...getAnalyticErrorProps(error),
  });

export const trackListingDetailsClicked = (cmaId: string) =>
  track.cmaListingDetailsClicked({
    product: 'cma',
    cma_id: cmaId,
  });

export const trackAllPhotosClicked = (cmaId: string) =>
  track.cmaAllPhotosClicked({
    product: 'cma',
    cma_id: cmaId,
  });

export const trackKitchenPhotosClicked = (cmaId: string) =>
  track.cmaKitchenPhotosClicked({
    product: 'cma',
    cma_id: cmaId,
  });

export const trackBedsPhotosClicked = (cmaId: string) =>
  track.cmaBedsPhotosClicked({
    product: 'cma',
    cma_id: cmaId,
  });

export const trackLivingPhotosClicked = (cmaId: string) =>
  track.cmaLivingPhotosClicked({
    product: 'cma',
    cma_id: cmaId,
  });

export const trackFloorplanClicked = (cmaId: string) =>
  track.cmaFloorplanClicked({
    product: 'cma',
    cma_id: cmaId,
  });

export const trackBathsPhotosClicked = (cmaId: string) =>
  track.cmaBathsPhotosClicked({
    product: 'cma',
    cma_id: cmaId,
  });
