import React from 'react';

import {color, font} from '@uc/cx-design-tokens';

type Props = {
  index: number;
  isMobile: boolean;
  priceText?: string[];
  cx: number;
  cy: number;
  r?: number;
  fill: string;
  textColor?: string;
};

export const ChartMarker: React.FC<Props> = ({
  index,
  isMobile,
  priceText,
  cx,
  cy,
  r = 12,
  fill,
  textColor = color.white,
}) => {
  let updatedCy = cy - 55 < 5 ? 5 : cy - 55;
  let cyPrice = updatedCy < 5 ? 25 : cy - 35;

  if (isMobile) {
    updatedCy = cy - 50 < 5 ? 5 : cy - 50;
    cyPrice = updatedCy < 5 ? 20 : cy - 35;
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-tn="chart marker SVG">
      {priceText && (
        <>
          <text
            x={cx}
            y={updatedCy}
            textAnchor="middle"
            dominantBaseline="central"
            fill={color.black}
            fontWeight={font.weightBold}
            fontSize={font.sizeCaption1}
            data-tn="price-label-coordination"
          >
            {priceText[0]}
          </text>
          <text
            x={cx}
            y={cyPrice}
            textAnchor="middle"
            dominantBaseline="central"
            fill={color.black}
            fontWeight={font.weightRegular}
            fontSize={isMobile ? font.sizeBody2 : font.sizeBody1}
            data-tn="price-value-coordination"
          >
            {priceText[1]}
          </text>
        </>
      )}
      <circle
        cx={cx}
        cy={cy}
        r={r}
        fill={fill}
        stroke="#fff"
        aria-label="chart marker circle"
      />
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        dominantBaseline="central"
        fill={textColor}
        fontWeight={font.weightMedium}
        fontSize={font.sizeBody2}
      >
        {index}
      </text>
    </svg>
  );
};
