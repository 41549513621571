import React from 'react';
import styled from '@emotion/styled';

import {font, spacing, color} from '@uc/cx-design-tokens';

import {useIsTNEPartnerAvailable} from '@/hooks/useIsTNEPartnerAvailable';
import {useStateValue} from '@/context/state';
import {GeneratedByPartner} from '@/pages/EstimatePage/SellerNetSheetForPartner/GeneratedByPartner';

export const FOOTER_INFO =
  '*Calculations include previously entered commission percentage. The information contained herein is being provided for informative purposes only and cannot be relied upon for accuracy. Property taxes are presumed to be reliable but are not guaranteed. Contact your agent for any questions.';
export const partnerFooterInfo = (partnerName?: string) =>
  `*The estimates provided here are for informational purposes only and should not be relied upon as actual closing figures. Compass and ${partnerName} offer no guarantee or warranty of results. Calculations include previously entered commission percentages. Property taxes are presumed to be reliable but may not be accurate. Contact ${partnerName} prior to going under contract for more details.`;

const Footer = styled.div`
  font-family: ${font.familyLegible};
  color: ${color.textSubtle};
  font-size: ${font.sizeCaption2};
  line-height: ${font.lineHeightCompact};
  margin-top: ${spacing['3x']};
`;

const PartnerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing['3x']};
`;

const PartnerFooterText = styled.div`
  font-family: ${font.familyLegible};
  color: ${color.textSubtle};
  font-size: ${font.sizeCaption2};
  line-height: ${font.lineHeightCompact};
  flex: 1;
`;

export const NetsheetFooter: React.FC = () => {
  const isTNEPartnerAvailable = useIsTNEPartnerAvailable();
  const [state] = useStateValue();

  const {partnerMetadata} = state?.partnerConfig ?? {};

  return isTNEPartnerAvailable ? (
    <PartnerFooter>
      <PartnerFooterText>
        {partnerFooterInfo(partnerMetadata?.displayName)}
      </PartnerFooterText>
      <GeneratedByPartner />
    </PartnerFooter>
  ) : (
    <Footer>{FOOTER_INFO}</Footer>
  );
};
