import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import type {State} from './store';

export const SET_COMP_LISTINGS = 'SET_COMP_LISTINGS';
type SET_COMP_LISTINGS = typeof SET_COMP_LISTINGS;

export const setCompListings = (
  compListings: ProcessedListing[],
): {type: SET_COMP_LISTINGS; payload: {compListings: ProcessedListing[]}} => ({
  type: SET_COMP_LISTINGS,
  payload: {compListings},
});

export type Action = ReturnType<typeof setCompListings>;

export const compListingsReducer = (state: State, action: Action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_COMP_LISTINGS: {
      const {compListings} = payload;
      return {
        ...state,
        compListings,
      };
    }
    default:
      throw `action type not found. type: ${type}`;
    // return state;
  }
};
