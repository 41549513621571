import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

export function getCityStateZip(listing: ProcessedListing | undefined): string {
  let cityStateZip = '-';

  if (listing && listing.location) {
    const locationProperties = [
      listing.location?.city,
      listing.location?.state,
      listing.location?.zipCode,
    ];

    if (locationProperties.filter(Boolean).length !== 0) {
      cityStateZip = locationProperties
        .map(prop => (typeof prop === 'undefined' ? '-' : prop))
        .join(', ');
    }
  }
  return cityStateZip;
}
