const margin = 0.00001;

export const getFlatFeeForPercent = ({
  basisPrice,
  percentageValue,
}: {
  basisPrice?: number;
  percentageValue?: number;
}) => {
  if (!basisPrice || percentageValue === undefined) {
    return '';
  }

  const result = basisPrice * (percentageValue / 100);
  const comapreValue = Math.floor(result) + 0.5;

  const toSerialize =
    Math.abs(comapreValue - result) < margin ? comapreValue : result;
  const numberString = Math.round(toSerialize).toString();

  return numberString;
};
