import {color} from '@uc/cx-design-tokens';
import {
  LotSizeUnit,
  NetSheetsGeneric,
} from '@uc/thrift2npme/dist/cma/cma_models';
import {NYC_GEO_ID} from '@uc/thrift2npme/dist/geography/common.js';
import {
  FeePaymentFrequentType,
  ListingType,
  SaleStatus,
} from '@uc/thrift2npme/dist/listing/listing';

export const CUSTOM_LISTING_PREFIX = 'customListing-';

// TODO: We should start splitting these constants into files that make a little more sense :)
export const hoaFeeFrequencyTypes: {
  [key: string]: number;
} = Object.keys(FeePaymentFrequentType).reduce((mapping, key: string) => {
  let label = key.replace('_', ' ').toLowerCase();
  label = label.charAt(0).toUpperCase() + label.slice(1);
  return {
    ...mapping,
    [label]: FeePaymentFrequentType[key as keyof typeof FeePaymentFrequentType],
  };
}, {});

export const lotSizeUnitTypes = {
  Acres: LotSizeUnit.ACRES,
  SqFt: LotSizeUnit.SQFT, // Default unit
};

// Only used on SubjectSelectionPage in omnisuggest calls
// to help determine the subject property
export const OMNIBOX_LISTING_TYPES = [
  ListingType.SALES,
  // ListingType.LISTING, // LISTING and SHORT_TERM_RENTAL are flipped in the omnibox api.
  ListingType.SHORT_TERM_RENTAL,
];

export const SUBJECT_PROPERTY_SOURCE = {
  OMNIBOX: 'omnibox',
  MANUAL: 'manual',
};

// Required for search filters.
const MAJOR_CONTRIBUTING_DATASET = 'majorContributingDataset';
export const DEFAULT_SALES_SEARCH_PARAMS = {
  agentSearch: true,
  facetFieldNames: [MAJOR_CONTRIBUTING_DATASET],
  listingTypes: [ListingType.SALES],
  num: 0,
  saleStatuses: Object.values(SaleStatus).filter(
    (v): v is SaleStatus => typeof v === 'number',
  ),
  start: 0,
};

export const DEFAULT_GEO_LOCALE = NYC_GEO_ID;

export const DEFAULT_LISTING_IMG =
  'https://www.compass.com/ucfe-assets/listing-assets/latest/svg/house.svg';

export const mainListingStatuses = {
  ACTIVE: 'Active',
  COMING_SOON: 'Coming Soon',
  OTHER: 'Other',
  PENDING: 'Pending',
  SOLD: 'Sold',
  CUSTOM: 'Custom',
};

export const listingStatusColorMap = {
  Active: color.green90,
  Pending: color.highlight,
  Sold: color.critical,
  Custom: color.teal80,
  Other: color.black,
  'Coming Soon': color.green90,
};

export function getStatusLabelColor(status: string) {
  switch (status) {
    case mainListingStatuses.ACTIVE:
      return [color.white, color.green90];
    case mainListingStatuses.COMING_SOON:
      return [color.white, color.green90];
    case mainListingStatuses.SOLD:
      return [color.white, color.red80];
    case mainListingStatuses.CUSTOM:
      return [color.white, color.teal80];
    case mainListingStatuses.PENDING:
      return [color.white, color.orange80];
    case mainListingStatuses.OTHER:
    default:
      return [color.orange80, color.orange30];
  }
}

export const listingStatusTextColorMap: {
  [key: (typeof mainListingStatuses)[keyof typeof mainListingStatuses]]: string;
} = {
  ...listingStatusColorMap,
  Pending: '#984a0a',
};

export const listingStatusMap: {
  [key: string]: (typeof mainListingStatuses)[keyof typeof mainListingStatuses];
} = {
  'application pending': mainListingStatuses.PENDING,
  'active under contract': mainListingStatuses.PENDING,
  'contract out': mainListingStatuses.PENDING,
  'leases out': mainListingStatuses.OTHER,
  'exclusive expired': mainListingStatuses.OTHER,
  rented: mainListingStatuses.OTHER,
  'board package out': mainListingStatuses.PENDING,
  'board approved': mainListingStatuses.PENDING,
  'contract signed': mainListingStatuses.PENDING,
  'contingencies dropped': mainListingStatuses.PENDING,
  'temporarily off market': mainListingStatuses.OTHER,
  'permanently off market': mainListingStatuses.OTHER,
  'coming soon': mainListingStatuses.COMING_SOON,
  incoming: mainListingStatuses.ACTIVE,
  closed: mainListingStatuses.SOLD,
  active: mainListingStatuses.ACTIVE,
  sold: mainListingStatuses.SOLD,
  pending: mainListingStatuses.PENDING,
  other: mainListingStatuses.OTHER,
  withdrawn: mainListingStatuses.OTHER,
  custom: mainListingStatuses.CUSTOM,
};

export const sortingListingStatusMap: Record<string, string> = {
  ...listingStatusMap,
  'coming soon': 'Coming Soon',
};

export const compsSortOrder = [
  'Coming Soon',
  mainListingStatuses.ACTIVE,
  mainListingStatuses.PENDING,
  mainListingStatuses.SOLD,
  mainListingStatuses.OTHER,
  mainListingStatuses.CUSTOM,
];

export const CUSTOMIZE_FIELDS_SUBJECT_OVERRIDE_VALUE_KEY = 'subject';

export const CMA_FLOW_QUIT_BUTTON_CONFIRMATIONS = {
  SAVE_QUIT: 'save_quit',
  KEEP_EDITING: 'keep_editing',
};

export const CMA_CUSTOMIZE_VIEW_FORMATS = {
  WEB: 'web',
  PDF: 'pdf',
};

export const CMA_CUSTOMIZE_ZOOM_FORMATS = {
  FILL: 'fill',
  FIT: 'fit',
  PERCENTAGE_NUMBER: 'percentage_number',
};

export const ONLY_ON_COMPASS_SOURCE = 'listing_editor_manual';
export const ONLY_ON_COMPASS_SOURCE_DISPLAY = 'Compass';

export const MAP_API_AWS_SECRET = 'compass.app.valuation.searchMap';
export const FILE_STACK_API_SECRET = 'compass.app.valuation.fileStackApi';

export const OPTY_FLAGS = {
  IS_MARKET_TREND_ENABLED: !!global?.uc?.opty?.features?.cma_market_trend,
  IS_SUNSET_CLASSIC_SEARCH_ENABLED:
    !!global?.uc?.opty?.features?.sunset_classic_search,
  IS_CMA_SEARCH_PARITY_ENABLED: !!global?.uc?.opty?.features?.cma_search_parity,
  IS_QUARTER_AND_THREE_QUARTER_BATHROOMS_ON_LISTING_PAGES_ENABLED:
    !!global?.uc?.opty?.features
      ?.quarter_and_three_quarter_bathrooms_on_listing_pages,
  IS_RELEASE_CD_CMA_VISIBILITY_NOTIFICATIONS_ENABLED:
    !!global?.uc?.opty?.features?.release_cd_cma_visibility_notifications,
  IS_RELEASE_CD_SELLER_CMA_COLLECTION_ENABLED:
    !!global?.uc?.opty?.features?.release_cd_seller_cma_collection,
  IS_TNE_PARTNER_SPECIFIC_SELLER_NET_SHEET_ENABLED:
    !!global?.uc?.opty?.features?.cma_tne_partner_specific_seller_net_sheet,
  IS_RELEASE_COMPENSATION_REMARKS_CMA_COMPS_ENABLED:
    !!global?.uc?.opty?.features?.release_compensation_remarks_cma_comps,
  IS_RELEASE_BUYERS_AGENT_COMPENSATION_CMA_COMPS_ENABLED:
    !!global?.uc?.opty?.features?.release_buyers_agent_compensation_cma_comps,
};

export const OPTY_VARIABLES = {
  BUYERS_AGENT_COMPENSATION_COMPASS_LISTINGS_ONLY_EDITABLE:
    global?.uc?.opty?.variables?.release_buyers_agent_compensation_cma_comps
      ?.compass_listings_only_editable ?? 'off',
  COMPENSATION_REMARKS_COMPASS_LISTINGS_ONLY_EDITABLE:
    global?.uc?.opty?.variables?.release_compensation_remarks_cma_comps
      ?.compass_listings_only_editable ?? 'off',
};

export const GENERIC_DEFAULT_FEES: NetSheetsGeneric = {
  salesPrice: {
    name: 'Sales price',
    isRange: true,
    minValue: '',
    maxValue: '',
    isPercentage: false,
  },
  mortgage: {
    name: 'Seller mortgage balance',
    isRange: false,
    minValue: '',
    maxValue: '',
    isPercentage: false,
  },
  predefinedFees: [
    {
      name: 'Buyer agent commission',
      isRange: false,
      minValue: '',
      maxValue: '',
      isPercentage: false,
    },
    {
      name: 'State tax',
      isRange: false,
      minValue: '',
      maxValue: '',
      isPercentage: false,
    },
    {
      name: 'Seller agent commission',
      isRange: false,
      minValue: '',
      maxValue: '',
      isPercentage: false,
    },
    {
      name: 'City or county tax',
      isRange: false,
      minValue: '',
      maxValue: '',
      isPercentage: false,
    },
    {
      name: 'Escrow',
      isRange: false,
      minValue: '',
      maxValue: '',
      isPercentage: false,
    },
    {
      name: 'Title fees',
      isRange: false,
      minValue: '',
      maxValue: '',
      isPercentage: false,
    },
  ],
  additionalFees: [{}],
};

export const EMOTION_CACHE_KEY = 'uc-cma';

// Comp Description paginate
export const FIRST_PAGE_COMP_DESCRIPTION_SIZE = 1100;
export const OTHER_PAGE_COMP_DESCRIPTION_SIZE = 2400;
export const MAX_SIZE_REMAINING_AFTER_ADJUSTMENTS = 1700;

// AGENT NOTES
export const AGENT_NOTES_LENGTH_LIMIT = 730;

export const ADJUSTMENT_EXPLANATION_LENGTH_LIMIT = 80;

export enum PinType {
  ACTIVE = 'active',
  PENDING = 'pending',
  SOLD = 'sold',
  OFF_MARKET = 'offMarket',
  COMING_SOON = 'comingSoon',
}

export const mapPinTypeToLabel: Record<PinType, string> = {
  [PinType.ACTIVE]: 'ACTIVE',
  [PinType.COMING_SOON]: 'COMING SOON',
  [PinType.OFF_MARKET]: 'OFF MARKET',
  [PinType.PENDING]: 'PENDING',
  [PinType.SOLD]: 'SOLD',
};

// refer to https://github.com/UrbanCompass/uc-frontend/blob/master/workspaces/maps/packages/clusters.react/src/pin/themes.ts#L17
export const mapPinTypeToColor: Record<PinType, string> = {
  [PinType.ACTIVE]: 'rgb(0,135,82)',
  [PinType.PENDING]: 'rgb(239, 108, 2)',
  [PinType.SOLD]: 'rgb(198, 40, 40)',
  [PinType.OFF_MARKET]: 'rgb(108, 108, 108)',
  [PinType.COMING_SOON]: 'rgb(94, 53, 177)',
};

// Role Name
export const ADMIN_ROLE = 'Admin';
