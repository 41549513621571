import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';

import {ErrorMessage} from './utils';

export function getError(item?: NetSheetsLineItem) {
  if (!item) return;

  const {minValue = '', maxValue = '', isPercentage, isRange} = item;
  const min = Number(minValue),
    max = Number(maxValue);

  if (isPercentage && (min >= 100 || max >= 100)) {
    return `${ErrorMessage.percentages}`;
  }

  if (isRange && maxValue && min > max) {
    return `${ErrorMessage.min}`;
  }
}
