import {CmaOutputSection} from '@uc/thrift2npme/dist/cma/cma_models';

export const getLegacySectionName = (
  sectionType: CmaOutputSection | undefined,
) => {
  switch (sectionType) {
    case CmaOutputSection.COVER:
      return 'Cover';
    case CmaOutputSection.SUBJECT_DETAILS:
      return 'Subject property details';
    case CmaOutputSection.MAP:
      return 'Map';
    case CmaOutputSection.SIDE_BY_SIDE_COMPARISON:
      return 'Comparable properties';
    case CmaOutputSection.STATUS_COMPARISON:
      return 'Status comparison';
    case CmaOutputSection.CHARTS_PRICE_VS_SQFT:
      return 'Price vs Sq Ft';
    case CmaOutputSection.CHARTS_PRICE_VS_DOM:
      return 'Price vs Days on Market';
    case CmaOutputSection.CHARTS_LISTING_AVERAGES:
      return 'Listing averages';
    case CmaOutputSection.ESTIMATE:
      return 'Estimate';
    case CmaOutputSection.UPGRADE_SUGGESTIONS:
      return 'Suggested upgrades';
    case CmaOutputSection.CONTACT:
      return 'Thank you';
    case CmaOutputSection.MARKET_TREND_REPORT:
      return 'Market report';
    case CmaOutputSection.NETSHEETS:
      return 'Netsheet';
    // TODO: See if we can reuse file name for section title of custom PDF's
    case CmaOutputSection.CUSTOM_PDF:
      return 'Custom PDF';
    default:
      return '';
  }
};
