import React, {ReactElement} from 'react';
import ReactDOM from 'react-dom';

import {Person} from '@uc/thrift2npme/dist/people/people_models';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import type {
  Cma,
  Metadata,
  AgentInputs,
} from '@uc/thrift2npme/dist/cma/cma_models';
import {getAllDisplayableFields} from '@uc/cma-adapters';
import type {OptyState} from '@uc/opty';

import ClientPage from '@/pages/Web/components/ClientPage';
import '../../../assert/flickity.min.css';
import {defaultState} from './context/store';
import {AllDisplayFieldsType} from '@/types/types';

let dataLiteralCMA: {
  loading: boolean;
  agent: Person;
  CMAMetaData: Metadata;
  subjectProperty: ProcessedListing;
  compListings: ProcessedListing[];
  agentInputs: AgentInputs;
  allDisplayFields: AllDisplayFieldsType;
  cma: Cma;
} | null = null;
let staticDir: string;
let error: boolean = false;

try {
  const innerhtml =
    document.getElementById('data-literal-cma')?.innerHTML ?? '';
  const unescapedString = window.unescape(innerhtml);
  dataLiteralCMA = JSON.parse(unescapedString || 'null');
} catch (e) {
  error = true;
}

let opty: OptyState = {};
try {
  opty = JSON.parse(document.getElementById('data-opty')?.innerHTML || '{}');
} catch (e) {
  opty = {};
}

try {
  staticDir = JSON.parse(
    document.getElementById('data-static-dir')?.innerHTML || '',
  );
} catch (e) {
  staticDir = '';
}

const allDisplayFields = getAllDisplayableFields(
  dataLiteralCMA?.subjectProperty ?? {},
  dataLiteralCMA?.compListings ?? [],
);
const mappedSideBySideFields = allDisplayFields.reduce<AllDisplayFieldsType>(
  (fieldMapping, fieldBlock) => {
    const {processedListingPath, displayFieldName, valueToDisplay} =
      fieldBlock || {};
    if (processedListingPath) {
      fieldMapping[processedListingPath] = {
        processedListingPath,
        displayFieldName,
        valueToDisplay,
      };
    }
    return fieldMapping;
  },
  {},
);

const initialState = {
  ...defaultState,
  ...dataLiteralCMA,
  error,
  staticDir,
  opty,
  allDisplayFields: mappedSideBySideFields,
};

const render = (element: ReactElement) => {
  ReactDOM.hydrate(element, document.getElementById('app'));
};

render(<ClientPage initialState={initialState} />);

module.hot?.accept('./main.tsx', () => {
  const ClientPage = require('./components/ClientPage').default;
  render(<ClientPage initialState={initialState} />);
});
