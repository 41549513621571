import {NetSheetsLineItemNumber, Value} from './types';

export function getNetEquity(
  salesPrice: NetSheetsLineItemNumber,
  mortgage: NetSheetsLineItemNumber,
): Value {
  const minValue = salesPrice.minValue - mortgage.minValue;
  const maxValue = salesPrice.maxValue - mortgage.maxValue;

  return {
    minValue: minValue || maxValue,
    maxValue: maxValue || minValue,
  };
}
