import produce from 'immer';

import setStoreField from '../setStoreField';
import {ICma} from '../types/Cma';

export const updateShowSettlementCostsFromPartnerReducer = (
  state: ICma,
  payload: boolean,
) =>
  produce(state, draftState => {
    setStoreField(
      draftState.cma.agentInputs?.netsheets?.input,
      'showSettlementCostsFromPartner',
      payload,
    );
  });
