import {
  ChartName,
  StatsName,
} from '@uc/thrift2npme/dist/trend_chart/trend_chart_service.ucfetch';

export interface IChartConfigInternal {
  title?: string;
  description?: string;
  label?: string;
  multiplier?: number;
  isMonthsOfInventory?: boolean;
}
export interface IChartConfig extends IChartConfigInternal {
  metric: ChartName;
  internals?: IChartConfigInternal[];
}

export const ChartConfigs: IChartConfig[] = [
  {
    metric: ChartName.STATUS_COUNT,
    internals: [
      {
        title: 'Homes for sale',
        description: 'Number of homes listed for sale, end of month',
        label: 'ACTIVE',
      },
      {
        title: 'New listings',
        description: 'Number of homes newly listed for sale, during the month',
        label: 'NEW_LISTING',
      },
      {
        title: 'Homes under contract',
        description: 'Number of homes that went into contract',
        label: 'PENDING',
      },
      {
        title: 'Homes sold',
        description: 'Number of homes sold',
        label: 'SOLD',
      },
    ],
  },
  {
    metric: ChartName.MONTHS_OF_INVENTORY_SOLD,
    title: 'Months of inventory',
    description: 'Months of inventory based on sold listings',
    isMonthsOfInventory: true,
  },
  {
    metric: ChartName.CLOSE_PRICE_VS_LIST_PRICE_PERCENT,
    title: 'Sold/List Price %',
    description: 'Sold price/original list price %',
  },
  {
    metric: ChartName.MEDIAN_SOLD_PRICE,
    title: 'Median sold price',
    description: 'Median price of sold homes (in 1000s)',
    multiplier: 1 / 1000,
  },
  {
    metric: ChartName.MEDIAN_PPSF,
    title: 'Median price per square foot',
    description: 'Median price per square foot of sold homes',
  },
  {
    metric: ChartName.MEDIAN_DOM,
    title: 'Median days on market',
    description: 'Median days on market of sold listings',
  },
];

export interface IMetric {
  metric: StatsName;
  title: string;
  isPrice?: boolean;
  isMonthsOfInventory?: boolean;
}

export const Metrics: IMetric[] = [
  {
    metric: StatsName.SOLD_LISTING_COUNT,
    title: 'Homes sold',
  },
  {
    metric: StatsName.FOR_SALE_LISTING_COUNT,
    title: 'Homes for sale',
  },
  {
    metric: StatsName.MEDIAN_DOM,
    title: 'Median days on market',
  },
  {
    metric: StatsName.MEDIAN_PPSF,
    title: 'Median price per square foot',
    isPrice: true,
  },
  {
    metric: StatsName.MONTHS_OF_INVENTORY_SOLD,
    title: 'Months of inventory',
    isMonthsOfInventory: true,
  },
  {
    metric: StatsName.MEDIAN_SOLD_PRICE,
    title: 'Median sold price',
    isPrice: true,
  },
  {
    metric: StatsName.IN_CONTRACT_LISTING_COUNT,
    title: 'Homes under contract',
  },
];
