import produce from 'immer';

import setStoreField from '../setStoreField';
import {ICma} from '../types/Cma';

const addNetsheetsToCmaReducer = (state: ICma) =>
  produce(state, draftState => {
    setStoreField(draftState.cma.agentInputs?.netsheets, 'addedToCma', true);
  });

export default addNetsheetsToCmaReducer;
