import {createReducer} from '../createReducer';
import ShouldQueryCompsBuilder from './types/ShouldQueryCompsBuilder';

// ACTION TYPES
export const SHOULD_QUERY_COMPS_BUILDER_ACTION_TYPES = {
  SET_SHOULD_QUERY_COMPS_BUILDER: 'SET_SHOULD_QUERY_COMPS_BUILDER',
} as const;

// ACTION CREATORS
export const setShouldQueryCompsBuilder = (
  shouldQueryCompsBuilder: boolean,
) => ({
  type: SHOULD_QUERY_COMPS_BUILDER_ACTION_TYPES.SET_SHOULD_QUERY_COMPS_BUILDER,
  payload: {shouldQueryCompsBuilder},
});

// REDUCERS
const setShouldQueryCompsBuilderReducer = (
  _state: boolean,
  payload: {shouldQueryCompsBuilder: boolean},
) => payload.shouldQueryCompsBuilder;

// COMBINED REDUCER
export default createReducer<ShouldQueryCompsBuilder>({
  [SHOULD_QUERY_COMPS_BUILDER_ACTION_TYPES.SET_SHOULD_QUERY_COMPS_BUILDER]:
    setShouldQueryCompsBuilderReducer,
});
