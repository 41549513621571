import produce from 'immer';

import setStoreField from '../setStoreField';
import {ICma} from '../types/Cma';

// TODO: this code is unused and should be removed. https://compass-tech.atlassian.net/browse/ACT-7986

const setPartnerCmaLeadGenerationReducer = (state: ICma, payload: boolean) =>
  produce(state, draftState => {
    setStoreField(
      draftState.cma.agentInputs?.netsheets,
      'partnerLeadGenerationOptIn',
      payload,
    );
  });

export default setPartnerCmaLeadGenerationReducer;
