import React, {FC} from 'react';
import styled from '@emotion/styled';

import {mq} from '@uc/cx-design-tokens';
import {Checkbox} from '@uc/cx.react';

type Props = {
  options: {
    label: string;
    value: number;
  }[];
  onChange?: (value: string[]) => void;
  value: string[];
};

export const StatusFilter: FC<Props> = ({options, value, onChange}) => {
  return (
    <Container data-tn="statusFilter">
      {options.map(option => (
        <Checkbox
          key={option.label}
          aria-label={option.label}
          checked={value.includes(option.label)}
          onChange={e => {
            if (e.target.checked) {
              onChange?.([...value, option.label]);
            } else {
              onChange?.(value.filter(v => v !== option.label));
            }
          }}
        >
          <span
            style={{
              color: 'var(--cx-color-textSubtle)',
              fontFamily: 'var(--cx-font-familyLegible)',
              fontSize: 'var(--cx-font-sizeBody2)',
            }}
          >
            <span
              style={{
                color: 'var(--cx-color-black)',
                fontFamily: 'var(--cx-font-familySans)',
                fontWeight: 'var(--cx-font-weightSemiBold)',
              }}
            >
              {option.label}
            </span>
            <span>{` `}</span>
            <StatusCount>
              {option.value}
              <StatusCountSuffix>
                {option.value <= 1 ? ' Listing' : ' Listings'}
              </StatusCountSuffix>
            </StatusCount>
          </span>
        </Checkbox>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: var(--cx-spacing-3x);

  ${({theme}) =>
    !theme.printMode &&
    `
      @media ${mq.maxWidthMobileLandscape} {
        gap: var(--cx-spacing-2x);
      }
    `}
`;

const StatusCount = styled.span`
  ::before {
    content: '(';
  }

  ::after {
    content: ')';
  }
`;

const StatusCountSuffix = styled.span`
  ${({theme}) =>
    !theme.printMode &&
    `
      @media ${mq.maxWidthMobile} {
        display: none;
      }
    `}
`;
