import {spacing} from '@uc/cx-design-tokens';

export type ImageSizingCss = {
  height: number;
  width: number;
  gap: string;
  justifyContent?: string;
  coverFit?: string;
};

const DEFAULT_PHOTO_GALLERY_ROWS = 4;
const DEFAULT_PHOTO_GALLERY_COLS = 3;
const DEFAULT_PHOTO_GALLERY_MATRIX_DISPLAY = `${DEFAULT_PHOTO_GALLERY_ROWS}x${DEFAULT_PHOTO_GALLERY_COLS}`;
const NUM_OF_PDF_PAGES = 1;

export function getMaxImagesPerPage(
  numRows: number | undefined,
  numCols: number | undefined,
) {
  return [
    numRows || DEFAULT_PHOTO_GALLERY_ROWS,
    numCols || DEFAULT_PHOTO_GALLERY_COLS,
  ].reduce((prev, curr) => prev * curr, 1);
}

export function getDefaultMaxNumberOfImagesForPhotoGallery(
  numRows: number | undefined,
  numCols: number | undefined,
) {
  return getMaxImagesPerPage(numRows, numCols) * NUM_OF_PDF_PAGES;
}

export function getPhotoGalleryOptionDisplay(
  numRows: number | undefined,
  numCols: number | undefined,
) {
  const string = [numRows, numCols].join('x');
  if (!numRows || !numCols || !PHOTO_GALLERY_MATRIX_OPTIONS.includes(string)) {
    return DEFAULT_PHOTO_GALLERY_MATRIX_DISPLAY;
  }

  return string;
}

export const PHOTO_GALLERY_MATRIX_OPTIONS = [
  '3x2',
  '4x2',
  '4x3',
  '2x2',
  '2x1',
].sort();

export function getImageSizingByMatrix(
  numRows = DEFAULT_PHOTO_GALLERY_ROWS,
  numCols = DEFAULT_PHOTO_GALLERY_COLS,
) {
  const string = `${numRows}x${numCols}`;
  const IMAGE_SIZING_BY_MATRIX: {
    [key: string]: ImageSizingCss;
  } = {
    ['3x2']: {
      height: 264,
      width: 352,
      gap: `${spacing['2x']} ${spacing['2x']}`,
    },
    ['4x2']: {
      height: 200,
      width: 267,
      gap: '16px 62px',
      justifyContent: 'center',
    },
    ['4x3']: {
      height: 203,
      width: 229,
      gap: `${spacing['2x']} ${spacing['2x']}`,
      coverFit: 'cover',
    },
    ['2x2']: {
      height: 400,
      width: 352,
      gap: `${spacing['2x']} ${spacing['2x']}`,
      coverFit: 'cover',
    },
    ['2x1']: {
      height: 400,
      width: 720,
      gap: `${spacing['2x']} ${spacing['2x']}`,
      coverFit: 'cover',
    },
  };

  return IMAGE_SIZING_BY_MATRIX[string];
}
