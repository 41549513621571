import styled from '@emotion/styled';
import React from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';
import {CmaOutputSection} from '@uc/thrift2npme/dist/cma/cma_models';

import {useStateValue} from '../../../context/state';

const Title = styled.div`
  font-size: ${font.sizeHeader3};
  font-weight: ${font.weightMedium};
  padding: ${spacing['4x']};
`;

const MarginWrapper = styled.div`
  width: fit-content;
  display: block;
  margin: ${spacing['4x']} auto;

  @media ${mq.maxWidthMobileLandscape} {
    width: 100%;
    padding: 0 ${spacing['4x']};
  }
`;

const FlexMarginWrapper = styled.div`
  width: fit-content;
  display: flex;
  margin: ${spacing['3x']} 0;

  @media ${mq.maxWidthMobileLandscape} {
    display: block;
    width: 100%;
  }
`;

const SuggestionWrapper = styled.div`
  width: 476px;

  @media ${mq.maxWidthMobileLandscape} {
    width: 100%;
  }
`;

const SuggestionItem = styled.div`
  margin-bottom: ${spacing['3x']};
  padding-bottom: ${spacing['2x']};
  border-bottom: 1px solid ${color.border};

  :last-child {
    border: none;
  }
`;

const SuggestionItemHeadline = styled.div`
  font-size: ${font.sizeCaption1};
  font-family: ${font.familyLegible};
  font-weight: ${font.weightMedium};
`;

const SuggestionItemTitle = styled.div`
  font-size: ${font.sizeSubheader1};
  margin-top: ${spacing['1x']};
  font-weight: ${font.weightMedium};
`;

const SuggestionItemDescription = styled.div`
  font-size: ${font.sizeSubheader2};
  margin-top: ${spacing['1x']};
`;

const LensItem = styled.div`
  width: 100%;
  margin-top: ${spacing['1x']};
`;

const EstimateWrapper = styled.div`
  width: 225px;
  margin-left: 40px;

  @media ${mq.maxWidthMobileLandscape} {
    width: 100%;
    margin-top: ${spacing['3x']};
    margin-left: 0;
  }
`;

const EstimateTitle = styled.div`
  font-size: ${font.sizeBody1};
  font-weight: ${font.weightMedium};
`;

const EstimateSection = styled.div`
  margin-top: ${spacing['2x']};
`;

const EstimateSectionTile = styled.div`
  font-size: ${font.sizeCaption1};
  font-family: ${font.familyLegible};
  font-weight: ${font.weightMedium};
`;

const EstimateSectionValue = styled.div`
  font-size: ${font.sizeSubheader2};
  font-weight: ${font.weightMedium};
`;

const Line = styled.hr`
  margin-top: ${spacing['2x']};
  border-color: ${color.border};
  border-top: none;
`;

const Disclaimer = styled.div`
  margin-top: ${spacing['2x']};
  font-size: ${font.sizeCaption1};
  font-family: ${font.familyLegible};
  font-weight: ${font.weightRegular};
`;

export const UpgradeSuggestionSection = () => {
  const [{agentInputs}] = useStateValue();
  const {presentationalSettings, upgradeRecommendation} = agentInputs || {};
  const {visibleSections = []} = presentationalSettings || {};
  const section =
    visibleSections.find(
      visibleSection =>
        visibleSection.sectionType === CmaOutputSection.UPGRADE_SUGGESTIONS,
    ) || {};

  const sectionDetails = section.suggestedUpgradesSection || {};
  const {hideTotalExpectedCost, hideExpectedResaleImpact} = sectionDetails;

  return (
    <div>
      <Title>Suggested Upgrades</Title>
      <MarginWrapper>
        <FlexMarginWrapper>
          <SuggestionWrapper>
            {upgradeRecommendation?.upgradeItems?.map((item, index) => (
              <SuggestionItem key={index}>
                <SuggestionItemHeadline>
                  UPGRADE {index + 1}
                </SuggestionItemHeadline>
                {item.title && (
                  <SuggestionItemTitle>{item.title}</SuggestionItemTitle>
                )}
                {item.description && (
                  <SuggestionItemDescription>
                    {item.description.split('\n').map((item, idx) => {
                      return (
                        item && (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        )
                      );
                    })}
                  </SuggestionItemDescription>
                )}
              </SuggestionItem>
            ))}
          </SuggestionWrapper>
          <EstimateWrapper>
            {(!hideTotalExpectedCost || !hideExpectedResaleImpact) && (
              <EstimateTitle data-tn="upgrade-estimate-title">
                Upgrade Estimates
              </EstimateTitle>
            )}
            <EstimateSection>
              {!hideTotalExpectedCost && (
                <>
                  <EstimateSectionTile>Total Expected Cost</EstimateSectionTile>
                  <EstimateSectionValue>
                    {upgradeRecommendation?.estimatedTotalCost
                      ? upgradeRecommendation.estimatedTotalCost
                      : 'To be determined'}
                  </EstimateSectionValue>
                </>
              )}
            </EstimateSection>
            <EstimateSection>
              {!hideExpectedResaleImpact && (
                <>
                  <EstimateSectionTile>
                    Expected Resale Impact
                  </EstimateSectionTile>
                  <EstimateSectionValue>
                    {upgradeRecommendation?.estimatedTotalImpact
                      ? upgradeRecommendation.estimatedTotalImpact
                      : 'To be determined'}
                  </EstimateSectionValue>
                </>
              )}
            </EstimateSection>
            {(!hideTotalExpectedCost || !hideExpectedResaleImpact) && (
              <>
                <Line />
                <Disclaimer>
                  <b>Disclaimer</b>: These are educated guesses about the cost
                  and impact of upgrades and do not represent an exact final
                  outcome. Compass offers no guarantee or warranty of results.
                </Disclaimer>
              </>
            )}
          </EstimateWrapper>
        </FlexMarginWrapper>
      </MarginWrapper>
    </div>
  );
};
