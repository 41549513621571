import styled from '@emotion/styled';
import React from 'react';

import {font, mq, spacing} from '@uc/cx-design-tokens';

const PropertyDetailAgentRemarksWrapper = styled.div`
  padding-top: ${spacing['3x']};

  @media ${mq.maxWidthMobileLandscape} {
    padding: ${spacing['3x']};
  }
`;

const PropertyDetailAgentRemarksTitle = styled.div`
  font-weight: ${font.weightMedium};
  padding-bottom: ${spacing['1x']};
  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeBody2};
    padding-bottom: ${spacing['3x']};
    text-align: center;
  }
`;

const Description = styled.div`
  white-space: pre-line;
  font-size: ${font.sizeBody1};

  @media ${mq.maxWidthMobileLandscape} {
    line-height: 26px;
  }
`;

type Props = {
  hideNotes?: boolean;
  remarks?: string;
  remarksTitle?: string;
};

export const PropertyAgentRemarks = ({
  remarks,
  remarksTitle,
  hideNotes,
}: Props) => {
  if (!remarks || hideNotes) {
    return null;
  }

  const strippedDescription = remarks.replace(/(<([^>]+)>)/gi, '').trim();
  return (
    <PropertyDetailAgentRemarksWrapper>
      <PropertyDetailAgentRemarksTitle>
        {remarksTitle || 'Listing Description'}
      </PropertyDetailAgentRemarksTitle>
      <Description>{strippedDescription}</Description>
    </PropertyDetailAgentRemarksWrapper>
  );
};
