import styled from '@emotion/styled';
import React from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';

const TooltipBlock = styled.div`
  background-color: ${color.white};
  border-radius: ${spacing.borderRadius};
  display: flex;
  padding: 10px;
  box-shadow: 0 1px 4px 0 ${color.innerShadow};

  @media ${mq.maxWidthMobileLandscape} {
    padding: ${spacing['1x']};
  }
`;

const Photo = styled.img`
  background-position: 50%;
  display: ${(props: {src: string}) => (props.src ? 'block' : 'none')};
  height: 50px;
  margin-right: 10px;
  width: 60px;

  @media ${mq.maxWidthMobileLandscape} {
    margin-right: ${spacing['1x']};
  }
`;
Photo.displayName = 'Photo';

const Address = styled.div`
  font-weight: ${font.weightSemiBold};
`;
Address.displayName = 'Address';

type Props = {
  active: boolean;
  payload?: {
    payload: {
      address: string;
      coverPhoto: string;
      price: number;
    };
  }[];
};
export const ChartTooltip: React.FC<Props> = ({active, payload}) => {
  if (!active) {
    return null;
  }

  const address = payload?.[0]?.payload?.address;
  const coverPhoto = payload?.[0]?.payload?.coverPhoto;
  const price = payload?.[0]?.payload?.price;

  return (
    <TooltipBlock>
      <Photo src={coverPhoto || ''} />
      <div>
        <Address>{address}</Address>
        <div>${price?.toLocaleString()}</div>
      </div>
    </TooltipBlock>
  );
};
