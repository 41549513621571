import produce from 'immer';

import setStoreField from '../setStoreField';
import {ISetCmaNetsheetsResultsPayload} from './setCmaNetsheetsResults';
import {ICma} from '../types/Cma';

const setCmaNetsheetsResultsReducer = (
  state: ICma,
  payload: ISetCmaNetsheetsResultsPayload,
) =>
  produce(state, draftState => {
    setStoreField(
      draftState.cma.agentInputs?.netsheets,
      'minResults',
      payload.minResults,
    );
    setStoreField(
      draftState.cma.agentInputs?.netsheets,
      'maxResults',
      payload.maxResults,
    );
  });

export default setCmaNetsheetsResultsReducer;
