import {ListingStatus} from '@uc/thrift2npme/dist/listing/listing_status';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {PinType} from '@/constants';

const PENDING_STATUSES: ListingStatus[] = [
  ListingStatus.CONTRACT_OUT,
  ListingStatus.CONTRACT_SIGNED,
  ListingStatus.CONTINGENCIES_DROPPED,
  ListingStatus.APPLICATION_PENDING,
  ListingStatus.LEASES_OUT,
];
const OFF_MARKET_STATUSES: ListingStatus[] = [
  ListingStatus.TEMPORARILY_OFF_MARKET,
  ListingStatus.PERMANENTLY_OFF_MARKET,
  ListingStatus.EXCLUSIVE_EXPIRED,
];
const SOLD_STATUSES: ListingStatus[] = [
  ListingStatus.SOLD,
  ListingStatus.RENTED,
];

// refer to https://github.com/UrbanCompass/uc-frontend/blob/master/workspaces/maps/packages/clusters.react/src/pin/pinType.ts#L50
export const getPinType = (listing: ProcessedListing): PinType => {
  if (listing.status === ListingStatus.COMING_SOON) {
    return PinType.COMING_SOON;
  } else if (listing.status === ListingStatus.ACTIVE) {
    return PinType.ACTIVE;
  } else if (SOLD_STATUSES.includes(listing.status!)) {
    return PinType.SOLD;
  } else if (PENDING_STATUSES.includes(listing.status!)) {
    return PinType.PENDING;
  } else if (OFF_MARKET_STATUSES.includes(listing.status!)) {
    return PinType.OFF_MARKET;
  } else {
    return PinType.ACTIVE;
  }
};
