export const findLargestVisibleSection = (
  sectionDivs: Element[],
  centerViewRefRect: DOMRect,
): number => {
  let maxAreaDivIndex = 0;
  let maxAreaDiv = 0;

  for (let i = 0; i < sectionDivs.length; i++) {
    const sectionDivRect = sectionDivs[i].getBoundingClientRect();
    const height = Math.max(
      Math.min(sectionDivRect.bottom, centerViewRefRect.bottom) -
        Math.max(sectionDivRect.top, centerViewRefRect.top),
      0,
    );

    const width = Math.max(
      Math.min(sectionDivRect.right, centerViewRefRect.right) -
        Math.max(sectionDivRect.left, centerViewRefRect.left),
      0,
    );

    if (height * width > maxAreaDiv) {
      maxAreaDiv = height * width;
      maxAreaDivIndex = i;
    }
  }

  return maxAreaDivIndex;
};
