import produce from 'immer';

import {
  NetSheetsGeneric,
  NetSheetsLineItem,
} from '@uc/thrift2npme/dist/cma/cma_models';

import setStoreField from '../setStoreField';
import {ICma} from '../types/Cma';

export const updateGenericSalesPriceReducer = (
  state: ICma,
  payload: NetSheetsLineItem,
) =>
  produce(state, draftState => {
    setStoreField(
      draftState.cma.agentInputs?.netsheets?.generic,
      'salesPrice',
      payload,
    );
  });

const updateGenericNetsheetsReducer = (
  state: ICma,
  payload: NetSheetsGeneric,
) =>
  produce(state, draftState => {
    setStoreField(draftState.cma.agentInputs?.netsheets, 'generic', payload);
  });

export default updateGenericNetsheetsReducer;
