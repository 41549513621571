import {createReducer} from '../createReducer';
import {SearchQuerySourceValues} from './types/SearchQuerySource';

// CONSTANTS
export const SEARCH_QUERY_SOURCES = {
  COMPS_QUERY_BUILDER: 'COMPS_QUERY_BUILDER',
  USER: 'USER',
  MAP: 'MAP',
};

// ACTION TYPES
export const SEARCH_QUERY_SOURCE_ACTION_TYPES = {
  SET_SEARCH_QUERY_SOURCE: 'SET_SEARCH_QUERY_SOURCE',
} as const;

// ACTION CREATORS
export const setSearchQuerySource = (
  searchQuerySource: SearchQuerySourceValues,
) => ({
  type: SEARCH_QUERY_SOURCE_ACTION_TYPES.SET_SEARCH_QUERY_SOURCE,
  payload: {searchQuerySource},
});

// REDUCERS
const setSearchQuerySourceReducer = (
  _state: SearchQuerySourceValues,
  payload: {searchQuerySource: SearchQuerySourceValues},
) => payload.searchQuerySource;

// COMBINED REDUCER
export default createReducer<SearchQuerySourceValues>({
  [SEARCH_QUERY_SOURCE_ACTION_TYPES.SET_SEARCH_QUERY_SOURCE]:
    setSearchQuerySourceReducer,
});
