import {createReducer} from '../createReducer';
import IHasAutoTriggeredMlsPanel from './types/HasAutoTriggeredMlsPanel';

export const hasAutoTriggeredMlsPanelInitialState: IHasAutoTriggeredMlsPanel = {
  hasAutoTriggeredMlsPanel: false,
};

// ACTION TYPES
export const HAS_AUTO_TRIGGERED_MLS_PANEL_ACTION_TYPES = {
  SET_HAS_AUTO_TRIGGERED_MLS_PANEL: 'SET_HAS_AUTO_TRIGGERED_MLS_PANEL',
} as const;

// ACTION CREATORS
export const setHasAutoTriggeredMlsPanel = (
  hasAutoTriggeredMlsPanel: boolean,
) => ({
  type: HAS_AUTO_TRIGGERED_MLS_PANEL_ACTION_TYPES.SET_HAS_AUTO_TRIGGERED_MLS_PANEL,
  payload: {hasAutoTriggeredMlsPanel},
});

// REDUCERS
const setHasAutoTriggeredMlsPanelReducer = (
  state: IHasAutoTriggeredMlsPanel,
  payload: {
    hasAutoTriggeredMlsPanel: boolean;
  },
) => ({hasAutoTriggeredMlsPanel: payload.hasAutoTriggeredMlsPanel});

// COMBINED REDUCER
export default createReducer<IHasAutoTriggeredMlsPanel>({
  [HAS_AUTO_TRIGGERED_MLS_PANEL_ACTION_TYPES.SET_HAS_AUTO_TRIGGERED_MLS_PANEL]:
    setHasAutoTriggeredMlsPanelReducer,
});
