import {
  displayStatuses,
  statusToDisplayStatus,
} from '@/utils/listingStatusMaps';

export const getColorByStatusValue = (status: number): string => {
  const lcStatus = statusToDisplayStatus[status]?.key;

  if (lcStatus && lcStatus in displayStatuses) {
    return displayStatuses[lcStatus].color;
  }
  return displayStatuses['other'].color;
};
