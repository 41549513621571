import {ActionWithGenericPayload} from './ducks/types/ActionWithGenericPayload';

export function createReducer<S>(
  // TODO: will be fixed in ACT-5176
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  actionHandlers: Record<string, (state: S, payload: any) => S>,
) {
  return (state: S, action: ActionWithGenericPayload) => {
    if (typeof action !== 'function' && action.type in actionHandlers) {
      return actionHandlers[action.type](state, action.payload);
    }
    return state;
  };
}
