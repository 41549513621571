import styled from '@emotion/styled';
import React from 'react';

import {color, font, mq} from '@uc/cx-design-tokens';
import {AgentInfo} from '@uc/thrift2npme/dist/cma/cma_models';

import {AgentAvatar} from '@/components/AgentAvatar';
import {EditableText} from '@/components/EditableText';
import {AgentCardPlaceholder} from '@/components/AgentCard/AgentCardPlaceholder';

interface Props {
  className?: string;
  agent?: AgentInfo;
  isPreview?: boolean;
  onChangeAgentInfo?: (agent: AgentInfo) => void;
}

export const AgentCard: React.FC<Props> = ({
  agent = {},
  isPreview,
  className,
  onChangeAgentInfo = () => {},
}: Props) => {
  if (!Object.keys(agent).length) {
    return isPreview ? (
      <AgentCardPlaceholder data-tn="agent-card-placeholder" />
    ) : null;
  }

  return (
    <AgentCardContainer
      data-tn="agentCard"
      isPreview={isPreview}
      className={className}
    >
      <AgentAvatar data-tn="agent-avatar" avatarUrl={agent.imageUrl} />
      <AgentDetails>
        <AgentName
          editable={isPreview}
          lengthLimit={30}
          onTextChange={(name: string) => onChangeAgentInfo({...agent, name})}
          value={agent.name}
        />
        <AgentDetailsInfo
          editable={isPreview}
          onTextChange={(info: string) => onChangeAgentInfo({...agent, info})}
          isMultiLine={true}
          value={agent.info}
        />
      </AgentDetails>
    </AgentCardContainer>
  );
};

const AgentCardContainer = styled.div<Props>`
  text-align: left;
  display: flex;
  min-width: 250px;
  height: 104px;
  overflow: hidden;

  ${({isPreview}: Props) =>
    isPreview &&
    `&:hover {
        outline: 1px solid #003375;
      }
  
    &:focus-within {
      outline: 1px dashed #003375;
    }
  `}

  @media ${mq.maxWidthMobileLandscape} {
    height: 80px;
  }
`;

const AgentDetails = styled.div`
  flex: 1;
  padding-left: 5px;
  font-family: ${font.familySans};
  text-align: left;
  width: 100%;
`;

const AgentName = styled(EditableText)`
  font-size: ${font.sizeSubheader1};
  line-height: 26px;
  height: 26px;
  font-weight: bold;
  margin-bottom: 7px;
  display: block;
  min-width: 190px;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 5px;

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeSubheader2};
    line-height: 25px;
    height: 25px;
    margin-bottom: 3px;
  }
`;

const AgentDetailsInfo = styled(EditableText)`
  font-size: ${font.sizeCallout};
  line-height: 21px;
  color: ${color.grey80};
  word-break: break-all;
  display: block;
  height: 63px;
  min-width: 190px;
  overflow: hidden;
  padding-left: 5px;

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeCaption1};
    line-height: 18px;
  }
`;
