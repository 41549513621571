import produce from 'immer';

import {calculateTotalNetProceeds} from '@/pages/EstimatePage/netsheets/calculateNetsheetCosts';
import setStoreField from '../setStoreField';
import {ISetCmaNetsheetsResultsPayload} from './setCmaNetsheetsCustomClosingFees';
import {ICma} from '../types/Cma';

const setCmaNetsheetsCustomClosingFeesReducer = (
  state: ICma,
  payload: ISetCmaNetsheetsResultsPayload,
) =>
  produce(state, draftState => {
    const minResults = draftState.cma.agentInputs?.netsheets?.minResults;
    const maxResults = draftState.cma.agentInputs?.netsheets?.maxResults;

    const minCalculations = {
      ...minResults?.calculations,
      fees: payload.customClosingFees.min,
    };
    const maxCalculations = {
      ...maxResults?.calculations,
      fees: payload.customClosingFees.max,
    };

    setStoreField(draftState.cma.agentInputs?.netsheets, 'minResults', {
      calculations: {
        ...minCalculations,
        totalNetProceeds: calculateTotalNetProceeds(minCalculations),
      },
    });

    setStoreField(draftState.cma.agentInputs?.netsheets, 'maxResults', {
      calculations: {
        ...maxCalculations,
        totalNetProceeds: calculateTotalNetProceeds(maxCalculations),
      },
    });
  });

export default setCmaNetsheetsCustomClosingFeesReducer;
