import {OPTY_FLAGS} from '../constants';
import {useStateValue} from '../context/state';

const useIsTNEPartnerAvailable = (): boolean => {
  const [state] = useStateValue();
  const {partnerConfig} = state ?? {};

  return (
    OPTY_FLAGS.IS_TNE_PARTNER_SPECIFIC_SELLER_NET_SHEET_ENABLED &&
    !!partnerConfig
  );
};

export {useIsTNEPartnerAvailable};
