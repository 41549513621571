import React, {FC} from 'react';
import styled from '@emotion/styled';

import type {SectionProps} from '@/pages/PreviewPage/types';

const ImageContainer = styled.div`
  text-align: center;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export const CustomPdfSection: FC<SectionProps> = ({cma, sectionIndex}) => {
  if (sectionIndex === undefined) {
    return null;
  }

  const source =
    cma.agentInputs?.presentationalSettings?.visibleSections?.[sectionIndex]
      ?.customPdfSection?.source;

  if (!source) {
    return null;
  }

  return (
    <ImageContainer>
      <Image src={source} alt="CustomPdf-image" />
    </ImageContainer>
  );
};
