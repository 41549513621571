import {createReducer} from '../createReducer';
import IIsCmaTitleFromAddress from './types/IsCmaTitleFromAddress';

export const CMA_TITLE_CHANGE_ACTION_TYPES = {
  SET_CMA_TITLE_FROM_ADDRESS: 'SET_CMA_TITLE_FROM_ADDRESS',
} as const;

export const isCmaTitleFromAddressInitialState: IIsCmaTitleFromAddress = false;

// ACTIONS
export const setCmaTitleFromAddress = (isCmaTitleFromAddress: boolean) => ({
  type: CMA_TITLE_CHANGE_ACTION_TYPES.SET_CMA_TITLE_FROM_ADDRESS,
  payload: {isCmaTitleFromAddress},
});

// REDUCERS
const setCmaTitleFromAddressReducer = (
  state: IIsCmaTitleFromAddress,
  payload: {isCmaTitleFromAddress: boolean},
) => payload.isCmaTitleFromAddress;

// COMBINED REDUCER
export default createReducer<IIsCmaTitleFromAddress>({
  [CMA_TITLE_CHANGE_ACTION_TYPES.SET_CMA_TITLE_FROM_ADDRESS]:
    setCmaTitleFromAddressReducer,
});
