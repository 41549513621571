import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

export const getBathrooms = (
  listing: ProcessedListing,
  noSuffix?: boolean,
): string => {
  const value = listing.size?.bathrooms;
  if (value == undefined) {
    return '-';
  }
  if (noSuffix) {
    return value.toLocaleString();
  } else {
    return `${value.toLocaleString()} BA`;
  }
};
