import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';
import {AgentInfo} from '@uc/thrift2npme/dist/cma/cma_models';

import {EditableText} from '@/components/EditableText';
import {AgentCardList} from '@/components/AgentCard/AgentCardList';
import {CoverSummaryLogo} from '@/pages/PreviewPage/components/Preview/components/CoverSummaryLogo/CoverSummaryLogo';
import {ListingPhoto} from '@/components/ListingPhoto';
import {SwipeIndicator} from '@/components/SwipeIndicator';
import {
  getDisplayImageFromThumbnailUrl,
  getAgentInfoFromPerson,
} from '@/utils/display-helpers';
import {SectionProps} from '@/pages/PreviewPage/types';

const MAX_TITLE_LENGTH = 50;

interface StyledProps {
  isPreview?: boolean;
}

const CoverWrapper = styled.div<StyledProps>`
  position: relative;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  z-index: 0;

  @media ${mq.maxWidthMobileLandscape} {
    position: initial;
    height: auto;
    width: 100%;
  }
`;

const SummaryWrapper = styled.div`
  align-items: center;
  border: 1px solid ${color.grey70};
  background-color: white;
  position: relative;
  text-align: center;
  min-width: 680px;
  max-width: 80%;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  top: 80px;

  @media ${mq.maxWidthMobileLandscape} {
    min-width: initial;
    max-width: initial;
    position: initial;
    border: 0;
    left: 0;
    top: 0;
    margin: 0;
    text-align: center;
    width: 100%;
  }
`;

const CoverSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 19px 49px 19px;
`;

const CoverSummaryTitle = styled.div`
  text-transform: capitalize;
  padding: 0 40px;

  color: ${color.textSubtle};
  font-weight: ${font.weightSemiBold};
  font-family: ${font.familyLegible};
  font-size: ${font.sizeCaption2};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeCaption1};
    margin: 0;
  }
`;

const CoverSummaryStreetAddressWrapper = styled.div`
  width: 90%;
  margin-top: ${spacing['1x']};
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${mq.maxWidthMobileLandscape} {
    width: 100%;
  }
`;

const CoverSummaryStreetAddress = styled(EditableText)`
  padding: 0 ${spacing['3x']};
  max-height: 120px;
  overflow: hidden;
  font-size: 35px;
  font-family: ${font.familySerif};
  font-weight: ${font.weightRegular};
  line-height: 115%;
  word-break: break-word;
`;

CoverSummaryStreetAddress.displayName = 'CoverSummaryStreetAddress';

const CoverPhotoWrapper = styled.div<StyledProps>`
  position: absolute;
  width: 100%;
  height: 70vh;
  top: calc(30vh - 70px);

  @media ${mq.maxWidthMobileLandscape} {
    display: none;
  }
`;

const PreparedDate = styled.div`
  background: ${color.grey20};
  height: ${spacing['3x']};
  line-height: ${spacing['3x']};
  color: ${color.black};
  font-size: ${font.sizeCaption2};
`;

const StyledSwipeIndicator = styled(SwipeIndicator)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${spacing['2x']} 0;
  text-align: center;
  background: linear-gradient(180deg, transparent, ${color.grey100});

  @media ${mq.maxWidthMobileLandscape} {
    display: none;
  }
`;
StyledSwipeIndicator.displayName = 'StyledSwipeIndicator';

export const CoverSection: React.FC<SectionProps> = ({
  cma = {},
  sectionIndex = 0,
  isIntersecting,
  isPreview,
  setSectionDetails = () => {},
}) => {
  const {agentInputs, metadata = {}, owner} = cma;
  const {
    assets,
    subjectProperty = {},
    presentationalSettings,
  } = agentInputs || {};
  const asset = assets?.[0];
  const subjectCoverPhotoUrl =
    asset?.originalDetails?.url ||
    subjectProperty.media?.[0]?.originalUrl ||
    getDisplayImageFromThumbnailUrl(subjectProperty.media?.[0]?.thumbnailUrl);

  const shouldShowIndicator = sectionIndex === 0 && !isPreview;
  const {visibleSections = []} = presentationalSettings || {};

  const {showLogo, logoLink, title, agentsInfo} =
    visibleSections[sectionIndex]?.coverSection || {};
  const coverTitle = title || subjectProperty?.location?.prettyAddress || '';

  const agentList = Array.isArray(agentsInfo)
    ? agentsInfo
    : owner
      ? [getAgentInfoFromPerson(owner)]
      : [];

  const updateSectionInfo = (info: {
    title?: string;
    agentsInfo?: AgentInfo[];
  }) => {
    setSectionDetails('coverSection', {
      ...visibleSections[sectionIndex]?.coverSection,
      ...info,
    });
  };

  return (
    <CoverWrapper isPreview={isPreview}>
      <SummaryWrapper>
        <CoverSummary>
          <CoverSummaryLogo
            size={'lg'}
            isPreview={isPreview}
            logoLink={logoLink}
            showLogo={showLogo}
          />
          <CoverSummaryTitle>COMPARATIVE MARKET ANALYSIS</CoverSummaryTitle>
          <CoverSummaryStreetAddressWrapper>
            <CoverSummaryStreetAddress
              className="cover-title"
              dataTn="coverTitle"
              editable={false}
              onTextChange={title => {
                updateSectionInfo({title});
              }}
              lengthLimit={MAX_TITLE_LENGTH}
              value={coverTitle}
              isMultiLine={true}
              shouldRestoreInitialValueWhenCleared={true}
            />
          </CoverSummaryStreetAddressWrapper>
          <AgentCardList
            agentList={agentList}
            isPreview={false}
            updateSectionInfo={updateSectionInfo}
          />
        </CoverSummary>
        <PreparedDate>
          Prepared on {dayjs(metadata.publishTimeMs).format('MMM DD, YYYY')}
        </PreparedDate>
      </SummaryWrapper>
      <CoverPhotoWrapper isPreview={isPreview}>
        <ListingPhoto listingPhotoUrl={subjectCoverPhotoUrl} />
        {shouldShowIndicator ? (
          <StyledSwipeIndicator shouldShowText={!!isIntersecting} />
        ) : null}
      </CoverPhotoWrapper>
    </CoverWrapper>
  );
};
