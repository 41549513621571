import produce from 'immer';

import {NetSheets} from '@uc/thrift2npme/dist/cma/cma_models';

import setStoreField from '../setStoreField';
import {ICma} from '../types/Cma';

const addCmaNetsheetsReducer = (state: ICma, payload: NetSheets) =>
  produce(state, draftState => {
    setStoreField(draftState.cma.agentInputs, 'netsheets', payload);
    setStoreField(draftState.cma.agentInputs?.netsheets, 'addedToCma', false);
  });

export default addCmaNetsheetsReducer;
