import styled from '@emotion/styled';
import React from 'react';

export const LoadingIndicator = () => (
  <Wrapper>
    Loading your listing presentation...
    <LoadingSpinner className="cx-animDotShift" />
  </Wrapper>
);

const Wrapper = styled.div`
  text-align: center;
  margin-top: 100px;
`;

const LoadingSpinner = styled.div`
  vertical-align: middle;
`;
