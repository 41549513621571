import React from 'react';
import styled from '@emotion/styled';

import {color} from '@uc/cx-design-tokens';
import {Icon} from '@uc/cx.react';

const StyledDelta = styled.div<{deltaColor: string}>`
  color: ${props => props.deltaColor};
  fill: ${props => props.deltaColor};
`;

interface IStatsValueDisplayProps {
  curVal: number;
  prevVal: number;
}

export const StatsValue = ({curVal, prevVal}: IStatsValueDisplayProps) => {
  const percentageDiff = getPercentageDiff(curVal, prevVal);
  if (!isFinite(percentageDiff) || isNaN(percentageDiff)) {
    return <></>;
  }

  const deltaColor = getDeltaIconColor(curVal, prevVal);
  const formattedYoY =
    percentageDiff < 10
      ? formatNumber(percentageDiff)
      : Math.round(percentageDiff).toString();

  return (
    <StyledDelta deltaColor={deltaColor}>
      {curVal > prevVal && (
        <Icon.ArrowUp aria-label="arrow up icon" width="12px" height="12px" />
      )}
      {curVal < prevVal && (
        <Icon.ArrowDown
          aria-label="arrow down icon"
          width="12px"
          height="12px"
        />
      )}
      {formattedYoY}%
    </StyledDelta>
  );
};

const getDeltaIconColor = (curVal: number, prevVal: number): string => {
  if (curVal === prevVal) return color.text;
  return curVal > prevVal ? color.success : color.critical;
};

const getPercentageDiff = (curVal: number, prevVal: number): number => {
  if (curVal === 0 && prevVal === 0) return 0;
  else return Math.abs((curVal - prevVal) / prevVal) * 100;
};

const formatNumber = (value: number): string => {
  if (!value && value !== 0) return '';

  const returnNumber = Number(value?.toFixed(1));
  return returnNumber + '';
};
