import React, {useMemo, VFC} from 'react';
import styled from '@emotion/styled';

import {font, spacing, color, mq} from '@uc/cx-design-tokens';

import {NetsheetAmount} from './NetsheetAmount';
import {NetsheetLineItem} from './NetsheetLineItem';
import {
  commissions,
  commissionsNameMap,
  getAmount,
} from '@/utils/netsheet-utils';
import {NetItem, NetsheetGroupProps, NetsheetProps} from '@/types/netsheet';
import {genericNetSheetsDataFilter} from '@/utils/netsheets/genericNetSheetsDataFilter';
import {getEstimatedCommissionText} from '@/utils/netsheets/getEstimatedCommissionText';
import {NetSheetsLineItemOriginal} from '@/utils/netsheets/types';
import {NetsheetFooter} from './NetsheetFooter';

const Wrapper = styled.div<{isPreview?: boolean}>`
  padding: ${spacing['8x']};
  font-family: ${font.familySans};
  ${(props: {isPreview?: boolean}) => props.isPreview && 'min-height: 50vh;'};
  @media ${mq.maxWidthMobileLandscape} {
    padding: ${spacing['1x']};
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${color.text};
  font-weight: ${font.weightMedium};
  font-size: ${font.sizeHeader4};
  line-height: ${font.lineHeightCompact};
  margin-bottom: 20px;
  img {
    height: 32px;
    width: auto;
  }
`;

const SubTitle = styled.div`
  color: ${color.text};
  font-weight: ${font.weightMedium};
  font-size: ${font.sizeSubheader2};
  line-height: ${font.lineHeightBody};
`;

const Proceeds = styled.div`
  color: ${color.black};
  font-size: ${font.sizeXl};
  font-weight: ${font.weightMedium};
  line-height: ${font.lineHeightCompact};
`;

const Section = styled.div`
  margin-top: ${spacing['4x']};
  &:nth-of-type(1) {
    margin-top: 20px;
  }
`;

const SubHeader = styled.div`
  color: ${color.text};
  font-size: ${font.sizeSubheader2};
  font-weight: ${font.weightMedium};
  line-height: ${font.lineHeightBody};
  margin-bottom: ${spacing['1x']};
`;

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing['2x']} 0;
  border-top: 1px solid ${color.grey110};
  margin-top: ${spacing['4x']};
`;

const Total = styled.div`
  color: ${color.text};
  font-weight: ${font.weightMedium};
  font-size: ${font.sizeSubheader2};
  line-height: ${font.lineHeightBody};
`;

const TotalValue = styled.div`
  font-weight: ${font.weightMedium};
  color: ${color.grey120};
  font-size: ${font.sizeBody1};
  line-height: ${font.lineHeightBody};
`;

const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing['1x']} 0;
  border-top: 1px solid ${color.black};
  border-bottom: 1px solid ${color.black};
`;

const Group: VFC<NetsheetGroupProps> = ({header, detail}) => {
  return (
    <Section key={header}>
      {header && <SubHeader data-tn={header}>{header}</SubHeader>}
      {detail.map(d => {
        return (
          <NetsheetLineItem
            key={d.title}
            title={d.title}
            value={getAmount({
              amount1: Number(d.minValue) ? d.minValue : undefined,
              amount2: Number(d.maxValue) ? d.maxValue : undefined,
            })}
            description={d.description}
          />
        );
      })}
    </Section>
  );
};

export const NetsheetGenericSection: VFC<NetsheetProps> = ({
  generic = {},
  isPreview,
}) => {
  const {
    salesPrice,
    mortgage,
    predefinedFees = [],
    additionalFees = [],
  } = generic;

  const {
    salesPriceAndMortgage,
    netEquity,
    subTotal,
    total,
    estimateAndAdditionalFees,
  } = useMemo(
    () =>
      genericNetSheetsDataFilter({
        salesPrice,
        mortgage,
        predefinedFees,
        additionalFees,
      }),
    [salesPrice, mortgage, predefinedFees, additionalFees],
  );

  const equityDetail: NetItem[] = useMemo(() => {
    return [
      {
        title: 'Projected Sales Price',
        minValue: salesPriceAndMortgage?.[0]?.minValue,
        maxValue: salesPriceAndMortgage?.[0]?.maxValue,
      },
      {
        title: 'Current Mortgage Balance',
        minValue: salesPriceAndMortgage?.[1]?.minValue,
        maxValue: salesPriceAndMortgage?.[1]?.maxValue,
      },
      {
        title: 'Net Equity in Property',
        minValue: netEquity?.minValue,
        maxValue: netEquity?.maxValue,
        description: ['Projected sale price - current mortgage balance'],
      },
    ].filter(item => Boolean(Number(item.minValue)));
  }, [salesPriceAndMortgage, netEquity]);

  const feesDetail: NetItem[] = useMemo(() => {
    const {commissionFees, extraFees} = estimateAndAdditionalFees.reduce<{
      commissionFees: NetSheetsLineItemOriginal[];
      extraFees: NetSheetsLineItemOriginal[];
    }>(
      ({commissionFees, extraFees}, fee) => {
        commissions.includes(fee.name ?? '')
          ? commissionFees.push(fee)
          : extraFees.push(fee);
        return {commissionFees, extraFees};
      },
      {commissionFees: [], extraFees: []},
    );

    const commissionFeesFormatted = commissionFees.map(fee => {
      const commissionText = getEstimatedCommissionText({
        min: fee.minPercentage || fee.minValue,
        max: fee.maxPercentage || fee.maxValue,
        isPercentage: fee.isPercentage || false,
      });

      return {
        ...fee,
        name: fee.name && commissionsNameMap[fee.name],
        description: commissionText ? [commissionText] : null,
      };
    });

    const list: NetItem[] = [...commissionFeesFormatted, ...extraFees].map(
      item => ({
        ...item,
        title: item.name || '',
        minValue: item.minValue,
        maxValue: item.maxValue,
      }),
    );

    if (list.length > 0) {
      list.push({
        title: 'Subtotal in Fees',
        minValue: subTotal.minValue,
        maxValue: subTotal.maxValue,
        description: [
          'Purchase contract will determine who is paying these costs',
        ],
      });
    }

    return list;
  }, [subTotal, predefinedFees, additionalFees]);

  if (!salesPriceAndMortgage.length) {
    return null;
  }

  return (
    <Wrapper isPreview={isPreview}>
      <Title>{'Seller net proceeds'}</Title>
      <SubTitleWrapper>
        <SubTitle>{'Estimated Net Proceeds'}</SubTitle>
        <Proceeds>
          <NetsheetAmount
            amount={getAmount({
              amount1: total.minValue,
              amount2: total.maxValue,
              asterisk: true,
            })}
          />
        </Proceeds>
      </SubTitleWrapper>
      <div>
        {equityDetail.length > 0 && (
          <Group
            header="Seller Price and Loan Information"
            detail={equityDetail}
          />
        )}
        {feesDetail.length > 0 && (
          <Group header="Estimated Closing Costs" detail={feesDetail} />
        )}
      </div>
      <TotalWrapper>
        <Total>{'Est. net proceeds'}</Total>
        <TotalValue>
          <NetsheetAmount
            amount={getAmount({
              amount1: total.minValue,
              amount2: total.maxValue,
              asterisk: true,
            })}
          />
        </TotalValue>
      </TotalWrapper>
      <NetsheetFooter />
    </Wrapper>
  );
};
