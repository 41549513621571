import styled from '@emotion/styled';
import React, {FC} from 'react';

import {font, mq, spacing} from '@uc/cx-design-tokens';
import {formatPrice, getListingPrice} from '@uc/format';
import {ListingStatus} from '@uc/thrift2npme/dist/listing/listing_status';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

type PriceBlockProps = {
  isSubject?: boolean;
  listing?: ProcessedListing;
};

export const OFF_MARKET_STATUSES = [
  ListingStatus.EXCLUSIVE_EXPIRED,
  ListingStatus.SOLD,
  ListingStatus.TEMPORARILY_OFF_MARKET,
  ListingStatus.PERMANENTLY_OFF_MARKET,
];
export const SOLD_LOCALIZED_STATUSES = ['Sold', 'Closed'];

export const PriceBlock: FC<PriceBlockProps> = ({isSubject, listing}) => {
  const isOffMarket =
    typeof listing?.status === 'number'
      ? OFF_MARKET_STATUSES.includes(listing.status)
      : false;
  const isSold = listing?.localizedStatus
    ? SOLD_LOCALIZED_STATUSES.includes(listing?.localizedStatus)
    : false;
  const priceTitle = isSold ? 'Sold Price' : 'Last Asking Price';
  const priceAmt = getListingPrice(listing ?? {});
  const formattedPrice = formatPrice(priceAmt || '');

  if (isSubject && isOffMarket) {
    return (
      <Wrapper data-tn="priceBlock">
        <div>
          <PriceValue>Pricing TBD</PriceValue>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper data-tn="priceBlock">
      {formattedPrice ? (
        <>
          <PriceValue>{formattedPrice}</PriceValue>
          <PriceLabel>{priceTitle}</PriceLabel>
        </>
      ) : (
        <>
          <br />
          <br />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 50px;
  text-align: right;
  font-family: ${font.familyLegible};
  padding-bottom: ${spacing['1x']};
  padding-right: ${spacing['1x']};
  padding-left: ${spacing['1x']};
  @media ${mq.maxWidthMobileLandscape} {
    margin: ${spacing['1x']} ${spacing['2x']};
    font-size: ${font.sizeCaption1};
  }
`;

const PriceLabel = styled.div`
  font-size: ${font.sizeCaption1};
  font-style: italic;
`;

const PriceValue = styled.div`
  font-size: ${font.sizeBody2};
  font-weight: ${font.weightSemiBold};
`;
