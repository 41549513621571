import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import {color} from '@uc/cx-design-tokens';

interface Props {
  className?: string;
  shouldShowText?: boolean;
}

interface StyledProps {
  shouldShowText?: boolean;
}

const blink = keyframes`
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
`;

const ContentWrapper = styled.div`
  animation: ${blink} 1s ease-in-out infinite;
  color: ${color.background};
  font-size: 20px;
  font-weight: bold;
`;

const SwipeText = styled.span<StyledProps>`
  transition: opacity 1s;
  opacity: ${({shouldShowText}: StyledProps) => (shouldShowText ? '1' : '0')};
`;

const ChevronDownSVG = styled.svg<StyledProps>`
  fill: ${color.onDarkText};
  transform: scale(1.5);
  transition: opacity 1s;
  opacity: ${({shouldShowText}: StyledProps) => (shouldShowText ? '1' : '0')};
`;

export const SwipeIndicator: React.FC<Props> = ({
  className,
  shouldShowText,
}: Props) => {
  return (
    <ContentWrapper className={className}>
      <SwipeText shouldShowText={shouldShowText} className="swipeText">
        Scroll Down to Start
      </SwipeText>
      <ChevronDownSVG
        data-tn="swipe-indicator-svg"
        shouldShowText={shouldShowText}
        className="cx-icon"
        width="16"
        height="16"
      >
        <use xlinkHref="/ucfe-assets/cx-icons/4/cx-icons.cdn.svg#cx-icon-chevronDown_16x16" />
      </ChevronDownSVG>
    </ContentWrapper>
  );
};
