import produce from 'immer';

import {LensImageItem} from '@uc/thrift2npme/dist/concierge_lens_v0/concierge_lens_v0_service';

import {createReducer} from '../createReducer';
import ILensSelectedImageItems from './types/LensSelectedImageItems';

export const lensSelectedImageItemsInitalState: ILensSelectedImageItems = {
  selectedLensImageItems: [],
};

export const LENS_SELECTION_ACTION_TYPES = {
  SET_SELECTIONS: 'SET_SELECTIONS',
} as const;

export const setSelectedLensImageItems = (
  selectedLensImageItems: LensImageItem[],
) => ({
  type: LENS_SELECTION_ACTION_TYPES.SET_SELECTIONS,
  payload: {selectedLensImageItems},
});

const setSelectionsReducer = (
  state: ILensSelectedImageItems,
  payload: {
    selectedLensImageItems: LensImageItem[];
  },
) =>
  produce(state, draftState => {
    draftState.selectedLensImageItems = payload.selectedLensImageItems;
  });

export default createReducer<ILensSelectedImageItems>({
  [LENS_SELECTION_ACTION_TYPES.SET_SELECTIONS]: setSelectionsReducer,
});
