export const getEstimatedCommissionText = ({
  min,
  max,
  isPercentage,
}: {
  min?: string;
  max?: string;
  isPercentage: boolean;
}): string | null => {
  if (!isPercentage) {
    return null;
  }

  const commission = min === max ? `${min}%` : `${min}% - ${max}%`;

  return `Estimated commission (${commission})`;
};
