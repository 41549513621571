import type {State} from './store';

export const SET_NAV_EXPANDED = 'SET_NAV_EXPANDED';
type SET_NAV_EXPANDED = typeof SET_NAV_EXPANDED;

export const setNavExpanded = (
  isNavExpanded: boolean,
): {type: SET_NAV_EXPANDED; payload: {isNavExpanded: boolean}} => ({
  type: SET_NAV_EXPANDED,
  payload: {isNavExpanded},
});

export type Action = ReturnType<typeof setNavExpanded>;

export const navigationReducer = (state: State, action: Action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_NAV_EXPANDED: {
      const {isNavExpanded} = payload;
      return {
        ...state,
        isNavExpanded,
      };
    }
    default:
      console.log('action type not found: ', type);
      return state;
  }
};
