import React, {FC} from 'react';

import {color, font} from '@uc/cx-design-tokens';

import {useResponsive} from '@/hooks';

type OwnProps = {
  cx?: number;
  cy: number;
  fill: string;
  index?: number;
  priceText?: string[];
  r?: number;
  textColor?: string;
};

export const ChartMarker: FC<OwnProps> = ({
  index,
  priceText,
  cx,
  cy,
  r = 12,
  fill,
  textColor = color.white,
}) => {
  const {isMobile} = useResponsive();

  let updatedCy = cy - 55 < 5 ? 5 : cy - 55;
  let cyPrice = updatedCy < 5 ? 25 : cy - 35;

  if (isMobile) {
    updatedCy = cy - 50 < 5 ? 5 : cy - 50;
    cyPrice = updatedCy < 5 ? 20 : cy - 35;
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      {priceText && (
        <>
          <text
            x={cx}
            y={updatedCy}
            textAnchor="middle"
            dominantBaseline="central"
            fill={color.black}
            fontWeight={font.weightBold}
            fontSize={font.sizeCaption1}
          >
            {priceText[0]}
          </text>
          <text
            x={cx}
            y={cyPrice}
            textAnchor="middle"
            dominantBaseline="central"
            fill={color.black}
            fontWeight={font.weightRegular}
            fontSize={isMobile ? font.sizeBody2 : font.sizeBody1}
          >
            {priceText[1]}
          </text>
        </>
      )}
      <circle cx={cx} cy={cy} r={r} fill={fill} stroke="#fff" />
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        dominantBaseline="central"
        fill={textColor}
        fontWeight={font.weightMedium}
        fontSize={font.sizeBody2}
      >
        {index}
      </text>
    </svg>
  );
};
