import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import styled from '@emotion/styled';

const ChartsContainer = styled.div`
  margin-bottom: 30px;
  width: 50%;
  display: inline-table;
`;

export interface IChartData {
  xAxis: string;
  yAxis: number;
}

interface IColumnChartProps {
  title: string;
  subtitle: string;
  data: IChartData[];
}

export const ColumnChart = (props: IColumnChartProps) => {
  const options: Highcharts.Options = {
    credits: {enabled: false},
    title: {
      align: 'left',
      text: props.title,
      margin: 30,
      style: {
        fontSize: '18px',
        fontWeight: '500',
        fontFamily: 'Compass Sans',
      },
    },
    subtitle: {
      align: 'left',
      text: props.subtitle,
      style: {
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Compass Sans',
        color: '#171717',
        fill: '#171717',
      },
    },
    chart: {reflow: true, height: 215, style: {fontSize: '8'}},
    colors: ['#2986FF', '#3FD4DE'],
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      enabled: false,
    },
    yAxis: {title: {text: ''}},
    xAxis: {
      categories: props.data.map(x => x.xAxis),
      labels: {style: {fontSize: '10px'}},
    },
    tooltip: {backgroundColor: 'white', enabled: false, shared: true},
    plotOptions: {
      series: {
        animation: false,
        label: {connectorAllowed: false},
        marker: {enabled: false},
        dataLabels: {
          enabled: true,
          crop: false,
          style: {fontSize: '9px'},
          animation: {duration: 0},
        },
        enableMouseTracking: false,
      },
    },
    series: [
      {
        name: props.title,
        data: props.data.map(x => [x.xAxis, x.yAxis]),
        type: 'column',
        tooltip: {},
      },
    ],
  };
  return (
    <ChartsContainer>
      <HighchartsReact oneToOne highcharts={Highcharts} options={options} />
    </ChartsContainer>
  );
};
