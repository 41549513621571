import styled from '@emotion/styled';
import React, {VFC} from 'react';

import type {Cma} from '@uc/thrift2npme/dist/cma/cma_models';

import {Disclaimer} from './Disclaimer';
import {MetricCharts} from './MetricCharts';
import {MetricTable} from './MetricTable';
import {ReportTitle} from './ReportTitle';

const Container = styled.div<{isPreview?: boolean}>`
  padding: 48px 48px 20px 48px;
  ${(props: {isPreview?: boolean}) => props.isPreview && 'min-height: 50vh;'}
`;

export const MarketReport: VFC<{cma: Cma; isPreview: boolean}> = ({
  cma,
  isPreview,
}) => {
  return (
    <Container isPreview={isPreview}>
      {cma.agentInputs?.chartQuery && cma.owner && (
        <ReportTitle
          chartQuery={cma.agentInputs.chartQuery}
          owner={cma.owner}
          title={cma.agentInputs.marketReportSettings?.marketReportTitle}
        />
      )}
      {cma.marketReportMetricsItems &&
        cma.agentInputs?.marketReportSettings?.visibleMetrics && (
          <MetricTable
            stats={cma.marketReportMetricsItems}
            visibleMetrics={
              cma.agentInputs?.marketReportSettings?.visibleMetrics
            }
          />
        )}
      {cma.marketReportChartsItems &&
        cma.agentInputs?.marketReportSettings?.visibleCharts && (
          <MetricCharts
            chartItems={cma.marketReportChartsItems}
            visibleCharts={cma.agentInputs?.marketReportSettings?.visibleCharts}
          />
        )}
      {cma.marketReportDisclaimer && (
        <Disclaimer disclaimer={cma.marketReportDisclaimer} />
      )}
    </Container>
  );
};
