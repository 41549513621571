import regression from 'regression';

export default function ({
  minX,
  minY,
  maxX,
  maxY,
  data,
}: {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  data: [number, number][];
}) {
  if (data.length <= 1) {
    return [];
  }

  const regressionResult = regression.linear(data);
  let trendLow = regressionResult.predict(minX);
  let trendHigh = regressionResult.predict(maxX);

  const [m, c] = regressionResult.equation; // y=mx+c
  // equation solved for x: x=(y-c)/m
  // solution used for x coordinate in the conditions below
  const yslope = c / m;
  if (maxY < trendLow[1]) {
    // trend segment point for low x extends above highest y coordinate
    trendLow = [maxY / m - yslope, maxY];
  }
  if (maxY < trendHigh[1]) {
    // trend segment point for high x extends above highest y coordinate
    trendHigh = [maxY / m - yslope, maxY];
  }
  if (minY > trendLow[1]) {
    // trend segment point for low x extends below lowest y coordinate
    trendLow = [minY / m - yslope, minY];
  }
  if (minY > trendHigh[1]) {
    // trend segment point for high x extends below lowest y coordinate
    trendHigh = [minY / m - yslope, minY];
  }
  return [trendLow, trendHigh];
}
