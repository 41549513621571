export function getComparablePropertySubSectionList(compListingLength: number) {
  return [
    SIDE_BY_SIDE_COMPARISON_SUBSECTION_TITLE,
    ...Array.from(Array(compListingLength).keys()).map(
      number => `${COMPARABLE_SUBSECTION_TITLE} #${number + 1}`,
    ),
  ];
}

export const SIDE_BY_SIDE_COMPARISON_SUBSECTION_TITLE =
  'Side-by-side comparison';
export const COMPARABLE_SUBSECTION_TITLE = 'Comparable';
