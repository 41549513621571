import produce from 'immer';

import setStoreField from '../setStoreField';
import {ICma} from '../types/Cma';

export const updateContactPartnerAgentReducer = (
  state: ICma,
  payload: boolean,
) =>
  produce(state, draftState => {
    setStoreField(
      draftState.cma.agentInputs?.netsheets?.input,
      'contactPartnerAgent',
      payload,
    );
  });
