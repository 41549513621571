import React, {FC} from 'react';
import styled from '@emotion/styled';

import {color, font, spacing} from '@uc/cx-design-tokens';
import {formatPrice, getListingPrice} from '@uc/format';
import type {Adjustment} from '@uc/thrift2npme/dist/valuation/ai_valuation/adjustments';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

export const AdjustedPriceBlock: FC<{
  adjustments: Adjustment[];
  listing: ProcessedListing;
}> = ({listing, adjustments}) => {
  const priceAmt = getListingPrice(listing) ?? 0;
  const calculatedPrice = adjustments.reduce(
    (accumulator, adjustment) => accumulator + (adjustment.value ?? 0),
    priceAmt,
  );

  return (
    <AdjustedPriceWrapper>
      <span>Adjusted Price</span>
      <AdjustedPriceValue>{formatPrice(calculatedPrice)}</AdjustedPriceValue>
    </AdjustedPriceWrapper>
  );
};

const AdjustedPriceWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${color.blue40};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: ${spacing.half} ${spacing['1x']};
  border-top: 1px solid ${color.grey80};
  font-size: ${font.sizeCaption1};
  font-family: ${font.familyLegible};
`;

const AdjustedPriceValue = styled.div`
  font-size: ${font.sizeBody2};
`;
