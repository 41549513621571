import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';

import {NetsheetsMembers} from './types';

export const merge = (
  unitNetSheetsLineList: NetsheetsMembers[],
): NetSheetsLineItem[] =>
  unitNetSheetsLineList
    .filter((item): item is NetSheetsLineItem | NetSheetsLineItem[] => !!item)
    .flat();
