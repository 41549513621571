import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

export const getListingKeyDetail = (
  listing: ProcessedListing,
  key: string,
): string => {
  return (
    listing?.detailedInfo?.keyDetails?.filter(el => el.key === key)[0]?.value ??
    ''
  );
};
