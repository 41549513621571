import styled from '@emotion/styled';
import React, {FC} from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';

const Title = styled.div`
  color: ${color.black};
  font-family: ${font.familySans};
  font-weight: ${font.weightMedium};
  font-size: ${font.sizeHeader3};
  padding: ${spacing['3x']};
`;

const Subtitle = styled.span`
  font-weight: ${font.weightRegular};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeSubheader2};
  }
`;

type Props = {
  title: string;
  subtitle?: string;
};

export const SectionTitle: FC<Props> = ({title, subtitle}: Props) => {
  if (!title) {
    return null;
  }
  return (
    <Title>
      {title}
      {subtitle ? <Subtitle> {subtitle}</Subtitle> : null}
    </Title>
  );
};
