import styled from '@emotion/styled';
import React from 'react';

import {spacing, mq} from '@uc/cx-design-tokens';
import {AgentInfo} from '@uc/thrift2npme/dist/cma/cma_models';

import {AgentCard} from './AgentCard';

interface Props {
  agentList: AgentInfo[];
  isPreview?: boolean;
  updateSectionInfo?: (info: {agentsInfo?: AgentInfo[]}) => void;
}

interface AgentWrapperProp {
  agentList: AgentInfo[];
}

const AgentWrapper = styled.div<AgentWrapperProp>`
  display: grid;
  grid-template-columns: repeat(
    ${(props: AgentWrapperProp) =>
      props.agentList.length > 1 ? '2, auto' : '1, 1fr'}
  );
  grid-template-rows: repeat(
    ${(props: AgentWrapperProp) =>
      props.agentList.length > 2 ? '2, 50%' : '1, 1fr'}
  );
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  margin-top: ${spacing['3x']};

  @media ${mq.maxWidthMobileLandscape} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    padding: 0;
    margin-top: ${spacing['4x']};
    grid-row-gap: 30px;
  }
`;

export const AgentCardList: React.FC<Props> = ({
  agentList,
  isPreview,
  updateSectionInfo = () => {},
}: Props) => {
  if (!agentList || !agentList.length) {
    return null;
  }
  return (
    <AgentWrapper agentList={agentList}>
      {agentList.map((agent: AgentInfo, i: number) => (
        <AgentCard
          key={i}
          isPreview={isPreview}
          agent={agent}
          onChangeAgentInfo={newAgent => {
            updateSectionInfo({
              agentsInfo: [
                ...agentList.slice(0, i),
                newAgent,
                ...agentList.slice(i + 1),
              ],
            });
          }}
        />
      ))}
    </AgentWrapper>
  );
};
