import React, {createContext, useContext, useReducer, Dispatch} from 'react';

import {reducer as defaultReducer, State, defaultState, Action} from './store';

export const StateContext = createContext<[State, Dispatch<Action>]>([
  defaultState as State,
  () => undefined,
]);

type StateProviderProps = {
  children: React.ReactNode;
  initialState: State;
  reducer?: (state: State, action: Action) => State;
};

export const StateProvider = ({
  reducer = defaultReducer,
  initialState,
  children,
}: StateProviderProps) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);
