import styled from '@emotion/styled';
import React, {FC} from 'react';
import type {TooltipProps} from 'recharts';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';

const TooltipBlock = styled.div`
  background-color: ${color.white};
  border-radius: ${spacing.borderRadius};
  display: flex;
  padding: 10px;
  box-shadow: 0px 1px 4px 0px ${color.innerShadow};

  @media ${mq.maxWidthMobileLandscape} {
    padding: ${spacing['1x']};
  }
`;

const Photo = styled.img`
  background-position: 50%;
  display: ${props => (props.src ? 'block' : 'none')};
  height: 50px;
  margin-right: 10px;
  width: 60px;

  @media ${mq.maxWidthMobileLandscape} {
    margin-right: ${spacing['1x']};
  }
`;

const Address = styled.div`
  font-weight: ${font.weightSemiBold};
`;

export const ChartTooltip: FC<TooltipProps> = ({active, payload}) => {
  if (!active) {
    return null;
  }

  const address = payload?.[0]?.payload?.address;
  const coverPhoto = payload?.[0]?.payload?.coverPhoto;
  const price = payload?.[0]?.payload?.price;

  return (
    <TooltipBlock>
      <Photo src={coverPhoto} />
      <div>
        <Address>{address}</Address>
        <div>${price?.toLocaleString()}</div>
      </div>
    </TooltipBlock>
  );
};
