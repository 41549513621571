import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

import {ViewPage} from '@/pages/Web/components/ViewPage/ViewPage';
import paths from '@/paths';

// Polyfill is required to have smooth scrolling after clicking a menu item
// for Safari (all other major browsers natively support this)
if (
  typeof window !== 'undefined' &&
  typeof document.documentElement !== 'undefined'
) {
  smoothscroll.polyfill();
}

const App = () => {
  return (
    <Switch>
      <Route path={paths.viewWeb.pattern} component={ViewPage} />
      <Route path={paths.viewWebOld.pattern} component={ViewPage} />
      <Route path="*">
        <Redirect to="/app/cma" />
      </Route>
    </Switch>
  );
};
export default App;
