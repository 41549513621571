import type {Asset} from '@uc/thrift2npme/dist/asset_library/asset_library_models';
import type {Media} from '@uc/thrift2npme/dist/translation_common/media';

/*
 * This function accepts the index of the property (0 means subject, 1+ means comp), the assets
 * that the user has uploaded, and the media array from the listing.
 *
 * For the subject property, the first asset may be the same as the first item in the media array.
 *
 * This function returns the de-duped media array.
 * Example: Assets: [A] Media: [B, C, D] returns [A, B, C, D]
 * Example: Assets: [A] Media: [A, B, C] returns [A, B, C]
 */
export const getMedia = (
  index: number | null,
  assets: Asset[],
  medias: Media[],
) => {
  const finalMedias = medias || [];
  if (index === 0) {
    const firstAsset = assets?.[0];
    if (
      firstAsset?.thumbnailDetails?.url &&
      firstAsset.thumbnailDetails.url !== medias?.[0]?.thumbnailUrl
    ) {
      const firstMedia: Media = {
        thumbnailUrl: firstAsset?.thumbnailDetails?.url,
        originalUrl: firstAsset?.originalDetails?.url,
      };
      return [firstMedia].concat(finalMedias);
    }
  }
  return finalMedias;
};
