import styled from '@emotion/styled';
import React from 'react';
import dayjs from 'dayjs';

import {font, spacing} from '@uc/cx-design-tokens';
import {formatPrice} from '@uc/format';
import {Person} from '@uc/thrift2npme/dist/people/api/person';
import {QueryParams} from '@uc/thrift2npme/dist/trend_chart/trend_chart_service.ucfetch';

import {MarketReportAgentCard} from './MarketReportAgentCard';

interface IReportTitleProps {
  owner: Person;
  chartQuery: QueryParams;
  title?: string;
}

export const ReportTitle = ({owner, chartQuery, title}: IReportTitleProps) => {
  const location =
    chartQuery.counties ||
    chartQuery.cities ||
    chartQuery.zipCodes ||
    chartQuery.neighborhoods;
  const timestamp = dayjs().subtract(1, 'month').format('MMMM YYYY');

  const minPriceUsd = chartQuery.minPriceUsd as number;
  const maxPriceUsd = chartQuery.maxPriceUsd as number;

  return (
    <SplitSection>
      <LeftSection>
        <Title>{title ?? 'Market Report'}</Title>
        <Paragraph>{timestamp}</Paragraph>
        {location && <Paragraph>{location?.join(', ')}</Paragraph>}
        <Paragraph>{chartQuery.propertyTypes?.join(', ')}</Paragraph>
        {!isNaN(minPriceUsd) && !isNaN(maxPriceUsd) && (
          <Paragraph>
            {formatPrice(minPriceUsd)}
            {' - '}
            {formatPrice(maxPriceUsd)}
          </Paragraph>
        )}
      </LeftSection>
      <RightSection>
        <MarketReportAgentCard agent={owner} />
      </RightSection>
    </SplitSection>
  );
};

const SplitSection = styled.div`
  display: flex;
  margin-bottom: ${spacing['2x']};
`;

const LeftSection = styled.div`
  flex-grow: 1;
`;

const RightSection = styled.div`
  padding-left: ${spacing['2x']};
`;

const Title = styled.h1`
  font-size: ${font.sizeHeader1};
  font-weight: ${font.weightRegular};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 498px;
  max-height: 120px;
`;

const Paragraph = styled.div`
  font-size: ${font.sizeSubheader1};
`;
