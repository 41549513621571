import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';

import {NetSheetsLineItemNumber} from './types';

export function fillValue(
  lineItem?: NetSheetsLineItem,
): NetSheetsLineItemNumber {
  if (lineItem) {
    const {minValue, maxValue} = lineItem;
    const max = maxValue || minValue || 0;
    const min = minValue || maxValue || 0;
    return {...lineItem, minValue: Number(min), maxValue: Number(max)};
  }

  return {
    minValue: 0,
    maxValue: 0,
  };
}
