import React from 'react';
import styled from '@emotion/styled';

import {Icon} from '@uc/cx.react';
import {DisclaimerResponse} from '@uc/thrift2npme/dist/trend_chart/trend_chart_service';

interface IDisclaimerProps {
  disclaimer: DisclaimerResponse;
}

export const Disclaimer = (props: IDisclaimerProps) => {
  return (
    <>
      <LongDisclaimer>{props.disclaimer.disclaimer}</LongDisclaimer>
      <Container>
        <LeftIcon>
          <Icon.CompassLogotype
            aria-label="Compass logo"
            className="compassIcon--2x"
          />
        </LeftIcon>
        <ShortDisclaimer>{props.disclaimer.shortText}</ShortDisclaimer>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LongDisclaimer = styled.div`
  text-align: center;
  font-size: 11px;
  margin: 50px 0;
  padding: 0px 40px;
`;

const ShortDisclaimer = styled.span`
  font-size: 9px;
  max-width: 430px;
  text-align: right;
`;

const LeftIcon = styled.div`
  margin-right: 20px;
`;
