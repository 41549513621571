import styled from '@emotion/styled';
import React from 'react';

import {color, font, mq} from '@uc/cx-design-tokens';

interface Props {
  className?: string;
}

export const AgentCardPlaceholder: React.FC<Props> = ({className}: Props) => {
  return (
    <AgentContainer className={className}>
      <BlankAvatar />
      <AgentDetails>
        <AgentName>Agent Name</AgentName>
        <AgentInfo>Executive Agent</AgentInfo>
        <AgentInfo>123.654.7890</AgentInfo>
        <AgentInfo>First.Lastname@compass.com</AgentInfo>
      </AgentDetails>
    </AgentContainer>
  );
};

const AgentContainer = styled.div`
  text-align: left;
  display: flex;
  width: 299px;
  height: 104px;

  &:hover {
    outline: 1px solid #003375;
  }

  @media ${mq.maxWidthMobileLandscape} {
    height: 80px;
  }
`;

const BlankAvatar = styled.div`
  width: 104px;
  height: 100%;
  background: #f4f4f4;

  @media ${mq.maxWidthMobileLandscape} {
    width: 80px;
  }
`;

const AgentDetails = styled.div`
  flex: 1;
  padding-left: 10px;
  font-family: ${font.familySans};
  text-align: left;
`;

const AgentName = styled.div`
  font-size: ${font.sizeSubheader1};
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 7px;
  color: ${color.grey60};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeSubheader2};
    line-height: 25px;
    height: 25px;
    margin-bottom: 3px;
  }
`;

const AgentInfo = styled.div`
  font-size: ${font.sizeCallout};
  line-height: 21px;
  color: ${color.grey80};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeCaption1};
    line-height: 18px;
  }
`;
