import {NetSheetsLineItemNumber, NetSheetsLineItemOriginal} from './types';

export function valueToString(
  lineItem: NetSheetsLineItemNumber,
): NetSheetsLineItemOriginal {
  const {minValue, maxValue, minPercentage, maxPercentage} = lineItem;

  return {
    ...lineItem,
    minValue: minValue.toString(),
    maxValue: maxValue.toString(),
    minPercentage: minPercentage?.toString(),
    maxPercentage: maxPercentage?.toString(),
  };
}
