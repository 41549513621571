/**
 * TODO: Currently all fields are placed in @uc/cma-adapters package,
 * but these fields are only used in the valuation app,
 * for ease of maintenance and development efficiency we should
 * migrate them to the src/utils/propertyFields directory.
 *
 * Ticket: https://compass-tech.atlassian.net/browse/ACT-6223
 */

export * from './listingDescription';
