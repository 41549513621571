import get from 'lodash.get';
import dayjs from 'dayjs';

import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import {formatPrice} from '@uc/format';
// TODO: Once the ProcessedListing struct has the compassPropertyId exposed as its own field, we can remove this
export const getPropertyId = (listing: ProcessedListing) => {
  const propertyPageLink = listing?.propertyPageLink;
  if (!propertyPageLink) {
    return;
  }
  const splitLinkChunks = propertyPageLink
    .split('/')
    .filter((chunk: unknown) => chunk);
  return splitLinkChunks[splitLinkChunks.length - 1];
};

export const getDaysOnMarket = (
  listing: ProcessedListing | undefined,
  path: string,
): string => {
  const value = get(listing, path, '-');

  return `${value} Days`;
};

export const getDateField = (
  listing: ProcessedListing | undefined,
  path: string,
): string => {
  const value = get(listing, path, '-');

  if (value === '-') {
    return '-';
  }

  return dayjs(new Date(value)).format('MMM DD, YYYY').toUpperCase();
};

export const getValueForPropertyDetailCmaLp = (
  path: string,
  listing: ProcessedListing,
): string => {
  if (path.includes('price')) {
    return formatPrice(get(listing, path, '-'));
  } else if (path === 'date.daysOnMarket') {
    return getDaysOnMarket(listing, path);
  }
  return getDateField(listing, path);
};
