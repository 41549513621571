import {useEffect, useState} from 'react';

import {
  fetchAgentSearchPreferences,
  fetchFilterPanelConfig,
} from '@/utils/agentSearch';

// Get the mlsId which agent selected in the agent-search.
// And get the region corresponding to MLS
// This is currently only used on the Set Subject page when using address-input.react component
export function useSearchPreferences() {
  const [limitToGeos, setLimitToGeos] = useState<string[]>([]);
  const [sourceNames, setSourceNames] = useState<string[]>([]);

  useEffect(() => {
    const getSearchPreferences = async () => {
      const {
        sourceId,
        userRoles: mockRoles = [],
        byModeAndType = {},
      } = await fetchAgentSearchPreferences();
      if (!sourceId) {
        return;
      }
      const key = `${sourceId}-SALES`;
      const {geographies, listingFeeds} = byModeAndType[key] ?? {};
      let defaultGeographies: string[] = [];
      let defaultListingFeeds: string[] = [];
      if (!geographies || !listingFeeds) {
        const filterPanelConfig = await fetchFilterPanelConfig({
          sourceId,
          mockRoles,
          userRoles: global?.uc?.user?.roles ?? [],
        });
        const regions = filterPanelConfig.regions
          ?.map(item => item.id)
          .filter((id): id is string => !!id);
        defaultGeographies = regions ?? filterPanelConfig.geographies ?? [];
        defaultListingFeeds =
          filterPanelConfig.listingFeeds
            ?.map(feed => feed.id)
            .filter((id): id is string => !!id) ?? [];
      }
      setLimitToGeos(geographies ?? defaultGeographies);
      setSourceNames(listingFeeds ?? defaultListingFeeds);
    };

    getSearchPreferences().catch(e => console.log(e));
  }, []);

  return {
    limitToGeos,
    sourceNames,
  };
}
