import produce from 'immer';

import {createReducer} from '../createReducer';
import IPartnerConfigState from './types/PartnerConfig';

export const partnerConfigInitialState: IPartnerConfigState = {};

export const PARTNER_CONFIG_ACTION_TYPES = {
  SET_PARTNER_CONFIG: 'SET_PARTNER_CONFIG',
} as const;

// ACTIONS
export const setPartnerConfig = (partnerConfig: IPartnerConfigState) => ({
  type: PARTNER_CONFIG_ACTION_TYPES.SET_PARTNER_CONFIG,
  payload: partnerConfig,
});

// REDUCER
export const partnerConfigReducer = createReducer<IPartnerConfigState>({
  [PARTNER_CONFIG_ACTION_TYPES.SET_PARTNER_CONFIG]: (
    state,
    partnerConfig: IPartnerConfigState,
  ) => produce(state, () => partnerConfig),
});
