import type {Person} from '@uc/thrift2npme/dist/people/people_models';

import type {State} from './store';

export const SET_AGENT = 'SET_AGENT';
type SET_AGENT = typeof SET_AGENT;

export const setAgent = (
  agent: Person,
): {type: SET_AGENT; payload: {agent: Person}} => ({
  type: SET_AGENT,
  payload: {agent},
});

export type Action = ReturnType<typeof setAgent>;

export const agentReducer = (state: State, action: Action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_AGENT: {
      const {agent} = payload;
      return {
        ...state,
        agent,
      };
    }
    default:
      console.log('action type not found: ', type);
      return state;
  }
};
