import {mq} from '@uc/cx-design-tokens';
import {useMediaQuery} from '@uc/cx.react';

interface UseResponsive {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isMobilePortrait: boolean;
}

/**
 * Hook returning flags for different device sizes based on the breakpoints from cx-design-token.
 * https://github.com/UrbanCompass/uc-frontend/blob/master/packages/cx-design-tokens/tokens/mq.json
 * This is useful for typescript content control. For styling please use media queries instead.
 */
export const useResponsive = (): UseResponsive => {
  const tabletMinQuery = useMediaQuery(mq.minWidthTablet);
  const tabletMaxQuery = useMediaQuery(mq.maxWidthTablet);
  const isMobile = useMediaQuery(mq.maxWidthMobileLandscape);
  const isMobilePortrait = useMediaQuery(mq.maxWidthMobile);
  const isTablet = tabletMinQuery && tabletMaxQuery;
  const isDesktop = useMediaQuery(mq.minWidthDesktop);
  const isLaptop = !isDesktop && !isTablet && !isMobile && !isMobilePortrait;

  return {
    isDesktop,
    isMobile,
    isTablet,
    isLaptop,
    isMobilePortrait,
  };
};
