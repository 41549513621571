import React from 'react';
import styled from '@emotion/styled';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';
import {CmaOutputSection, type Cma} from '@uc/thrift2npme/dist/cma/cma_models';

import {ListingPhoto} from '@/pages/Web/components/listingPhoto/listingPhoto';
import {PropertyAgentRemarks} from '@/pages/Web/components/propertyAgentRemarks/propertyAgentRemarks';
import {getDisplayImageFromThumbnailUrl} from '@/utils/display-helpers';
import {getPriceAnalysisDisclaimer} from './getPriceAnalysisDisclaimer';

export const PricingAnalysis = ({
  cma,
  isPreview,
}: {
  cma: Cma;
  isPreview: boolean;
}) => {
  const {subjectProperty, agentInputs} = cma;

  const {presentationalSettings} = agentInputs || {};
  const {visibleSections = []} = presentationalSettings || {};
  const section =
    visibleSections.find(
      visibleSection =>
        visibleSection.sectionType === CmaOutputSection.ESTIMATE,
    ) || {};

  const priceEstimatesectionDetails = section.priceEstimateSection || {
    hideNotes: false,
  };
  const {hideNotes = false} = priceEstimatesectionDetails;
  const listingPhotoUrl =
    agentInputs?.assets?.[0]?.originalDetails?.url ||
    subjectProperty?.media?.[0]?.originalUrl ||
    getDisplayImageFromThumbnailUrl(subjectProperty?.media?.[0]?.thumbnailUrl);

  return (
    <PricingAnalysisContainer
      isPreview={isPreview}
      data-tn="PricingAnalysisContainer-view"
    >
      <PhotoWrapper data-tn="PhotoWrapper-view">
        <ListingPhoto
          data-tn="ListingPhoto-view"
          listingPhotoUrl={listingPhotoUrl}
        />
        <OverLayContainer data-tn="OverLayContainer-view">
          <Title>Price Estimate</Title>
          <PriceRange data-tn="PriceRange-view">
            {agentInputs?.price?.value
              ? agentInputs.price.value + '*'
              : 'Price to be Determined'}
          </PriceRange>
          <Address>
            <div>{subjectProperty?.location?.prettyAddress}</div>
          </Address>
        </OverLayContainer>
      </PhotoWrapper>

      <AgentRemarkWrapper data-tn="AgentRemarkWrapper-view">
        <PropertyAgentRemarks
          data-tn="PropertyAgentRemarks-view"
          remarks={agentInputs?.remarks}
          hideNotes={hideNotes}
          remarksTitle="Agent Remarks"
        />
      </AgentRemarkWrapper>
      {agentInputs?.remarks && !hideNotes && (
        <Separator data-tn="Separator-view" />
      )}
      <Disclaimer data-tn="Disclaimer-view">
        {getPriceAnalysisDisclaimer(cma)}
      </Disclaimer>
    </PricingAnalysisContainer>
  );
};

const PricingAnalysisContainer = styled.div<{
  isPreview?: boolean;
}>`
  align-items: center;
  background-color: ${color.background};
  padding-bottom: 40px;
  ${(props: {isPreview?: boolean}) => props.isPreview && 'min-height: 50vh;'};
`;

const PhotoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 30vh;

  @media ${mq.maxWidthMobileLandscape} {
    height: 35vh;
  }
`;

const OverLayContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  color: ${color.white};

  @media ${mq.maxWidthMobileLandscape} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    font-size: ${font.sizeBody2};
  }
`;

const Title = styled.div`
  margin-top: 8vh;
  font-size: ${font.sizeBody2};
  font-weight: ${font.weightBold};
  text-transform: uppercase;

  @media ${mq.maxWidthMobileLandscape} {
    margin-top: 0;
    font-size: ${font.sizeBody2};
    padding-bottom: ${spacing['1x']};
  }
`;

const PriceRange = styled.div`
  margin-top: 5px;
  font-size: ${font.sizeHeader3};
  font-weight: ${font.weightBold};

  @media ${mq.maxWidthMobileLandscape} {
    margin-top: 0;
    font-size: ${font.sizeSubheader1};
    padding-bottom: ${spacing['2x']};
  }
`;

const Address = styled.div`
  margin-top: 10px;
  font-size: ${font.sizeSubheader2};
  font-weight: ${font.weightMedium};
`;

const AgentRemarkWrapper = styled.div`
  width: 70%;
  max-width: 1000px;
  margin: 20px auto;

  @media ${mq.maxWidthTabletLandscape} {
    width: calc(100vw - 40px);
  }

  @media ${mq.maxWidthMobileLandscape} {
    width: 100vw;
    margin: 0;
  }
`;

const Separator = styled.hr`
  width: 70%;
  max-width: 1000px;
  margin: 20px auto;

  @media ${mq.maxWidthTabletLandscape} {
    width: calc(100vw - 40px);
  }
`;

const Disclaimer = styled.div`
  width: 70%;
  max-width: 1000px;
  margin: 0 auto;
  font-size: ${font.sizeCaption1};
  font-family: ${font.familyLegible};
  color: ${color.text};

  @media ${mq.maxWidthTabletLandscape} {
    width: calc(100vw - 40px);
  }

  @media ${mq.maxWidthMobileLandscape} {
    margin: 0 20px;
  }
`;
