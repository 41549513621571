import styled from '@emotion/styled';
import React, {FC} from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';
import {Icon} from '@uc/cx.react';

import {displayStatuses} from '@/utils/listingStatusMaps';
import {ROW_HEADER_HEIGHT_PX} from './constants';

type Props = {
  displayStatus?: string;
  isExpanded?: boolean;
  listingsCount?: number;
  onClick?: () => void;
};

export const GroupHeader: FC<Props> = ({
  displayStatus,
  isExpanded = false,
  listingsCount,
  onClick,
}) => (
  <Wrapper
    data-e2e-listings-group-name-header={displayStatus}
    className="group-header"
    border={typeof displayStatus === 'undefined' ? 'bottom' : 'top'}
    onClick={onClick}
  >
    {typeof displayStatus !== 'undefined' &&
    typeof listingsCount !== 'undefined' ? (
      <>
        <GroupName>{displayStatuses[displayStatus]?.label}</GroupName>
        <ListingsCountLabel>({listingsCount})</ListingsCountLabel>
        <ChevronSvg
          data-tn="chevron-icon"
          width="16"
          height="16"
          isExpanded={isExpanded}
        />
      </>
    ) : (
      <div data-tn="empty-group-header">&nbsp;</div>
    )}
  </Wrapper>
);

const Wrapper = styled.div<{border?: 'bottom' | 'top'}>`
  cursor: pointer;
  border-${({border}) => border}: 1px solid ${color.grey40};
  height: ${ROW_HEADER_HEIGHT_PX}px;

  padding: 0 ${spacing['2x']};
  display: flex;
  align-items: center;
`;

const GroupName = styled.span`
  font-weight: ${font.weightBold};
  vertical-align: middle;
`;

const ListingsCountLabel = styled.span`
  padding-left: ${spacing['1x']};

  @media ${mq.maxWidthMobileLandscape} {
    padding-left: ${spacing.half};
  }
`;

const ChevronSvg = styled(Icon.ChevronDown)<{isExpanded: boolean}>`
  transform: scale(1.5) ${({isExpanded}) => (isExpanded ? '' : ' scale(1, -1)')};
  vertical-align: middle;
  margin-left: ${spacing['2x']};
  transition: transform 0.5s;

  @media ${mq.maxWidthMobileLandscape} {
    margin-left: ${spacing['1x']};
    transform: scale(1.3)
      ${({isExpanded}) => (isExpanded ? '' : ' scale(1, -1)')};
  }
`;
