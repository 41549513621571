/**
 * comparison listings related helper functions
 */

import currency from 'currency.js';

import type {AgentInputs} from '@uc/thrift2npme/dist/cma/cma_models';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import type {Adjustment} from '@uc/thrift2npme/dist/valuation/ai_valuation/adjustments';
import {getListingPrice} from '@uc/format';

import {statusToDisplayStatus} from '@/utils/listingStatusMaps';

export const groupByStatus = (listings: ProcessedListing[]) => {
  return listings.reduce<Record<string, ProcessedListing[]>>((prev, curr) => {
    const label = statusToDisplayStatus[curr.status as number].label;
    return {...prev, [label]: [...(prev[label] ?? []), curr]};
  }, {});
};

export const getAdjustedPrice = ({
  listing,
  appliedAdjustments,
}: {
  listing: ProcessedListing;
  appliedAdjustments: Record<string, Adjustment[]>;
}): number => {
  const adjustments =
    (listing.listingIdSHA && appliedAdjustments[listing.listingIdSHA]) || [];
  return adjustments.reduce<number>(
    (prev, curr) => prev + (curr.value || 0),
    getListingPrice(listing) || 0,
  );
};

export const calculateAverage = ({
  compListings,
  agentInputs,
}: {
  compListings: ProcessedListing[];
  agentInputs: AgentInputs;
}): ProcessedListing => {
  if (compListings.length <= 0) {
    return {};
  }

  const {appliedAdjustments = {}, selectListingAttributes = []} = agentInputs;

  const overridePricePerSquareFoot =
    selectListingAttributes.find(item =>
      item.processedListingPath?.includes('price.perSquareFoot'),
    )?.overrideValue ?? {};

  const count = compListings.length;
  let countWithSqFt = 0;
  let countWithPerSqFt = 0;

  let totalBeds = 0;
  let totalBaths = 0;
  let totalLotSize = 0;
  let totalOnMarketDays = 0;
  let totalSqFt = 0;
  let totalPerSqFt = 0;
  let totalPrice = 0;

  for (const listing of compListings) {
    const adjustedPrice = getAdjustedPrice({listing, appliedAdjustments});

    totalPrice += adjustedPrice;
    totalBeds += listing.size?.bedrooms ?? 0;
    totalBaths += listing.size?.bathrooms ?? 0;
    totalLotSize += listing.size?.lotSizeInSquareFeet ?? 0;
    totalOnMarketDays += listing.date?.daysOnMarket ?? 0;
    totalSqFt += listing.size?.squareFeet ?? 0;

    const overridePrice = overridePricePerSquareFoot[listing.listingIdSHA!];
    const numOverridePrice = currency(overridePrice).value;
    const pricePerSquareFoot = overridePrice
      ? isNaN(numOverridePrice)
        ? 0
        : numOverridePrice
      : listing.price?.perSquareFoot ?? 0;
    if (pricePerSquareFoot) {
      totalPerSqFt += pricePerSquareFoot;
      countWithPerSqFt += 1;
    }

    if (listing.size?.squareFeet) {
      countWithSqFt += 1;
    }
  }

  return {
    size: {
      bedrooms: Number((totalBeds / count).toFixed(1)),
      bathrooms: Number((totalBaths / count).toFixed(1)),
      squareFeet: countWithSqFt
        ? Math.round(totalSqFt / countWithSqFt)
        : undefined,
      lotSizeInSquareFeet: Math.round(totalLotSize / count),
    },
    date: {
      daysOnMarket: Math.round(totalOnMarketDays / count),
    },
    price: {
      perSquareFoot: countWithPerSqFt
        ? Math.round(totalPerSqFt / countWithPerSqFt)
        : undefined,
      lastKnown: Math.round(totalPrice / count),
    },
  };
};
