import {getPreferences} from '@uc/thrift2npme/dist/agent_preferences/agent_preferences_service.ucfetch';
import {
  getFilterPanels,
  getFilterPanelsByMockRoles,
} from '@uc/thrift2npme/dist/search_query_config/search_query_config_service.ucfetch';
import type {
  FilterPanelsResponse,
  FilterPanel,
} from '@uc/thrift2npme/dist/search_query_config/filter_panels';

import {ADMIN_ROLE} from '@/constants';

export const fetchFilterPanelConfig = async ({
  sourceId,
  mockRoles,
  userRoles,
}: {
  sourceId: string;
  mockRoles: string[];
  userRoles: string[];
}): Promise<FilterPanel> => {
  let res: FilterPanelsResponse;
  if (userRoles.includes(ADMIN_ROLE) && mockRoles.length > 0) {
    res = await getFilterPanelsByMockRoles({mockRoles});
  } else {
    res = await getFilterPanels({});
  }
  return res.filterPanels?.find(item => item.id === sourceId) ?? {};
};

export const fetchAgentSearchPreferences = async (): Promise<{
  sourceId?: string;
  userRoles?: string[];
  workflow?: string;
  byModeAndType?: {
    [key: string]: {
      geographies?: string[];
      listingFeeds?: string[];
    };
  };
}> => {
  const {preferences} = await getPreferences('agent-search', {});
  if (!preferences) {
    return {};
  }
  return JSON.parse(preferences);
};
