import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

export const COMPASS_TYPE = 'Compass Type';
export const SINGLE_FAMILY = 'Single Family';
export const CONDO_CO_OP_FEES = 'Condo/Co-op Fees';
export const HOA_FEES = 'HOA Fees';

export const getDetailFields = (listing: ProcessedListing) => {
  const keyDetails = listing?.detailedInfo?.keyDetails;
  if (!keyDetails) {
    return null;
  }

  let propertyType: string | undefined;
  for (let i = 0; i < keyDetails.length; i++) {
    if (keyDetails[i].key === COMPASS_TYPE) {
      propertyType = keyDetails[i].value;
    }
  }

  return keyDetails.filter(detail => {
    // filter DOM because we already show it in the porperty general info section
    if (detail.key?.trim() === 'DOM') {
      return false;
    }
    if (!detail.value || detail.value.trim() === '-') {
      return false;
    }
    if (
      propertyType === SINGLE_FAMILY &&
      (detail.key === CONDO_CO_OP_FEES || detail.key === HOA_FEES)
    ) {
      return false;
    }

    return true;
  });
};

export const propertyDetailFieldsForCmaLp = [
  ['Original Listing Price', 'price.listed'],
  ['Last Asking Price', 'price.preClose'],
  ['Closing Price', 'price.closed'],
  ['PPSF', 'price.perSquareFoot'],
  ['Listed Date', 'date.listed'],
  ['Contract Date', 'date.contract'],
  ['Sold Date', 'date.closed'],
  ['DOM', 'date.daysOnMarket'],
];
