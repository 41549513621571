import isEqual from 'lodash/isEqual';

import {
  convertDisplayListingToProcessedListing,
  defaultDisplayableFields,
  DISPLAY_LISTING_VALUE_ATTRIBUTES,
} from '@uc/cma-adapters';
import {
  DisplayListing,
  ListingDetail,
  ListingValueCharge,
  ListingValueContactArray,
  ListingValueDouble,
  ListingValueI32,
  ListingValueI64,
  ListingValueListingStatus,
  ListingValueString,
  ListingValueStringArray,
  ListingValueStringMap,
} from '@uc/thrift2npme/dist/cma/cma_models';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {type CustomizeFieldsModalArgs} from '@/types/types';
import {setDisplayListings, setSubjectProperty} from '@/context/ducks/cma';
import {
  fieldsToCustomListingAttribute,
  getCustomizablePropertyFields,
} from '@/utils/customize-fields';
import {createReducer} from '../createReducer';
import type {ThunkFunction} from '../types/Store';
import PropertyField from './types/PropertyField';

export const propertyFieldsInitialState: PropertyField[] =
  defaultDisplayableFields.filter(field => field.defaultSelected);

export const PROPERTY_FIELDS_ACTION_TYPES = {
  SET_PROPERTY_FIELDS: 'SET_PROPERTY_FIELDS',
} as const;

// ACTIONS
export const setPropertyFields = (
  propertyFields: CustomizeFieldsModalArgs['selectedFields'],
  shouldTransform = false,
) => ({
  type: PROPERTY_FIELDS_ACTION_TYPES.SET_PROPERTY_FIELDS,
  payload: {
    propertyFields: shouldTransform
      ? fieldsToCustomListingAttribute(propertyFields)
      : propertyFields,
  },
});

// REDUCERS
const setPropertyFieldsReducer = (
  state: PropertyField[],
  payload: {propertyFields: PropertyField[]},
) => payload.propertyFields;

// THUNKS
// Helpful for filtering any current fields after a comp has been removed
// i.e. comp1 has "Washer" as a listed amenity alone, and when removed, we want to remove
//      the amenity from the list of user-selected customizable fields if it exists
export const filterPropertyFields =
  (): ThunkFunction => (dispatch, state, getState) => {
    const latestState = getState();
    const subjectProperty = latestState.cma.cma.agentInputs?.subjectProperty;
    const comps = latestState.comps?.compListings;
    const allPropertyFields = getCustomizablePropertyFields(
      subjectProperty || {},
      comps,
    );
    const allFieldsSet = new Set(
      allPropertyFields.map(field => field.processedListingPath),
    );
    const currentFields = latestState.propertyFields;
    const currentFieldsFiltered = currentFields.filter((field: PropertyField) =>
      allFieldsSet.has(field.processedListingPath as string),
    );
    dispatch(setPropertyFields(currentFieldsFiltered));
  };

export default createReducer<PropertyField[]>({
  [PROPERTY_FIELDS_ACTION_TYPES.SET_PROPERTY_FIELDS]: setPropertyFieldsReducer,
});

// Since a value of a DisplayListing field can be of multiple types (i.e primitive, array, object, etc.)
// we pass in the field-defined attribute value type to determine what value to pull from the DisplayListing
// for comparison below

type IAttributeValue =
  | ListingValueString
  | ListingValueListingStatus
  | ListingValueDouble
  | ListingValueCharge
  | ListingValueI64
  | ListingValueI32
  | ListingValueStringArray
  | ListingValueStringMap
  | ListingValueContactArray
  | ListingDetail[];
const getDisplayListingCompareValue = (
  attributeValue: IAttributeValue,
  attributeType = '',
) => {
  if (!attributeValue) {
    return;
  }
  if (
    attributeType === DISPLAY_LISTING_VALUE_ATTRIBUTES.VALUE_AMENITIES ||
    attributeType === DISPLAY_LISTING_VALUE_ATTRIBUTES.VALUE_KEY_DETAILS
  ) {
    return (attributeValue as ListingValueStringMap).valueMap;
  } else if (
    attributeType === DISPLAY_LISTING_VALUE_ATTRIBUTES.LISTING_DETAIL
  ) {
    return attributeValue;
  }
  return (
    attributeValue as Exclude<
      IAttributeValue,
      ListingValueStringMap | ListingDetail[]
    >
  ).value;
};

export const clearTableEdits =
  (clearSubject?: boolean): ThunkFunction =>
  (dispatch, state, getState) => {
    // TODO: when cma_price_estimate_2 flag is fully opend.
    // The following codes can be simplized to just set
    // filteredEditedDisplayListings and filteredOrigDisplayListings
    // be undefined
    const latestState = getState();
    const {editedDisplayListings = {}, origDisplayListings = {}} =
      latestState.cma.cma.agentInputs || {};
    const filteredEditedDisplayListings = Object.keys(
      editedDisplayListings,
    ).reduce((acc: {[key: string]: DisplayListing}, listingId) => {
      if (listingId === 'subject' && !clearSubject) {
        acc[listingId] = editedDisplayListings[listingId];
      }
      return acc;
    }, {});
    const filteredOrigDisplayListings = Object.keys(origDisplayListings).reduce(
      (acc: {[key: string]: DisplayListing}, listingId) => {
        if (listingId === 'subject' && !clearSubject) {
          acc[listingId] = editedDisplayListings[listingId];
        }
        return acc;
      },
      {},
    );
    dispatch(
      setDisplayListings(
        Object.keys(filteredEditedDisplayListings).length
          ? filteredEditedDisplayListings
          : undefined,
        Object.keys(filteredOrigDisplayListings).length
          ? filteredOrigDisplayListings
          : undefined,
      ),
    );
  };

/* eslint-disable valid-jsdoc */
/**
 * Compares values of incoming edits/original values of a field with any previous edits/original values of a field
 * and determines whether a save is needed or not, and what to save in the editedDisplayListings and origDisplayListings
 * newEditedDisplayListing - this is the incoming edit
 * newOriginalDisplayListing - this is the incoming original listing value before the edit
 */
/* eslint-disable valid-jsdoc */
export const saveEditedValues =
  (
    listingKey: string | undefined,
    fieldId: keyof DisplayListing | undefined,
    newEditedDisplayListing: DisplayListing,
    newOriginalDisplayListing: DisplayListing,
    attributeType: string,
  ): ThunkFunction =>
  (dispatch, state, getState) => {
    if (!listingKey || !fieldId) {
      return;
    }
    const latestState = getState();

    const editedDisplayListings =
      latestState.cma.cma.agentInputs?.editedDisplayListings || {};
    const originalDisplayListings =
      latestState.cma.cma.agentInputs?.origDisplayListings || {};

    // Get the values of the field from the new edit/orig value, and any previous edit/orig values saved from prev edits
    const existingEditedFieldValue = getDisplayListingCompareValue(
      editedDisplayListings?.[listingKey]?.[fieldId] as IAttributeValue,
      attributeType,
    );
    const existingOriginalFieldValue = getDisplayListingCompareValue(
      originalDisplayListings?.[listingKey]?.[fieldId] as IAttributeValue,
      attributeType,
    );
    const newEditedFieldValue = getDisplayListingCompareValue(
      newEditedDisplayListing?.[fieldId] as IAttributeValue,
      attributeType,
    );
    const newOriginalFieldValue = getDisplayListingCompareValue(
      newOriginalDisplayListing?.[fieldId] as IAttributeValue,
      attributeType,
    );

    // Blank action, no edits are needed
    if (
      isEqual(newEditedFieldValue, existingEditedFieldValue) &&
      existingEditedFieldValue === undefined &&
      existingOriginalFieldValue === undefined
    ) {
      return;
    }

    // New edit/orig value merge with existing edits/orig values to potentially save back into the CMA
    const newEditedDisplayListings = {
      ...editedDisplayListings,
      [listingKey]: {
        ...editedDisplayListings?.[listingKey],
        ...newEditedDisplayListing,
      },
    };
    const newOriginalDisplayListings = {
      ...originalDisplayListings,
      [listingKey]: {
        ...originalDisplayListings?.[listingKey],
        ...newOriginalDisplayListing,
      },
    };

    if (listingKey === 'subject') {
      const subjectProperty = latestState.cma.cma.agentInputs?.subjectProperty;
      const lotSizeUnit = latestState.cma.cma.agentInputs?.lotSizeUnit;

      const newSubjectProperty = convertDisplayListingToProcessedListing(
        newEditedDisplayListings?.[listingKey],
        subjectProperty as ProcessedListing,
        {lotSizeUnit},
      );

      dispatch(setSubjectProperty(newSubjectProperty, lotSizeUnit));
    }

    if (!isEqual(existingEditedFieldValue, newEditedFieldValue)) {
      // Edited value is different (i.e being re-edited), save the new edit and persist existing original values
      dispatch(
        setDisplayListings(
          newEditedDisplayListings,
          newOriginalDisplayListings,
        ),
      );
      return;
    }
    // Edge case - No previous edits, but new edit coming in is clearing the value (to undefined), save
    // both the undefined value for the field, and the original value associated with the listing
    if (
      !isEqual(newEditedFieldValue, newOriginalFieldValue) &&
      originalDisplayListings?.[listingKey]?.[fieldId] === undefined
    ) {
      dispatch(
        setDisplayListings(
          newEditedDisplayListings,
          newOriginalDisplayListings,
        ),
      );
    }
  };
