import type {AgentInputs} from '@uc/thrift2npme/dist/cma/cma_models';

import type {State} from './store';

export const SET_AGENT_INPUTS = 'SET_AGENT_INPUTS';
type SET_AGENT_INPUTS = typeof SET_AGENT_INPUTS;

export const setAgentInputs = (
  agentInputs: AgentInputs,
): {type: SET_AGENT_INPUTS; payload: {agentInputs: AgentInputs}} => ({
  type: SET_AGENT_INPUTS,
  payload: {agentInputs},
});

export type Action = ReturnType<typeof setAgentInputs>;

export const agentInputsReducer = (state: State, action: Action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_AGENT_INPUTS: {
      const {agentInputs} = payload;
      return {
        ...state,
        agentInputs,
      };
    }
    default:
      console.log('agent inputs not found: ', type);
      return state;
  }
};
