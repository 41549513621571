import produce from 'immer';

import {AgentInputs} from '@uc/thrift2npme/dist/cma/cma_models';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import {
  Adjustment,
  AdjustmentFeedback,
} from '@uc/thrift2npme/dist/valuation/ai_valuation/adjustments';
import {
  GetAdjustmentsRequest,
  GetAdjustmentsResponse,
} from '@uc/thrift2npme/dist/valuation/ai_valuation/valuation_service.ucfetch';
import {getAdjustments} from '@uc/thrift2npme/dist/valuation/ai_valuation/valuation_service.ucfetch.js';

import IAi from '@/context/ducks/types/Ai';
import {ApiError} from '@/types/ApiError';
import {createReducer} from '../createReducer';
import IStoreState, {ThunkFunction} from '../types/Store';
import {trackCmaAnalyzeResultsGetAdjustmentError} from '@/utils/analyticTracks';

export const aiInitalState: IAi = {
  appliedAdjustments: {},
  feedback: {},
  suggestedAdjustments: {},
  isFetching: false,
  error: null,
};

export const AI_ACTION_TYPES = {
  AI_FETCH_SUGGESTED_ADJUSTMENTS: 'AI_FETCH_SUGGESTED_ADJUSTMENTS',
  AI_FETCH_SUGGESTED_ADJUSTMENTS_SUCCESS:
    'AI_FETCH_SUGGESTED_ADJUSTMENTS_SUCCESS',
  AI_FETCH_SUGGESTED_ADJUSTMENTS_FAILURE:
    'AI_FETCH_SUGGESTED_ADJUSTMENTS_FAILURE',
  AI_SET_MODAL_RESPONSE: 'AI_SET_MODAL_RESPONSE',
} as const;

// ACTIONS
const shouldFetchSuggestedAdjustments = (
  state: IStoreState,
  subjectProperty: ProcessedListing,
  listingIdSHAs: string[],
) =>
  !(
    state.ai.isFetching ||
    !subjectProperty ||
    !listingIdSHAs ||
    listingIdSHAs.length === 0
  );

const fetchSuggestedAdjustments = () => ({
  type: AI_ACTION_TYPES.AI_FETCH_SUGGESTED_ADJUSTMENTS,
});

const fetchSuggestedAdjustmentsSuccess = (
  suggestedAdjustments: {[key: string]: Adjustment[]} | undefined,
) => ({
  type: AI_ACTION_TYPES.AI_FETCH_SUGGESTED_ADJUSTMENTS_SUCCESS,
  payload: {suggestedAdjustments},
});

const fetchSuggestedAdjustmentsFailure = (error: ApiError | null) => ({
  type: AI_ACTION_TYPES.AI_FETCH_SUGGESTED_ADJUSTMENTS_FAILURE,
  payload: {error},
});

export const setModalResponse = (
  appliedAdjustments: {[key: string]: Adjustment[]},
  feedback: {[key: string]: AdjustmentFeedback},
) => ({
  type: AI_ACTION_TYPES.AI_SET_MODAL_RESPONSE,
  payload: {appliedAdjustments, feedback},
});

// REDUCERS
const fetchSuggestedAdjustmentsReducer = (state: IAi) =>
  produce(state, draftState => {
    draftState.isFetching = true;
    draftState.error = null;
  });

const fetchSuggestedAdjustmentsSuccessReducer = (
  state: IAi,
  payload: {
    suggestedAdjustments: {[key: string]: Adjustment[]};
  },
) =>
  produce(state, draftState => {
    draftState.suggestedAdjustments = payload.suggestedAdjustments;
    draftState.isFetching = false;
    draftState.error = null;
  });

const fetchSuggestedAdjustmentsFailureReducer = (
  state: IAi,
  payload: {error: ApiError | null},
) =>
  produce(state, draftState => {
    draftState.isFetching = false;
    draftState.error = payload.error;
  });

const setModalResponseReducer = (
  state: IAi,
  payload: {
    feedback: {[key: string]: AdjustmentFeedback};
    appliedAdjustments: {[key: string]: Adjustment[]};
  },
) =>
  produce(state, draftState => {
    draftState.appliedAdjustments = payload.appliedAdjustments;
    draftState.feedback = payload.feedback;
  });

export const getAdjustmentsList =
  (): ThunkFunction => async (dispatch, state, getState) => {
    const currentState = getState();
    const {cmaId, agentInputs} = currentState.cma.cma;
    const {
      subjectProperty,
      compIds: comparableIds,
      editedDisplayListings,
    } = agentInputs || {};
    dispatch(fetchSuggestedAdjustments());
    try {
      const req: GetAdjustmentsRequest = {
        comparableIds,
        subjectProperty,
      };
      if (editedDisplayListings) {
        req.editedListings = editedDisplayListings;
      }
      const resp: GetAdjustmentsResponse = await getAdjustments(req);
      dispatch(fetchSuggestedAdjustmentsSuccess(resp.suggestedAdjustments));
    } catch (error) {
      trackCmaAnalyzeResultsGetAdjustmentError({
        cmaId: cmaId || '',
        subjectListingId: subjectProperty?.listingIdSHA || '',
        error,
      });
      dispatch(fetchSuggestedAdjustmentsFailure(error));
    }
  };

export const getSuggestedAdjustments =
  (): ThunkFunction => (dispatch, state, getState) => {
    const currentState = getState();
    const {subjectProperty, compIds: listingIdSHAs} = currentState.cma.cma
      .agentInputs as AgentInputs;
    if (
      shouldFetchSuggestedAdjustments(
        currentState,
        subjectProperty as ProcessedListing,
        listingIdSHAs as string[],
      )
    ) {
      dispatch(getAdjustmentsList());
    }
  };

export default createReducer<IAi>({
  [AI_ACTION_TYPES.AI_FETCH_SUGGESTED_ADJUSTMENTS]:
    fetchSuggestedAdjustmentsReducer,
  [AI_ACTION_TYPES.AI_FETCH_SUGGESTED_ADJUSTMENTS_SUCCESS]:
    fetchSuggestedAdjustmentsSuccessReducer,
  [AI_ACTION_TYPES.AI_FETCH_SUGGESTED_ADJUSTMENTS_FAILURE]:
    fetchSuggestedAdjustmentsFailureReducer,
  [AI_ACTION_TYPES.AI_SET_MODAL_RESPONSE]: setModalResponseReducer,
});
