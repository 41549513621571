const placeholder = encodeURIComponent(`
<svg width="720" height="450" viewBox="0 0 720 450" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M437.71 327.446L436.839 318.739" stroke="black" stroke-width="0.6"/>
<path d="M255.299 112.819L254.428 121.526" stroke="black" stroke-width="0.6"/>
<path d="M213.07 112.819L212.344 121.526" stroke="black" stroke-width="0.6"/>
<path d="M297.237 126.605L296.657 135.312" stroke="black" stroke-width="0.6"/>
<path d="M241.222 112.819L240.497 121.526" stroke="black" stroke-width="0.6"/>
<path d="M311.604 126.605L310.298 135.167" stroke="black" stroke-width="0.6"/>
<path d="M227.146 112.819L226.42 121.526" stroke="black" stroke-width="0.6"/>
<path d="M479.938 327.301L478.632 318.739" stroke="black" stroke-width="0.6"/>
<path d="M367.183 272.592L367.038 263.885" stroke="black" stroke-width="0.6"/>
<path d="M283.596 112.964L282.29 121.526" stroke="black" stroke-width="0.6"/>
<path d="M466.007 327.301L464.701 318.739" stroke="black" stroke-width="0.6"/>
<path d="M395.771 299.874L394.465 291.312" stroke="black" stroke-width="0.6"/>
<path d="M494.015 327.301L492.709 318.739" stroke="black" stroke-width="0.6"/>
<path d="M535.808 327.446L535.083 318.739" stroke="black" stroke-width="0.6"/>
<path d="M381.695 286.233L380.534 277.526" stroke="black" stroke-width="0.6"/>
<path d="M479.503 341.087L479.213 332.38" stroke="black" stroke-width="0.6"/>
<path d="M350.059 223.833L356.009 230.218" stroke="black" stroke-width="0.6"/>
<path d="M349.769 243.569L356.299 237.909" stroke="black" stroke-width="0.6"/>
<path d="M339.611 154.032L338.45 162.594" stroke="black" stroke-width="0.6"/>
<path d="M351.22 258.371L354.848 250.534" stroke="black" stroke-width="0.6"/>
<path d="M352.091 209.031L353.978 217.448" stroke="black" stroke-width="0.6"/>
<path d="M334.967 225.139L342.949 228.767" stroke="black" stroke-width="0.6"/>
<path d="M334.677 241.537L343.239 239.796" stroke="black" stroke-width="0.6"/>
<path d="M336.419 209.757L341.643 216.722" stroke="black" stroke-width="0.6"/>
<path d="M335.403 256.919L342.513 251.985" stroke="black" stroke-width="0.6"/>
<path d="M352.962 195.1L353.107 203.807" stroke="black" stroke-width="0.6"/>
<path d="M351.366 272.157L354.703 264.175" stroke="black" stroke-width="0.6"/>
<path d="M320.891 225.284L329.018 228.622" stroke="black" stroke-width="0.6"/>
<path d="M320.601 240.666H329.308" stroke="black" stroke-width="0.6"/>
<path d="M337.579 195.39L340.482 203.662" stroke="black" stroke-width="0.6"/>
<path d="M335.983 271.286L341.933 265.046" stroke="black" stroke-width="0.6"/>
<path d="M321.762 210.337L328.147 216.142" stroke="black" stroke-width="0.6"/>
<path d="M320.891 255.759L329.163 253.146" stroke="black" stroke-width="0.6"/>
<path d="M605.899 286.233L605.319 277.526" stroke="black" stroke-width="0.6"/>
<path d="M351.366 285.943L354.703 277.961" stroke="black" stroke-width="0.6"/>
<path d="M366.603 286.233L367.619 277.526" stroke="black" stroke-width="0.6"/>
<path d="M322.778 195.826L327.276 203.226" stroke="black" stroke-width="0.6"/>
<path d="M321.181 270.27L328.727 266.062" stroke="black" stroke-width="0.6"/>
<path d="M306.96 225.284L314.941 228.622" stroke="black" stroke-width="0.6"/>
<path d="M306.67 240.231L315.232 241.247" stroke="black" stroke-width="0.6"/>
<path d="M338.45 181.459L339.611 190.166" stroke="black" stroke-width="0.6"/>
<path d="M336.273 285.217L341.788 278.542" stroke="black" stroke-width="0.6"/>
<path d="M307.54 210.482L314.361 215.997" stroke="black" stroke-width="0.6"/>
<path d="M306.67 254.888L315.232 253.872" stroke="black" stroke-width="0.6"/>
<path d="M323.648 181.604L326.405 189.876" stroke="black" stroke-width="0.6"/>
<path d="M321.471 284.492L328.437 279.267" stroke="black" stroke-width="0.6"/>
<path d="M308.266 196.116L313.635 202.936" stroke="black" stroke-width="0.6"/>
<path d="M306.815 269.399L315.086 266.932" stroke="black" stroke-width="0.6"/>
<path d="M366.167 299.874L368.054 291.312" stroke="black" stroke-width="0.6"/>
<path d="M351.22 299.584L354.848 291.747" stroke="black" stroke-width="0.6"/>
<path d="M293.029 225.139L300.865 228.767" stroke="black" stroke-width="0.6"/>
<path d="M292.593 239.796L301.155 241.682" stroke="black" stroke-width="0.6"/>
<path d="M381.114 300.019V291.312" stroke="black" stroke-width="0.6"/>
<path d="M336.273 299.003L341.643 292.183" stroke="black" stroke-width="0.6"/>
<path d="M293.464 210.627L300.285 215.852" stroke="black" stroke-width="0.6"/>
<path d="M292.593 254.307L301.3 254.597" stroke="black" stroke-width="0.6"/>
<path d="M309.137 181.894L312.765 189.731" stroke="black" stroke-width="0.6"/>
<path d="M306.96 283.621L314.941 280.138" stroke="black" stroke-width="0.6"/>
<path d="M321.617 298.423L328.292 292.908" stroke="black" stroke-width="0.6"/>
<path d="M324.374 167.818L325.68 176.38" stroke="black" stroke-width="0.6"/>
<path d="M294.19 196.116L299.704 202.791" stroke="black" stroke-width="0.6"/>
<path d="M292.593 268.674L301.155 267.658" stroke="black" stroke-width="0.6"/>
<path d="M423.343 327.446L423.053 318.739" stroke="black" stroke-width="0.6"/>
<path d="M350.93 313.225L355.139 305.533" stroke="black" stroke-width="0.6"/>
<path d="M365.732 313.515L368.344 305.243" stroke="black" stroke-width="0.6"/>
<path d="M279.098 224.849L286.644 229.057" stroke="black" stroke-width="0.6"/>
<path d="M278.807 239.36L286.934 242.118" stroke="black" stroke-width="0.6"/>
<path d="M336.273 312.644L341.788 305.969" stroke="black" stroke-width="0.6"/>
<path d="M380.534 313.66L381.695 305.098" stroke="black" stroke-width="0.6"/>
<path d="M309.862 167.818L312.039 176.235" stroke="black" stroke-width="0.6"/>
<path d="M307.105 297.697L314.796 293.634" stroke="black" stroke-width="0.6"/>
<path d="M294.77 182.039L298.979 189.585" stroke="black" stroke-width="0.6"/>
<path d="M292.739 282.895L301.155 280.864" stroke="black" stroke-width="0.6"/>
<path d="M279.533 210.482L286.208 215.997" stroke="black" stroke-width="0.6"/>
<path d="M278.517 253.727L287.224 255.033" stroke="black" stroke-width="0.6"/>
<path d="M321.617 312.063L328.292 306.549" stroke="black" stroke-width="0.6"/>
<path d="M395.191 313.66V304.953" stroke="black" stroke-width="0.6"/>
<path d="M280.113 196.261L285.773 202.791" stroke="black" stroke-width="0.6"/>
<path d="M278.517 268.093L287.224 268.239" stroke="black" stroke-width="0.6"/>
<path d="M324.954 154.032V162.739" stroke="black" stroke-width="0.6"/>
<path d="M295.496 167.963L298.398 176.235" stroke="black" stroke-width="0.6"/>
<path d="M292.739 296.971L301.01 294.214" stroke="black" stroke-width="0.6"/>
<path d="M365.442 327.01L368.78 319.029" stroke="black" stroke-width="0.6"/>
<path d="M350.785 326.72L355.284 319.319" stroke="black" stroke-width="0.6"/>
<path d="M310.443 154.032L311.459 162.739" stroke="black" stroke-width="0.6"/>
<path d="M307.105 311.483L314.651 307.13" stroke="black" stroke-width="0.6"/>
<path d="M280.694 182.039L285.192 189.585" stroke="black" stroke-width="0.6"/>
<path d="M278.517 282.17L287.224 281.444" stroke="black" stroke-width="0.6"/>
<path d="M265.167 224.704L272.567 229.202" stroke="black" stroke-width="0.6"/>
<path d="M264.876 238.925L272.858 242.408" stroke="black" stroke-width="0.6"/>
<path d="M336.128 326.285L341.788 319.755" stroke="black" stroke-width="0.6"/>
<path d="M380.099 327.301L382.13 318.884" stroke="black" stroke-width="0.6"/>
<path d="M265.602 210.337L272.132 216.142" stroke="black" stroke-width="0.6"/>
<path d="M264.586 253.292L273.003 255.613" stroke="black" stroke-width="0.6"/>
<path d="M394.61 327.301L395.626 318.739" stroke="black" stroke-width="0.6"/>
<path d="M321.617 325.85L328.292 320.19" stroke="black" stroke-width="0.6"/>
<path d="M266.037 196.261L271.697 202.791" stroke="black" stroke-width="0.6"/>
<path d="M264.586 267.513L273.148 268.819" stroke="black" stroke-width="0.6"/>
<path d="M292.884 310.903L301.01 307.855" stroke="black" stroke-width="0.6"/>
<path d="M296.076 154.032L297.673 162.594" stroke="black" stroke-width="0.6"/>
<path d="M281.274 167.963L284.467 176.09" stroke="black" stroke-width="0.6"/>
<path d="M278.517 296.246L287.224 294.94" stroke="black" stroke-width="0.6"/>
<path d="M451.786 327.301L450.77 318.739" stroke="black" stroke-width="0.6"/>
<path d="M339.031 167.673V176.38" stroke="black" stroke-width="0.6"/>
<path d="M307.25 325.269L314.651 320.771" stroke="black" stroke-width="0.6"/>
<path d="M409.122 327.446L409.267 318.739" stroke="black" stroke-width="0.6"/>
<path d="M493.434 341.087L493.289 332.38" stroke="black" stroke-width="0.6"/>
<path d="M266.618 182.039L271.116 189.44" stroke="black" stroke-width="0.6"/>
<path d="M264.441 281.589L273.148 282.025" stroke="black" stroke-width="0.6"/>
<path d="M350.495 340.361L355.574 333.251" stroke="black" stroke-width="0.6"/>
<path d="M365.152 340.652L369.07 332.96" stroke="black" stroke-width="0.6"/>
<path d="M379.663 340.797L382.566 332.67" stroke="black" stroke-width="0.6"/>
<path d="M335.983 339.926L341.933 333.541" stroke="black" stroke-width="0.6"/>
<path d="M251.235 224.413L258.346 229.493" stroke="black" stroke-width="0.6"/>
<path d="M250.945 238.635L258.636 242.698" stroke="black" stroke-width="0.6"/>
<path d="M250.8 252.856L258.927 255.904" stroke="black" stroke-width="0.6"/>
<path d="M251.671 210.192L258.056 216.287" stroke="black" stroke-width="0.6"/>
<path d="M278.662 310.177L287.079 308.436" stroke="black" stroke-width="0.6"/>
<path d="M281.855 154.177L283.886 162.594" stroke="black" stroke-width="0.6"/>
<path d="M394.175 340.942L396.206 332.525" stroke="black" stroke-width="0.6"/>
<path d="M321.617 339.49L328.437 333.976" stroke="black" stroke-width="0.6"/>
<path d="M292.884 324.689L300.865 321.351" stroke="black" stroke-width="0.6"/>
<path d="M296.802 140.246L297.092 148.953" stroke="black" stroke-width="0.6"/>
<path d="M267.198 168.108L270.536 176.09" stroke="black" stroke-width="0.6"/>
<path d="M264.441 295.666L273.148 295.52" stroke="black" stroke-width="0.6"/>
<path d="M252.106 196.116L257.475 202.936" stroke="black" stroke-width="0.6"/>
<path d="M250.655 266.932L259.072 269.254" stroke="black" stroke-width="0.6"/>
<path d="M325.535 140.391L324.519 148.953" stroke="black" stroke-width="0.6"/>
<path d="M307.25 338.91L314.651 334.557" stroke="black" stroke-width="0.6"/>
<path d="M408.541 341.087L409.847 332.525" stroke="black" stroke-width="0.6"/>
<path d="M252.541 182.039L257.04 189.585" stroke="black" stroke-width="0.6"/>
<path d="M250.51 281.154L259.072 282.605" stroke="black" stroke-width="0.6"/>
<path d="M350.35 353.857L355.719 347.037" stroke="black" stroke-width="0.6"/>
<path d="M364.861 354.147L369.36 346.746" stroke="black" stroke-width="0.6"/>
<path d="M282.435 140.246L283.306 148.953" stroke="black" stroke-width="0.6"/>
<path d="M278.662 324.108L287.079 321.932" stroke="black" stroke-width="0.6"/>
<path d="M267.779 154.177L269.955 162.594" stroke="black" stroke-width="0.6"/>
<path d="M264.441 309.597L273.148 309.016" stroke="black" stroke-width="0.6"/>
<path d="M379.228 354.437L383.001 346.601" stroke="black" stroke-width="0.6"/>
<path d="M335.838 353.567L342.078 347.472" stroke="black" stroke-width="0.6"/>
<path d="M237.449 224.268L244.125 229.638" stroke="black" stroke-width="0.6"/>
<path d="M237.159 238.345L244.415 242.988" stroke="black" stroke-width="0.6"/>
<path d="M507.946 327.446L506.785 318.739" stroke="black" stroke-width="0.6"/>
<path d="M422.908 341.087L423.488 332.38" stroke="black" stroke-width="0.6"/>
<path d="M292.884 338.33L300.865 335.137" stroke="black" stroke-width="0.6"/>
<path d="M237.74 210.047L243.834 216.432" stroke="black" stroke-width="0.6"/>
<path d="M236.869 252.421L244.705 256.339" stroke="black" stroke-width="0.6"/>
<path d="M393.594 354.583L396.642 346.456" stroke="black" stroke-width="0.6"/>
<path d="M321.471 353.131L328.437 347.907" stroke="black" stroke-width="0.6"/>
<path d="M253.122 168.108L256.605 176.09" stroke="black" stroke-width="0.6"/>
<path d="M250.51 295.085L259.072 296.101" stroke="black" stroke-width="0.6"/>
<path d="M550.03 313.66L549.014 304.953" stroke="black" stroke-width="0.6"/>
<path d="M238.175 195.971L243.399 202.936" stroke="black" stroke-width="0.6"/>
<path d="M236.724 266.497L244.85 269.69" stroke="black" stroke-width="0.6"/>
<path d="M507.511 341.087L507.366 332.38" stroke="black" stroke-width="0.6"/>
<path d="M307.105 352.551L314.796 348.343" stroke="black" stroke-width="0.6"/>
<path d="M408.106 354.728L410.283 346.311" stroke="black" stroke-width="0.6"/>
<path d="M268.359 140.391L269.375 148.953" stroke="black" stroke-width="0.6"/>
<path d="M264.441 323.528L273.148 322.512" stroke="black" stroke-width="0.6"/>
<path d="M238.61 182.039L242.964 189.585" stroke="black" stroke-width="0.6"/>
<path d="M236.578 280.573L244.995 283.04" stroke="black" stroke-width="0.6"/>
<path d="M437.129 341.087L437.42 332.38" stroke="black" stroke-width="0.6"/>
<path d="M278.662 337.894L287.079 335.717" stroke="black" stroke-width="0.6"/>
<path d="M253.702 154.177L256.024 162.594" stroke="black" stroke-width="0.6"/>
<path d="M250.51 309.016L259.217 309.597" stroke="black" stroke-width="0.6"/>
<path d="M451.351 341.087L451.205 332.38" stroke="black" stroke-width="0.6"/>
<path d="M364.571 367.788L369.65 360.678" stroke="black" stroke-width="0.6"/>
<path d="M350.059 367.353L356.009 360.968" stroke="black" stroke-width="0.6"/>
<path d="M378.938 367.933L383.291 360.387" stroke="black" stroke-width="0.6"/>
<path d="M335.693 367.063L342.223 361.403" stroke="black" stroke-width="0.6"/>
<path d="M422.327 354.728L424.069 346.166" stroke="black" stroke-width="0.6"/>
<path d="M292.884 352.116L301.01 348.923" stroke="black" stroke-width="0.6"/>
<path d="M223.518 223.978L229.903 229.928" stroke="black" stroke-width="0.6"/>
<path d="M223.228 238.054L230.193 243.279" stroke="black" stroke-width="0.6"/>
<path d="M239.191 168.108L242.528 176.09" stroke="black" stroke-width="0.6"/>
<path d="M236.578 294.65L244.995 296.536" stroke="black" stroke-width="0.6"/>
<path d="M223.953 209.902L229.613 216.577" stroke="black" stroke-width="0.6"/>
<path d="M223.083 252.131L230.484 256.629" stroke="black" stroke-width="0.6"/>
<path d="M393.304 368.078L396.932 360.242" stroke="black" stroke-width="0.6"/>
<path d="M321.327 366.627L328.582 361.693" stroke="black" stroke-width="0.6"/>
<path d="M264.586 337.314L273.148 336.153" stroke="black" stroke-width="0.6"/>
<path d="M268.794 126.605L268.94 135.312" stroke="black" stroke-width="0.6"/>
<path d="M224.244 195.971L229.178 203.081" stroke="black" stroke-width="0.6"/>
<path d="M222.938 266.207L230.629 270.125" stroke="black" stroke-width="0.6"/>
<path d="M254.283 140.391L255.444 148.953" stroke="black" stroke-width="0.6"/>
<path d="M250.51 322.947L259.217 323.092" stroke="black" stroke-width="0.6"/>
<path d="M407.526 368.224L410.718 360.097" stroke="black" stroke-width="0.6"/>
<path d="M307.105 366.192L314.796 362.129" stroke="black" stroke-width="0.6"/>
<path d="M278.662 351.535L287.079 349.359" stroke="black" stroke-width="0.6"/>
<path d="M436.549 354.728L437.855 346.166" stroke="black" stroke-width="0.6"/>
<path d="M239.626 154.177L241.948 162.594" stroke="black" stroke-width="0.6"/>
<path d="M236.433 308.581L244.995 310.032" stroke="black" stroke-width="0.6"/>
<path d="M222.792 280.138L230.774 283.476" stroke="black" stroke-width="0.6"/>
<path d="M224.679 181.894L228.742 189.585" stroke="black" stroke-width="0.6"/>
<path d="M364.281 381.284L369.94 374.609" stroke="black" stroke-width="0.6"/>
<path d="M349.914 380.994L356.154 374.899" stroke="black" stroke-width="0.6"/>
<path d="M633.907 258.806L633.472 250.099" stroke="black" stroke-width="0.6"/>
<path d="M421.892 368.369L424.504 360.097" stroke="black" stroke-width="0.6"/>
<path d="M292.884 365.757L301.01 362.709" stroke="black" stroke-width="0.6"/>
<path d="M335.548 380.558L342.368 375.189" stroke="black" stroke-width="0.6"/>
<path d="M378.647 381.429L383.581 374.318" stroke="black" stroke-width="0.6"/>
<path d="M283.016 126.605L282.726 135.312" stroke="black" stroke-width="0.6"/>
<path d="M225.26 167.963L228.307 176.09" stroke="black" stroke-width="0.6"/>
<path d="M222.647 294.069L230.919 296.972" stroke="black" stroke-width="0.6"/>
<path d="M250.51 336.733H259.217" stroke="black" stroke-width="0.6"/>
<path d="M254.718 126.605L255.008 135.312" stroke="black" stroke-width="0.6"/>
<path d="M100.75 167.673L100.314 176.38" stroke="black" stroke-width="0.6"/>
<path d="M209.732 223.833L215.682 230.073" stroke="black" stroke-width="0.6"/>
<path d="M209.442 237.764L215.972 243.569" stroke="black" stroke-width="0.6"/>
<path d="M450.77 354.873L451.786 346.166" stroke="black" stroke-width="0.6"/>
<path d="M264.586 351.1L273.148 349.939" stroke="black" stroke-width="0.6"/>
<path d="M392.869 381.719L397.367 374.173" stroke="black" stroke-width="0.6"/>
<path d="M321.327 380.268L328.727 375.624" stroke="black" stroke-width="0.6"/>
<path d="M185.207 112.819L184.192 121.526" stroke="black" stroke-width="0.6"/>
<path d="M210.022 209.757L215.392 216.722" stroke="black" stroke-width="0.6"/>
<path d="M209.297 251.84L216.262 257.065" stroke="black" stroke-width="0.6"/>
<path d="M240.206 140.391L241.512 148.953" stroke="black" stroke-width="0.6"/>
<path d="M236.433 322.367L245.14 323.673" stroke="black" stroke-width="0.6"/>
<path d="M311.168 140.246L310.878 148.953" stroke="black" stroke-width="0.6"/>
<path d="M209.006 265.772L216.407 270.415" stroke="black" stroke-width="0.6"/>
<path d="M210.458 195.826L214.956 203.226" stroke="black" stroke-width="0.6"/>
<path d="M436.113 368.369L438.29 359.952" stroke="black" stroke-width="0.6"/>
<path d="M278.662 365.176L287.079 363.144" stroke="black" stroke-width="0.6"/>
<path d="M407.235 381.864L411.153 374.028" stroke="black" stroke-width="0.6"/>
<path d="M306.96 379.833L314.796 376.06" stroke="black" stroke-width="0.6"/>
<path d="M222.647 308L230.919 310.467" stroke="black" stroke-width="0.6"/>
<path d="M225.695 154.177L227.872 162.594" stroke="black" stroke-width="0.6"/>
<path d="M210.893 181.894L214.666 189.731" stroke="black" stroke-width="0.6"/>
<path d="M208.861 279.703L216.552 283.911" stroke="black" stroke-width="0.6"/>
<path d="M250.51 350.519L259.217 350.374" stroke="black" stroke-width="0.6"/>
<path d="M464.846 354.873L465.717 346.166" stroke="black" stroke-width="0.6"/>
<path d="M292.739 379.398L301.01 376.495" stroke="black" stroke-width="0.6"/>
<path d="M421.457 381.864L424.94 373.883" stroke="black" stroke-width="0.6"/>
<path d="M240.642 126.605L240.932 135.312" stroke="black" stroke-width="0.6"/>
<path d="M236.433 336.298L245.14 337.169" stroke="black" stroke-width="0.6"/>
<path d="M363.991 394.78L370.086 388.54" stroke="black" stroke-width="0.6"/>
<path d="M349.769 394.49L356.299 388.83" stroke="black" stroke-width="0.6"/>
<path d="M450.335 368.514L452.221 359.952" stroke="black" stroke-width="0.6"/>
<path d="M264.586 364.741L273.148 363.58" stroke="black" stroke-width="0.6"/>
<path d="M335.403 394.054L342.513 389.12" stroke="black" stroke-width="0.6"/>
<path d="M378.357 394.925L383.872 388.25" stroke="black" stroke-width="0.6"/>
<path d="M211.328 167.963L214.231 176.235" stroke="black" stroke-width="0.6"/>
<path d="M208.716 293.779L216.698 297.407" stroke="black" stroke-width="0.6"/>
<path d="M222.502 321.932L230.919 324.108" stroke="black" stroke-width="0.6"/>
<path d="M226.13 140.391L227.436 148.953" stroke="black" stroke-width="0.6"/>
<path d="M195.946 223.543L201.46 230.363" stroke="black" stroke-width="0.6"/>
<path d="M195.656 237.619L201.751 243.859" stroke="black" stroke-width="0.6"/>
<path d="M392.578 395.215L397.658 388.105" stroke="black" stroke-width="0.6"/>
<path d="M321.181 393.764L328.727 389.556" stroke="black" stroke-width="0.6"/>
<path d="M196.236 209.612L201.17 216.867" stroke="black" stroke-width="0.6"/>
<path d="M195.511 251.55L201.896 257.21" stroke="black" stroke-width="0.6"/>
<path d="M435.678 382.01L438.725 373.883" stroke="black" stroke-width="0.6"/>
<path d="M278.662 378.817L287.079 376.93" stroke="black" stroke-width="0.6"/>
<path d="M196.672 195.68L200.735 203.371" stroke="black" stroke-width="0.6"/>
<path d="M195.22 265.481L202.186 270.705" stroke="black" stroke-width="0.6"/>
<path d="M86.6735 181.459L86.2382 190.166" stroke="black" stroke-width="0.6"/>
<path d="M406.8 395.36L411.444 387.959" stroke="black" stroke-width="0.6"/>
<path d="M306.96 393.329L314.941 389.846" stroke="black" stroke-width="0.6"/>
<path d="M208.716 307.565L216.698 310.903" stroke="black" stroke-width="0.6"/>
<path d="M211.764 154.177L213.795 162.594" stroke="black" stroke-width="0.6"/>
<path d="M479.068 354.873L479.648 346.166" stroke="black" stroke-width="0.6"/>
<path d="M236.433 350.084L245.14 350.81" stroke="black" stroke-width="0.6"/>
<path d="M464.411 368.514L466.152 359.952" stroke="black" stroke-width="0.6"/>
<path d="M250.51 364.306L259.217 364.16" stroke="black" stroke-width="0.6"/>
<path d="M196.962 181.749L200.445 189.731" stroke="black" stroke-width="0.6"/>
<path d="M195.075 279.412L202.331 284.201" stroke="black" stroke-width="0.6"/>
<path d="M222.502 335.717L230.919 337.749" stroke="black" stroke-width="0.6"/>
<path d="M226.711 126.605L227.001 135.312" stroke="black" stroke-width="0.6"/>
<path d="M421.021 395.505L425.375 387.814" stroke="black" stroke-width="0.6"/>
<path d="M292.739 392.893L301.01 390.281" stroke="black" stroke-width="0.6"/>
<path d="M264.586 378.382L273.148 377.366" stroke="black" stroke-width="0.6"/>
<path d="M449.899 382.01L452.657 373.738" stroke="black" stroke-width="0.6"/>
<path d="M591.823 300.019L591.388 291.312" stroke="black" stroke-width="0.6"/>
<path d="M349.479 407.985L356.59 402.761" stroke="black" stroke-width="0.6"/>
<path d="M363.7 408.13L370.376 402.471" stroke="black" stroke-width="0.6"/>
<path d="M208.716 321.496L216.843 324.544" stroke="black" stroke-width="0.6"/>
<path d="M212.199 140.391L213.36 148.953" stroke="black" stroke-width="0.6"/>
<path d="M197.397 167.963L200.009 176.235" stroke="black" stroke-width="0.6"/>
<path d="M194.93 293.344L202.476 297.842" stroke="black" stroke-width="0.6"/>
<path d="M378.067 408.421L384.162 402.326" stroke="black" stroke-width="0.6"/>
<path d="M335.258 407.55L342.659 403.051" stroke="black" stroke-width="0.6"/>
<path d="M392.288 408.711L397.948 402.036" stroke="black" stroke-width="0.6"/>
<path d="M321.036 407.26L328.873 403.487" stroke="black" stroke-width="0.6"/>
<path d="M269.375 112.819L268.359 121.526" stroke="black" stroke-width="0.6"/>
<path d="M182.16 223.398L187.239 230.508" stroke="black" stroke-width="0.6"/>
<path d="M181.87 237.329L187.384 244.004" stroke="black" stroke-width="0.6"/>
<path d="M435.243 395.505L439.161 387.814" stroke="black" stroke-width="0.6"/>
<path d="M278.662 392.458L287.079 390.717" stroke="black" stroke-width="0.6"/>
<path d="M578.037 299.874L577.021 291.312" stroke="black" stroke-width="0.6"/>
<path d="M181.725 251.26L187.674 257.5" stroke="black" stroke-width="0.6"/>
<path d="M182.45 209.466L186.949 217.012" stroke="black" stroke-width="0.6"/>
<path d="M478.487 368.514L480.084 359.952" stroke="black" stroke-width="0.6"/>
<path d="M236.433 363.725L245.14 364.596" stroke="black" stroke-width="0.6"/>
<path d="M222.502 349.503L231.064 351.39" stroke="black" stroke-width="0.6"/>
<path d="M492.999 354.873L493.725 346.166" stroke="black" stroke-width="0.6"/>
<path d="M170.986 126.605L170.406 135.312" stroke="black" stroke-width="0.6"/>
<path d="M197.833 154.032L199.574 162.594" stroke="black" stroke-width="0.6"/>
<path d="M194.93 307.275L202.476 311.338" stroke="black" stroke-width="0.6"/>
<path d="M306.815 406.824L314.941 403.777" stroke="black" stroke-width="0.6"/>
<path d="M406.51 408.856L411.734 401.891" stroke="black" stroke-width="0.6"/>
<path d="M250.51 377.946L259.217 377.801" stroke="black" stroke-width="0.6"/>
<path d="M463.976 382.01L466.588 373.738" stroke="black" stroke-width="0.6"/>
<path d="M181.434 265.191L187.82 270.996" stroke="black" stroke-width="0.6"/>
<path d="M182.885 195.535L186.513 203.517" stroke="black" stroke-width="0.6"/>
<path d="M208.571 335.282L216.843 338.185" stroke="black" stroke-width="0.6"/>
<path d="M212.634 126.605L212.78 135.312" stroke="black" stroke-width="0.6"/>
<path d="M181.289 279.122L188.11 284.636" stroke="black" stroke-width="0.6"/>
<path d="M183.176 181.749L186.223 189.876" stroke="black" stroke-width="0.6"/>
<path d="M449.464 395.651L453.092 387.669" stroke="black" stroke-width="0.6"/>
<path d="M264.441 392.023L273.148 391.152" stroke="black" stroke-width="0.6"/>
<path d="M353.542 181.459L352.526 190.166" stroke="black" stroke-width="0.6"/>
<path d="M420.731 409.001L425.665 401.745" stroke="black" stroke-width="0.6"/>
<path d="M292.739 406.389L301.155 404.213" stroke="black" stroke-width="0.6"/>
<path d="M198.268 140.246L199.139 148.953" stroke="black" stroke-width="0.6"/>
<path d="M194.785 321.061L202.621 324.979" stroke="black" stroke-width="0.6"/>
<path d="M349.334 421.336L356.735 416.692" stroke="black" stroke-width="0.6"/>
<path d="M363.555 421.626L370.521 416.402" stroke="black" stroke-width="0.6"/>
<path d="M183.611 167.818L185.788 176.235" stroke="black" stroke-width="0.6"/>
<path d="M181.144 292.908L188.11 298.132" stroke="black" stroke-width="0.6"/>
<path d="M335.113 421.046L342.804 416.982" stroke="black" stroke-width="0.6"/>
<path d="M377.777 421.916L384.452 416.257" stroke="black" stroke-width="0.6"/>
<path d="M222.502 363.29L231.064 365.031" stroke="black" stroke-width="0.6"/>
<path d="M492.564 368.514L494.16 359.952" stroke="black" stroke-width="0.6"/>
<path d="M478.052 382.155L480.519 373.738" stroke="black" stroke-width="0.6"/>
<path d="M236.433 377.511L245.14 378.382" stroke="black" stroke-width="0.6"/>
<path d="M208.571 349.068L216.843 351.825" stroke="black" stroke-width="0.6"/>
<path d="M507.075 354.873L507.801 346.166" stroke="black" stroke-width="0.6"/>
<path d="M434.953 409.001L439.596 401.745" stroke="black" stroke-width="0.6"/>
<path d="M278.517 406.099L287.224 404.648" stroke="black" stroke-width="0.6"/>
<path d="M391.998 422.062L398.238 415.967" stroke="black" stroke-width="0.6"/>
<path d="M320.891 420.756L329.018 417.418" stroke="black" stroke-width="0.6"/>
<path d="M168.374 223.252L172.872 230.653" stroke="black" stroke-width="0.6"/>
<path d="M168.084 237.184L173.163 244.294" stroke="black" stroke-width="0.6"/>
<path d="M463.54 395.651L467.023 387.669" stroke="black" stroke-width="0.6"/>
<path d="M250.51 391.587H259.217" stroke="black" stroke-width="0.6"/>
<path d="M168.664 209.321L172.582 217.158" stroke="black" stroke-width="0.6"/>
<path d="M167.939 250.97L173.453 257.79" stroke="black" stroke-width="0.6"/>
<path d="M180.999 306.839L188.255 311.773" stroke="black" stroke-width="0.6"/>
<path d="M184.047 154.032L185.353 162.594" stroke="black" stroke-width="0.6"/>
<path d="M198.703 126.605V135.312" stroke="black" stroke-width="0.6"/>
<path d="M194.785 334.847L202.621 338.475" stroke="black" stroke-width="0.6"/>
<path d="M406.219 422.207L412.024 415.822" stroke="black" stroke-width="0.6"/>
<path d="M306.815 420.32L315.086 417.708" stroke="black" stroke-width="0.6"/>
<path d="M521.442 341.087V332.38" stroke="black" stroke-width="0.6"/>
<path d="M167.648 264.901L173.598 271.286" stroke="black" stroke-width="0.6"/>
<path d="M168.954 195.535L172.292 203.517" stroke="black" stroke-width="0.6"/>
<path d="M409.702 313.66L408.686 304.953" stroke="black" stroke-width="0.6"/>
<path d="M264.441 405.664L273.148 405.083" stroke="black" stroke-width="0.6"/>
<path d="M449.029 409.146L453.382 401.6" stroke="black" stroke-width="0.6"/>
<path d="M465.427 341.087L465.137 332.38" stroke="black" stroke-width="0.6"/>
<path d="M549.594 327.446L549.304 318.739" stroke="black" stroke-width="0.6"/>
<path d="M169.39 181.604L172.002 190.021" stroke="black" stroke-width="0.6"/>
<path d="M167.503 278.832L173.743 284.927" stroke="black" stroke-width="0.6"/>
<path d="M292.593 420.03L301.155 418.144" stroke="black" stroke-width="0.6"/>
<path d="M420.441 422.352L425.955 415.677" stroke="black" stroke-width="0.6"/>
<path d="M492.128 382.155L494.595 373.738" stroke="black" stroke-width="0.6"/>
<path d="M222.502 376.93L231.064 378.817" stroke="black" stroke-width="0.6"/>
<path d="M208.571 362.854L216.843 365.466" stroke="black" stroke-width="0.6"/>
<path d="M506.64 368.514L508.236 359.952" stroke="black" stroke-width="0.6"/>
<path d="M180.999 320.626L188.4 325.269" stroke="black" stroke-width="0.6"/>
<path d="M184.337 140.246L185.062 148.953" stroke="black" stroke-width="0.6"/>
<path d="M477.617 395.651L480.954 387.669" stroke="black" stroke-width="0.6"/>
<path d="M236.433 391.152L245.14 392.023" stroke="black" stroke-width="0.6"/>
<path d="M349.189 434.832L356.88 430.769" stroke="black" stroke-width="0.6"/>
<path d="M363.41 435.122L370.666 430.478" stroke="black" stroke-width="0.6"/>
<path d="M194.785 348.633L202.621 352.261" stroke="black" stroke-width="0.6"/>
<path d="M521.006 354.873L521.877 346.166" stroke="black" stroke-width="0.6"/>
<path d="M169.68 167.818L171.566 176.38" stroke="black" stroke-width="0.6"/>
<path d="M167.358 292.618L173.888 298.423" stroke="black" stroke-width="0.6"/>
<path d="M377.632 435.412L384.597 430.188" stroke="black" stroke-width="0.6"/>
<path d="M334.967 434.542L342.949 431.059" stroke="black" stroke-width="0.6"/>
<path d="M434.517 422.497L439.886 415.677" stroke="black" stroke-width="0.6"/>
<path d="M278.517 419.595L287.224 418.434" stroke="black" stroke-width="0.6"/>
<path d="M463.25 409.146L467.313 401.6" stroke="black" stroke-width="0.6"/>
<path d="M250.51 405.228L259.217 405.518" stroke="black" stroke-width="0.6"/>
<path d="M391.708 435.557L398.528 430.043" stroke="black" stroke-width="0.6"/>
<path d="M320.891 434.251L329.018 431.349" stroke="black" stroke-width="0.6"/>
<path d="M180.854 334.557L188.4 338.91" stroke="black" stroke-width="0.6"/>
<path d="M535.373 341.087L535.518 332.38" stroke="black" stroke-width="0.6"/>
<path d="M154.588 223.107L158.651 230.798" stroke="black" stroke-width="0.6"/>
<path d="M154.443 236.893L158.796 244.439" stroke="black" stroke-width="0.6"/>
<path d="M170.115 154.032L171.276 162.739" stroke="black" stroke-width="0.6"/>
<path d="M167.213 306.549L174.033 312.063" stroke="black" stroke-width="0.6"/>
<path d="M154.878 209.176L158.361 217.303" stroke="black" stroke-width="0.6"/>
<path d="M154.152 250.825L159.086 257.935" stroke="black" stroke-width="0.6"/>
<path d="M405.929 435.703L412.314 429.898" stroke="black" stroke-width="0.6"/>
<path d="M306.67 433.816L315.087 431.64" stroke="black" stroke-width="0.6"/>
<path d="M506.205 382.155L508.527 373.738" stroke="black" stroke-width="0.6"/>
<path d="M208.571 376.64L216.843 379.107" stroke="black" stroke-width="0.6"/>
<path d="M155.313 195.39L158.071 203.662" stroke="black" stroke-width="0.6"/>
<path d="M154.007 264.61L159.232 271.576" stroke="black" stroke-width="0.6"/>
<path d="M448.739 422.642L453.818 415.531" stroke="black" stroke-width="0.6"/>
<path d="M264.441 419.159L273.148 418.869" stroke="black" stroke-width="0.6"/>
<path d="M491.693 395.651L494.886 387.669" stroke="black" stroke-width="0.6"/>
<path d="M222.502 390.717L231.064 392.458" stroke="black" stroke-width="0.6"/>
<path d="M520.571 368.514L522.313 359.952" stroke="black" stroke-width="0.6"/>
<path d="M194.64 362.419L202.621 365.902" stroke="black" stroke-width="0.6"/>
<path d="M521.877 327.446L521.006 318.739" stroke="black" stroke-width="0.6"/>
<path d="M292.593 433.526L301.155 432.075" stroke="black" stroke-width="0.6"/>
<path d="M420.151 435.848L426.245 429.753" stroke="black" stroke-width="0.6"/>
<path d="M236.433 404.793L245.14 405.954" stroke="black" stroke-width="0.6"/>
<path d="M477.326 409.146L481.39 401.455" stroke="black" stroke-width="0.6"/>
<path d="M155.604 181.604L157.635 190.021" stroke="black" stroke-width="0.6"/>
<path d="M153.717 278.542L159.377 285.072" stroke="black" stroke-width="0.6"/>
<path d="M170.551 140.246L170.841 148.953" stroke="black" stroke-width="0.6"/>
<path d="M167.213 320.335L174.033 325.705" stroke="black" stroke-width="0.6"/>
<path d="M180.854 348.343L188.4 352.551" stroke="black" stroke-width="0.6"/>
<path d="M534.938 354.873L535.953 346.166" stroke="black" stroke-width="0.6"/>
<path d="M156.62 140.246V148.953" stroke="black" stroke-width="0.6"/>
<path d="M153.572 292.328L159.522 298.713" stroke="black" stroke-width="0.6"/>
<path d="M155.894 167.818L157.345 176.38" stroke="black" stroke-width="0.6"/>
<path d="M349.044 448.328L357.025 444.7" stroke="black" stroke-width="0.6"/>
<path d="M363.265 448.473L370.956 444.409" stroke="black" stroke-width="0.6"/>
<path d="M462.815 422.642L467.749 415.531" stroke="black" stroke-width="0.6"/>
<path d="M250.51 418.724L259.217 419.305" stroke="black" stroke-width="0.6"/>
<path d="M278.517 433.091L287.224 432.365" stroke="black" stroke-width="0.6"/>
<path d="M434.227 435.993L440.177 429.608" stroke="black" stroke-width="0.6"/>
<path d="M377.341 448.763L384.742 444.264" stroke="black" stroke-width="0.6"/>
<path d="M334.967 448.037L343.094 444.99" stroke="black" stroke-width="0.6"/>
<path d="M320.746 447.747L329.163 445.28" stroke="black" stroke-width="0.6"/>
<path d="M391.563 448.908L398.673 443.974" stroke="black" stroke-width="0.6"/>
<path d="M549.304 341.087L549.739 332.38" stroke="black" stroke-width="0.6"/>
<path d="M167.068 334.121L174.179 339.2" stroke="black" stroke-width="0.6"/>
<path d="M208.571 390.281L216.843 392.893" stroke="black" stroke-width="0.6"/>
<path d="M505.769 395.651L508.962 387.669" stroke="black" stroke-width="0.6"/>
<path d="M194.64 376.205L202.621 379.543" stroke="black" stroke-width="0.6"/>
<path d="M520.136 382.155L522.603 373.738" stroke="black" stroke-width="0.6"/>
<path d="M153.572 306.259L159.667 312.354" stroke="black" stroke-width="0.6"/>
<path d="M156.329 154.032L157.055 162.739" stroke="black" stroke-width="0.6"/>
<path d="M222.502 404.358L230.919 406.244" stroke="black" stroke-width="0.6"/>
<path d="M491.403 409.292L495.321 401.455" stroke="black" stroke-width="0.6"/>
<path d="M140.947 222.962L144.285 230.944" stroke="black" stroke-width="0.6"/>
<path d="M140.657 236.748L144.575 244.585" stroke="black" stroke-width="0.6"/>
<path d="M405.639 449.198L412.605 443.829" stroke="black" stroke-width="0.6"/>
<path d="M306.67 447.312L315.232 445.571" stroke="black" stroke-width="0.6"/>
<path d="M140.366 250.679L144.72 258.225" stroke="black" stroke-width="0.6"/>
<path d="M141.237 209.176L143.994 217.303" stroke="black" stroke-width="0.6"/>
<path d="M534.502 368.514L536.389 359.952" stroke="black" stroke-width="0.6"/>
<path d="M180.854 361.984L188.4 366.192" stroke="black" stroke-width="0.6"/>
<path d="M448.448 436.138L454.108 429.463" stroke="black" stroke-width="0.6"/>
<path d="M264.441 432.8H273.148" stroke="black" stroke-width="0.6"/>
<path d="M142.833 140.246L142.398 148.953" stroke="black" stroke-width="0.6"/>
<path d="M141.527 195.245L143.704 203.662" stroke="black" stroke-width="0.6"/>
<path d="M140.221 264.465L145.01 271.721" stroke="black" stroke-width="0.6"/>
<path d="M236.433 418.434L245.14 419.74" stroke="black" stroke-width="0.6"/>
<path d="M476.891 422.642L481.68 415.386" stroke="black" stroke-width="0.6"/>
<path d="M199.139 112.819L198.268 121.526" stroke="black" stroke-width="0.6"/>
<path d="M419.86 449.344L426.536 443.684" stroke="black" stroke-width="0.6"/>
<path d="M292.593 447.022L301.155 446.006" stroke="black" stroke-width="0.6"/>
<path d="M153.427 320.045L159.812 325.995" stroke="black" stroke-width="0.6"/>
<path d="M563.526 327.446V318.739" stroke="black" stroke-width="0.6"/>
<path d="M184.772 126.605L184.627 135.312" stroke="black" stroke-width="0.6"/>
<path d="M167.068 347.907L174.179 352.986" stroke="black" stroke-width="0.6"/>
<path d="M548.869 354.728L550.175 346.166" stroke="black" stroke-width="0.6"/>
<path d="M140.076 278.252L145.155 285.362" stroke="black" stroke-width="0.6"/>
<path d="M141.818 181.459L143.414 190.021" stroke="black" stroke-width="0.6"/>
<path d="M577.747 313.66L577.457 304.953" stroke="black" stroke-width="0.6"/>
<path d="M250.51 432.365L259.072 433.091" stroke="black" stroke-width="0.6"/>
<path d="M462.525 436.138L468.039 429.463" stroke="black" stroke-width="0.6"/>
<path d="M129.047 140.246L128.177 148.953" stroke="black" stroke-width="0.6"/>
<path d="M519.7 395.651L523.038 387.669" stroke="black" stroke-width="0.6"/>
<path d="M194.64 389.846L202.621 393.329" stroke="black" stroke-width="0.6"/>
<path d="M142.108 167.818L143.124 176.38" stroke="black" stroke-width="0.6"/>
<path d="M139.931 292.183L145.3 299.003" stroke="black" stroke-width="0.6"/>
<path d="M208.571 403.922L216.843 406.679" stroke="black" stroke-width="0.6"/>
<path d="M505.334 409.292L509.397 401.455" stroke="black" stroke-width="0.6"/>
<path d="M433.937 449.489L440.467 443.539" stroke="black" stroke-width="0.6"/>
<path d="M278.517 446.586L287.224 446.296" stroke="black" stroke-width="0.6"/>
<path d="M534.067 382.01L536.679 373.738" stroke="black" stroke-width="0.6"/>
<path d="M180.854 375.77L188.545 379.978" stroke="black" stroke-width="0.6"/>
<path d="M563.09 341.087L563.961 332.38" stroke="black" stroke-width="0.6"/>
<path d="M153.282 333.831L159.812 339.636" stroke="black" stroke-width="0.6"/>
<path d="M222.502 417.998L230.919 420.03" stroke="black" stroke-width="0.6"/>
<path d="M490.967 422.787L495.756 415.386" stroke="black" stroke-width="0.6"/>
<path d="M548.433 368.369L550.465 359.952" stroke="black" stroke-width="0.6"/>
<path d="M167.068 361.693L174.179 366.627" stroke="black" stroke-width="0.6"/>
<path d="M139.786 305.969L145.446 312.644" stroke="black" stroke-width="0.6"/>
<path d="M142.543 154.032L142.688 162.739" stroke="black" stroke-width="0.6"/>
<path d="M264.441 446.296L273.148 446.731" stroke="black" stroke-width="0.6"/>
<path d="M448.158 449.489L454.398 443.539" stroke="black" stroke-width="0.6"/>
<path d="M127.161 222.817L129.918 231.089" stroke="black" stroke-width="0.6"/>
<path d="M126.871 236.603L130.208 244.73" stroke="black" stroke-width="0.6"/>
<path d="M476.601 436.138L481.97 429.463" stroke="black" stroke-width="0.6"/>
<path d="M236.433 431.93L244.995 433.526" stroke="black" stroke-width="0.6"/>
<path d="M127.451 209.031L129.773 217.448" stroke="black" stroke-width="0.6"/>
<path d="M126.726 250.389L130.499 258.371" stroke="black" stroke-width="0.6"/>
<path d="M126.435 264.32L130.644 271.866" stroke="black" stroke-width="0.6"/>
<path d="M127.741 195.245L129.483 203.807" stroke="black" stroke-width="0.6"/>
<path d="M562.8 354.728L564.251 346.166" stroke="black" stroke-width="0.6"/>
<path d="M153.282 347.617L159.957 353.277" stroke="black" stroke-width="0.6"/>
<path d="M577.312 327.446L577.747 318.739" stroke="black" stroke-width="0.6"/>
<path d="M139.641 319.755L145.446 326.285" stroke="black" stroke-width="0.6"/>
<path d="M519.41 409.292L523.328 401.455" stroke="black" stroke-width="0.6"/>
<path d="M194.785 403.487L202.621 407.115" stroke="black" stroke-width="0.6"/>
<path d="M533.777 395.651L537.114 387.669" stroke="black" stroke-width="0.6"/>
<path d="M180.854 389.556L188.545 393.619" stroke="black" stroke-width="0.6"/>
<path d="M126.29 278.106L130.789 285.507" stroke="black" stroke-width="0.6"/>
<path d="M128.032 181.459L129.193 190.166" stroke="black" stroke-width="0.6"/>
<path d="M208.571 417.563L216.843 420.465" stroke="black" stroke-width="0.6"/>
<path d="M505.044 422.787L509.687 415.386" stroke="black" stroke-width="0.6"/>
<path d="M462.234 449.634L468.329 443.394" stroke="black" stroke-width="0.6"/>
<path d="M250.51 445.861L259.072 447.022" stroke="black" stroke-width="0.6"/>
<path d="M167.068 375.479L174.179 380.268" stroke="black" stroke-width="0.6"/>
<path d="M548.143 382.01L550.9 373.738" stroke="black" stroke-width="0.6"/>
<path d="M157.055 126.605L156.184 135.312" stroke="black" stroke-width="0.6"/>
<path d="M222.502 431.64L230.919 433.961" stroke="black" stroke-width="0.6"/>
<path d="M490.677 436.283L496.046 429.317" stroke="black" stroke-width="0.6"/>
<path d="M128.322 167.673L128.757 176.38" stroke="black" stroke-width="0.6"/>
<path d="M126.145 291.892L130.934 299.148" stroke="black" stroke-width="0.6"/>
<path d="M139.641 333.541L145.591 339.926" stroke="black" stroke-width="0.6"/>
<path d="M577.021 341.087L578.037 332.525" stroke="black" stroke-width="0.6"/>
<path d="M153.282 361.403L159.957 366.918" stroke="black" stroke-width="0.6"/>
<path d="M562.365 368.369L564.686 359.952" stroke="black" stroke-width="0.6"/>
<path d="M591.533 313.66L591.678 304.953" stroke="black" stroke-width="0.6"/>
<path d="M126 305.679L131.079 312.789" stroke="black" stroke-width="0.6"/>
<path d="M476.311 449.634L482.26 443.394" stroke="black" stroke-width="0.6"/>
<path d="M236.578 445.571L244.995 447.457" stroke="black" stroke-width="0.6"/>
<path d="M648.128 231.379L647.258 222.672" stroke="black" stroke-width="0.6"/>
<path d="M533.341 409.146L537.405 401.455" stroke="black" stroke-width="0.6"/>
<path d="M180.854 403.197L188.4 407.405" stroke="black" stroke-width="0.6"/>
<path d="M113.23 236.458L115.842 244.875" stroke="black" stroke-width="0.6"/>
<path d="M113.375 222.672L115.697 231.089" stroke="black" stroke-width="0.6"/>
<path d="M519.12 422.787L523.764 415.386" stroke="black" stroke-width="0.6"/>
<path d="M194.785 417.273L202.621 420.901" stroke="black" stroke-width="0.6"/>
<path d="M112.939 250.389L116.132 258.516" stroke="black" stroke-width="0.6"/>
<path d="M113.665 209.031L115.406 217.448" stroke="black" stroke-width="0.6"/>
<path d="M167.068 389.12L174.179 394.054" stroke="black" stroke-width="0.6"/>
<path d="M547.708 395.651L551.191 387.669" stroke="black" stroke-width="0.6"/>
<path d="M139.496 347.327L145.591 353.567" stroke="black" stroke-width="0.6"/>
<path d="M576.586 354.728L578.473 346.311" stroke="black" stroke-width="0.6"/>
<path d="M504.753 436.283L509.978 429.317" stroke="black" stroke-width="0.6"/>
<path d="M208.716 431.204L216.843 434.251" stroke="black" stroke-width="0.6"/>
<path d="M113.955 195.245L115.116 203.807" stroke="black" stroke-width="0.6"/>
<path d="M112.794 264.175L116.277 272.157" stroke="black" stroke-width="0.6"/>
<path d="M126 319.464L131.224 326.43" stroke="black" stroke-width="0.6"/>
<path d="M591.098 327.446L591.968 318.739" stroke="black" stroke-width="0.6"/>
<path d="M562.074 382.01L564.977 373.883" stroke="black" stroke-width="0.6"/>
<path d="M153.282 375.044L159.957 380.559" stroke="black" stroke-width="0.6"/>
<path d="M112.649 277.961L116.422 285.798" stroke="black" stroke-width="0.6"/>
<path d="M114.246 181.459L114.826 190.166" stroke="black" stroke-width="0.6"/>
<path d="M490.387 449.634L496.337 443.394" stroke="black" stroke-width="0.6"/>
<path d="M222.647 445.135L230.919 447.747" stroke="black" stroke-width="0.6"/>
<path d="M605.609 300.019V291.312" stroke="black" stroke-width="0.6"/>
<path d="M112.504 291.747L116.567 299.439" stroke="black" stroke-width="0.6"/>
<path d="M125.855 333.251L131.224 340.071" stroke="black" stroke-width="0.6"/>
<path d="M590.807 341.087L592.259 332.525" stroke="black" stroke-width="0.6"/>
<path d="M576.296 368.369L578.763 360.097" stroke="black" stroke-width="0.6"/>
<path d="M139.496 361.113L145.591 367.208" stroke="black" stroke-width="0.6"/>
<path d="M114.536 167.673V176.38" stroke="black" stroke-width="0.6"/>
<path d="M533.051 422.787L537.84 415.386" stroke="black" stroke-width="0.6"/>
<path d="M180.854 416.837L188.4 421.191" stroke="black" stroke-width="0.6"/>
<path d="M547.418 409.146L551.481 401.6" stroke="black" stroke-width="0.6"/>
<path d="M167.068 402.906L174.179 407.695" stroke="black" stroke-width="0.6"/>
<path d="M518.685 436.283L524.054 429.317" stroke="black" stroke-width="0.6"/>
<path d="M194.785 430.914L202.621 434.687" stroke="black" stroke-width="0.6"/>
<path d="M605.319 313.66L605.899 304.953" stroke="black" stroke-width="0.6"/>
<path d="M112.359 305.533L116.712 313.079" stroke="black" stroke-width="0.6"/>
<path d="M561.639 395.651L565.267 387.669" stroke="black" stroke-width="0.6"/>
<path d="M153.282 388.83L159.957 394.345" stroke="black" stroke-width="0.6"/>
<path d="M504.463 449.634L510.268 443.249" stroke="black" stroke-width="0.6"/>
<path d="M208.716 444.845L216.698 448.183" stroke="black" stroke-width="0.6"/>
<path d="M125.855 347.037L131.224 353.857" stroke="black" stroke-width="0.6"/>
<path d="M590.517 354.728L592.694 346.311" stroke="black" stroke-width="0.6"/>
<path d="M99.7339 222.672L101.33 231.234" stroke="black" stroke-width="0.6"/>
<path d="M99.4437 236.458L101.475 244.875" stroke="black" stroke-width="0.6"/>
<path d="M100.024 208.886L101.04 217.593" stroke="black" stroke-width="0.6"/>
<path d="M99.2986 250.244L101.766 258.516" stroke="black" stroke-width="0.6"/>
<path d="M575.86 382.01L579.198 373.883" stroke="black" stroke-width="0.6"/>
<path d="M139.496 374.754L145.736 380.849" stroke="black" stroke-width="0.6"/>
<path d="M605.029 327.301L606.19 318.739" stroke="black" stroke-width="0.6"/>
<path d="M112.214 319.319L116.858 326.72" stroke="black" stroke-width="0.6"/>
<path d="M99.1534 264.03L101.911 272.157" stroke="black" stroke-width="0.6"/>
<path d="M100.169 195.1L100.75 203.807" stroke="black" stroke-width="0.6"/>
<path d="M647.838 245.165L647.548 236.458" stroke="black" stroke-width="0.6"/>
<path d="M100.459 181.459L100.605 190.166" stroke="black" stroke-width="0.6"/>
<path d="M98.8633 277.816L102.056 285.943" stroke="black" stroke-width="0.6"/>
<path d="M546.982 422.642L551.916 415.531" stroke="black" stroke-width="0.6"/>
<path d="M167.068 416.547L174.179 421.481" stroke="black" stroke-width="0.6"/>
<path d="M532.761 436.283L538.13 429.317" stroke="black" stroke-width="0.6"/>
<path d="M180.999 430.478L188.4 434.977" stroke="black" stroke-width="0.6"/>
<path d="M125.71 360.823L131.369 367.498" stroke="black" stroke-width="0.6"/>
<path d="M590.082 368.369L592.984 360.097" stroke="black" stroke-width="0.6"/>
<path d="M561.349 409.146L565.702 401.6" stroke="black" stroke-width="0.6"/>
<path d="M153.282 402.471L159.957 408.13" stroke="black" stroke-width="0.6"/>
<path d="M604.594 341.087L606.48 332.525" stroke="black" stroke-width="0.6"/>
<path d="M112.214 333.106L116.858 340.361" stroke="black" stroke-width="0.6"/>
<path d="M619.395 300.019L619.831 291.312" stroke="black" stroke-width="0.6"/>
<path d="M98.718 291.602L102.201 299.584" stroke="black" stroke-width="0.6"/>
<path d="M518.394 449.634L524.344 443.249" stroke="black" stroke-width="0.6"/>
<path d="M194.785 444.409L202.621 448.473" stroke="black" stroke-width="0.6"/>
<path d="M619.685 286.233L619.54 277.526" stroke="black" stroke-width="0.6"/>
<path d="M575.57 395.505L579.488 387.814" stroke="black" stroke-width="0.6"/>
<path d="M139.496 388.54L145.736 394.635" stroke="black" stroke-width="0.6"/>
<path d="M676.136 176.525L675.41 167.818" stroke="black" stroke-width="0.6"/>
<path d="M72.4521 208.886V217.593" stroke="black" stroke-width="0.6"/>
<path d="M58.8112 208.886L58.0856 217.593" stroke="black" stroke-width="0.6"/>
<path d="M563.816 313.66L563.235 304.953" stroke="black" stroke-width="0.6"/>
<path d="M98.718 305.388L102.346 313.225" stroke="black" stroke-width="0.6"/>
<path d="M619.105 313.66L620.121 304.953" stroke="black" stroke-width="0.6"/>
<path d="M604.303 354.583L606.915 346.311" stroke="black" stroke-width="0.6"/>
<path d="M112.069 346.746L117.003 354.002" stroke="black" stroke-width="0.6"/>
<path d="M589.792 381.864L593.274 373.883" stroke="black" stroke-width="0.6"/>
<path d="M125.71 374.464L131.369 381.139" stroke="black" stroke-width="0.6"/>
<path d="M633.617 272.592V263.885" stroke="black" stroke-width="0.6"/>
<path d="M85.9479 222.672L86.9637 231.234" stroke="black" stroke-width="0.6"/>
<path d="M85.8027 236.313L87.2539 245.02" stroke="black" stroke-width="0.6"/>
<path d="M86.2382 208.886L86.8186 217.593" stroke="black" stroke-width="0.6"/>
<path d="M85.5126 250.099L87.3991 258.661" stroke="black" stroke-width="0.6"/>
<path d="M618.815 327.301L620.411 318.739" stroke="black" stroke-width="0.6"/>
<path d="M98.573 319.029L102.491 326.865" stroke="black" stroke-width="0.6"/>
<path d="M546.692 436.138L552.206 429.463" stroke="black" stroke-width="0.6"/>
<path d="M167.068 430.188L174.179 435.267" stroke="black" stroke-width="0.6"/>
<path d="M153.282 416.257L159.957 421.771" stroke="black" stroke-width="0.6"/>
<path d="M561.058 422.642L565.992 415.531" stroke="black" stroke-width="0.6"/>
<path d="M532.471 449.634L538.42 443.394" stroke="black" stroke-width="0.6"/>
<path d="M180.999 444.119L188.4 448.763" stroke="black" stroke-width="0.6"/>
<path d="M86.5283 195.1V203.807" stroke="black" stroke-width="0.6"/>
<path d="M85.3674 263.885L87.5442 272.302" stroke="black" stroke-width="0.6"/>
<path d="M575.28 409.146L579.779 401.6" stroke="black" stroke-width="0.6"/>
<path d="M139.496 402.181L145.736 408.421" stroke="black" stroke-width="0.6"/>
<path d="M633.472 286.233L633.907 277.526" stroke="black" stroke-width="0.6"/>
<path d="M85.2223 277.671L87.6893 285.943" stroke="black" stroke-width="0.6"/>
<path d="M112.069 360.533L117.003 367.788" stroke="black" stroke-width="0.6"/>
<path d="M604.013 368.224L607.206 360.097" stroke="black" stroke-width="0.6"/>
<path d="M98.4279 332.815L102.491 340.506" stroke="black" stroke-width="0.6"/>
<path d="M618.525 340.942L620.701 332.525" stroke="black" stroke-width="0.6"/>
<path d="M589.501 395.505L593.565 387.814" stroke="black" stroke-width="0.6"/>
<path d="M125.71 388.25L131.369 394.925" stroke="black" stroke-width="0.6"/>
<path d="M633.181 299.874L634.197 291.312" stroke="black" stroke-width="0.6"/>
<path d="M85.0771 291.457L87.8344 299.729" stroke="black" stroke-width="0.6"/>
<path d="M128.612 154.032L128.467 162.739" stroke="black" stroke-width="0.6"/>
<path d="M632.891 313.66L634.487 305.098" stroke="black" stroke-width="0.6"/>
<path d="M84.932 305.243L87.9794 313.37" stroke="black" stroke-width="0.6"/>
<path d="M560.768 436.138L566.283 429.463" stroke="black" stroke-width="0.6"/>
<path d="M153.282 429.898L159.957 435.557" stroke="black" stroke-width="0.6"/>
<path d="M618.089 354.583L621.137 346.456" stroke="black" stroke-width="0.6"/>
<path d="M98.4279 346.601L102.636 354.292" stroke="black" stroke-width="0.6"/>
<path d="M603.723 381.864L607.496 374.028" stroke="black" stroke-width="0.6"/>
<path d="M112.069 374.318L117.003 381.429" stroke="black" stroke-width="0.6"/>
<path d="M546.402 449.634L552.497 443.394" stroke="black" stroke-width="0.6"/>
<path d="M167.213 443.829L174.179 449.053" stroke="black" stroke-width="0.6"/>
<path d="M574.99 422.642L580.069 415.531" stroke="black" stroke-width="0.6"/>
<path d="M139.496 415.967L145.591 422.062" stroke="black" stroke-width="0.6"/>
<path d="M72.307 222.527L72.5972 231.234" stroke="black" stroke-width="0.6"/>
<path d="M72.0167 236.313L72.8874 245.02" stroke="black" stroke-width="0.6"/>
<path d="M632.601 327.301L634.778 318.884" stroke="black" stroke-width="0.6"/>
<path d="M84.932 318.884L88.1246 327.011" stroke="black" stroke-width="0.6"/>
<path d="M589.211 409.001L593.855 401.745" stroke="black" stroke-width="0.6"/>
<path d="M125.71 402.036L131.369 408.566" stroke="black" stroke-width="0.6"/>
<path d="M647.693 258.806V250.099" stroke="black" stroke-width="0.6"/>
<path d="M71.8716 250.099L73.0325 258.661" stroke="black" stroke-width="0.6"/>
<path d="M647.403 272.592L647.983 263.885" stroke="black" stroke-width="0.6"/>
<path d="M71.7266 263.885L73.1777 272.447" stroke="black" stroke-width="0.6"/>
<path d="M98.4279 360.387L102.636 367.933" stroke="black" stroke-width="0.6"/>
<path d="M617.799 368.224L621.427 360.242" stroke="black" stroke-width="0.6"/>
<path d="M647.112 286.233L648.273 277.526" stroke="black" stroke-width="0.6"/>
<path d="M71.5814 277.526L73.3228 286.088" stroke="black" stroke-width="0.6"/>
<path d="M603.433 395.36L607.786 387.814" stroke="black" stroke-width="0.6"/>
<path d="M112.069 387.959L117.003 395.07" stroke="black" stroke-width="0.6"/>
<path d="M114.826 154.032L114.246 162.739" stroke="black" stroke-width="0.6"/>
<path d="M632.311 340.942L635.068 332.67" stroke="black" stroke-width="0.6"/>
<path d="M84.787 332.67L88.1247 340.652" stroke="black" stroke-width="0.6"/>
<path d="M646.967 299.874L648.419 291.312" stroke="black" stroke-width="0.6"/>
<path d="M71.4363 291.312L73.4679 299.729" stroke="black" stroke-width="0.6"/>
<path d="M560.478 449.634L566.573 443.394" stroke="black" stroke-width="0.6"/>
<path d="M153.427 443.539L159.812 449.344" stroke="black" stroke-width="0.6"/>
<path d="M574.699 436.138L580.359 429.463" stroke="black" stroke-width="0.6"/>
<path d="M139.496 429.608L145.591 435.848" stroke="black" stroke-width="0.6"/>
<path d="M661.769 231.379L661.624 222.672" stroke="black" stroke-width="0.6"/>
<path d="M588.921 422.497L594.145 415.677" stroke="black" stroke-width="0.6"/>
<path d="M125.71 415.677L131.369 422.352" stroke="black" stroke-width="0.6"/>
<path d="M617.509 381.719L621.717 374.028" stroke="black" stroke-width="0.6"/>
<path d="M98.2827 374.028L102.636 381.574" stroke="black" stroke-width="0.6"/>
<path d="M646.677 313.515L648.709 305.098" stroke="black" stroke-width="0.6"/>
<path d="M71.2911 305.098L73.613 313.515" stroke="black" stroke-width="0.6"/>
<path d="M632.02 354.583L635.358 346.456" stroke="black" stroke-width="0.6"/>
<path d="M84.6418 346.456L88.2698 354.437" stroke="black" stroke-width="0.6"/>
<path d="M603.142 409.001L608.076 401.745" stroke="black" stroke-width="0.6"/>
<path d="M112.069 401.745L117.003 408.856" stroke="black" stroke-width="0.6"/>
<path d="M619.976 272.592L619.395 263.885" stroke="black" stroke-width="0.6"/>
<path d="M71.146 318.739L73.7581 327.156" stroke="black" stroke-width="0.6"/>
<path d="M646.387 327.156L648.999 318.884" stroke="black" stroke-width="0.6"/>
<path d="M16.4373 318.594L16.1471 327.301" stroke="black" stroke-width="0.6"/>
<path d="M58.3757 236.313L58.5208 245.02" stroke="black" stroke-width="0.6"/>
<path d="M661.624 245.165L661.914 236.458" stroke="black" stroke-width="0.6"/>
<path d="M58.2308 250.099L58.6662 258.661" stroke="black" stroke-width="0.6"/>
<path d="M661.334 258.806L662.059 250.099" stroke="black" stroke-width="0.6"/>
<path d="M631.73 368.078L635.503 360.242" stroke="black" stroke-width="0.6"/>
<path d="M84.6418 360.242L88.2698 368.078" stroke="black" stroke-width="0.6"/>
<path d="M58.0856 263.74L58.8112 272.447" stroke="black" stroke-width="0.6"/>
<path d="M661.189 272.447L662.35 263.885" stroke="black" stroke-width="0.6"/>
<path d="M617.219 395.36L622.007 387.959" stroke="black" stroke-width="0.6"/>
<path d="M98.2827 387.814L102.636 395.36" stroke="black" stroke-width="0.6"/>
<path d="M675.846 203.952L675.7 195.245" stroke="black" stroke-width="0.6"/>
<path d="M574.409 449.489L580.649 443.394" stroke="black" stroke-width="0.6"/>
<path d="M139.641 443.249L145.591 449.634" stroke="black" stroke-width="0.6"/>
<path d="M588.631 435.993L594.435 429.608" stroke="black" stroke-width="0.6"/>
<path d="M125.855 429.317L131.369 436.138" stroke="black" stroke-width="0.6"/>
<path d="M660.899 286.233L662.495 277.671" stroke="black" stroke-width="0.6"/>
<path d="M57.7953 277.526L58.9562 286.088" stroke="black" stroke-width="0.6"/>
<path d="M71.146 332.525L73.7581 340.797" stroke="black" stroke-width="0.6"/>
<path d="M646.097 340.797L649.289 332.67" stroke="black" stroke-width="0.6"/>
<path d="M602.852 422.497L608.366 415.677" stroke="black" stroke-width="0.6"/>
<path d="M112.069 415.386L117.003 422.642" stroke="black" stroke-width="0.6"/>
<path d="M660.608 299.874L662.785 291.457" stroke="black" stroke-width="0.6"/>
<path d="M57.7953 291.312L59.1013 299.874" stroke="black" stroke-width="0.6"/>
<path d="M631.44 381.719L635.793 374.173" stroke="black" stroke-width="0.6"/>
<path d="M84.6418 373.883L88.2698 381.719" stroke="black" stroke-width="0.6"/>
<path d="M690.067 135.312L689.486 126.605" stroke="black" stroke-width="0.6"/>
<path d="M645.806 354.437L649.579 346.601" stroke="black" stroke-width="0.6"/>
<path d="M71.001 346.311L73.9033 354.583" stroke="black" stroke-width="0.6"/>
<path d="M57.6501 304.953L59.2464 313.515" stroke="black" stroke-width="0.6"/>
<path d="M660.463 313.515L662.93 305.243" stroke="black" stroke-width="0.6"/>
<path d="M616.928 408.856L622.152 401.891" stroke="black" stroke-width="0.6"/>
<path d="M98.2827 401.455L102.636 409.001" stroke="black" stroke-width="0.6"/>
<path d="M72.7423 195.1L72.1619 203.807" stroke="black" stroke-width="0.6"/>
<path d="M588.34 449.489L594.725 443.539" stroke="black" stroke-width="0.6"/>
<path d="M125.855 442.958L131.224 449.924" stroke="black" stroke-width="0.6"/>
<path d="M660.173 327.156L663.22 319.029" stroke="black" stroke-width="0.6"/>
<path d="M57.5052 318.739L59.3918 327.156" stroke="black" stroke-width="0.6"/>
<path d="M675.41 231.379L675.991 222.672" stroke="black" stroke-width="0.6"/>
<path d="M675.265 245.02L676.136 236.458" stroke="black" stroke-width="0.6"/>
<path d="M645.516 368.078L649.725 360.387" stroke="black" stroke-width="0.6"/>
<path d="M71.001 360.097L73.9033 368.224" stroke="black" stroke-width="0.6"/>
<path d="M631.15 395.215L636.084 388.105" stroke="black" stroke-width="0.6"/>
<path d="M84.6418 387.669L88.2698 395.505" stroke="black" stroke-width="0.6"/>
<path d="M602.562 435.993L608.511 429.608" stroke="black" stroke-width="0.6"/>
<path d="M112.069 429.172L117.003 436.283" stroke="black" stroke-width="0.6"/>
<path d="M675.12 258.806L676.426 250.099" stroke="black" stroke-width="0.6"/>
<path d="M675.7 217.593L675.846 209.031" stroke="black" stroke-width="0.6"/>
<path d="M44.2996 263.74L44.4447 272.447" stroke="black" stroke-width="0.6"/>
<path d="M674.83 272.447L676.571 263.885" stroke="black" stroke-width="0.6"/>
<path d="M58.5211 222.527L58.2308 231.234" stroke="black" stroke-width="0.6"/>
<path d="M57.36 332.525L59.3916 340.942" stroke="black" stroke-width="0.6"/>
<path d="M659.883 340.797L663.511 332.815" stroke="black" stroke-width="0.6"/>
<path d="M616.783 422.352L622.443 415.822" stroke="black" stroke-width="0.6"/>
<path d="M98.2827 415.241L102.636 422.787" stroke="black" stroke-width="0.6"/>
<path d="M44.1544 277.526L44.5898 286.233" stroke="black" stroke-width="0.6"/>
<path d="M674.685 286.088L676.861 277.671" stroke="black" stroke-width="0.6"/>
<path d="M44.0093 291.167L44.7349 299.874" stroke="black" stroke-width="0.6"/>
<path d="M674.394 299.729L677.006 291.457" stroke="black" stroke-width="0.6"/>
<path d="M645.226 381.574L650.015 374.318" stroke="black" stroke-width="0.6"/>
<path d="M71.001 373.738L73.9033 381.864" stroke="black" stroke-width="0.6"/>
<path d="M630.859 408.711L636.374 402.036" stroke="black" stroke-width="0.6"/>
<path d="M84.6418 401.31L88.2698 409.291" stroke="black" stroke-width="0.6"/>
<path d="M30.6586 263.74L30.0781 272.447" stroke="black" stroke-width="0.6"/>
<path d="M659.592 354.292L663.801 346.601" stroke="black" stroke-width="0.6"/>
<path d="M57.36 346.166L59.5367 354.583" stroke="black" stroke-width="0.6"/>
<path d="M44.7349 236.313L44.1544 245.02" stroke="black" stroke-width="0.6"/>
<path d="M43.8641 304.953L44.8799 313.515" stroke="black" stroke-width="0.6"/>
<path d="M674.249 313.37L677.297 305.243" stroke="black" stroke-width="0.6"/>
<path d="M602.271 449.344L608.802 443.684" stroke="black" stroke-width="0.6"/>
<path d="M112.069 442.813L116.858 450.069" stroke="black" stroke-width="0.6"/>
<path d="M44.4447 250.099L44.2996 258.661" stroke="black" stroke-width="0.6"/>
<path d="M616.493 435.848L622.733 429.753" stroke="black" stroke-width="0.6"/>
<path d="M98.4279 428.882L102.636 436.573" stroke="black" stroke-width="0.6"/>
<path d="M43.8641 318.594L45.0251 327.301" stroke="black" stroke-width="0.6"/>
<path d="M673.959 327.01L677.442 319.029" stroke="black" stroke-width="0.6"/>
<path d="M645.081 395.07L650.305 388.105" stroke="black" stroke-width="0.6"/>
<path d="M71.001 387.524L73.9033 395.651" stroke="black" stroke-width="0.6"/>
<path d="M659.447 367.933L663.946 360.533" stroke="black" stroke-width="0.6"/>
<path d="M57.36 359.952L59.5367 368.369" stroke="black" stroke-width="0.6"/>
<path d="M689.196 231.379L690.357 222.672" stroke="black" stroke-width="0.6"/>
<path d="M689.051 245.02L690.502 236.458" stroke="black" stroke-width="0.6"/>
<path d="M689.341 217.593L690.212 209.031" stroke="black" stroke-width="0.6"/>
<path d="M688.761 258.661L690.792 250.244" stroke="black" stroke-width="0.6"/>
<path d="M630.714 422.207L636.664 415.967" stroke="black" stroke-width="0.6"/>
<path d="M84.6418 415.096L88.2698 422.932" stroke="black" stroke-width="0.6"/>
<path d="M688.616 272.447L690.938 264.03" stroke="black" stroke-width="0.6"/>
<path d="M689.486 203.952L690.067 195.245" stroke="black" stroke-width="0.6"/>
<path d="M673.669 340.652L677.732 332.96" stroke="black" stroke-width="0.6"/>
<path d="M43.7192 332.38L45.0253 340.942" stroke="black" stroke-width="0.6"/>
<path d="M30.5135 277.526L30.2233 286.233" stroke="black" stroke-width="0.6"/>
<path d="M689.632 190.166L689.922 181.459" stroke="black" stroke-width="0.6"/>
<path d="M688.471 286.088L691.083 277.816" stroke="black" stroke-width="0.6"/>
<path d="M659.157 381.429L664.236 374.464" stroke="black" stroke-width="0.6"/>
<path d="M57.2148 373.593L59.5367 382.01" stroke="black" stroke-width="0.6"/>
<path d="M644.791 408.566L650.595 402.036" stroke="black" stroke-width="0.6"/>
<path d="M71.001 401.165L73.9033 409.292" stroke="black" stroke-width="0.6"/>
<path d="M688.18 299.729L691.373 291.602" stroke="black" stroke-width="0.6"/>
<path d="M30.3684 291.167V299.874" stroke="black" stroke-width="0.6"/>
<path d="M661.914 217.593L661.479 209.031" stroke="black" stroke-width="0.6"/>
<path d="M689.777 176.525V167.818" stroke="black" stroke-width="0.6"/>
<path d="M616.203 449.344L622.878 443.684" stroke="black" stroke-width="0.6"/>
<path d="M98.4279 442.668L102.636 450.214" stroke="black" stroke-width="0.6"/>
<path d="M673.524 354.147L678.022 346.746" stroke="black" stroke-width="0.6"/>
<path d="M43.7192 346.166L45.1704 354.728" stroke="black" stroke-width="0.6"/>
<path d="M30.2233 304.953L30.5135 313.66" stroke="black" stroke-width="0.6"/>
<path d="M688.035 313.37L691.518 305.388" stroke="black" stroke-width="0.6"/>
<path d="M630.424 435.703L636.809 429.898" stroke="black" stroke-width="0.6"/>
<path d="M84.6418 428.737L88.2698 436.719" stroke="black" stroke-width="0.6"/>
<path d="M16.5824 304.953L16.1471 313.66" stroke="black" stroke-width="0.6"/>
<path d="M658.867 394.925L664.526 388.25" stroke="black" stroke-width="0.6"/>
<path d="M57.2148 387.379L59.5367 395.796" stroke="black" stroke-width="0.6"/>
<path d="M673.233 367.788L678.167 360.678" stroke="black" stroke-width="0.6"/>
<path d="M43.574 359.807L45.1703 368.369" stroke="black" stroke-width="0.6"/>
<path d="M687.745 326.866L691.808 319.174" stroke="black" stroke-width="0.6"/>
<path d="M30.2233 318.594L30.5135 327.301" stroke="black" stroke-width="0.6"/>
<path d="M644.5 422.062L650.74 415.967" stroke="black" stroke-width="0.6"/>
<path d="M71.001 414.951L73.9033 423.078" stroke="black" stroke-width="0.6"/>
<path d="M702.837 231.234L704.724 222.817" stroke="black" stroke-width="0.6"/>
<path d="M702.692 245.02L704.869 236.603" stroke="black" stroke-width="0.6"/>
<path d="M703.127 217.593L704.578 209.031" stroke="black" stroke-width="0.6"/>
<path d="M702.547 258.661L705.014 250.244" stroke="black" stroke-width="0.6"/>
<path d="M687.455 340.506L691.953 333.106" stroke="black" stroke-width="0.6"/>
<path d="M30.0781 332.38L30.6586 341.087" stroke="black" stroke-width="0.6"/>
<path d="M703.272 203.952L704.433 195.245" stroke="black" stroke-width="0.6"/>
<path d="M702.402 272.302L705.159 264.03" stroke="black" stroke-width="0.6"/>
<path d="M630.279 449.198L637.099 443.829" stroke="black" stroke-width="0.6"/>
<path d="M84.6418 442.523L88.2698 450.36" stroke="black" stroke-width="0.6"/>
<path d="M658.722 408.566L664.672 402.181" stroke="black" stroke-width="0.6"/>
<path d="M57.2148 401.02L59.5367 409.437" stroke="black" stroke-width="0.6"/>
<path d="M672.943 381.284L678.458 374.464" stroke="black" stroke-width="0.6"/>
<path d="M43.574 373.593L45.1703 382.155" stroke="black" stroke-width="0.6"/>
<path d="M702.112 285.943L705.449 277.816" stroke="black" stroke-width="0.6"/>
<path d="M703.272 190.166L704.288 181.604" stroke="black" stroke-width="0.6"/>
<path d="M703.418 176.525L704.143 167.818" stroke="black" stroke-width="0.6"/>
<path d="M701.966 299.584L705.594 291.602" stroke="black" stroke-width="0.6"/>
<path d="M71.001 428.592L73.9033 436.863" stroke="black" stroke-width="0.6"/>
<path d="M644.355 435.557L651.031 429.898" stroke="black" stroke-width="0.6"/>
<path d="M687.31 354.147L692.244 346.891" stroke="black" stroke-width="0.6"/>
<path d="M30.0781 346.021L30.6586 354.728" stroke="black" stroke-width="0.6"/>
<path d="M689.922 162.739L689.632 154.032" stroke="black" stroke-width="0.6"/>
<path d="M703.563 162.739L703.998 154.032" stroke="black" stroke-width="0.6"/>
<path d="M701.821 313.225L705.885 305.533" stroke="black" stroke-width="0.6"/>
<path d="M675.991 190.166L675.555 181.604" stroke="black" stroke-width="0.6"/>
<path d="M672.798 394.78L678.603 388.395" stroke="black" stroke-width="0.6"/>
<path d="M43.574 387.234L45.1703 395.796" stroke="black" stroke-width="0.6"/>
<path d="M658.432 422.062L664.962 416.112" stroke="black" stroke-width="0.6"/>
<path d="M57.2148 414.806L59.5367 423.222" stroke="black" stroke-width="0.6"/>
<path d="M687.019 367.643L692.389 360.823" stroke="black" stroke-width="0.6"/>
<path d="M29.9332 359.807L30.8039 368.514" stroke="black" stroke-width="0.6"/>
<path d="M16.4372 332.38L16.2921 341.087" stroke="black" stroke-width="0.6"/>
<path d="M703.708 149.098L703.998 140.391" stroke="black" stroke-width="0.6"/>
<path d="M701.531 326.72L706.03 319.319" stroke="black" stroke-width="0.6"/>
<path d="M71.001 442.378L73.9033 450.504" stroke="black" stroke-width="0.6"/>
<path d="M644.21 449.053L651.176 443.974" stroke="black" stroke-width="0.6"/>
<path d="M716.623 231.234L719.09 222.817" stroke="black" stroke-width="0.6"/>
<path d="M716.478 244.875L719.235 236.603" stroke="black" stroke-width="0.6"/>
<path d="M716.768 217.593L718.945 209.031" stroke="black" stroke-width="0.6"/>
<path d="M716.333 258.516L719.38 250.389" stroke="black" stroke-width="0.6"/>
<path d="M701.386 340.361L706.175 333.106" stroke="black" stroke-width="0.6"/>
<path d="M703.708 135.312L703.853 126.605" stroke="black" stroke-width="0.6"/>
<path d="M672.508 408.421L678.893 402.326" stroke="black" stroke-width="0.6"/>
<path d="M43.574 401.02L45.1703 409.582" stroke="black" stroke-width="0.6"/>
<path d="M716.913 203.807L718.8 195.39" stroke="black" stroke-width="0.6"/>
<path d="M716.043 272.157L719.526 264.175" stroke="black" stroke-width="0.6"/>
<path d="M29.9332 373.448L30.8039 382.155" stroke="black" stroke-width="0.6"/>
<path d="M686.874 381.139L692.679 374.609" stroke="black" stroke-width="0.6"/>
<path d="M715.898 285.798L719.671 277.961" stroke="black" stroke-width="0.6"/>
<path d="M717.058 190.166L718.655 181.604" stroke="black" stroke-width="0.6"/>
<path d="M658.286 435.412L665.107 430.043" stroke="black" stroke-width="0.6"/>
<path d="M57.36 428.447L59.5367 436.864" stroke="black" stroke-width="0.6"/>
<path d="M16.2921 346.021V354.728" stroke="black" stroke-width="0.6"/>
<path d="M689.922 149.098L689.632 140.391" stroke="black" stroke-width="0.6"/>
<path d="M703.853 121.671V112.964" stroke="black" stroke-width="0.6"/>
<path d="M701.096 354.002L706.465 347.037" stroke="black" stroke-width="0.6"/>
<path d="M717.203 176.525L718.51 167.818" stroke="black" stroke-width="0.6"/>
<path d="M715.752 299.439L719.961 291.747" stroke="black" stroke-width="0.6"/>
<path d="M686.729 394.635L692.824 388.54" stroke="black" stroke-width="0.6"/>
<path d="M29.9332 387.234L30.8039 395.941" stroke="black" stroke-width="0.6"/>
<path d="M715.607 313.079L720.106 305.679" stroke="black" stroke-width="0.6"/>
<path d="M717.203 162.739L718.364 154.177" stroke="black" stroke-width="0.6"/>
<path d="M672.363 421.916L679.038 416.257" stroke="black" stroke-width="0.6"/>
<path d="M43.574 414.661L45.1703 423.223" stroke="black" stroke-width="0.6"/>
<path d="M703.853 66.8172L703.708 58.1102" stroke="black" stroke-width="0.6"/>
<path d="M16.2921 359.807L16.4372 368.514" stroke="black" stroke-width="0.6"/>
<path d="M700.951 367.498L706.61 360.968" stroke="black" stroke-width="0.6"/>
<path d="M717.349 149.098L718.365 140.391" stroke="black" stroke-width="0.6"/>
<path d="M715.317 326.575L720.251 319.464" stroke="black" stroke-width="0.6"/>
<path d="M703.853 107.885L703.708 99.178" stroke="black" stroke-width="0.6"/>
<path d="M658.141 448.908L665.252 444.119" stroke="black" stroke-width="0.6"/>
<path d="M57.36 442.233L59.5367 450.65" stroke="black" stroke-width="0.6"/>
<path d="M29.9332 401.02L30.8039 409.582" stroke="black" stroke-width="0.6"/>
<path d="M686.439 408.276L692.969 402.471" stroke="black" stroke-width="0.6"/>
<path d="M715.172 340.216L720.541 333.251" stroke="black" stroke-width="0.6"/>
<path d="M717.349 135.312L718.219 126.605" stroke="black" stroke-width="0.6"/>
<path d="M16.2921 373.448L16.4372 382.155" stroke="black" stroke-width="0.6"/>
<path d="M700.66 380.994L706.755 374.754" stroke="black" stroke-width="0.6"/>
<path d="M672.218 435.267L679.183 430.188" stroke="black" stroke-width="0.6"/>
<path d="M43.574 428.447L45.1703 437.009" stroke="black" stroke-width="0.6"/>
<path d="M703.853 80.4581L703.708 71.7511" stroke="black" stroke-width="0.6"/>
<path d="M703.853 53.0311L703.708 44.3241" stroke="black" stroke-width="0.6"/>
<path d="M717.494 121.671L718.219 112.964" stroke="black" stroke-width="0.6"/>
<path d="M715.027 353.712L720.686 347.182" stroke="black" stroke-width="0.6"/>
<path d="M686.294 421.772L693.259 416.402" stroke="black" stroke-width="0.6"/>
<path d="M29.9332 414.661L30.8039 423.368" stroke="black" stroke-width="0.6"/>
<path d="M700.515 394.49L707.046 388.685" stroke="black" stroke-width="0.6"/>
<path d="M16.2921 387.234L16.4372 395.941" stroke="black" stroke-width="0.6"/>
<path d="M672.073 448.763L679.473 444.264" stroke="black" stroke-width="0.6"/>
<path d="M43.7192 442.088L45.1704 450.65" stroke="black" stroke-width="0.6"/>
<path d="M717.494 107.885L718.219 99.178" stroke="black" stroke-width="0.6"/>
<path d="M714.737 367.353L720.832 361.113" stroke="black" stroke-width="0.6"/>
<path d="M700.37 407.985L707.191 402.616" stroke="black" stroke-width="0.6"/>
<path d="M16.2921 400.875L16.4372 409.582" stroke="black" stroke-width="0.6"/>
<path d="M29.9332 428.447L30.8039 437.009" stroke="black" stroke-width="0.6"/>
<path d="M686.149 435.122L693.405 430.333" stroke="black" stroke-width="0.6"/>
<path d="M717.494 94.2442L718.074 85.5372" stroke="black" stroke-width="0.6"/>
<path d="M714.591 380.849L720.977 375.044" stroke="black" stroke-width="0.6"/>
<path d="M700.225 421.481L707.336 416.547" stroke="black" stroke-width="0.6"/>
<path d="M16.2921 414.661L16.4372 423.368" stroke="black" stroke-width="0.6"/>
<path d="M714.447 394.345L721.122 388.83" stroke="black" stroke-width="0.6"/>
<path d="M717.639 80.4581L718.074 71.7511" stroke="black" stroke-width="0.6"/>
<path d="M29.9332 442.088L30.8039 450.795" stroke="black" stroke-width="0.6"/>
<path d="M686.004 448.618L693.55 444.409" stroke="black" stroke-width="0.6"/>
<path d="M703.853 25.6041L703.708 16.8971" stroke="black" stroke-width="0.6"/>
<path d="M714.301 407.84L721.412 402.761" stroke="black" stroke-width="0.6"/>
<path d="M717.639 66.8172L718.074 58.1102" stroke="black" stroke-width="0.6"/>
<path d="M16.2921 428.302L16.4372 437.009" stroke="black" stroke-width="0.6"/>
<path d="M700.08 434.977L707.481 430.624" stroke="black" stroke-width="0.6"/>
<path d="M714.156 421.336L721.557 416.837" stroke="black" stroke-width="0.6"/>
<path d="M717.639 53.0311L718.074 44.3241" stroke="black" stroke-width="0.6"/>
<path d="M703.853 94.2442L703.708 85.5372" stroke="black" stroke-width="0.6"/>
<path d="M16.2921 442.088L16.4372 450.795" stroke="black" stroke-width="0.6"/>
<path d="M699.935 448.473L707.626 444.555" stroke="black" stroke-width="0.6"/>
<path d="M703.853 39.3902L703.708 30.6832" stroke="black" stroke-width="0.6"/>
<path d="M714.011 434.832L721.702 430.769" stroke="black" stroke-width="0.6"/>
<path d="M717.494 39.2451L718.074 30.6832" stroke="black" stroke-width="0.6"/>
<path d="M703.853 11.9631V3.2561" stroke="black" stroke-width="0.6"/>
<path d="M713.866 448.328L721.702 444.7" stroke="black" stroke-width="0.6"/>
<path d="M717.494 25.6041L718.074 16.8971" stroke="black" stroke-width="0.6"/>
<path d="M717.494 11.818L718.219 3.2561" stroke="black" stroke-width="0.6"/>
<path d="M676.571 66.6721L674.975 58.1102" stroke="black" stroke-width="0.6"/>
<path d="M662.785 121.526L660.608 113.109" stroke="black" stroke-width="0.6"/>
<path d="M662.495 176.38L661.044 167.818" stroke="black" stroke-width="0.6"/>
<path d="M649.144 52.7408L646.242 44.6143" stroke="black" stroke-width="0.6"/>
<path d="M649.144 93.9539L646.242 85.8273" stroke="black" stroke-width="0.6"/>
<path d="M635.358 11.5278L632.02 3.54639" stroke="black" stroke-width="0.6"/>
<path d="M635.503 80.0227L631.875 72.1864" stroke="black" stroke-width="0.6"/>
<path d="M635.503 121.236L631.875 113.254" stroke="black" stroke-width="0.6"/>
<path d="M621.717 11.3828L617.654 3.69165" stroke="black" stroke-width="0.6"/>
<path d="M608.076 120.945L603.142 113.69" stroke="black" stroke-width="0.6"/>
<path d="M594.435 52.0153L588.776 45.34" stroke="black" stroke-width="0.6"/>
<path d="M580.649 92.938L574.409 86.8431" stroke="black" stroke-width="0.6"/>
<path d="M580.649 106.579L574.554 100.484" stroke="black" stroke-width="0.6"/>
<path d="M566.863 106.289L560.188 100.774" stroke="black" stroke-width="0.6"/>
<path d="M566.428 189.15L560.768 182.62" stroke="black" stroke-width="0.6"/>
<path d="M564.541 286.088L562.51 277.671" stroke="black" stroke-width="0.6"/>
<path d="M553.077 105.999L545.966 101.065" stroke="black" stroke-width="0.6"/>
<path d="M553.077 119.785L545.966 114.706" stroke="black" stroke-width="0.6"/>
<path d="M539.146 23.5724L531.745 18.9287" stroke="black" stroke-width="0.6"/>
<path d="M539.146 37.2135L531.745 32.7148" stroke="black" stroke-width="0.6"/>
<path d="M511.429 9.35105L503.447 5.72314" stroke="black" stroke-width="0.6"/>
<path d="M511.574 132.41L503.302 129.507" stroke="black" stroke-width="0.6"/>
<path d="M483.712 62.8991L475.005 61.7382" stroke="black" stroke-width="0.6"/>
<path d="M469.635 62.4635L460.928 62.1733" stroke="black" stroke-width="0.6"/>
<path d="M469.635 172.607L461.073 171.591" stroke="black" stroke-width="0.6"/>
<path d="M467.023 299.584L463.685 291.602" stroke="black" stroke-width="0.6"/>
<path d="M441.628 48.097L432.921 49.258" stroke="black" stroke-width="0.6"/>
<path d="M441.628 171.446L432.921 172.752" stroke="black" stroke-width="0.6"/>
<path d="M438.871 299.729L435.678 291.602" stroke="black" stroke-width="0.6"/>
<path d="M427.552 7.17444L418.845 7.75489" stroke="black" stroke-width="0.6"/>
<path d="M427.552 20.67L418.99 21.6859" stroke="black" stroke-width="0.6"/>
<path d="M427.406 74.7986L419.135 77.4107" stroke="black" stroke-width="0.6"/>
<path d="M427.261 143.003L419.28 146.341" stroke="black" stroke-width="0.6"/>
<path d="M413.04 128.782L405.494 133.135" stroke="black" stroke-width="0.6"/>
<path d="M413.185 184.071L405.204 187.554" stroke="black" stroke-width="0.6"/>
<path d="M411.008 285.798L407.38 277.961" stroke="black" stroke-width="0.6"/>
<path d="M399.399 6.59387L390.982 8.48039" stroke="black" stroke-width="0.6"/>
<path d="M398.964 73.9279L391.418 78.1363" stroke="black" stroke-width="0.6"/>
<path d="M384.887 60.1418L377.486 64.6405" stroke="black" stroke-width="0.6"/>
<path d="M369.65 127.331L364.571 134.441" stroke="black" stroke-width="0.6"/>
<path d="M356.59 46.0654L349.624 51.2896" stroke="black" stroke-width="0.6"/>
<path d="M341.643 86.2628L336.564 93.3735" stroke="black" stroke-width="0.6"/>
<path d="M341.207 99.7584L336.854 107.304" stroke="black" stroke-width="0.6"/>
<path d="M340.917 113.254L337.144 121.091" stroke="black" stroke-width="0.6"/>
<path d="M314.216 31.9893L307.831 37.7939" stroke="black" stroke-width="0.6"/>
<path d="M312.619 99.4683L309.427 107.595" stroke="black" stroke-width="0.6"/>
<path d="M312.184 112.964L309.862 121.381" stroke="black" stroke-width="0.6"/>
<path d="M299.994 31.8441L293.9 37.939" stroke="black" stroke-width="0.6"/>
<path d="M298.688 85.8273L295.206 93.8087" stroke="black" stroke-width="0.6"/>
<path d="M285.918 31.699L279.968 38.0841" stroke="black" stroke-width="0.6"/>
<path d="M285.628 45.1948L280.259 52.0153" stroke="black" stroke-width="0.6"/>
<path d="M284.032 99.3232L281.855 107.74" stroke="black" stroke-width="0.6"/>
<path d="M272.277 4.70728L265.457 10.2217" stroke="black" stroke-width="0.6"/>
<path d="M271.987 18.2031L265.747 24.1529" stroke="black" stroke-width="0.6"/>
<path d="M271.406 45.0498L266.327 52.1605" stroke="black" stroke-width="0.6"/>
<path d="M243.544 31.5538L238.175 38.2292" stroke="black" stroke-width="0.6"/>
<path d="M243.254 45.0498L238.465 52.3056" stroke="black" stroke-width="0.6"/>
<path d="M242.093 85.5372L239.626 93.954" stroke="black" stroke-width="0.6"/>
<path d="M228.017 85.5372L225.55 93.954" stroke="black" stroke-width="0.6"/>
<path d="M215.101 44.9047L210.458 52.3056" stroke="black" stroke-width="0.6"/>
<path d="M214.811 58.4004L210.748 66.2367" stroke="black" stroke-width="0.6"/>
<path d="M214.376 72.0413L211.183 80.0227" stroke="black" stroke-width="0.6"/>
<path d="M187.094 44.9047L182.305 52.3056" stroke="black" stroke-width="0.6"/>
<path d="M185.643 99.178L183.756 107.74" stroke="black" stroke-width="0.6"/>
<path d="M159.086 45.0498L154.152 52.1605" stroke="black" stroke-width="0.6"/>
<path d="M158.796 58.5454L154.443 66.0914" stroke="black" stroke-width="0.6"/>
<path d="M146.026 4.70728L139.351 10.2217" stroke="black" stroke-width="0.6"/>
<path d="M143.849 99.3232L141.382 107.595" stroke="black" stroke-width="0.6"/>
<path d="M143.559 112.964L141.672 121.526" stroke="black" stroke-width="0.6"/>
<path d="M116.132 99.4683L112.939 107.595" stroke="black" stroke-width="0.6"/>
<path d="M61.2782 72.767L55.6187 79.4423" stroke="black" stroke-width="0.6"/>
<path d="M60.5525 113.399L56.3441 121.091" stroke="black" stroke-width="0.6"/>
<path d="M47.2018 86.4078L41.6874 93.2283" stroke="black" stroke-width="0.6"/>
<path d="M45.8958 154.322L42.8484 162.449" stroke="black" stroke-width="0.6"/>
<path d="M31.094 222.672L29.6428 231.234" stroke="black" stroke-width="0.6"/>
<path d="M30.9488 236.313L29.7878 245.02" stroke="black" stroke-width="0.6"/>
<path d="M20.3553 5.72314L12.3739 9.20593" stroke="black" stroke-width="0.6"/>
<path d="M19.6297 73.2024L13.0995 79.007" stroke="black" stroke-width="0.6"/>
<path d="M19.1944 100.194L13.5349 106.724" stroke="black" stroke-width="0.6"/>
<path d="M18.0336 181.749L14.6959 189.876" stroke="black" stroke-width="0.6"/>
<path d="M16.8726 277.526L15.8568 286.088" stroke="black" stroke-width="0.6"/>
<path d="M6.13394 32.86L-1.41211 36.9232" stroke="black" stroke-width="0.6"/>
<path d="M4.68278 154.612L0.0390625 162.013" stroke="black" stroke-width="0.6"/>
<path d="M4.24739 181.894L0.474365 189.731" stroke="black" stroke-width="0.6"/>
<path d="M3.08652 277.526L1.49023 286.088" stroke="black" stroke-width="0.6"/>
<path d="M2.94132 304.953L1.7804 313.515" stroke="black" stroke-width="0.6"/>
<path d="M2.65115 442.088L2.07068 450.795" stroke="black" stroke-width="0.6"/>
<path d="M690.067 11.818L689.486 3.2561" stroke="black" stroke-width="0.6"/>
<path d="M690.212 25.6041L689.341 16.8971" stroke="black" stroke-width="0.6"/>
<path d="M690.212 39.2451L689.341 30.6832" stroke="black" stroke-width="0.6"/>
<path d="M690.212 53.0311L689.341 44.3241" stroke="black" stroke-width="0.6"/>
<path d="M690.212 66.6721L689.341 58.1102" stroke="black" stroke-width="0.6"/>
<path d="M690.212 80.4581L689.341 71.7511" stroke="black" stroke-width="0.6"/>
<path d="M690.212 107.885L689.341 99.178" stroke="black" stroke-width="0.6"/>
<path d="M676.426 11.818L675.12 3.2561" stroke="black" stroke-width="0.6"/>
<path d="M676.426 25.6041L674.975 17.0422" stroke="black" stroke-width="0.6"/>
<path d="M676.571 39.2451L674.975 30.6832" stroke="black" stroke-width="0.6"/>
<path d="M676.571 53.0311L674.975 44.4692" stroke="black" stroke-width="0.6"/>
<path d="M676.571 80.4581L674.975 71.8962" stroke="black" stroke-width="0.6"/>
<path d="M676.571 94.0991L674.975 85.5372" stroke="black" stroke-width="0.6"/>
<path d="M676.571 107.885L674.975 99.3232" stroke="black" stroke-width="0.6"/>
<path d="M676.426 121.526L675.12 112.964" stroke="black" stroke-width="0.6"/>
<path d="M676.426 135.312L675.12 126.75" stroke="black" stroke-width="0.6"/>
<path d="M676.281 149.098L675.265 140.391" stroke="black" stroke-width="0.6"/>
<path d="M676.281 162.739L675.265 154.177" stroke="black" stroke-width="0.6"/>
<path d="M662.785 11.818L660.753 3.2561" stroke="black" stroke-width="0.6"/>
<path d="M662.785 25.459L660.608 17.0422" stroke="black" stroke-width="0.6"/>
<path d="M662.785 39.2449L660.608 30.8281" stroke="black" stroke-width="0.6"/>
<path d="M662.93 52.886L660.608 44.4692" stroke="black" stroke-width="0.6"/>
<path d="M662.93 66.6719L660.608 58.2551" stroke="black" stroke-width="0.6"/>
<path d="M662.93 80.313L660.608 71.8962" stroke="black" stroke-width="0.6"/>
<path d="M662.93 94.099L660.608 85.6822" stroke="black" stroke-width="0.6"/>
<path d="M662.785 107.74L660.608 99.3232" stroke="black" stroke-width="0.6"/>
<path d="M662.785 135.167L660.753 126.75" stroke="black" stroke-width="0.6"/>
<path d="M662.64 148.953L660.753 140.536" stroke="black" stroke-width="0.6"/>
<path d="M662.495 162.739L660.899 154.177" stroke="black" stroke-width="0.6"/>
<path d="M662.35 190.166L661.189 181.604" stroke="black" stroke-width="0.6"/>
<path d="M649.144 11.6727L646.387 3.40112" stroke="black" stroke-width="0.6"/>
<path d="M649.144 25.3138L646.242 17.1873" stroke="black" stroke-width="0.6"/>
<path d="M649.144 39.0997L646.242 30.8281" stroke="black" stroke-width="0.6"/>
<path d="M649.289 66.5269L646.242 58.4004" stroke="black" stroke-width="0.6"/>
<path d="M649.144 80.1678L646.242 72.0413" stroke="black" stroke-width="0.6"/>
<path d="M649.144 107.595L646.242 99.4683" stroke="black" stroke-width="0.6"/>
<path d="M649.144 121.381L646.242 113.109" stroke="black" stroke-width="0.6"/>
<path d="M648.999 135.167L646.387 126.895" stroke="black" stroke-width="0.6"/>
<path d="M648.999 148.953L646.387 140.536" stroke="black" stroke-width="0.6"/>
<path d="M648.854 162.594L646.532 154.177" stroke="black" stroke-width="0.6"/>
<path d="M648.709 176.38L646.677 167.963" stroke="black" stroke-width="0.6"/>
<path d="M648.564 190.166L646.822 181.604" stroke="black" stroke-width="0.6"/>
<path d="M648.419 203.807L646.967 195.245" stroke="black" stroke-width="0.6"/>
<path d="M648.273 217.593L647.112 209.031" stroke="black" stroke-width="0.6"/>
<path d="M635.503 25.1688L631.875 17.3325" stroke="black" stroke-width="0.6"/>
<path d="M635.503 38.9548L631.875 30.9734" stroke="black" stroke-width="0.6"/>
<path d="M635.503 52.5957L631.875 44.7594" stroke="black" stroke-width="0.6"/>
<path d="M635.503 66.3817L631.875 58.5454" stroke="black" stroke-width="0.6"/>
<path d="M635.503 93.8087L631.875 85.9725" stroke="black" stroke-width="0.6"/>
<path d="M635.503 107.45L631.875 99.6134" stroke="black" stroke-width="0.6"/>
<path d="M635.358 135.022L632.02 127.04" stroke="black" stroke-width="0.6"/>
<path d="M635.213 148.808L632.02 140.681" stroke="black" stroke-width="0.6"/>
<path d="M635.213 162.449L632.165 154.322" stroke="black" stroke-width="0.6"/>
<path d="M635.068 176.235L632.311 167.963" stroke="black" stroke-width="0.6"/>
<path d="M634.923 190.021L632.456 181.749" stroke="black" stroke-width="0.6"/>
<path d="M634.778 203.807L632.601 195.39" stroke="black" stroke-width="0.6"/>
<path d="M634.632 217.593L632.746 209.031" stroke="black" stroke-width="0.6"/>
<path d="M634.342 231.379L632.891 222.672" stroke="black" stroke-width="0.6"/>
<path d="M634.197 245.02L633.181 236.458" stroke="black" stroke-width="0.6"/>
<path d="M621.717 25.0237L617.509 17.4777" stroke="black" stroke-width="0.6"/>
<path d="M621.717 38.8098L617.509 31.1187" stroke="black" stroke-width="0.6"/>
<path d="M621.862 52.4507L617.509 44.9047" stroke="black" stroke-width="0.6"/>
<path d="M621.862 66.2366L617.509 58.6906" stroke="black" stroke-width="0.6"/>
<path d="M621.862 79.8774L617.509 72.3314" stroke="black" stroke-width="0.6"/>
<path d="M621.862 93.6635L617.509 86.1174" stroke="black" stroke-width="0.6"/>
<path d="M621.862 107.304L617.509 99.7584" stroke="black" stroke-width="0.6"/>
<path d="M621.717 121.091L617.509 113.399" stroke="black" stroke-width="0.6"/>
<path d="M621.572 148.663L617.654 140.826" stroke="black" stroke-width="0.6"/>
<path d="M621.427 162.304L617.799 154.467" stroke="black" stroke-width="0.6"/>
<path d="M621.427 176.09L617.944 168.108" stroke="black" stroke-width="0.6"/>
<path d="M621.282 189.876L618.089 181.749" stroke="black" stroke-width="0.6"/>
<path d="M620.991 203.662L618.234 195.535" stroke="black" stroke-width="0.6"/>
<path d="M620.846 217.448L618.379 209.176" stroke="black" stroke-width="0.6"/>
<path d="M620.701 231.234L618.67 222.817" stroke="black" stroke-width="0.6"/>
<path d="M620.411 245.02L618.815 236.458" stroke="black" stroke-width="0.6"/>
<path d="M620.266 258.806L619.105 250.099" stroke="black" stroke-width="0.6"/>
<path d="M607.931 11.2375L603.287 3.83655" stroke="black" stroke-width="0.6"/>
<path d="M608.076 24.8785L603.287 17.6227" stroke="black" stroke-width="0.6"/>
<path d="M608.076 38.5194L603.142 31.4087" stroke="black" stroke-width="0.6"/>
<path d="M608.076 52.3056L603.142 45.0498" stroke="black" stroke-width="0.6"/>
<path d="M608.076 65.9464L603.142 58.8357" stroke="black" stroke-width="0.6"/>
<path d="M608.076 79.7325L603.142 72.6218" stroke="black" stroke-width="0.6"/>
<path d="M608.076 93.3735L603.142 86.2628" stroke="black" stroke-width="0.6"/>
<path d="M608.076 107.16L603.142 100.049" stroke="black" stroke-width="0.6"/>
<path d="M607.931 148.372L603.287 140.971" stroke="black" stroke-width="0.6"/>
<path d="M607.786 162.158L603.433 154.612" stroke="black" stroke-width="0.6"/>
<path d="M607.641 175.945L603.578 168.254" stroke="black" stroke-width="0.6"/>
<path d="M607.496 189.731L603.723 181.894" stroke="black" stroke-width="0.6"/>
<path d="M607.351 203.517L603.868 195.535" stroke="black" stroke-width="0.6"/>
<path d="M607.206 217.303L604.013 209.176" stroke="black" stroke-width="0.6"/>
<path d="M606.915 231.234L604.303 222.962" stroke="black" stroke-width="0.6"/>
<path d="M606.77 245.02L604.448 236.603" stroke="black" stroke-width="0.6"/>
<path d="M606.48 258.661L604.738 250.244" stroke="black" stroke-width="0.6"/>
<path d="M606.19 272.447L605.029 263.885" stroke="black" stroke-width="0.6"/>
<path d="M594.29 10.9473L588.921 4.12683" stroke="black" stroke-width="0.6"/>
<path d="M594.29 24.7334L588.921 17.7678" stroke="black" stroke-width="0.6"/>
<path d="M594.29 38.3743L588.921 31.5538" stroke="black" stroke-width="0.6"/>
<path d="M594.435 65.8013L588.776 59.126" stroke="black" stroke-width="0.6"/>
<path d="M594.435 79.4423L588.776 72.767" stroke="black" stroke-width="0.6"/>
<path d="M594.435 93.2283L588.776 86.553" stroke="black" stroke-width="0.6"/>
<path d="M594.435 106.869L588.776 100.194" stroke="black" stroke-width="0.6"/>
<path d="M594.29 120.655L588.921 113.835" stroke="black" stroke-width="0.6"/>
<path d="M594.29 134.441L588.921 127.621" stroke="black" stroke-width="0.6"/>
<path d="M594.145 148.227L589.066 141.262" stroke="black" stroke-width="0.6"/>
<path d="M594.145 162.013L589.066 154.903" stroke="black" stroke-width="0.6"/>
<path d="M594 175.799L589.211 168.544" stroke="black" stroke-width="0.6"/>
<path d="M593.855 189.586L589.356 182.185" stroke="black" stroke-width="0.6"/>
<path d="M593.71 203.372L589.501 195.826" stroke="black" stroke-width="0.6"/>
<path d="M593.419 217.303L589.792 209.321" stroke="black" stroke-width="0.6"/>
<path d="M593.274 231.089L589.937 222.962" stroke="black" stroke-width="0.6"/>
<path d="M592.984 244.875L590.227 236.603" stroke="black" stroke-width="0.6"/>
<path d="M592.694 258.661L590.372 250.244" stroke="black" stroke-width="0.6"/>
<path d="M592.404 272.447L590.662 263.885" stroke="black" stroke-width="0.6"/>
<path d="M592.113 286.233L590.952 277.526" stroke="black" stroke-width="0.6"/>
<path d="M580.504 10.8023L574.699 4.27209" stroke="black" stroke-width="0.6"/>
<path d="M580.504 24.4432L574.554 18.0581" stroke="black" stroke-width="0.6"/>
<path d="M580.649 38.0841L574.554 31.8441" stroke="black" stroke-width="0.6"/>
<path d="M580.649 51.7251L574.554 45.6302" stroke="black" stroke-width="0.6"/>
<path d="M580.649 65.511L574.554 59.271" stroke="black" stroke-width="0.6"/>
<path d="M580.649 79.152L574.409 73.0571" stroke="black" stroke-width="0.6"/>
<path d="M580.649 120.365L574.554 114.125" stroke="black" stroke-width="0.6"/>
<path d="M580.504 134.151L574.554 127.766" stroke="black" stroke-width="0.6"/>
<path d="M580.504 147.937L574.699 141.407" stroke="black" stroke-width="0.6"/>
<path d="M580.359 161.723L574.699 155.048" stroke="black" stroke-width="0.6"/>
<path d="M580.214 175.509L574.845 168.689" stroke="black" stroke-width="0.6"/>
<path d="M580.069 189.44L574.99 182.33" stroke="black" stroke-width="0.6"/>
<path d="M579.924 203.226L575.135 195.971" stroke="black" stroke-width="0.6"/>
<path d="M579.778 217.012L575.425 209.466" stroke="black" stroke-width="0.6"/>
<path d="M579.488 230.944L575.57 223.107" stroke="black" stroke-width="0.6"/>
<path d="M579.198 244.73L575.86 236.748" stroke="black" stroke-width="0.6"/>
<path d="M578.908 258.516L576.151 250.389" stroke="black" stroke-width="0.6"/>
<path d="M578.618 272.447L576.441 264.03" stroke="black" stroke-width="0.6"/>
<path d="M578.327 286.233L576.731 277.671" stroke="black" stroke-width="0.6"/>
<path d="M566.718 10.512L560.333 4.56226" stroke="black" stroke-width="0.6"/>
<path d="M566.718 24.153L560.333 18.3484" stroke="black" stroke-width="0.6"/>
<path d="M566.863 51.4347L560.188 45.9203" stroke="black" stroke-width="0.6"/>
<path d="M566.863 65.2209L560.188 59.5614" stroke="black" stroke-width="0.6"/>
<path d="M566.863 78.8619L560.188 73.3474" stroke="black" stroke-width="0.6"/>
<path d="M566.863 92.6479L560.188 87.1334" stroke="black" stroke-width="0.6"/>
<path d="M566.863 120.075L560.188 114.415" stroke="black" stroke-width="0.6"/>
<path d="M566.863 133.861L560.333 128.056" stroke="black" stroke-width="0.6"/>
<path d="M566.718 147.647L560.333 141.697" stroke="black" stroke-width="0.6"/>
<path d="M566.573 161.433L560.478 155.338" stroke="black" stroke-width="0.6"/>
<path d="M566.573 175.364L560.623 168.979" stroke="black" stroke-width="0.6"/>
<path d="M566.138 203.081L560.913 196.116" stroke="black" stroke-width="0.6"/>
<path d="M565.992 216.867L561.058 209.757" stroke="black" stroke-width="0.6"/>
<path d="M565.702 230.798L561.349 223.252" stroke="black" stroke-width="0.6"/>
<path d="M565.557 244.584L561.494 236.893" stroke="black" stroke-width="0.6"/>
<path d="M565.267 258.516L561.784 250.389" stroke="black" stroke-width="0.6"/>
<path d="M564.977 272.302L562.074 264.03" stroke="black" stroke-width="0.6"/>
<path d="M564.251 299.874L562.8 291.312" stroke="black" stroke-width="0.6"/>
<path d="M552.932 10.2217L546.112 4.85242" stroke="black" stroke-width="0.6"/>
<path d="M552.932 23.8626L546.112 18.6384" stroke="black" stroke-width="0.6"/>
<path d="M553.077 37.5036L545.966 32.4246" stroke="black" stroke-width="0.6"/>
<path d="M553.077 51.1445L545.966 46.2106" stroke="black" stroke-width="0.6"/>
<path d="M553.077 64.7854L545.966 59.9966" stroke="black" stroke-width="0.6"/>
<path d="M553.077 92.2124L545.966 87.4236" stroke="black" stroke-width="0.6"/>
<path d="M553.077 133.571L545.966 128.492" stroke="black" stroke-width="0.6"/>
<path d="M552.932 147.357L546.112 141.987" stroke="black" stroke-width="0.6"/>
<path d="M552.932 161.143L546.112 155.628" stroke="black" stroke-width="0.6"/>
<path d="M552.787 175.074L546.257 169.269" stroke="black" stroke-width="0.6"/>
<path d="M552.497 202.791L546.547 196.406" stroke="black" stroke-width="0.6"/>
<path d="M552.206 216.722L546.692 209.902" stroke="black" stroke-width="0.6"/>
<path d="M552.061 230.508L546.982 223.398" stroke="black" stroke-width="0.6"/>
<path d="M551.771 244.439L547.273 237.038" stroke="black" stroke-width="0.6"/>
<path d="M551.481 258.371L547.563 250.534" stroke="black" stroke-width="0.6"/>
<path d="M551.191 272.157L547.853 264.175" stroke="black" stroke-width="0.6"/>
<path d="M550.465 299.874L548.578 291.312" stroke="black" stroke-width="0.6"/>
<path d="M539.146 9.93166L531.89 5.14282" stroke="black" stroke-width="0.6"/>
<path d="M539.291 50.8543L531.745 46.5009" stroke="black" stroke-width="0.6"/>
<path d="M539.291 78.1363L531.745 74.073" stroke="black" stroke-width="0.6"/>
<path d="M539.291 91.9224L531.6 87.714" stroke="black" stroke-width="0.6"/>
<path d="M539.291 105.708L531.745 101.5" stroke="black" stroke-width="0.6"/>
<path d="M539.291 133.135L531.745 128.782" stroke="black" stroke-width="0.6"/>
<path d="M539.146 147.066L531.745 142.423" stroke="black" stroke-width="0.6"/>
<path d="M539.146 160.853L531.89 155.919" stroke="black" stroke-width="0.6"/>
<path d="M539.001 174.784L532.035 169.559" stroke="black" stroke-width="0.6"/>
<path d="M538.856 188.57L532.18 183.055" stroke="black" stroke-width="0.6"/>
<path d="M538.711 202.501L532.326 196.696" stroke="black" stroke-width="0.6"/>
<path d="M538.42 216.432L532.471 210.192" stroke="black" stroke-width="0.6"/>
<path d="M538.275 230.363L532.761 223.688" stroke="black" stroke-width="0.6"/>
<path d="M537.985 244.294L532.906 237.184" stroke="black" stroke-width="0.6"/>
<path d="M537.695 258.225L533.341 250.679" stroke="black" stroke-width="0.6"/>
<path d="M537.259 272.157L533.631 264.32" stroke="black" stroke-width="0.6"/>
<path d="M536.969 285.943L533.922 277.816" stroke="black" stroke-width="0.6"/>
<path d="M536.534 299.874L534.357 291.457" stroke="black" stroke-width="0.6"/>
<path d="M536.098 313.66L534.792 305.098" stroke="black" stroke-width="0.6"/>
<path d="M525.215 9.64122L517.669 5.43286" stroke="black" stroke-width="0.6"/>
<path d="M525.36 23.2822L517.524 19.219" stroke="black" stroke-width="0.6"/>
<path d="M525.36 36.923L517.524 33.0049" stroke="black" stroke-width="0.6"/>
<path d="M525.36 50.4189L517.524 46.791" stroke="black" stroke-width="0.6"/>
<path d="M525.505 64.2051L517.524 60.5771" stroke="black" stroke-width="0.6"/>
<path d="M525.505 77.8459L517.524 74.3632" stroke="black" stroke-width="0.6"/>
<path d="M525.505 91.4869L517.524 88.1492" stroke="black" stroke-width="0.6"/>
<path d="M525.505 105.273L517.524 101.79" stroke="black" stroke-width="0.6"/>
<path d="M525.505 119.059L517.524 115.431" stroke="black" stroke-width="0.6"/>
<path d="M525.36 132.845L517.524 129.217" stroke="black" stroke-width="0.6"/>
<path d="M525.215 160.417L517.669 156.354" stroke="black" stroke-width="0.6"/>
<path d="M525.215 174.348L517.669 169.85" stroke="black" stroke-width="0.6"/>
<path d="M525.07 188.28L517.814 183.491" stroke="black" stroke-width="0.6"/>
<path d="M524.925 202.211L517.959 196.986" stroke="black" stroke-width="0.6"/>
<path d="M524.634 216.142L518.249 210.482" stroke="black" stroke-width="0.6"/>
<path d="M524.489 230.218L518.394 223.833" stroke="black" stroke-width="0.6"/>
<path d="M524.199 244.149L518.685 237.329" stroke="black" stroke-width="0.6"/>
<path d="M523.909 258.08L518.975 250.825" stroke="black" stroke-width="0.6"/>
<path d="M523.473 272.011L519.41 264.32" stroke="black" stroke-width="0.6"/>
<path d="M523.183 285.943L519.7 277.961" stroke="black" stroke-width="0.6"/>
<path d="M522.748 299.729L520.136 291.457" stroke="black" stroke-width="0.6"/>
<path d="M522.313 313.66L520.571 305.098" stroke="black" stroke-width="0.6"/>
<path d="M511.429 22.847L503.447 19.5093" stroke="black" stroke-width="0.6"/>
<path d="M511.429 36.4878L503.302 33.4403" stroke="black" stroke-width="0.6"/>
<path d="M511.574 50.1288L503.302 47.2264" stroke="black" stroke-width="0.6"/>
<path d="M511.574 63.7697L503.302 61.0125" stroke="black" stroke-width="0.6"/>
<path d="M511.574 77.4107L503.302 74.7986" stroke="black" stroke-width="0.6"/>
<path d="M511.574 104.838L503.302 102.226" stroke="black" stroke-width="0.6"/>
<path d="M511.574 118.624L503.302 115.866" stroke="black" stroke-width="0.6"/>
<path d="M511.574 146.196L503.302 143.148" stroke="black" stroke-width="0.6"/>
<path d="M511.429 160.127L503.447 156.789" stroke="black" stroke-width="0.6"/>
<path d="M511.429 173.913L503.447 170.285" stroke="black" stroke-width="0.6"/>
<path d="M511.284 187.989L503.592 183.781" stroke="black" stroke-width="0.6"/>
<path d="M511.139 201.92L503.738 197.277" stroke="black" stroke-width="0.6"/>
<path d="M510.848 215.852L503.883 210.627" stroke="black" stroke-width="0.6"/>
<path d="M510.703 229.928L504.173 224.123" stroke="black" stroke-width="0.6"/>
<path d="M510.413 243.859L504.463 237.619" stroke="black" stroke-width="0.6"/>
<path d="M510.123 257.935L504.753 250.97" stroke="black" stroke-width="0.6"/>
<path d="M509.687 271.866L505.189 264.465" stroke="black" stroke-width="0.6"/>
<path d="M509.252 285.798L505.479 277.961" stroke="black" stroke-width="0.6"/>
<path d="M508.817 299.729L505.914 291.457" stroke="black" stroke-width="0.6"/>
<path d="M508.381 313.515L506.35 305.098" stroke="black" stroke-width="0.6"/>
<path d="M497.498 8.91563L489.226 6.01331" stroke="black" stroke-width="0.6"/>
<path d="M497.498 22.5567L489.226 19.9446" stroke="black" stroke-width="0.6"/>
<path d="M497.643 36.0525L489.226 33.7306" stroke="black" stroke-width="0.6"/>
<path d="M497.643 49.6932L489.226 47.6616" stroke="black" stroke-width="0.6"/>
<path d="M497.643 63.3342L489.226 61.4478" stroke="black" stroke-width="0.6"/>
<path d="M497.643 76.9753L489.081 75.2339" stroke="black" stroke-width="0.6"/>
<path d="M497.643 90.7612L489.081 88.8748" stroke="black" stroke-width="0.6"/>
<path d="M497.643 104.402L489.081 102.661" stroke="black" stroke-width="0.6"/>
<path d="M497.643 118.188L489.081 116.302" stroke="black" stroke-width="0.6"/>
<path d="M497.643 131.974L489.226 129.943" stroke="black" stroke-width="0.6"/>
<path d="M497.643 145.76L489.226 143.584" stroke="black" stroke-width="0.6"/>
<path d="M497.643 159.691L489.226 157.224" stroke="black" stroke-width="0.6"/>
<path d="M497.498 173.623L489.226 170.72" stroke="black" stroke-width="0.6"/>
<path d="M497.352 187.554L489.371 184.216" stroke="black" stroke-width="0.6"/>
<path d="M497.207 201.485L489.516 197.567" stroke="black" stroke-width="0.6"/>
<path d="M497.062 215.561L489.661 211.063" stroke="black" stroke-width="0.6"/>
<path d="M496.192 257.79L490.532 251.115" stroke="black" stroke-width="0.6"/>
<path d="M495.901 271.721L490.967 264.61" stroke="black" stroke-width="0.6"/>
<path d="M495.466 285.798L491.403 278.106" stroke="black" stroke-width="0.6"/>
<path d="M494.886 299.729L491.838 291.602" stroke="black" stroke-width="0.6"/>
<path d="M494.45 313.515L492.273 305.098" stroke="black" stroke-width="0.6"/>
<path d="M483.566 8.62547L475.15 6.44873" stroke="black" stroke-width="0.6"/>
<path d="M483.566 22.1214L475.15 20.2349" stroke="black" stroke-width="0.6"/>
<path d="M483.712 35.7623L475.15 34.166" stroke="black" stroke-width="0.6"/>
<path d="M483.712 49.2582L475.005 47.9521" stroke="black" stroke-width="0.6"/>
<path d="M483.712 76.5399L475.005 75.6692" stroke="black" stroke-width="0.6"/>
<path d="M483.712 90.1809L475.005 89.3102" stroke="black" stroke-width="0.6"/>
<path d="M483.712 103.967L475.005 103.096" stroke="black" stroke-width="0.6"/>
<path d="M483.712 131.394L475.005 130.523" stroke="black" stroke-width="0.6"/>
<path d="M483.712 145.325L475.005 144.019" stroke="black" stroke-width="0.6"/>
<path d="M483.712 159.111L475.15 157.66" stroke="black" stroke-width="0.6"/>
<path d="M483.566 173.042L475.15 171.156" stroke="black" stroke-width="0.6"/>
<path d="M483.566 187.118L475.15 184.651" stroke="black" stroke-width="0.6"/>
<path d="M483.421 201.195L475.295 198.002" stroke="black" stroke-width="0.6"/>
<path d="M483.276 215.271L475.44 211.353" stroke="black" stroke-width="0.6"/>
<path d="M482.986 229.347L475.73 224.704" stroke="black" stroke-width="0.6"/>
<path d="M482.696 243.424L476.02 238.054" stroke="black" stroke-width="0.6"/>
<path d="M482.405 257.645L476.311 251.26" stroke="black" stroke-width="0.6"/>
<path d="M481.97 271.721L476.746 264.756" stroke="black" stroke-width="0.6"/>
<path d="M481.535 285.652L477.181 278.106" stroke="black" stroke-width="0.6"/>
<path d="M480.954 299.584L477.762 291.602" stroke="black" stroke-width="0.6"/>
<path d="M480.519 313.515L478.197 305.098" stroke="black" stroke-width="0.6"/>
<path d="M469.635 8.19018L461.073 6.73901" stroke="black" stroke-width="0.6"/>
<path d="M469.635 21.831L461.073 20.67" stroke="black" stroke-width="0.6"/>
<path d="M469.635 35.3269L461.073 34.6013" stroke="black" stroke-width="0.6"/>
<path d="M469.635 48.9678L461.073 48.3873" stroke="black" stroke-width="0.6"/>
<path d="M469.635 76.1046H460.928" stroke="black" stroke-width="0.6"/>
<path d="M469.635 89.7454L460.928 89.8905" stroke="black" stroke-width="0.6"/>
<path d="M469.635 103.531H460.928" stroke="black" stroke-width="0.6"/>
<path d="M469.635 117.172L460.928 117.318" stroke="black" stroke-width="0.6"/>
<path d="M469.635 130.958H460.928" stroke="black" stroke-width="0.6"/>
<path d="M469.635 144.745L460.928 144.599" stroke="black" stroke-width="0.6"/>
<path d="M469.635 158.676L460.928 158.095" stroke="black" stroke-width="0.6"/>
<path d="M469.635 186.538L461.073 185.087" stroke="black" stroke-width="0.6"/>
<path d="M469.49 200.759L461.073 198.438" stroke="black" stroke-width="0.6"/>
<path d="M469.345 214.836L461.219 211.788" stroke="black" stroke-width="0.6"/>
<path d="M469.2 229.057L461.509 224.994" stroke="black" stroke-width="0.6"/>
<path d="M468.91 243.278L461.799 238.199" stroke="black" stroke-width="0.6"/>
<path d="M468.474 257.5L462.089 251.405" stroke="black" stroke-width="0.6"/>
<path d="M468.039 271.576L462.67 264.756" stroke="black" stroke-width="0.6"/>
<path d="M467.604 285.652L463.105 278.106" stroke="black" stroke-width="0.6"/>
<path d="M466.443 313.515L464.121 305.098" stroke="black" stroke-width="0.6"/>
<path d="M455.704 7.90002L446.997 7.17444" stroke="black" stroke-width="0.6"/>
<path d="M455.704 34.8916H446.997" stroke="black" stroke-width="0.6"/>
<path d="M455.704 48.5325L446.997 48.8227" stroke="black" stroke-width="0.6"/>
<path d="M455.704 62.0283L446.997 62.6088" stroke="black" stroke-width="0.6"/>
<path d="M455.704 75.6692L446.997 76.5399" stroke="black" stroke-width="0.6"/>
<path d="M455.704 89.3102L446.997 90.326" stroke="black" stroke-width="0.6"/>
<path d="M455.559 102.951L446.997 104.112" stroke="black" stroke-width="0.6"/>
<path d="M455.559 116.592L446.997 117.753" stroke="black" stroke-width="0.6"/>
<path d="M455.559 130.378L446.997 131.539" stroke="black" stroke-width="0.6"/>
<path d="M455.704 158.095L446.997 158.676" stroke="black" stroke-width="0.6"/>
<path d="M455.704 172.026L446.997 172.172" stroke="black" stroke-width="0.6"/>
<path d="M455.704 186.103L446.997 185.667" stroke="black" stroke-width="0.6"/>
<path d="M455.559 200.179L446.997 198.873" stroke="black" stroke-width="0.6"/>
<path d="M455.559 214.4L447.142 212.078" stroke="black" stroke-width="0.6"/>
<path d="M455.269 228.767L447.287 225.284" stroke="black" stroke-width="0.6"/>
<path d="M454.979 242.988L447.578 238.49" stroke="black" stroke-width="0.6"/>
<path d="M454.543 257.355L448.013 251.55" stroke="black" stroke-width="0.6"/>
<path d="M454.108 271.431L448.448 264.901" stroke="black" stroke-width="0.6"/>
<path d="M453.527 285.652L449.029 278.252" stroke="black" stroke-width="0.6"/>
<path d="M452.947 299.584L449.609 291.602" stroke="black" stroke-width="0.6"/>
<path d="M452.366 313.515L450.19 305.098" stroke="black" stroke-width="0.6"/>
<path d="M441.628 7.60972L432.921 7.4646" stroke="black" stroke-width="0.6"/>
<path d="M441.628 21.1055L432.921 21.3957" stroke="black" stroke-width="0.6"/>
<path d="M441.628 34.6013L432.921 35.3269" stroke="black" stroke-width="0.6"/>
<path d="M441.628 61.593L432.921 63.0442" stroke="black" stroke-width="0.6"/>
<path d="M441.483 75.2339L433.066 76.9753" stroke="black" stroke-width="0.6"/>
<path d="M441.483 88.8748L433.066 90.7612" stroke="black" stroke-width="0.6"/>
<path d="M441.483 102.516L433.066 104.547" stroke="black" stroke-width="0.6"/>
<path d="M441.483 143.584L433.066 145.76" stroke="black" stroke-width="0.6"/>
<path d="M441.483 157.515L433.066 159.256" stroke="black" stroke-width="0.6"/>
<path d="M441.628 185.522L432.921 186.248" stroke="black" stroke-width="0.6"/>
<path d="M441.628 199.599L432.921 199.453" stroke="black" stroke-width="0.6"/>
<path d="M441.628 213.965L432.921 212.659" stroke="black" stroke-width="0.6"/>
<path d="M441.483 228.331L433.211 225.719" stroke="black" stroke-width="0.6"/>
<path d="M441.047 242.843L433.501 238.635" stroke="black" stroke-width="0.6"/>
<path d="M440.612 257.21L433.937 251.695" stroke="black" stroke-width="0.6"/>
<path d="M440.032 271.431L434.372 264.901" stroke="black" stroke-width="0.6"/>
<path d="M439.451 285.652L435.098 278.106" stroke="black" stroke-width="0.6"/>
<path d="M438.29 313.515L436.259 305.098" stroke="black" stroke-width="0.6"/>
<path d="M427.552 34.166L418.99 35.6172" stroke="black" stroke-width="0.6"/>
<path d="M427.552 47.6616L418.99 49.5481" stroke="black" stroke-width="0.6"/>
<path d="M427.406 61.3026L418.99 63.4794" stroke="black" stroke-width="0.6"/>
<path d="M427.406 88.2942L419.135 91.1965" stroke="black" stroke-width="0.6"/>
<path d="M427.261 115.576L419.28 118.914" stroke="black" stroke-width="0.6"/>
<path d="M427.261 156.789L419.135 159.982" stroke="black" stroke-width="0.6"/>
<path d="M427.552 184.797L418.99 186.828" stroke="black" stroke-width="0.6"/>
<path d="M427.552 199.018L418.99 200.034" stroke="black" stroke-width="0.6"/>
<path d="M427.552 213.384L418.845 213.094" stroke="black" stroke-width="0.6"/>
<path d="M427.552 227.896L418.99 226.01" stroke="black" stroke-width="0.6"/>
<path d="M427.116 242.553L419.28 238.925" stroke="black" stroke-width="0.6"/>
<path d="M426.681 257.065L419.86 251.84" stroke="black" stroke-width="0.6"/>
<path d="M426.1 271.576L420.441 264.901" stroke="black" stroke-width="0.6"/>
<path d="M425.375 285.652L421.166 278.106" stroke="black" stroke-width="0.6"/>
<path d="M424.069 313.66L422.473 305.098" stroke="black" stroke-width="0.6"/>
<path d="M413.475 6.88403L404.913 8.19007" stroke="black" stroke-width="0.6"/>
<path d="M413.475 20.3799L404.913 22.1213" stroke="black" stroke-width="0.6"/>
<path d="M413.475 33.8757L405.059 36.0525" stroke="black" stroke-width="0.6"/>
<path d="M413.33 47.3716L405.059 49.9837" stroke="black" stroke-width="0.6"/>
<path d="M413.33 60.8673L405.204 63.9148" stroke="black" stroke-width="0.6"/>
<path d="M413.185 74.3632L405.204 77.8459" stroke="black" stroke-width="0.6"/>
<path d="M413.185 87.8589L405.349 91.632" stroke="black" stroke-width="0.6"/>
<path d="M413.04 101.5L405.349 105.563" stroke="black" stroke-width="0.6"/>
<path d="M413.04 115.141L405.349 119.349" stroke="black" stroke-width="0.6"/>
<path d="M412.895 142.423L405.494 146.921" stroke="black" stroke-width="0.6"/>
<path d="M413.04 169.995L405.349 174.058" stroke="black" stroke-width="0.6"/>
<path d="M413.33 198.292L405.059 200.759" stroke="black" stroke-width="0.6"/>
<path d="M413.475 212.804L404.913 213.82" stroke="black" stroke-width="0.6"/>
<path d="M413.475 227.461L404.913 226.445" stroke="black" stroke-width="0.6"/>
<path d="M413.185 242.408L405.204 239.07" stroke="black" stroke-width="0.6"/>
<path d="M412.605 257.21L405.784 251.695" stroke="black" stroke-width="0.6"/>
<path d="M411.879 271.576L406.51 264.756" stroke="black" stroke-width="0.6"/>
<path d="M410.283 299.874L408.106 291.457" stroke="black" stroke-width="0.6"/>
<path d="M399.399 19.9446L390.982 22.4116" stroke="black" stroke-width="0.6"/>
<path d="M399.254 33.4403L391.127 36.3426" stroke="black" stroke-width="0.6"/>
<path d="M399.254 46.9362L391.127 50.2738" stroke="black" stroke-width="0.6"/>
<path d="M399.109 60.4319L391.273 64.2049" stroke="black" stroke-width="0.6"/>
<path d="M398.819 87.4236L391.563 92.0673" stroke="black" stroke-width="0.6"/>
<path d="M398.819 101.065L391.563 105.999" stroke="black" stroke-width="0.6"/>
<path d="M398.673 114.56L391.708 119.785" stroke="black" stroke-width="0.6"/>
<path d="M398.528 128.201L391.853 133.716" stroke="black" stroke-width="0.6"/>
<path d="M398.528 141.842L391.853 147.502" stroke="black" stroke-width="0.6"/>
<path d="M398.528 155.628L391.853 161.143" stroke="black" stroke-width="0.6"/>
<path d="M398.673 183.2L391.708 188.425" stroke="black" stroke-width="0.6"/>
<path d="M398.964 197.422L391.418 201.63" stroke="black" stroke-width="0.6"/>
<path d="M399.254 211.933L391.127 214.546" stroke="black" stroke-width="0.6"/>
<path d="M399.544 227.026H390.837" stroke="black" stroke-width="0.6"/>
<path d="M399.254 242.408L391.127 239.07" stroke="black" stroke-width="0.6"/>
<path d="M398.383 257.355L391.998 251.55" stroke="black" stroke-width="0.6"/>
<path d="M397.367 271.866L392.869 264.465" stroke="black" stroke-width="0.6"/>
<path d="M396.497 286.088L393.74 277.816" stroke="black" stroke-width="0.6"/>
<path d="M385.323 6.30359L377.051 8.77056" stroke="black" stroke-width="0.6"/>
<path d="M385.178 19.6544L377.051 22.7019" stroke="black" stroke-width="0.6"/>
<path d="M385.178 33.15L377.196 36.6328" stroke="black" stroke-width="0.6"/>
<path d="M385.032 46.646L377.341 50.7092" stroke="black" stroke-width="0.6"/>
<path d="M384.597 87.1334L377.777 92.5027" stroke="black" stroke-width="0.6"/>
<path d="M384.452 100.629L377.922 106.434" stroke="black" stroke-width="0.6"/>
<path d="M384.307 114.125L378.067 120.22" stroke="black" stroke-width="0.6"/>
<path d="M384.162 127.766L378.212 134.151" stroke="black" stroke-width="0.6"/>
<path d="M384.017 141.407L378.357 147.937" stroke="black" stroke-width="0.6"/>
<path d="M383.872 155.048L378.357 161.723" stroke="black" stroke-width="0.6"/>
<path d="M383.872 168.689L378.502 175.509" stroke="black" stroke-width="0.6"/>
<path d="M383.872 182.475L378.357 189.15" stroke="black" stroke-width="0.6"/>
<path d="M384.162 196.406L378.212 202.646" stroke="black" stroke-width="0.6"/>
<path d="M384.742 210.772L377.632 215.706" stroke="black" stroke-width="0.6"/>
<path d="M385.468 226.155L376.906 227.896" stroke="black" stroke-width="0.6"/>
<path d="M385.178 242.553L377.196 238.925" stroke="black" stroke-width="0.6"/>
<path d="M383.727 257.935L378.502 250.97" stroke="black" stroke-width="0.6"/>
<path d="M382.566 272.302L379.663 264.03" stroke="black" stroke-width="0.6"/>
<path d="M371.246 6.01331L363.12 9.06076" stroke="black" stroke-width="0.6"/>
<path d="M371.101 19.364L363.12 22.9919" stroke="black" stroke-width="0.6"/>
<path d="M370.956 32.86L363.265 36.9232" stroke="black" stroke-width="0.6"/>
<path d="M370.811 46.3557L363.41 50.9995" stroke="black" stroke-width="0.6"/>
<path d="M370.231 86.698L363.991 92.7929" stroke="black" stroke-width="0.6"/>
<path d="M370.086 100.339L364.136 106.724" stroke="black" stroke-width="0.6"/>
<path d="M369.795 113.835L364.426 120.655" stroke="black" stroke-width="0.6"/>
<path d="M369.36 140.971L364.861 148.372" stroke="black" stroke-width="0.6"/>
<path d="M369.215 154.467L365.006 162.159" stroke="black" stroke-width="0.6"/>
<path d="M368.925 168.108L365.297 175.944" stroke="black" stroke-width="0.6"/>
<path d="M368.78 181.749L365.442 189.876" stroke="black" stroke-width="0.6"/>
<path d="M368.78 195.535L365.442 203.517" stroke="black" stroke-width="0.6"/>
<path d="M368.925 209.321L365.297 217.158" stroke="black" stroke-width="0.6"/>
<path d="M370.376 224.123L363.846 229.783" stroke="black" stroke-width="0.6"/>
<path d="M370.086 244.004L364.136 237.474" stroke="black" stroke-width="0.6"/>
<path d="M368.054 258.661L366.167 250.244" stroke="black" stroke-width="0.6"/>
<path d="M357.025 5.72314L349.189 9.20593" stroke="black" stroke-width="0.6"/>
<path d="M356.88 19.219L349.189 23.2822" stroke="black" stroke-width="0.6"/>
<path d="M356.735 32.5698L349.479 37.2136" stroke="black" stroke-width="0.6"/>
<path d="M356.445 59.5614L349.769 65.2209" stroke="black" stroke-width="0.6"/>
<path d="M356.154 72.9119L350.059 79.1518" stroke="black" stroke-width="0.6"/>
<path d="M355.864 86.4078L350.205 93.0832" stroke="black" stroke-width="0.6"/>
<path d="M355.574 99.9036L350.495 107.014" stroke="black" stroke-width="0.6"/>
<path d="M355.429 113.544L350.785 120.945" stroke="black" stroke-width="0.6"/>
<path d="M354.993 127.04L351.075 134.732" stroke="black" stroke-width="0.6"/>
<path d="M354.703 140.681L351.366 148.663" stroke="black" stroke-width="0.6"/>
<path d="M354.413 154.177L351.801 162.449" stroke="black" stroke-width="0.6"/>
<path d="M353.978 167.818L352.091 176.38" stroke="black" stroke-width="0.6"/>
<path d="M342.949 5.43286L335.258 9.4961" stroke="black" stroke-width="0.6"/>
<path d="M342.804 18.9287L335.403 23.4273" stroke="black" stroke-width="0.6"/>
<path d="M342.513 32.2794L335.548 37.5036" stroke="black" stroke-width="0.6"/>
<path d="M342.078 59.271L335.983 65.3659" stroke="black" stroke-width="0.6"/>
<path d="M341.933 72.767L336.273 79.4423" stroke="black" stroke-width="0.6"/>
<path d="M340.482 126.895L337.579 135.022" stroke="black" stroke-width="0.6"/>
<path d="M340.047 140.391L338.015 148.808" stroke="black" stroke-width="0.6"/>
<path d="M328.727 5.28784L321.327 9.64133" stroke="black" stroke-width="0.6"/>
<path d="M328.582 18.7837L321.471 23.7176" stroke="black" stroke-width="0.6"/>
<path d="M328.437 32.1344L321.617 37.6488" stroke="black" stroke-width="0.6"/>
<path d="M328.147 45.6302L321.907 51.7251" stroke="black" stroke-width="0.6"/>
<path d="M327.857 58.9808L322.197 65.6562" stroke="black" stroke-width="0.6"/>
<path d="M327.276 85.9725L322.778 93.5185" stroke="black" stroke-width="0.6"/>
<path d="M326.841 99.6134L323.213 107.45" stroke="black" stroke-width="0.6"/>
<path d="M326.551 113.109L323.503 121.236" stroke="black" stroke-width="0.6"/>
<path d="M326.115 126.75L323.939 135.167" stroke="black" stroke-width="0.6"/>
<path d="M314.651 5.14282L307.395 9.78656" stroke="black" stroke-width="0.6"/>
<path d="M313.925 45.4849L308.121 51.87" stroke="black" stroke-width="0.6"/>
<path d="M313.635 58.8357L308.411 65.8013" stroke="black" stroke-width="0.6"/>
<path d="M313.345 72.3314L308.701 79.7323" stroke="black" stroke-width="0.6"/>
<path d="M312.91 85.8273L308.992 93.6636" stroke="black" stroke-width="0.6"/>
<path d="M300.575 4.99768L293.464 9.93162" stroke="black" stroke-width="0.6"/>
<path d="M300.285 18.3484L293.609 24.0079" stroke="black" stroke-width="0.6"/>
<path d="M299.704 45.34L294.19 52.0153" stroke="black" stroke-width="0.6"/>
<path d="M299.414 58.6906L294.48 65.9464" stroke="black" stroke-width="0.6"/>
<path d="M298.253 99.3232L295.641 107.595" stroke="black" stroke-width="0.6"/>
<path d="M297.818 112.964L296.076 121.526" stroke="black" stroke-width="0.6"/>
<path d="M286.353 4.85242L279.533 10.0766" stroke="black" stroke-width="0.6"/>
<path d="M286.208 18.2031L279.678 24.1529" stroke="black" stroke-width="0.6"/>
<path d="M285.193 58.6906L280.549 65.9464" stroke="black" stroke-width="0.6"/>
<path d="M284.902 72.1864L280.984 79.8775" stroke="black" stroke-width="0.6"/>
<path d="M284.467 85.6822L281.42 93.8087" stroke="black" stroke-width="0.6"/>
<path d="M271.697 31.5538L266.037 38.2292" stroke="black" stroke-width="0.6"/>
<path d="M271.116 58.5454L266.763 66.0914" stroke="black" stroke-width="0.6"/>
<path d="M270.681 72.0413L267.053 80.0227" stroke="black" stroke-width="0.6"/>
<path d="M270.246 85.6822L267.488 93.9538" stroke="black" stroke-width="0.6"/>
<path d="M269.81 99.178L267.924 107.74" stroke="black" stroke-width="0.6"/>
<path d="M258.201 4.70728L251.526 10.2217" stroke="black" stroke-width="0.6"/>
<path d="M257.911 18.0581L251.816 24.2981" stroke="black" stroke-width="0.6"/>
<path d="M257.62 31.5538L252.106 38.2292" stroke="black" stroke-width="0.6"/>
<path d="M257.33 45.0498L252.396 52.1605" stroke="black" stroke-width="0.6"/>
<path d="M257.04 58.5454L252.832 66.0914" stroke="black" stroke-width="0.6"/>
<path d="M256.605 72.0413L253.122 80.0227" stroke="black" stroke-width="0.6"/>
<path d="M256.169 85.6822L253.557 93.9538" stroke="black" stroke-width="0.6"/>
<path d="M255.734 99.178L253.992 107.74" stroke="black" stroke-width="0.6"/>
<path d="M244.125 4.56226L237.594 10.3669" stroke="black" stroke-width="0.6"/>
<path d="M242.818 58.5454L238.755 66.2366" stroke="black" stroke-width="0.6"/>
<path d="M242.528 72.0413L239.191 80.0227" stroke="black" stroke-width="0.6"/>
<path d="M241.658 99.178L240.061 107.74" stroke="black" stroke-width="0.6"/>
<path d="M230.048 4.56226L223.518 10.3669" stroke="black" stroke-width="0.6"/>
<path d="M229.758 18.0581L223.808 24.2981" stroke="black" stroke-width="0.6"/>
<path d="M229.468 31.4087L224.099 38.3743" stroke="black" stroke-width="0.6"/>
<path d="M229.178 44.9047L224.534 52.3056" stroke="black" stroke-width="0.6"/>
<path d="M228.742 58.4004L224.824 66.2367" stroke="black" stroke-width="0.6"/>
<path d="M228.452 72.0413L225.26 80.0227" stroke="black" stroke-width="0.6"/>
<path d="M227.581 99.178L225.985 107.74" stroke="black" stroke-width="0.6"/>
<path d="M215.972 4.56226L209.587 10.3669" stroke="black" stroke-width="0.6"/>
<path d="M215.682 18.0581L209.877 24.4432" stroke="black" stroke-width="0.6"/>
<path d="M215.392 31.4087L210.167 38.3743" stroke="black" stroke-width="0.6"/>
<path d="M213.941 85.5372L211.619 93.954" stroke="black" stroke-width="0.6"/>
<path d="M213.505 99.178L211.909 107.74" stroke="black" stroke-width="0.6"/>
<path d="M201.751 18.0581L195.801 24.4432" stroke="black" stroke-width="0.6"/>
<path d="M201.46 31.4087L196.091 38.3743" stroke="black" stroke-width="0.6"/>
<path d="M201.17 44.9047L196.381 52.3056" stroke="black" stroke-width="0.6"/>
<path d="M200.735 58.4004L196.817 66.2367" stroke="black" stroke-width="0.6"/>
<path d="M200.445 72.0413L197.107 80.0227" stroke="black" stroke-width="0.6"/>
<path d="M200.009 85.5372L197.542 93.954" stroke="black" stroke-width="0.6"/>
<path d="M199.574 99.178L197.833 107.74" stroke="black" stroke-width="0.6"/>
<path d="M187.965 4.56226L181.434 10.3669" stroke="black" stroke-width="0.6"/>
<path d="M187.674 18.0581L181.725 24.2981" stroke="black" stroke-width="0.6"/>
<path d="M187.384 31.4087L182.015 38.3743" stroke="black" stroke-width="0.6"/>
<path d="M186.804 58.5454L182.74 66.2366" stroke="black" stroke-width="0.6"/>
<path d="M186.368 72.0413L183.031 80.0227" stroke="black" stroke-width="0.6"/>
<path d="M186.078 85.6822L183.466 93.9538" stroke="black" stroke-width="0.6"/>
<path d="M174.033 4.56226L167.503 10.3669" stroke="black" stroke-width="0.6"/>
<path d="M173.743 18.0581L167.648 24.2981" stroke="black" stroke-width="0.6"/>
<path d="M173.453 31.5538L167.939 38.2292" stroke="black" stroke-width="0.6"/>
<path d="M173.163 45.0498L168.229 52.1605" stroke="black" stroke-width="0.6"/>
<path d="M172.727 58.5454L168.664 66.0914" stroke="black" stroke-width="0.6"/>
<path d="M172.437 72.0413L168.954 80.0227" stroke="black" stroke-width="0.6"/>
<path d="M172.002 85.6822L169.245 93.9538" stroke="black" stroke-width="0.6"/>
<path d="M171.712 99.3232L169.68 107.74" stroke="black" stroke-width="0.6"/>
<path d="M171.276 112.964L170.115 121.526" stroke="black" stroke-width="0.6"/>
<path d="M159.957 4.70728L153.427 10.3668" stroke="black" stroke-width="0.6"/>
<path d="M159.667 18.0581L153.572 24.2981" stroke="black" stroke-width="0.6"/>
<path d="M158.506 72.0413L154.878 80.0227" stroke="black" stroke-width="0.6"/>
<path d="M158.216 85.6822L155.168 93.8087" stroke="black" stroke-width="0.6"/>
<path d="M157.78 99.3232L155.459 107.74" stroke="black" stroke-width="0.6"/>
<path d="M145.736 18.2031L139.496 24.298" stroke="black" stroke-width="0.6"/>
<path d="M145.446 31.5538L139.786 38.2292" stroke="black" stroke-width="0.6"/>
<path d="M145.155 45.0498L140.076 52.1605" stroke="black" stroke-width="0.6"/>
<path d="M144.865 58.6906L140.366 66.0915" stroke="black" stroke-width="0.6"/>
<path d="M144.575 72.1864L140.657 79.8775" stroke="black" stroke-width="0.6"/>
<path d="M144.285 85.6822L140.947 93.8087" stroke="black" stroke-width="0.6"/>
<path d="M143.124 126.605L142.108 135.167" stroke="black" stroke-width="0.6"/>
<path d="M131.805 18.2031L125.42 24.1529" stroke="black" stroke-width="0.6"/>
<path d="M131.514 31.699L125.71 38.0841" stroke="black" stroke-width="0.6"/>
<path d="M131.224 45.1948L126 52.0153" stroke="black" stroke-width="0.6"/>
<path d="M130.934 58.6906L126.29 65.9464" stroke="black" stroke-width="0.6"/>
<path d="M130.644 72.1864L126.58 79.8775" stroke="black" stroke-width="0.6"/>
<path d="M130.354 85.8273L126.871 93.8087" stroke="black" stroke-width="0.6"/>
<path d="M130.063 99.3232L127.161 107.595" stroke="black" stroke-width="0.6"/>
<path d="M129.628 112.964L127.451 121.381" stroke="black" stroke-width="0.6"/>
<path d="M129.338 126.605L127.886 135.167" stroke="black" stroke-width="0.6"/>
<path d="M118.019 4.85242L111.053 10.0766" stroke="black" stroke-width="0.6"/>
<path d="M117.873 18.3484L111.343 24.0079" stroke="black" stroke-width="0.6"/>
<path d="M117.583 31.699L111.634 38.0841" stroke="black" stroke-width="0.6"/>
<path d="M117.293 45.1948L111.779 52.0153" stroke="black" stroke-width="0.6"/>
<path d="M117.003 58.6906L112.069 65.9464" stroke="black" stroke-width="0.6"/>
<path d="M116.712 72.3314L112.359 79.8774" stroke="black" stroke-width="0.6"/>
<path d="M116.422 85.8273L112.649 93.6636" stroke="black" stroke-width="0.6"/>
<path d="M115.842 113.109L113.23 121.381" stroke="black" stroke-width="0.6"/>
<path d="M115.116 140.391L113.955 148.953" stroke="black" stroke-width="0.6"/>
<path d="M104.087 4.99768L96.9767 10.0767" stroke="black" stroke-width="0.6"/>
<path d="M103.942 18.3484L97.267 24.0079" stroke="black" stroke-width="0.6"/>
<path d="M103.652 31.8441L97.4121 37.939" stroke="black" stroke-width="0.6"/>
<path d="M103.362 45.34L97.7023 51.8702" stroke="black" stroke-width="0.6"/>
<path d="M103.217 58.8357L97.9926 65.8013" stroke="black" stroke-width="0.6"/>
<path d="M102.926 72.3314L98.2827 79.7323" stroke="black" stroke-width="0.6"/>
<path d="M102.636 85.9725L98.4279 93.6636" stroke="black" stroke-width="0.6"/>
<path d="M102.346 99.4683L98.718 107.45" stroke="black" stroke-width="0.6"/>
<path d="M102.056 113.109L99.1534 121.236" stroke="black" stroke-width="0.6"/>
<path d="M101.62 126.75L99.4437 135.167" stroke="black" stroke-width="0.6"/>
<path d="M101.33 140.391L99.7339 148.953" stroke="black" stroke-width="0.6"/>
<path d="M101.04 154.032L100.024 162.739" stroke="black" stroke-width="0.6"/>
<path d="M90.1562 4.99768L82.9004 9.93162" stroke="black" stroke-width="0.6"/>
<path d="M90.0111 18.4933L83.0455 23.8626" stroke="black" stroke-width="0.6"/>
<path d="M89.7209 31.9893L83.3358 37.7939" stroke="black" stroke-width="0.6"/>
<path d="M89.4307 45.4849L83.481 51.7248" stroke="black" stroke-width="0.6"/>
<path d="M89.2855 58.9808L83.7711 65.6562" stroke="black" stroke-width="0.6"/>
<path d="M88.9954 72.4768L84.0614 79.5875" stroke="black" stroke-width="0.6"/>
<path d="M88.7051 85.9725L84.3516 93.5185" stroke="black" stroke-width="0.6"/>
<path d="M88.4149 99.6134L84.6418 107.45" stroke="black" stroke-width="0.6"/>
<path d="M88.1246 113.109L84.932 121.236" stroke="black" stroke-width="0.6"/>
<path d="M87.8344 126.75L85.0771 135.022" stroke="black" stroke-width="0.6"/>
<path d="M87.5442 140.391L85.3674 148.808" stroke="black" stroke-width="0.6"/>
<path d="M86.9637 167.818L85.9479 176.38" stroke="black" stroke-width="0.6"/>
<path d="M76.2251 5.14282L68.8242 9.78656" stroke="black" stroke-width="0.6"/>
<path d="M75.9348 18.6384L68.9692 23.7175" stroke="black" stroke-width="0.6"/>
<path d="M75.7897 32.1344L69.1144 37.7939" stroke="black" stroke-width="0.6"/>
<path d="M75.6447 45.6302L69.4047 51.7251" stroke="black" stroke-width="0.6"/>
<path d="M75.3544 59.126L69.6948 65.6562" stroke="black" stroke-width="0.6"/>
<path d="M75.0642 72.6218L69.84 79.5874" stroke="black" stroke-width="0.6"/>
<path d="M74.9191 86.1174L70.1302 93.3732" stroke="black" stroke-width="0.6"/>
<path d="M74.6288 99.7584L70.4204 107.304" stroke="black" stroke-width="0.6"/>
<path d="M74.3386 113.254L70.5656 121.091" stroke="black" stroke-width="0.6"/>
<path d="M74.0484 126.895L70.8558 135.022" stroke="black" stroke-width="0.6"/>
<path d="M73.7581 140.536L71.146 148.808" stroke="black" stroke-width="0.6"/>
<path d="M73.4679 154.177L71.4363 162.594" stroke="black" stroke-width="0.6"/>
<path d="M73.3228 167.818L71.7266 176.38" stroke="black" stroke-width="0.6"/>
<path d="M73.0325 181.459L71.8716 190.166" stroke="black" stroke-width="0.6"/>
<path d="M62.294 5.28784L54.7479 9.64133" stroke="black" stroke-width="0.6"/>
<path d="M62.0038 18.7837L54.8931 23.5725" stroke="black" stroke-width="0.6"/>
<path d="M61.8585 32.2794L55.0381 37.6487" stroke="black" stroke-width="0.6"/>
<path d="M61.7135 45.6302L55.1832 51.58" stroke="black" stroke-width="0.6"/>
<path d="M61.4232 59.126L55.4734 65.5111" stroke="black" stroke-width="0.6"/>
<path d="M60.9879 86.2628L55.9088 93.2284" stroke="black" stroke-width="0.6"/>
<path d="M60.6977 99.7584L56.1991 107.159" stroke="black" stroke-width="0.6"/>
<path d="M60.2623 126.895L56.6344 134.877" stroke="black" stroke-width="0.6"/>
<path d="M59.9721 140.536L56.9247 148.663" stroke="black" stroke-width="0.6"/>
<path d="M59.6818 154.177L57.2148 162.449" stroke="black" stroke-width="0.6"/>
<path d="M59.2464 181.459L57.6501 190.021" stroke="black" stroke-width="0.6"/>
<path d="M48.0727 18.9287L40.8169 23.4273" stroke="black" stroke-width="0.6"/>
<path d="M47.9274 32.4246L40.9618 37.5036" stroke="black" stroke-width="0.6"/>
<path d="M47.7823 45.7754L41.1069 51.4349" stroke="black" stroke-width="0.6"/>
<path d="M47.6372 59.271L41.2521 65.3659" stroke="black" stroke-width="0.6"/>
<path d="M47.3471 72.9119L41.5425 79.297" stroke="black" stroke-width="0.6"/>
<path d="M46.9116 99.9036L41.9777 107.014" stroke="black" stroke-width="0.6"/>
<path d="M46.6214 113.544L42.1228 120.945" stroke="black" stroke-width="0.6"/>
<path d="M46.4762 127.04L42.413 134.732" stroke="black" stroke-width="0.6"/>
<path d="M46.186 140.681L42.7032 148.663" stroke="black" stroke-width="0.6"/>
<path d="M45.7506 167.963L43.1385 176.235" stroke="black" stroke-width="0.6"/>
<path d="M45.4604 181.604L43.2837 190.021" stroke="black" stroke-width="0.6"/>
<path d="M45.3154 195.245L43.574 203.807" stroke="black" stroke-width="0.6"/>
<path d="M45.0253 208.886L43.7192 217.593" stroke="black" stroke-width="0.6"/>
<path d="M44.88 222.672L44.0093 231.234" stroke="black" stroke-width="0.6"/>
<path d="M34.1414 19.0739L26.5953 23.2822" stroke="black" stroke-width="0.6"/>
<path d="M33.9963 32.5698L26.7405 37.3587" stroke="black" stroke-width="0.6"/>
<path d="M33.8511 46.0654L26.8855 51.2896" stroke="black" stroke-width="0.6"/>
<path d="M33.706 59.4163L27.1758 65.2209" stroke="black" stroke-width="0.6"/>
<path d="M33.5609 73.0571L27.3209 79.152" stroke="black" stroke-width="0.6"/>
<path d="M33.1258 100.049L27.7565 106.869" stroke="black" stroke-width="0.6"/>
<path d="M32.6903 127.185L28.1917 134.586" stroke="black" stroke-width="0.6"/>
<path d="M32.3999 140.826L28.3367 148.518" stroke="black" stroke-width="0.6"/>
<path d="M32.2549 154.322L28.627 162.304" stroke="black" stroke-width="0.6"/>
<path d="M31.9646 167.963L28.7721 176.09" stroke="black" stroke-width="0.6"/>
<path d="M31.6744 181.604L29.0623 189.876" stroke="black" stroke-width="0.6"/>
<path d="M31.3842 209.031L29.4977 217.448" stroke="black" stroke-width="0.6"/>
<path d="M30.8039 250.099L29.9332 258.661" stroke="black" stroke-width="0.6"/>
<path d="M20.2104 19.219L12.5193 23.1371" stroke="black" stroke-width="0.6"/>
<path d="M20.0651 32.7148L12.6642 37.2135" stroke="black" stroke-width="0.6"/>
<path d="M19.92 46.2106L12.8093 51.1445" stroke="black" stroke-width="0.6"/>
<path d="M19.7749 59.7064L12.9545 65.0757" stroke="black" stroke-width="0.6"/>
<path d="M19.0494 113.69L13.6801 120.655" stroke="black" stroke-width="0.6"/>
<path d="M18.7592 127.331L13.9703 134.586" stroke="black" stroke-width="0.6"/>
<path d="M18.614 140.971L14.1154 148.372" stroke="black" stroke-width="0.6"/>
<path d="M18.4689 154.467L14.2605 162.159" stroke="black" stroke-width="0.6"/>
<path d="M18.1786 168.108L14.5507 176.09" stroke="black" stroke-width="0.6"/>
<path d="M17.7433 195.39L14.9861 203.662" stroke="black" stroke-width="0.6"/>
<path d="M17.5981 209.031L15.1311 217.448" stroke="black" stroke-width="0.6"/>
<path d="M17.453 222.672L15.2762 231.234" stroke="black" stroke-width="0.6"/>
<path d="M17.308 236.458L15.4215 244.875" stroke="black" stroke-width="0.6"/>
<path d="M17.1628 250.099L15.5665 258.661" stroke="black" stroke-width="0.6"/>
<path d="M17.0177 263.74L15.7117 272.447" stroke="black" stroke-width="0.6"/>
<path d="M16.7274 291.167L16.0018 299.874" stroke="black" stroke-width="0.6"/>
<path d="M6.42424 6.01331L-1.70227 9.06076" stroke="black" stroke-width="0.6"/>
<path d="M6.27903 19.364L-1.55725 22.9919" stroke="black" stroke-width="0.6"/>
<path d="M5.98882 46.3557L-1.41211 50.8543" stroke="black" stroke-width="0.6"/>
<path d="M5.84385 59.8516L-1.26685 64.9306" stroke="black" stroke-width="0.6"/>
<path d="M5.55345 86.8431L-0.831665 92.6478" stroke="black" stroke-width="0.6"/>
<path d="M5.40836 100.339L-0.686523 106.579" stroke="black" stroke-width="0.6"/>
<path d="M5.11828 113.98L-0.54126 120.51" stroke="black" stroke-width="0.6"/>
<path d="M4.97296 127.476L-0.251221 134.441" stroke="black" stroke-width="0.6"/>
<path d="M4.3927 168.254L0.184326 175.945" stroke="black" stroke-width="0.6"/>
<path d="M4.1023 195.535L0.619507 203.517" stroke="black" stroke-width="0.6"/>
<path d="M3.81209 209.176L0.764648 217.303" stroke="black" stroke-width="0.6"/>
<path d="M3.66712 222.817L0.909912 231.089" stroke="black" stroke-width="0.6"/>
<path d="M3.52191 236.458L1.05493 244.875" stroke="black" stroke-width="0.6"/>
<path d="M3.3767 250.099L1.19995 258.661" stroke="black" stroke-width="0.6"/>
<path d="M3.2316 263.885L1.34509 272.302" stroke="black" stroke-width="0.6"/>
<path d="M2.94155 291.312L1.6355 299.874" stroke="black" stroke-width="0.6"/>
<path d="M2.79623 332.38L1.92554 341.087" stroke="black" stroke-width="0.6"/>
<path d="M2.65112 346.021L1.92554 354.728" stroke="black" stroke-width="0.6"/>
<path d="M2.65115 359.807L2.07068 368.514" stroke="black" stroke-width="0.6"/>
<path d="M2.65115 373.448L2.07068 382.155" stroke="black" stroke-width="0.6"/>
<path d="M2.50603 400.875L2.07068 409.582" stroke="black" stroke-width="0.6"/>
<path d="M2.50603 414.661L2.07068 423.368" stroke="black" stroke-width="0.6"/>
<path d="M2.65115 428.447L2.07068 437.009" stroke="black" stroke-width="0.6"/>
<path d="M690.212 94.2442L689.341 85.5372" stroke="black" stroke-width="0.6"/>
<path d="M690.067 121.671L689.486 112.964" stroke="black" stroke-width="0.6"/>
<path d="M662.205 203.952L661.334 195.245" stroke="black" stroke-width="0.6"/>
<path d="M621.717 134.877L617.654 127.185" stroke="black" stroke-width="0.6"/>
<path d="M607.931 134.586L603.287 127.331" stroke="black" stroke-width="0.6"/>
<path d="M566.863 37.7939L560.188 32.1344" stroke="black" stroke-width="0.6"/>
<path d="M553.077 78.5715L545.966 73.6376" stroke="black" stroke-width="0.6"/>
<path d="M552.642 188.86L546.402 182.765" stroke="black" stroke-width="0.6"/>
<path d="M550.755 286.088L548.143 277.816" stroke="black" stroke-width="0.6"/>
<path d="M539.291 64.4953L531.745 60.287" stroke="black" stroke-width="0.6"/>
<path d="M539.291 119.349L531.745 115.141" stroke="black" stroke-width="0.6"/>
<path d="M525.36 146.631L517.524 142.713" stroke="black" stroke-width="0.6"/>
<path d="M511.574 91.0516L503.302 88.5846" stroke="black" stroke-width="0.6"/>
<path d="M496.917 229.638L489.952 224.413" stroke="black" stroke-width="0.6"/>
<path d="M496.627 243.714L490.242 237.764" stroke="black" stroke-width="0.6"/>
<path d="M483.712 117.608L475.005 116.882" stroke="black" stroke-width="0.6"/>
<path d="M455.704 21.3958L446.997 20.9604" stroke="black" stroke-width="0.6"/>
<path d="M455.704 144.164L446.997 145.18" stroke="black" stroke-width="0.6"/>
<path d="M441.483 116.157L433.066 118.333" stroke="black" stroke-width="0.6"/>
<path d="M441.483 129.798L433.066 131.974" stroke="black" stroke-width="0.6"/>
<path d="M427.261 101.935L419.135 104.983" stroke="black" stroke-width="0.6"/>
<path d="M427.261 129.362L419.28 132.555" stroke="black" stroke-width="0.6"/>
<path d="M427.406 170.72L419.135 173.478" stroke="black" stroke-width="0.6"/>
<path d="M424.649 299.729L421.747 291.457" stroke="black" stroke-width="0.6"/>
<path d="M413.04 156.209L405.494 160.562" stroke="black" stroke-width="0.6"/>
<path d="M398.528 169.414L391.853 174.784" stroke="black" stroke-width="0.6"/>
<path d="M384.742 73.6376L377.632 78.5715" stroke="black" stroke-width="0.6"/>
<path d="M370.666 59.7064L363.555 64.9306" stroke="black" stroke-width="0.6"/>
<path d="M370.521 73.2024L363.846 78.862" stroke="black" stroke-width="0.6"/>
<path d="M342.368 45.7754L335.693 51.4349" stroke="black" stroke-width="0.6"/>
<path d="M327.566 72.4768L322.487 79.5875" stroke="black" stroke-width="0.6"/>
<path d="M314.506 18.4933L307.54 23.8626" stroke="black" stroke-width="0.6"/>
<path d="M299.124 72.1864L294.77 79.8775" stroke="black" stroke-width="0.6"/>
<path d="M243.834 18.0581L237.885 24.2981" stroke="black" stroke-width="0.6"/>
<path d="M202.041 4.56226L195.511 10.3669" stroke="black" stroke-width="0.6"/>
<path d="M159.377 31.5538L153.862 38.2292" stroke="black" stroke-width="0.6"/>
<path d="M157.345 112.964L155.894 121.526" stroke="black" stroke-width="0.6"/>
<path d="M131.95 4.70728L125.274 10.2217" stroke="black" stroke-width="0.6"/>
<path d="M115.552 126.605L113.665 135.167" stroke="black" stroke-width="0.6"/>
<path d="M87.2539 154.032L85.6576 162.594" stroke="black" stroke-width="0.6"/>
<path d="M59.5367 167.818L57.36 176.235" stroke="black" stroke-width="0.6"/>
<path d="M58.9562 195.245L57.7953 203.807" stroke="black" stroke-width="0.6"/>
<path d="M48.2177 5.43286L40.5265 9.4961" stroke="black" stroke-width="0.6"/>
<path d="M34.2865 5.57788L26.4502 9.35091" stroke="black" stroke-width="0.6"/>
<path d="M33.2707 86.553L27.4661 93.0832" stroke="black" stroke-width="0.6"/>
<path d="M32.8353 113.544L27.9014 120.8" stroke="black" stroke-width="0.6"/>
<path d="M31.5295 195.245L29.2076 203.662" stroke="black" stroke-width="0.6"/>
<path d="M19.4846 86.698L13.3898 92.938" stroke="black" stroke-width="0.6"/>
<path d="M5.69866 73.3474L-0.976685 78.8619" stroke="black" stroke-width="0.6"/>
<path d="M4.82788 141.117L-0.106079 148.227" stroke="black" stroke-width="0.6"/>
<path d="M2.79621 318.594L1.7804 327.301" stroke="black" stroke-width="0.6"/>
<path d="M2.65115 387.234L2.07068 395.941" stroke="black" stroke-width="0.6"/>
</svg>
`);

export const placeholderCoverSrc = `data:image/svg+xml;charset=UTF-8,${placeholder}`;
