import {useEffect, useRef} from 'react';

export const KEYS = {
  ESC: 'Escape',
  LEFT_ARROW: 'ArrowLeft',
  RIGHT_ARROW: 'ArrowRight',
};

export const useKeyPress = (
  keys: string[] | string,
  handler: ({key}: {key: string}) => void,
) => {
  const eventListenerRef = useRef<(event: KeyboardEvent) => void>();

  useEffect(() => {
    eventListenerRef.current = (event: KeyboardEvent) => {
      if (Array.isArray(keys) ? keys.includes(event.key) : keys === event.key) {
        handler(event);
      }
    };
  }, [keys, handler]);

  useEffect(() => {
    const eventListener = (event: KeyboardEvent) =>
      eventListenerRef.current?.(event);
    window.addEventListener('keyup', eventListener);
    return () => window.removeEventListener('keyup', eventListener);
  }, []);
};
