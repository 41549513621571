export const getCoordinates = (
  data: Record<string, number>[],
  xAxisKey = '',
  yAxisKey = '',
): [number, number][] => data.map(d => [d[xAxisKey], d[yAxisKey]]);

export const getMinMaxCoordinates = (
  data: Record<string, number>[],
  xAxisKey = '',
  yAxisKey = '',
  xAxisOffset = 0,
) => {
  const coordinates = getCoordinates(data, xAxisKey, yAxisKey);

  let xmin = coordinates.reduce<number>(
    (acc, [x]) => Math.min(x, acc),
    coordinates[0][0],
  );
  let xmax = coordinates.reduce<number>(
    (acc, [x]) => Math.max(x, acc),
    coordinates[0][0],
  );
  let ymin = coordinates.reduce<number>(
    (acc, [, y]) => Math.min(y, acc),
    coordinates[0][1],
  );
  let ymax = coordinates.reduce<number>(
    (acc, [, y]) => Math.max(y, acc),
    coordinates[0][1],
  );

  xmin = Math.max(0, xmin - (xmax - xmin) / 10 - (xAxisOffset + 10));
  xmax = xmax + (xmax - xmin) / 10 + 10;
  ymin = Math.max(0, ymin - (ymax - ymin) / 10 - 10);
  ymax = ymax + (ymax - ymin) / 10 + 10;

  return {
    xmin,
    xmax,
    ymin,
    ymax,
  };
};
