import React from 'react';

export const NetsheetAmount: React.FC<{amount?: string[]}> = ({amount}) => {
  if (!amount || amount.length === 0 || amount.length > 2) {
    return null;
  }

  return (
    <span>
      {amount.length === 1 && amount}
      {amount.length === 2 &&
        (amount[0] === amount[1] ? amount[0] : `${amount[0]} - ${amount[1]}`)}
    </span>
  );
};
