import styled from '@emotion/styled';
import React from 'react';

import {font, mq, spacing} from '@uc/cx-design-tokens';

const PropertyDetailCellTitle = styled.div`
  font-size: ${font.sizeCaption1};
  font-weight: ${font.weightBold};
  font-family: ${font.familyLegible};

  @media ${mq.maxWidthMobileLandscape} {
    padding-bottom: ${spacing.half};
  }
`;

const PropertyDetailCellContent = styled.div`
  font-size: ${font.sizeBody1};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeBody2};
  }
`;

type Props = {
  caption?: string;
  value?: string;
};

export const PropertyDetailCell = ({caption, value}: Props) => {
  return (
    <div>
      <PropertyDetailCellTitle>{caption}</PropertyDetailCellTitle>
      <PropertyDetailCellContent>
        {value ? value : '-'}
      </PropertyDetailCellContent>
    </div>
  );
};
