import range from 'lodash.range';
import React, {ReactElement} from 'react';

import {color, font} from '@uc/cx-design-tokens';

import priceTickFormatter from '../utils/price-tick-formatter';

type XTickProps = {
  isMobile?: boolean;
  payload: {
    value: number;
  };
  x: number;
  y: number;
};

const XTick = ({x, y, payload, isMobile}: XTickProps) => {
  const fontSize = isMobile ? font.sizeBody2 : font.sizeBody1;
  return (
    <text
      fontWeight={font.weightRegular}
      fontSize={fontSize}
      dy={isMobile ? 16 : 24}
      x={x}
      y={y}
      textAnchor="middle"
      fill={color.black}
    >
      {Math.round(payload.value).toLocaleString()}
    </text>
  );
};

type YTickProps = {
  isMobile?: boolean;
  payload: {
    value: number;
  };
  y?: number;
};

const YTick = ({y, payload, isMobile = false}: YTickProps) => (
  <text
    fontWeight={font.weightRegular}
    fontSize={isMobile ? font.sizeBody2 : font.sizeBody1}
    dy={4}
    x={5}
    y={y}
    fill={color.black}
  >
    {priceTickFormatter(payload.value)}
  </text>
);

export const getXAxisProps = ({
  min,
  max,
  isMobile,
}: {
  min: number;
  max: number;
  isMobile: boolean;
}): {
  tickLine: boolean;
  fill: string;
  tick: (props: XTickProps) => ReactElement;
  ticks: number[];
  height?: number;
} => {
  const tickCount = isMobile ? 4 : 5;
  const height = isMobile ? undefined : 50;
  return {
    tickLine: isMobile,
    fill: 'black',
    tick: (props: XTickProps) => <XTick isMobile={isMobile} {...props} />,
    ticks: range(min, max + 1, (max - min) / tickCount).map(v => Math.round(v)),
    height,
  };
};

export const getYAxisProps = ({
  min,
  max,
  isMobile,
}: {
  min: number;
  max: number;
  isMobile: boolean;
}): {
  dx: number;
  axisLine: boolean;
  tickLine: boolean;
  tickFormatter: (v: number) => string;
  ticks: number[];
  tick?: (props: YTickProps) => ReactElement;
} => {
  const tickCount = isMobile ? 4 : 5;
  return {
    dx: -10,
    axisLine: false,
    tickLine: false,
    tickFormatter: priceTickFormatter,
    ticks: range(min, max + 1, (max - min) / tickCount).map(v => Math.round(v)),
    tick: (props: YTickProps) => <YTick isMobile={isMobile} {...props} />,
  };
};
