import React from 'react';
import styled from '@emotion/styled';

import {Person} from '@uc/thrift2npme/dist/people/api/person';

interface IAgentCardProps {
  agent: Person;
}

export const MarketReportAgentCard = ({agent}: IAgentCardProps) => {
  return (
    <Content>
      {agent.avatarUrl ? (
        <img
          src={agent.avatarUrl}
          alt={`agent ${agent.displayName} avatar image`}
          width="64px"
        />
      ) : (
        <></>
      )}
      <div className="agent-details">
        {agent.displayName ? (
          <div className="agent-fullname">{agent.displayName}</div>
        ) : null}
        {agent?.profile?.agent?.businessTitle ? (
          <div className="otherDetails">
            {agent.profile.agent.businessTitle}
          </div>
        ) : null}
        {agent.phone ? <div className="otherDetails">{agent.phone}</div> : null}
        {agent.email ? <div className="otherDetails">{agent.email}</div> : null}
      </div>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 10px;
  padding-left: 15px;

  .agent-details {
    font-size: 10px;
    line-height: 9px;
    top: -3px;
    position: relative;
    margin-right: 5px;
  }
  .agent-fullname {
    font-size: 12px;
    line-height: 150%;
    font-weight: var(--cx-font-weightMedium);
  }
  .otherDetails {
    font-size: 10px;
  }
`;
