import {MarketReportSettings} from '@uc/thrift2npme/dist/cma/cma_models';
import {
  ChartName,
  StatsName,
} from '@uc/thrift2npme/dist/trend_chart/trend_chart_service.ucfetch';

export const DEFAULT_MARKET_REPORT_SETTINGS: MarketReportSettings = {
  visibleCharts: [
    {chartName: ChartName.STATUS_COUNT, label: 'PENDING'},
    {chartName: ChartName.STATUS_COUNT, label: 'NEW_LISTING'},
    {chartName: ChartName.STATUS_COUNT, label: 'SOLD'},
    {chartName: ChartName.STATUS_COUNT, label: 'ACTIVE'},
    {chartName: ChartName.MONTHS_OF_INVENTORY_SOLD},
    {chartName: ChartName.MEDIAN_SOLD_PRICE},
    {chartName: ChartName.MEDIAN_PPSF},
    {chartName: ChartName.MEDIAN_DOM},
    {chartName: ChartName.CLOSE_PRICE_VS_LIST_PRICE_PERCENT},
  ],
  visibleMetrics: [
    StatsName.SOLD_LISTING_COUNT,
    StatsName.FOR_SALE_LISTING_COUNT,
    StatsName.MEDIAN_DOM,
    StatsName.MEDIAN_PPSF,
    StatsName.MONTHS_OF_INVENTORY_SOLD,
    StatsName.MEDIAN_SOLD_PRICE,
    StatsName.IN_CONTRACT_LISTING_COUNT,
  ],
  marketReportTitle: '',
};

export const SECTION_LIST_PANEL_WIDTH_IN_PX = 290;
export const COLLAPSED_SECTION_LIST_PANEL_WIDTH_IN_PX = 10;
export const SETTINGS_SIDE_PANEL_WIDTH_IN_PX = 312;

export enum PreviewFormat {
  WEB,
  PDF,
}

export type FileStackContent = {
  dimensions: {
    height: number;
    width: number;
  };
  numpages: number;
};

export const PdfPageSideShortInMm = 216;
export const PdfPageSideLongInMm = 279.4;
export const MmToPx = 3.7795275591;
export const PAGE_LIST_CONTAINER_ID = 'page-list-root';
export const SECTION_CONTAINER = 'section-container';
export const COMPARABLE_PROPERTIES_SUBSECTION_CONTAINER =
  'comparable-properties-subsection-container';
export const DEBOUNCE_FOR_SECTION_LIST_SCROLL_IN_MS = 50;
export const PDF_PAGE_CONTAINER_PADDING_IN_PX = 48;

export enum PageZoomLevel {
  '300%' = 3,
  '200%' = 2,
  '125%' = 1.25,
  '100%' = 1,
  '75%' = 0.75,
  '50%' = 0.5,
  '25%' = 0.25,
  '10%' = 0.1,
  'Fit' = 'Fit', // the whole page fits within the viewport of the screen
  'Fill' = 'Fill', // the page's x dimensions take up the whole page from left to rigth
}

// TODO move all to PageZoomV2 when PageZoom is removed
// maps the page zoom level from a PageZoomLevel constant to a human readable string
export const PAGE_ZOOM_LEVEL_TITLES: {[key in PageZoomLevel]: string} = {
  [PageZoomLevel['300%']]: '300%',
  [PageZoomLevel['200%']]: '200%',
  [PageZoomLevel['125%']]: '125%',
  [PageZoomLevel['100%']]: '100%',
  [PageZoomLevel['75%']]: '75%',
  [PageZoomLevel['50%']]: '50%',
  [PageZoomLevel['25%']]: '25%',
  [PageZoomLevel['10%']]: '10%',
  [PageZoomLevel.Fit]: 'Fit',
  [PageZoomLevel.Fill]: 'Fill',
};

export const PERCENTAGE_PAGE_ZOOM_LEVELS = [
  PageZoomLevel['300%'],
  PageZoomLevel['200%'],
  PageZoomLevel['125%'],
  PageZoomLevel['100%'],
  PageZoomLevel['75%'],
  PageZoomLevel['50%'],
  PageZoomLevel['25%'],
  PageZoomLevel['10%'],
];

export const [
  PRIMARY_INFORMATION_CONTENT_FIELDS_FOR_PDF_PROPERTY_SUMMARY,
  SECONDARY_INFORMATION_CONTENT_FIELDS_FOR_PDF_PROPERTY_SUMMARY,
] = [
  [
    'size.bedrooms',
    'size.bathrooms',
    'size.squareFeet',
    'price.charges.[0].chargeType.HOA_FEE',
    'buildingInfo.buildingYearOpened',
  ],
  [
    'price.listed',
    'price.preClose',
    'price.closed',
    'price.perSquareFoot',
    'date.listed',
    'date.contract',
    'date.closed',
    'date.daysOnMarket',
  ],
];
