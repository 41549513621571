import produce from 'immer';

import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import type {Media} from '@uc/thrift2npme/dist/translation_common/media';

import {createReducer} from '../createReducer';
import IModals from './types/Modals';

// DEFAULT STATE
export const modalsInitialState: IModals = {
  listingPage: null, // when null, listing page modal is closed. to open, set this to the listingPageUrl
  propertyModalListingIndex: null,
  photoViewerModalPhotoIndex: null,
  photoViewerModalPhotos: null,
  photoViewerModalListing: null,
} as const;

// ACTION TYPES
export const MODALS_ACTION_TYPES = {
  SET_MODAL_LISTING_PAGE: 'SET_MODAL_LISTING_PAGE',
  SET_PROPERTY_MODAL_LISTING_INDEX: 'SET_PROPERTY_MODAL_LISTING_INDEX',
  SET_PHOTO_VIEWER_MODAL_LISTING: 'SET_PHOTO_VIEWER_MODAL_LISTING',
  SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX: 'SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX',
  SET_PHOTO_VIEWER_MODAL_PHOTOS: 'SET_PHOTO_VIEWER_MODAL_PHOTOS',
};

// ACTION CREATORS
export const setModalListingPage = (listingPage: string | null) => ({
  type: MODALS_ACTION_TYPES.SET_MODAL_LISTING_PAGE,
  payload: {listingPage},
});

export const setPropertyModalListingIndex = (
  propertyModalListingIndex: number | null | undefined,
) => ({
  type: MODALS_ACTION_TYPES.SET_PROPERTY_MODAL_LISTING_INDEX,
  payload: {propertyModalListingIndex},
});

export const setPhotoViewerModalPhotos = (
  photoViewerModalPhotos: Media[] | null,
) => {
  return {
    type: MODALS_ACTION_TYPES.SET_PHOTO_VIEWER_MODAL_PHOTOS,
    payload: {photoViewerModalPhotos},
  };
};

export const setPhotoViewerModalListing = (
  photoViewerModalListing: ProcessedListing,
) => {
  return {
    type: MODALS_ACTION_TYPES.SET_PHOTO_VIEWER_MODAL_LISTING,
    payload: {photoViewerModalListing},
  };
};

export const setPhotoViewerModalPhotoIndex = (
  photoViewerModalPhotoIndex: number | null | undefined,
) => ({
  type: MODALS_ACTION_TYPES.SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX,
  payload: {photoViewerModalPhotoIndex},
});

// REDUCERS
const listingPageReducer = (state: IModals, payload: {listingPage: string}) =>
  produce(state, draftState => {
    draftState.listingPage = payload.listingPage;
  });

const propertyModalListingIndexReducer = (
  state: IModals,
  payload: {propertyModalListingIndex: number},
) =>
  produce(state, draftState => {
    draftState.propertyModalListingIndex = payload.propertyModalListingIndex;
  });

const photoViewerModalListingReducer = (
  state: IModals,
  payload: {photoViewerModalListing: ProcessedListing},
) =>
  produce(state, draftState => {
    draftState.photoViewerModalListing = payload.photoViewerModalListing;
  });

const photoViewerModalPhotosReducer = (
  state: IModals,
  payload: {photoViewerModalPhotos: Media[]},
) =>
  produce(state, draftState => {
    draftState.photoViewerModalPhotos = payload.photoViewerModalPhotos;
  });

const photoViewerModalPhotoIndexReducer = (
  state: IModals,
  payload: {photoViewerModalPhotoIndex: number},
) =>
  produce(state, draftState => {
    draftState.photoViewerModalPhotoIndex = payload.photoViewerModalPhotoIndex;
  });

// COMBINED REDUCER
export default createReducer<IModals>({
  [MODALS_ACTION_TYPES.SET_MODAL_LISTING_PAGE]: listingPageReducer,
  [MODALS_ACTION_TYPES.SET_PROPERTY_MODAL_LISTING_INDEX]:
    propertyModalListingIndexReducer,
  [MODALS_ACTION_TYPES.SET_PHOTO_VIEWER_MODAL_LISTING]:
    photoViewerModalListingReducer,
  [MODALS_ACTION_TYPES.SET_PHOTO_VIEWER_MODAL_PHOTOS]:
    photoViewerModalPhotosReducer,
  [MODALS_ACTION_TYPES.SET_PHOTO_VIEWER_MODAL_PHOTO_INDEX]:
    photoViewerModalPhotoIndexReducer,
});
