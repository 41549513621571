import {DataPoint} from 'regression';

import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {DisplayStatusValue} from '@/types/types';

export enum AxisKeyType {
  PRICE = 'price',
  SIZE = 'size',
  DOM = 'daysOnMarket',
}

export type ChartDataType = {
  x?: number;
  [AxisKeyType.PRICE]?: number;
  [AxisKeyType.SIZE]?: number;
  [AxisKeyType.DOM]?: number;

  originalIndex?: number;
  status?: DisplayStatusValue;
  address?: string;
  coverPhoto?: string;

  baths?: number;
  beds?: number;
  rowHeight?: number;
  priceText?: string[];

  listing?: ProcessedListing;
};

export const getCoordinates: (
  data: ChartDataType[],
  xAxisKey: AxisKeyType,
  yAxisKey: AxisKeyType,
) => DataPoint[] = (data, xAxisKey, yAxisKey) =>
  data.map(d => [d[xAxisKey], d[yAxisKey]] as DataPoint);

export const getMinMaxCoordinates: (
  data: ChartDataType[],
  xAxisKey: AxisKeyType,
  yAxisKey: AxisKeyType,
  xAxisOffset?: number,
) => {xmin: number; xmax: number; ymin: number; ymax: number} = (
  data,
  xAxisKey,
  yAxisKey,
  xAxisOffset = 0,
) => {
  const coordinates = getCoordinates(data, xAxisKey, yAxisKey);

  let xmin = coordinates.reduce<number>(
    (acc, [x]) => (isNaN(acc) ? x : Math.min(x, acc)),
    NaN,
  );
  let xmax = coordinates.reduce<number>(
    (acc, [x]) => (isNaN(acc) ? x : Math.max(x, acc)),
    NaN,
  );
  let ymin = coordinates.reduce<number>(
    (acc, [, y]) => (isNaN(acc) ? y : Math.min(y, acc)),
    NaN,
  );
  let ymax = coordinates.reduce<number>(
    (acc, [, y]) => (isNaN(acc) ? y : Math.max(y, acc)),
    NaN,
  );

  xmin = Math.max(0, xmin - (xmax - xmin) / 10 - (xAxisOffset + 10));
  xmax = xmax + (xmax - xmin) / 10 + 10;
  ymin = Math.max(0, ymin - (ymax - ymin) / 10 - 10);
  ymax = ymax + (ymax - ymin) / 10 + 10;

  return {
    xmin,
    xmax,
    ymin,
    ymax,
  };
};
