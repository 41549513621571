import produce from 'immer';

import {
  Cma,
  CmaOutputSection,
  Fetcher,
  PresentationalSectionSettings,
} from '@uc/thrift2npme/dist/cma/cma_models';
import {preview as previewCma} from '@uc/thrift2npme/dist/cma/cma_service.ucfetch';

import {PageZoomLevel} from '@/pages/PreviewPage/utils';
import {createReducer} from '../createReducer';
import type {ThunkFunction} from '../types/Store';
import IPreviewSettings from './types/PreviewSettings';

export const presentationalSettingsInitialState: IPreviewSettings = {
  presentationalSettings: {
    visibleSections: [
      {
        sectionType: CmaOutputSection.COVER,
      },
      {
        sectionType: CmaOutputSection.SUBJECT_DETAILS,
      },
      {
        sectionType: CmaOutputSection.MAP,
      },
      {
        sectionType: CmaOutputSection.SIDE_BY_SIDE_COMPARISON,
      },
      {
        sectionType: CmaOutputSection.STATUS_COMPARISON,
      },
      {
        sectionType: CmaOutputSection.CHARTS_PRICE_VS_SQFT,
      },
      {
        sectionType: CmaOutputSection.CHARTS_PRICE_VS_DOM,
      },
      {
        sectionType: CmaOutputSection.CHARTS_LISTING_AVERAGES,
      },
      {
        sectionType: CmaOutputSection.ESTIMATE,
      },
      {
        sectionType: CmaOutputSection.CONTACT,
      },
    ],
    hiddenSections: [],
  },
  isFetchingPreviewCma: false,
  isGoogleMapApiLoaded: false,
  sectionSettingsVisibility: false,
  previewCma: undefined,
  printMode: false,
  isSellerNetSheetOnly: false,
  isSectionListPanelCollapsed: false,
  zoomLevel: PageZoomLevel['Fit'],
  calculatedZoomLevel: undefined,
  focusedSectionIdx: 0,
  pdfInViewSectionIndex: 0,
  webInViewSectionIndex: 0,
  focusedSectionIdxUpdatedAt: new Date(),
  pdfInViewSectionIndexUpdatedAt: new Date(),
  webInViewSectionIndexUpdatedAt: new Date(),
  comparablePropertiesSubSectionIdx: -1,
  comparablePropertiesSubSectionInViewIndex: -1,
  comparablePropertiesSubSectionIdxUpdatedAt: new Date(),
  comparablePropertiesSubSectionInViewIndexUpdatedAt: new Date(),
};

export const PRESENTATIONAL_SETTINGS_ACTION_TYPES = {
  SET_SECTIONS: 'SET_SECTIONS',
  SET_FOCUSED_SECTION_IDX: 'SET_FOCUSED_SECTION_IDX',
  SET_PREVIEW_CMA: 'SET_PREVIEW_CMA',
  SET_PREVIEW_CMA_SUCCESS: 'SET_PREVIEW_CMA_SUCCESS',
  SET_PREVIEW_CMA_FAILURE: 'SET_PREVIEW_CMA_FAILURE',
  TOGGLE_SECTION_SETTING_VISIBILITY: 'TOGGLE_SECTION_SETTING_VISIBILITY',
  SET_MARKET_REPORT_SUCCESS: 'SET_MARKET_REPORT_SUCCESS',
  SET_MARKET_REPORT_FAILURE: 'SET_MARKET_REPORT_FAILURE',
  SET_PRINT_MODE: 'SET_PRINT_MODE',
  SET_SELLER_NETSHEET_ONLY: 'SET_SELLER_NETSHEET_ONLY',
  SET_IS_SECTION_LIST_PANEL_COLLAPSED: 'SET_IS_SECTION_LIST_PANEL_COLLAPSED',
  SET_ZOOM_LEVEL: 'SET_ZOOM_LEVEL',
  SET_CALCULATED_ZOOM_LEVEL: 'SET_CALCULATED_ZOOM_LEVEL',
  SET_PDF_IN_VIEW_SECTION_INDEX: 'SET_PDF_IN_VIEW_SECTION_INDEX',
  SET_WEB_IN_VIEW_SECTION_INDEX: 'SET_WEB_IN_VIEW_SECTION_INDEX',
  SET_COMPARABLE_PROPERTIES_SUB_SECTION_INDEX:
    'SET_COMPARABLE_PROPERTIES_SUB_SECTION_INDEX',
  SET_COMPARABLE_PROPERTIES_SUB_SECTION_IN_VIEW_INDEX:
    'SET_COMPARABLE_PROPERTIES_SUB_SECTION_IN_VIEW_INDEX',
  SET_GOOGLE_MAP_API_LOADED: 'SET_GOOGLE_MAP_API_LOADED',
  TOGGLE_SELLER_NETSHEET_SECTION: 'TOGGLE_SELLER_NETSHEET_SECTION',
} as const;

// ACTIONS
// We should always have one section set for our preview output
export const setSections = (
  visibleSections: PresentationalSectionSettings[] = [],
  hiddenSections: PresentationalSectionSettings[] = [],
) => {
  return {
    type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_SECTIONS,
    payload: {
      visibleSections,
      hiddenSections,
    },
  };
};

export const setPreviewCmaStatus = () => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_PREVIEW_CMA,
});

export const setPreviewCmaSuccess = (previewCma: Cma | null) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_PREVIEW_CMA_SUCCESS,
  payload: {
    cma: previewCma,
  },
});

export const setPreviewCmaFailure = () => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_PREVIEW_CMA_FAILURE,
});

const setMarketReportSuccess = (previewCma: Cma) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_MARKET_REPORT_SUCCESS,
  payload: {
    cma: previewCma,
  },
});

const setMarketReportFailure = () => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_MARKET_REPORT_FAILURE,
});

const setFocusedSectionIdx = (idx: number) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_FOCUSED_SECTION_IDX,
  payload: {
    idx,
  },
});

export const setIsSectionListPanelCollapsed = (collapse: boolean) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_IS_SECTION_LIST_PANEL_COLLAPSED,
  payload: {
    collapse,
  },
});

export const setZoomLevel = (zoomLevel: PageZoomLevel) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_ZOOM_LEVEL,
  payload: {
    zoomLevel,
  },
});

export const setCalculatedZoomLevel = (calculatedZoomLevel: number) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_CALCULATED_ZOOM_LEVEL,
  payload: {
    calculatedZoomLevel,
  },
});

export const setPrintMode = () => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_PRINT_MODE,
});

export const setSellerNetSheetOnly = (isSellerNetSheetOnly: boolean) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_SELLER_NETSHEET_ONLY,
  payload: {
    isSellerNetSheetOnly,
  },
});

export const toggleSectionSettings = (forceOption?: boolean) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.TOGGLE_SECTION_SETTING_VISIBILITY,
  payload: {
    forceOption,
  },
});

export const setPdfInViewSectionIndex = (sectionIndex: number) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_PDF_IN_VIEW_SECTION_INDEX,
  payload: {
    sectionIndex,
  },
});

export const setWebInViewSectionIndex = (sectionIndex: number) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_WEB_IN_VIEW_SECTION_INDEX,
  payload: {
    sectionIndex,
  },
});

export const setComparablePropertiesSubSectionIndex = (
  subSectionIndex: number,
) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_COMPARABLE_PROPERTIES_SUB_SECTION_INDEX,
  payload: {
    subSectionIndex,
  },
});

export const setComparablePropertiesSubSectionInViewIndex = (
  subSectionInViewIndex: number,
) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_COMPARABLE_PROPERTIES_SUB_SECTION_IN_VIEW_INDEX,
  payload: {
    subSectionInViewIndex,
  },
});

export const setGoogleMapApiLoaded = () => {
  return {
    type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_GOOGLE_MAP_API_LOADED,
  };
};

// THUNKS
export const setFocusedSection =
  (idx: number): ThunkFunction =>
  (dispatch, state, getState) => {
    const latestState = getState();
    const visibleSectionsLength =
      latestState.previewSettings.presentationalSettings.visibleSections
        ?.length || 0;
    if (idx < 0) {
      dispatch(setFocusedSectionIdx(0));
      return;
    } else if (idx >= visibleSectionsLength) {
      dispatch(setFocusedSectionIdx(visibleSectionsLength - 1));
      return;
    }
    return dispatch(setFocusedSectionIdx(idx));
  };

export const moveSection =
  (sourceIdx: number, destinationIdx: number): ThunkFunction =>
  (dispatch, state, getState) => {
    const latestState = getState();
    const {hiddenSections, visibleSections} =
      latestState.previewSettings.presentationalSettings;
    const visibleSectionsCopy = visibleSections?.slice() ?? [];
    const visibleSectionsLength = visibleSectionsCopy.length;
    if (
      isNaN(sourceIdx) ||
      isNaN(destinationIdx) ||
      sourceIdx === destinationIdx ||
      sourceIdx < 0 ||
      sourceIdx > visibleSectionsLength - 1 ||
      destinationIdx < 0 ||
      destinationIdx > visibleSectionsLength - 1
    ) {
      return;
    }
    // Insert the section at the new position and shift the sections in-between accordingly
    const [sectionToMove] = visibleSectionsCopy.splice(sourceIdx, 1);
    visibleSectionsCopy.splice(destinationIdx, 0, sectionToMove);
    dispatch(setSections(visibleSectionsCopy, hiddenSections));
    dispatch(setFocusedSectionIdx(destinationIdx));
  };

export const insertSection =
  (sectionType: number, destinationIdx: number): ThunkFunction =>
  (dispatch, state, getState) => {
    if (isNaN(sectionType) || sectionType === null) {
      return;
    }
    const latestState = getState();
    const {hiddenSections, visibleSections} =
      latestState.previewSettings.presentationalSettings;
    const visibleSectionsCopy: PresentationalSectionSettings[] =
      visibleSections?.slice() ?? [];

    const sectionIndex = visibleSectionsCopy.findIndex(
      section => section.sectionType === sectionType,
    );

    if (sectionIndex > -1) {
      return;
    }

    // If destinationIdx is not specified, it will be inserted at the end
    if (isNaN(destinationIdx) || destinationIdx === null) {
      visibleSectionsCopy.push({
        sectionType,
      });
    } else {
      visibleSectionsCopy.splice(destinationIdx, 0, {
        sectionType,
      });
    }

    dispatch(setSections(visibleSectionsCopy, hiddenSections));
  };

export const removeSection =
  (sectionType: number): ThunkFunction =>
  (dispatch, state, getState) => {
    if (isNaN(sectionType) || sectionType === null) {
      return;
    }
    const latestState = getState();
    const {hiddenSections, visibleSections} =
      latestState.previewSettings.presentationalSettings;
    const visibleSectionsCopy: PresentationalSectionSettings[] =
      visibleSections?.slice() ?? [];

    const sectionIndex = visibleSectionsCopy.findIndex(
      section => section.sectionType === sectionType,
    );

    if (sectionIndex === -1) {
      return;
    }

    visibleSectionsCopy.splice(sectionIndex, 1);

    dispatch(setSections(visibleSectionsCopy, hiddenSections));
  };

export const moveSectionPrev =
  (sectionPosition: number): ThunkFunction =>
  dispatch => {
    if (isNaN(sectionPosition) || sectionPosition === 0) {
      return;
    }
    dispatch(moveSection(sectionPosition, sectionPosition - 1));
  };

export const moveSectionNext =
  (sectionPosition: number): ThunkFunction =>
  (dispatch, state, getState) => {
    const latestState = getState();
    const {visibleSections} =
      latestState.previewSettings.presentationalSettings;
    if (
      isNaN(sectionPosition) ||
      !visibleSections ||
      sectionPosition === visibleSections.length - 1
    ) {
      return;
    }

    dispatch(moveSection(sectionPosition, sectionPosition + 1));
  };

export const toggleFullBreakdownPrice = (enabled: boolean) => ({
  type: PRESENTATIONAL_SETTINGS_ACTION_TYPES.TOGGLE_SELLER_NETSHEET_SECTION,
  payload: {
    breakdownFullPrice: enabled,
  },
});

// THUNKS
export const getPreviewCma =
  (): ThunkFunction => async (dispatch, state, getState) => {
    const latestState = getState();
    const shouldFetchPreviewCma =
      !latestState.previewSettings.isFetchingPreviewCma;
    if (shouldFetchPreviewCma) {
      dispatch(setPreviewCmaStatus());
      const cmaId = latestState.cma.cma.cmaId as string;
      try {
        const previewCmaResp = await previewCma(cmaId, {
          fetchers: [
            Fetcher.PEOPLE_SERVICE,
            Fetcher.LISTING_TRANSLATION_SERVICE,
          ],
        });
        dispatch(setPreviewCmaSuccess(previewCmaResp.cma ?? null));
      } catch (error) {
        dispatch(setPreviewCmaFailure());
      }
    }
  };

export const getMarketReport =
  (): ThunkFunction => async (dispatch, state, getState) => {
    const latestState = getState();
    const shouldFetchPreviewCma =
      !latestState.previewSettings.isFetchingPreviewCma;
    if (shouldFetchPreviewCma) {
      dispatch(setPreviewCmaStatus());
      const cmaId = latestState.cma.cma.cmaId as string;
      try {
        const previewCmaResp = await previewCma(cmaId, {
          fetchers: [
            // Fetcher.PEOPLE_SERVICE,
            // Fetcher.LISTING_TRANSLATION_SERVICE,
            Fetcher.MARKET_ANALYSIS_SERVICE,
          ],
        });
        dispatch(setMarketReportSuccess(previewCmaResp.cma as Cma));
      } catch (error) {
        dispatch(setMarketReportFailure());
      }
    }
  };

// REDUCERS
const setSectionsReducer = (
  state: IPreviewSettings,
  payload: {
    visibleSections: PresentationalSectionSettings[];
    hiddenSections: PresentationalSectionSettings[];
  },
) =>
  produce(state, draftState => {
    draftState.presentationalSettings.visibleSections = payload.visibleSections;
    draftState.presentationalSettings.hiddenSections = payload.hiddenSections;
  });

const setPrintModeReducer = (state: IPreviewSettings) =>
  produce(state, draftState => {
    draftState.printMode = true;
  });

const setSellerNetSheetOnlyReducer = (
  state: IPreviewSettings,
  payload: {isSellerNetSheetOnly: boolean},
) =>
  produce(state, draftState => {
    draftState.isSellerNetSheetOnly = payload.isSellerNetSheetOnly;
  });

const setFocusedSectionIdxReducer = (
  state: IPreviewSettings,
  payload: {idx: number},
) =>
  produce(state, draftState => {
    draftState.focusedSectionIdx = payload.idx;
    draftState.focusedSectionIdxUpdatedAt = new Date();
  });

const setIsSectionListPanelCollapsedReducer = (
  state: IPreviewSettings,
  payload: {collapse: boolean},
) =>
  produce(state, draftState => {
    draftState.isSectionListPanelCollapsed = payload.collapse;
  });

const setZoomLevelReducer = (
  state: IPreviewSettings,
  payload: {zoomLevel: PageZoomLevel},
) =>
  produce(state, draftState => {
    draftState.zoomLevel = payload.zoomLevel;
  });

const setCalculatedZoomLevelReducer = (
  state: IPreviewSettings,
  payload: {calculatedZoomLevel: number},
) =>
  produce(state, draftState => {
    draftState.calculatedZoomLevel = payload.calculatedZoomLevel;
  });

const setPdfInViewSectionIndexReducer = (
  state: IPreviewSettings,
  payload: {sectionIndex: number},
) =>
  produce(state, draftState => {
    draftState.pdfInViewSectionIndex = payload.sectionIndex;
    draftState.pdfInViewSectionIndexUpdatedAt = new Date();

    if (draftState.focusedSectionIdx !== payload.sectionIndex) {
      draftState.focusedSectionIdx = payload.sectionIndex;
      draftState.focusedSectionIdxUpdatedAt = new Date();
    }
  });

const setWebInViewSectionIndexReducer = (
  state: IPreviewSettings,
  payload: {sectionIndex: number},
) =>
  produce(state, draftState => {
    draftState.webInViewSectionIndex = payload.sectionIndex;
    draftState.webInViewSectionIndexUpdatedAt = new Date();

    if (draftState.focusedSectionIdx !== payload.sectionIndex) {
      draftState.focusedSectionIdx = payload.sectionIndex;
      draftState.focusedSectionIdxUpdatedAt = new Date();
    }
  });

const setComparablePropertiesSubSectionIndexReducer = (
  state: IPreviewSettings,
  payload: {subSectionIndex: number},
) =>
  produce(state, draftState => {
    const comparablePropertiesSectionIndex =
      draftState.presentationalSettings.visibleSections?.findIndex(
        visibleSection =>
          visibleSection.sectionType ===
          CmaOutputSection.SIDE_BY_SIDE_COMPARISON,
      );

    if (
      comparablePropertiesSectionIndex !== undefined &&
      comparablePropertiesSectionIndex !== -1
    ) {
      draftState.comparablePropertiesSubSectionIdx = payload.subSectionIndex;
      draftState.comparablePropertiesSubSectionIdxUpdatedAt = new Date();

      if (
        comparablePropertiesSectionIndex !== draftState.pdfInViewSectionIndex
      ) {
        draftState.pdfInViewSectionIndex = comparablePropertiesSectionIndex;
        draftState.pdfInViewSectionIndexUpdatedAt = new Date();
      }

      if (comparablePropertiesSectionIndex !== draftState.focusedSectionIdx) {
        draftState.focusedSectionIdx = comparablePropertiesSectionIndex;
        draftState.focusedSectionIdxUpdatedAt = new Date();
      }
    }
  });

const setComparablePropertiesSubSectionInViewIndexReducer = (
  state: IPreviewSettings,
  payload: {subSectionInViewIndex: number},
) =>
  produce(state, draftState => {
    const comparablePropertiesSectionIndex =
      draftState.presentationalSettings.visibleSections?.findIndex(
        visibleSection =>
          visibleSection.sectionType ===
          CmaOutputSection.SIDE_BY_SIDE_COMPARISON,
      );

    if (
      comparablePropertiesSectionIndex !== undefined &&
      comparablePropertiesSectionIndex !== -1
    ) {
      draftState.comparablePropertiesSubSectionInViewIndex =
        payload.subSectionInViewIndex;
      draftState.comparablePropertiesSubSectionInViewIndexUpdatedAt =
        new Date();

      if (
        draftState.comparablePropertiesSubSectionIdx !==
        payload.subSectionInViewIndex
      ) {
        draftState.comparablePropertiesSubSectionIdx =
          payload.subSectionInViewIndex;
        draftState.comparablePropertiesSubSectionIdxUpdatedAt = new Date();
      }

      if (
        comparablePropertiesSectionIndex !== draftState.pdfInViewSectionIndex
      ) {
        draftState.pdfInViewSectionIndex = comparablePropertiesSectionIndex;
        draftState.pdfInViewSectionIndexUpdatedAt = new Date();
      }

      if (comparablePropertiesSectionIndex !== draftState.focusedSectionIdx) {
        draftState.focusedSectionIdx = comparablePropertiesSectionIndex;
        draftState.focusedSectionIdxUpdatedAt = new Date();
      }
    }
  });

const setPreviewCmaStatusReducer = (state: IPreviewSettings) =>
  produce(state, draftState => {
    draftState.isFetchingPreviewCma = true;
  });

const setPreviewCmaSuccessReducer = (
  state: IPreviewSettings,
  payload: {cma: Cma},
) =>
  produce(state, draftState => {
    draftState.previewCma = payload.cma;
    draftState.isFetchingPreviewCma = false;
  });

const setPreviewCmaFailureReducer = (state: IPreviewSettings) =>
  produce(state, draftState => {
    draftState.isFetchingPreviewCma = false;
  });

const toggleSectionSettingsVisibilityReducer = (
  state: IPreviewSettings,
  payload: {forceOption?: boolean},
) =>
  produce(state, draftState => {
    if (payload.forceOption !== undefined) {
      draftState.sectionSettingsVisibility = payload.forceOption;
    } else {
      draftState.sectionSettingsVisibility = !state.sectionSettingsVisibility;
    }
  });

const setMarketReportSuccessReducer = (
  state: IPreviewSettings,
  payload: {cma: Cma},
) =>
  produce(state, draftState => {
    if (draftState.previewCma) {
      draftState.previewCma.marketReportChartsItems =
        payload.cma.marketReportChartsItems;
      draftState.previewCma.marketReportDisclaimer =
        payload.cma.marketReportDisclaimer;
      draftState.previewCma.marketReportMetricsItems =
        payload.cma.marketReportMetricsItems;
      draftState.isFetchingPreviewCma = false;
    }
  });

const setMarketReportFailureReducer = (state: IPreviewSettings) =>
  produce(state, draftState => {
    draftState.isFetchingPreviewCma = false;
  });

const setGoogleMapApiLoadedReducer = (state: IPreviewSettings) =>
  produce(state, draftState => {
    draftState.isGoogleMapApiLoaded = true;
  });

const toggleSellerNetSheetSectionReducer = (
  state: IPreviewSettings,
  payload: {breakdownFullPrice: boolean},
) =>
  produce(state, draftState => {
    if (!draftState.presentationalSettings.visibleSections) {
      draftState.presentationalSettings.visibleSections = [];
    }

    const netsheetSectionIndex =
      draftState.presentationalSettings.visibleSections.findIndex(
        section => section.sectionType === CmaOutputSection.NETSHEETS,
      );

    if (netsheetSectionIndex === -1) {
      draftState.presentationalSettings.visibleSections.push({
        sectionType: CmaOutputSection.NETSHEETS,
        sellerNetSheetSection: {
          breakdownFullPrice: payload.breakdownFullPrice,
        },
      });
    } else {
      const section =
        draftState.presentationalSettings.visibleSections[netsheetSectionIndex];

      if (!section.sellerNetSheetSection) {
        section.sellerNetSheetSection = {
          breakdownFullPrice: payload.breakdownFullPrice,
        };
      } else {
        section.sellerNetSheetSection.breakdownFullPrice =
          payload.breakdownFullPrice;
      }
    }
  });

export default createReducer<IPreviewSettings>({
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_SECTIONS]: setSectionsReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_FOCUSED_SECTION_IDX]:
    setFocusedSectionIdxReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_PREVIEW_CMA]:
    setPreviewCmaStatusReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_PREVIEW_CMA_SUCCESS]:
    setPreviewCmaSuccessReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_PREVIEW_CMA_FAILURE]:
    setPreviewCmaFailureReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.TOGGLE_SECTION_SETTING_VISIBILITY]:
    toggleSectionSettingsVisibilityReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_MARKET_REPORT_SUCCESS]:
    setMarketReportSuccessReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_MARKET_REPORT_FAILURE]:
    setMarketReportFailureReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_PRINT_MODE]: setPrintModeReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_SELLER_NETSHEET_ONLY]:
    setSellerNetSheetOnlyReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_IS_SECTION_LIST_PANEL_COLLAPSED]:
    setIsSectionListPanelCollapsedReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_ZOOM_LEVEL]: setZoomLevelReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_CALCULATED_ZOOM_LEVEL]:
    setCalculatedZoomLevelReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_PDF_IN_VIEW_SECTION_INDEX]:
    setPdfInViewSectionIndexReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_WEB_IN_VIEW_SECTION_INDEX]:
    setWebInViewSectionIndexReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_COMPARABLE_PROPERTIES_SUB_SECTION_INDEX]:
    setComparablePropertiesSubSectionIndexReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_COMPARABLE_PROPERTIES_SUB_SECTION_IN_VIEW_INDEX]:
    setComparablePropertiesSubSectionInViewIndexReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_GOOGLE_MAP_API_LOADED]:
    setGoogleMapApiLoadedReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.TOGGLE_SELLER_NETSHEET_SECTION]:
    toggleSellerNetSheetSectionReducer,
});
