import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import type {CustomListingAttribute} from '@uc/thrift2npme/dist/cma/cma_models';

type Props = {
  listing: ProcessedListing;
  hiddenPropertyInformation?: string[];
  selectListingAttributes?: CustomListingAttribute[];
  // TODO[https://compass-tech.atlassian.net/browse/ACT-5294]: update @uc/cma-output-sections to export the defined AllDisplayFieldsType
  allDisplayFields?: any;
};

export const getPropertyGeneralInfo = ({
  listing,
  hiddenPropertyInformation = [],
  selectListingAttributes,
  allDisplayFields,
}: Props): Record<string, string> => {
  const listingPaths = [
    'size.bedrooms',
    'size.bathrooms',
    'size.squareFeet',
    'price.charges.[0].chargeType.HOA_FEE',
    'buildingInfo.buildingYearOpened',
  ];

  const selectedPropertyPathKeys = selectListingAttributes?.map(
    v => v.processedListingPath,
  );
  const shouldDisplay = (path: string) => {
    const isInSelectListingAttribute =
      selectedPropertyPathKeys?.includes(path) ?? true;
    return (
      !hiddenPropertyInformation.includes(path) && isInSelectListingAttribute
    );
  };

  const displayProperties =
    allDisplayFields &&
    listingPaths
      .filter(shouldDisplay)
      .reduce((pre: Record<string, string>, path: string) => {
        if (allDisplayFields[path]) {
          const {displayFieldName, valueToDisplay} = allDisplayFields[path];
          return {
            ...pre,
            [displayFieldName]: valueToDisplay?.(listing) || '-',
          };
        }

        return pre;
      }, {});

  return displayProperties ?? {};
};
