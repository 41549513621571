import {createReducer} from './createReducer';
import {
  CMA_UPDATES_ACTION_TYPES,
  hasUnsavedChangesReducer,
  updateCmaFailureReducer,
  updateCmaReducer,
  updateCmaSuccessReducer,
} from './ducks/cmaUpdates';
import ICmaUpdates from './ducks/types/CmaUpdates';

export const createCmaUpdateReducer = (updateCmaActions: string[] = []) => {
  const hasUnsavedChangesActionMap = updateCmaActions.reduce<{
    [key: string]: (state: ICmaUpdates) => ICmaUpdates;
  }>((acc, action) => {
    acc[action] = hasUnsavedChangesReducer;
    return acc;
  }, {});

  return createReducer<ICmaUpdates>({
    [CMA_UPDATES_ACTION_TYPES.UPDATE_CMA]: updateCmaReducer,
    [CMA_UPDATES_ACTION_TYPES.UPDATE_CMA_SUCCESS]: updateCmaSuccessReducer,
    [CMA_UPDATES_ACTION_TYPES.UPDATE_CMA_FAILURE]: updateCmaFailureReducer,
    ...hasUnsavedChangesActionMap,
  });
};
