import React, {useRef, useImperativeHandle} from 'react';
import {BBox, getDiscreteZoomLevelForContainer} from '@uc-maps/geospatial';
import {
  GoogleMapProvider,
  MapContainer as UcMapContainer,
  useMap,
} from '@uc-maps/maps.react';

import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {ComparableMapMarkers} from './ComparableMapMarkers';
import {computeBbox} from './helpers';

type ComparableMapProps = {
  compListings: ProcessedListing[];
  subjectProperty: ProcessedListing;
  defaultGestureHandling?: 'auto' | 'none';
};

export const ComparableMap = React.forwardRef(
  (
    {
      compListings,
      subjectProperty,
      defaultGestureHandling = 'none',
    }: ComparableMapProps,
    ref,
  ) => {
    const mapRef = useRef<google.maps.Map>();
    const {getMapProps, mapApi} = useMap();

    useImperativeHandle(ref, () => ({
      resetMap: (size: {height: number; width: number}) => {
        mapApi.reposition({
          center: [
            subjectProperty.location?.longitude || 0,
            subjectProperty.location?.latitude || 0,
          ],
          zoomLevel: getDiscreteZoomLevelForContainer(
            computeBbox(compListings, subjectProperty) as BBox,
            size,
          ),
        });
      },
      setOptions: (options: google.maps.MapOptions) => {
        mapRef.current?.setOptions(options);
      },
    }));

    const onGoogleApiLoaded = (map: google.maps.Map) => {
      map.setOptions({
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        },
      });
      map.setOptions({gestureHandling: defaultGestureHandling});
      mapRef.current = map;
    };

    return (
      <UcMapContainer {...getMapProps()}>
        <GoogleMapProvider
          zoomControl={true}
          mapTypeControl={true}
          onGoogleApiLoaded={onGoogleApiLoaded}
        >
          <ComparableMapMarkers
            compListings={compListings}
            subjectProperty={subjectProperty}
          />
        </GoogleMapProvider>
      </UcMapContainer>
    );
  },
);
