import {format} from 'date-fns';

import {getAllDisplayableFields} from '@uc/cma-adapters';
import {
  formatBathsNoLabel,
  getBathrooms,
  formatBedroomsNoLabel,
  formatAnnualTaxes,
  formatCondoCoopFees,
  formatCondoCoopFeesLabel,
  formatFeePaymentFrequent,
  formatHOAFees,
  formatMonthlyTaxes,
  formatTaxes,
  formatLotSize,
  formatSquareFeet,
  getListingPrice,
  formatListingPrice,
  formatPrice,
} from '@uc/format';
import {getTaxes} from '@uc/get-listing-charges-and-fees';
import {NYC_GEO_ID} from '@uc/thrift2npme/dist/geography/common';
import {FeePaymentFrequentType} from '@uc/thrift2npme/dist/listing/listing';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {type CustomizeFieldsModalArgs} from '@/types/types';
import {
  ONLY_ON_COMPASS_SOURCE,
  ONLY_ON_COMPASS_SOURCE_DISPLAY,
} from '@/constants';
import type PropertyField from '@/context/ducks/types/PropertyField';

// When displaying fields in the customize rows modal, we don't need to show the Compass source name
const SOURCES_TO_HIDE_DISPLAY_NAME = new Set([ONLY_ON_COMPASS_SOURCE]);

/**
 * This object represents all listing fields for the SxS charts.
 * Note: When adding a new field to listingFields, follow the below object to add the necesary
 * attributes.
 * {
 *   id: string - Required attribute for referencing a field (unique to Valuation app, choose a name)
 *   displayFieldName: string - Required attribute representing the display name for each field
 *   mcKey: string - Required attribute: this is the key used in identifying the corresponding field in the SxS chart for the PDF CMA.
 *                   If adding a new field that doesn't exist in the PDF already, choose a name to reference when
 *                   building out the SxS chart for PDF (see customize-fields-on-canvas.js in src/utils)
 *   processedListingPath: string - Required attribute representing the path in a processed listing to this value
 *   defaultSelected: boolean - Optional attribute representing whether this field should initially be displayed on a property column for a CMA.
 *   valueToDisplay: function - Required attribute that helps determine exactly what value to show for this field
 *   listingFieldSource: object {
 *     source: string - Required attribute representing the MLS source (i.e sf_ebrd, listing_editor_manual)
 *     sourceName: string - Required attribute representing the MLS source name (i.e EBRD)
 *   },
 *   isCustomField: boolean - Optional attribute representing whether this field is a new field to show on the PDF that doesn't exist in
 *                            the PDF already via the legacy CMA (i.e Original to Latest Asking Price % Change)
 * }
 */
export const listingFields: Record<
  string,
  {
    id: string;
    displayFieldName: ((listing: ProcessedListing) => string) | string;
    mcKey?: string;
    processedListingPath?: string;
    defaultSelected?: boolean;
    valueToDisplay?: (
      listing: ProcessedListing,
    ) => string | number | undefined | null;
    listingFieldSource?: {
      source: string;
      sourceName: string;
    };
    [key: string]: unknown;
  }
> = {
  Status: {
    id: 'Status',
    displayFieldName: 'Status',
    mcKey: 'Status',
    processedListingPath: 'localizedStatus',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => listing?.localizedStatus,
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  Bedrooms: {
    id: 'Bedrooms',
    displayFieldName: 'Bedrooms',
    mcKey: 'Beds',
    processedListingPath: 'size.bedrooms',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      return formatBedroomsNoLabel(listing);
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  Baths: {
    id: 'Baths',
    displayFieldName: 'Baths',
    mcKey: 'Baths Full / Half',
    processedListingPath: 'size.bathrooms',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      return formatBathsNoLabel(getBathrooms(listing));
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Square Feet': {
    id: 'Square Feet',
    displayFieldName: 'Square Feet',
    mcKey: 'Sq. Ft.',
    processedListingPath: 'size.squareFeet',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      const sqft = listing?.size?.squareFeet;
      return sqft ? formatSquareFeet(sqft, '') : null;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  PPSF: {
    id: 'PPSF',
    displayFieldName: 'PPSF',
    mcKey: 'Price per Square Foot',
    processedListingPath: 'price.perSquareFoot',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing, adjustedPrice?: string) => {
      // try to calculate ppsf because agent may have edited listing.size.squareFeet
      const listingPrice = adjustedPrice || getListingPrice(listing);
      const listingSquareFeet = listing?.size?.squareFeet;
      if (
        typeof listingPrice === 'number' &&
        typeof listingSquareFeet === 'number' &&
        listingSquareFeet !== 0
      ) {
        return formatPrice(listingPrice / listingSquareFeet);
      }

      // if we can't calculate ppsf, fall back on listing.price.perSquareFoot
      const ppsf = listing?.price?.perSquareFoot;
      return ppsf ? formatPrice(ppsf) : null;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Original Listing Price': {
    id: 'Original Listing Price',
    displayFieldName: 'Original Listing Price',
    mcKey: 'Original Listing Price',
    processedListingPath: 'price.listed',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      const originalListingPrice = listing?.price?.listed;
      return originalListingPrice ? formatPrice(originalListingPrice) : null;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Last Asking Price': {
    id: 'Last Asking Price',
    displayFieldName: 'Last Asking Price',
    mcKey: 'Last Asking Price',
    processedListingPath: 'price.preClose',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      const lastAskingPrice = listing?.price?.preClose;
      if (lastAskingPrice) {
        return formatPrice(lastAskingPrice);
      }
      return formatListingPrice(listing) || null;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Closing Price': {
    id: 'Closing Price',
    displayFieldName: 'Closing Price',
    mcKey: 'Closing Price',
    processedListingPath: 'price.closed',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      const closingPrice = listing?.price?.closed;
      return closingPrice ? formatPrice(closingPrice) : null;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'HOA Fees': {
    id: 'HOA Fees',
    displayFieldName: 'HOA Fees',
    mcKey: 'HOA Fees',
    processedListingPath: 'price.charges.[0].chargeType.HOA_FEE',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) =>
      formatHOAFees(listing, true) || null,
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  Fees: {
    id: 'Fees',
    displayFieldName: (listing: ProcessedListing) =>
      formatCondoCoopFeesLabel(listing) || 'Condo/Co-op Fees',
    mcKey: 'Condo/Coop Fees',
    processedListingPath:
      'price.charges.[0].chargeType.MAINTENANCE_COMMON_CHARGES',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) =>
      formatCondoCoopFees(listing, true) || null,
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  Taxes: {
    id: 'Taxes',
    displayFieldName: 'Taxes',
    mcKey: 'Taxes',
    processedListingPath: 'price.charges.[0].chargeType.RE_TAXES',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      // TODO: Move some of this logic into the package that fetches listing charges for getMonthlyTaxes
      const taxesArr = getTaxes(listing);
      if (!taxesArr?.length) {
        return null;
      }
      const formattedTaxList = formatTaxes(listing, true);
      const frequencies = new Set(
        taxesArr.map(taxItem => taxItem?.paymentFrequentType),
      );
      // In the case of multiple frequencies (i.e monthly tax, annual tax, etc), just display the data as given to avoid
      // any erronous conversions
      if (frequencies.size > 1) {
        return formattedTaxList.join(',');
      }
      // In NYC geo, we display taxes in per month formats
      if (listing?.location?.geoId === NYC_GEO_ID && taxesArr?.length) {
        if (frequencies.has(FeePaymentFrequentType.MONTHLY)) {
          const totalMonthlyTaxes = taxesArr.reduce(
            (totalTaxPerMonth, taxItem) => {
              return (totalTaxPerMonth += taxItem.chargeAmount || 0);
            },
            0,
          );
          return `$${totalMonthlyTaxes}${formatFeePaymentFrequent(
            FeePaymentFrequentType.MONTHLY,
          )}`;
        }
        const monthlyTaxes = formatMonthlyTaxes(listing);
        return monthlyTaxes
          ? `${monthlyTaxes}${formatFeePaymentFrequent(
              FeePaymentFrequentType.MONTHLY,
            )}`
          : null;
      }
      const annualTaxes = formatAnnualTaxes(listing);
      return annualTaxes
        ? `${annualTaxes}${formatFeePaymentFrequent(
            FeePaymentFrequentType.ANNUALLY,
          )}`
        : null;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Listed Date': {
    id: 'Listed Date',
    displayFieldName: 'Listed Date',
    mcKey: 'Listed Date',
    processedListingPath: 'date.listed',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      const listedDate = listing?.date?.listed;
      return listedDate ? format(new Date(listedDate), 'LLL dd, yyyy') : null;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Contract Date': {
    id: 'Contract Date',
    displayFieldName: 'Contract Date',
    mcKey: 'Contract Date',
    processedListingPath: 'date.contract',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      const contractDate = listing?.date?.contract;
      return contractDate
        ? format(new Date(contractDate), 'LLL dd, yyyy')
        : null;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Sold Date': {
    id: 'Sold Date',
    displayFieldName: 'Sold Date',
    mcKey: 'Sold Date',
    processedListingPath: 'date.closed',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      const soldDate = listing?.date?.closed;
      return soldDate ? format(new Date(soldDate), 'LLL dd, yyyy') : null;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Days on Market': {
    id: 'Days on Market',
    displayFieldName: 'DOM - Days On Market',
    mcKey: 'DOM',
    processedListingPath: 'date.daysOnMarket',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      const dom = listing?.date?.daysOnMarket ?? NaN;
      if (isNaN(dom)) {
        return null;
      }
      return `${dom}`;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  CDOM: {
    id: 'Cumulative Days On Market',
    displayFieldName: 'CDOM - Cumulative Days on Market',
    mcKey: 'CDOM - Cumulative Days on Market',
    processedListingPath: 'date.cumulativeDaysOnMarket',
    isCustomField: true,
    valueToDisplay: (listing: ProcessedListing) => {
      const cdom = listing?.date?.cumulativeDaysOnMarket ?? NaN;
      if (isNaN(Number(cdom))) {
        return null;
      }
      return `${cdom}`;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Lot Size': {
    id: 'Lot Size',
    displayFieldName: 'Lot Size',
    mcKey: 'Lot Size',
    processedListingPath: 'size.lotSizeInSquareFeet',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) =>
      formatLotSize(listing) || null,
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Property Type': {
    id: 'Property Type',
    displayFieldName: 'Property Type',
    mcKey: 'Property Type',
    processedListingPath: 'detailedInfo.propertyType.masterType.GLOBAL.[0]',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) =>
      listing?.detailedInfo?.propertyType?.masterType?.GLOBAL?.[0] || null,
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Year Built': {
    id: 'Year Built',
    displayFieldName: 'Year Built',
    mcKey: 'Year Built',
    processedListingPath: 'buildingInfo.buildingYearOpened',
    defaultSelected: true,
    valueToDisplay: (listing: ProcessedListing) => {
      const yearBuilt = listing?.buildingInfo?.buildingYearOpened;
      return yearBuilt;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
    // TODO: Incorporate these attrs in all fields in CMA adapters, this is a test
    getValue: (listing: ProcessedListing) =>
      listing?.buildingInfo?.buildingYearOpened,
    inputType: 'integerInput',
    onEditBlur: (
      listing: ProcessedListing,
      newValue: unknown,
      onEditSuccess: ({
        fieldId,
        newEditedDisplayListing,
        newOriginalDisplayListing,
      }: {
        fieldId: string;
        newEditedDisplayListing: {yearBuilt: {value: unknown}};
        newOriginalDisplayListing: {yearBuilt: {value: unknown}};
      }) => void,
    ) => {
      const newEditedDisplayListing = {
        yearBuilt: {
          value: newValue,
        },
      };

      const newOriginalDisplayListing = {
        yearBuilt: {
          value: listing?.buildingInfo?.buildingYearOpened,
        },
      };

      onEditSuccess({
        fieldId: 'yearBuilt',
        newEditedDisplayListing,
        newOriginalDisplayListing,
      });
    },
  },
  'Listing Brokerage': {
    id: 'Listing Brokerage',
    displayFieldName: 'Listing Brokerage',
    mcKey: 'Listing Brokerage',
    processedListingPath: 'fullContacts',
    valueToDisplay: (listing: ProcessedListing) => {
      const listingCompanies = (listing?.fullContacts || []).reduce(
        (filtered: string[], contact) => {
          if (contact?.contactType === 'Listing Agent') {
            filtered.push(contact?.company || '');
          }
          return filtered;
        },
        [],
      );
      const dedupedList = [...new Set(listingCompanies)];
      return dedupedList.length ? dedupedList.join(', ') : null;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  },
  'Original to Latest Asking Price': {
    id: 'Original to Latest Asking Price',
    displayFieldName: 'Original to Latest Asking Price % Change',
    mcKey: 'Original to Latest Asking Price % Change',
    // This is made up but selectListingAttributes needs a path
    processedListingPath: 'price.origToLatestPriceChange',
    valueToDisplay: (listing: ProcessedListing) => {
      const originalListingPrice = listing?.price?.listed || null;
      const lastAskingPrice =
        listing?.price?.preClose || getListingPrice(listing) || null;
      if (!originalListingPrice || !lastAskingPrice) {
        return null;
      }
      const priceDiff = lastAskingPrice - originalListingPrice;
      const pctDiff =
        Math.round((priceDiff / originalListingPrice) * 100 * 10) / 10;
      return `${pctDiff}%`;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
    isCustomField: true,
  },
  'Latest Asking to Sold Price': {
    id: 'Latest Asking to Sold Price',
    displayFieldName: 'Latest Asking to Sold Price % Change',
    mcKey: 'Latest Asking to Sold Price % Change',
    // This is made up but selectListingAttributes needs a path
    processedListingPath: 'price.latestToSoldPriceChange',
    valueToDisplay: (listing: ProcessedListing) => {
      const lastAskingPrice =
        listing?.price?.preClose || getListingPrice(listing) || null;
      const soldPrice = listing?.price?.closed || null;
      if (!lastAskingPrice || !soldPrice) {
        return null;
      }
      const priceDiff = soldPrice - lastAskingPrice;
      const pctDiff = Math.round((priceDiff / lastAskingPrice) * 100 * 10) / 10;
      return `${pctDiff}%`;
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
    isCustomField: true,
  },
  Address: {
    id: 'Address',
    displayFieldName: 'Address',
    mcKey: 'Address',
    processedListingPath: 'listing.location?.agentAddress',
    defaultSelected: false,
    valueToDisplay: (listing: ProcessedListing) =>
      listing.location?.agentAddress || null,
  },
  City: {
    id: 'City',
    displayFieldName: 'City',
    mcKey: 'City',
    processedListingPath: 'listing.location?.city',
    defaultSelected: false,
    valueToDisplay: (listing: ProcessedListing) =>
      listing.location?.city || null,
  },
  State: {
    id: 'State',
    displayFieldName: 'State',
    mcKey: 'State',
    processedListingPath: 'listing.location?.state',
    defaultSelected: false,
    valueToDisplay: (listing: ProcessedListing) =>
      listing.location?.state || null,
  },
  Zip: {
    id: 'Zip',
    displayFieldName: 'Zip',
    mcKey: 'Zip',
    processedListingPath: 'listing.location?.zipCode',
    defaultSelected: false,
    valueToDisplay: (listing: ProcessedListing) =>
      listing.location?.zipCode || null,
  },
};

/*
 * This array represents listings fields to display on the search results table view
 */

export const searchResultsTableFields = [
  listingFields['Address'],
  listingFields['City'],
  listingFields['State'],
  listingFields['Zip'],
  listingFields['Status'],
  listingFields['Bedrooms'],
  listingFields['Baths'],
  listingFields['Square Feet'],
  listingFields['PPSF'],
  listingFields['Original Listing Price'],
  listingFields['Last Asking Price'],
  listingFields['Closing Price'],
  listingFields['HOA Fees'],
  listingFields['Fees'],
  listingFields['Taxes'],
  listingFields['Listed Date'],
  listingFields['Contract Date'],
  listingFields['Sold Date'],
  listingFields['Days on Market'],
  listingFields['Lot Size'],
  listingFields['Property Type'],
  listingFields['Year Built'],
  listingFields['Listing Brokerage'],
];

export const customListingAttributeToFields = (
  listingAttributes: Array<
    Omit<PropertyField, 'displayFieldName'> & {
      displayFieldName: string;
      defaultSelected?: boolean;
    }
  >,
) => {
  return listingAttributes.map<
    Omit<
      PropertyField,
      'processedListingPath' | 'listingFieldSource' | 'displayFieldName'
    > & {
      label: string;
      value: string;
      fieldSource?: PropertyField['listingFieldSource'];
    }
  >(attr => {
    const {
      listingFieldSource,
      displayFieldName,
      processedListingPath,
      ...restAttr
    } = attr || {};
    return {
      ...restAttr,
      id: `${attr?.mcKey}_${processedListingPath}_${listingFieldSource?.source}`,
      label: displayFieldName,
      value: processedListingPath,
      ...(listingFieldSource && {
        fieldSource: {
          ...listingFieldSource,
          hideSourceName: SOURCES_TO_HIDE_DISPLAY_NAME.has(
            listingFieldSource.source,
          ),
        },
      }),
    };
  });
};

export const fieldsToCustomListingAttribute = (
  fields: CustomizeFieldsModalArgs['selectedFields'],
) => {
  return fields.map<PropertyField>(field => {
    const {fieldSource, label, id, value, ...fieldAttributes} = field;
    const {hideSourceName, ...fieldSourceAttrs} = fieldSource || {};
    return {
      ...fieldAttributes,
      displayFieldName: label,
      processedListingPath: value,
      listingFieldSource: fieldSourceAttrs,
    };
  });
};

// The override value expects a string or CMA update will fail
export const stringifiedFieldValue = (
  value: string | number | null | undefined,
) => {
  if (value === null || value === undefined) {
    return '-';
  }
  return value + '';
};

export const getDisplayFieldName = (
  listing: ProcessedListing,
  fieldName: ((listing: ProcessedListing) => string) | string,
) => {
  return fieldName instanceof Function ? fieldName(listing) : fieldName;
};

export const getAllAmenityFields = (
  subjectProperty: Partial<ProcessedListing>,
  comps: Partial<ProcessedListing>[] = [],
) => {
  const subjectAmenities = subjectProperty?.detailedInfo?.amenities || [];
  const totalCompAmenities = comps.reduce(
    (amenityList: string[], comp) =>
      amenityList.concat(comp?.detailedInfo?.amenities || []),
    [],
  );
  const totalAmenities = [
    ...new Set([...subjectAmenities, ...totalCompAmenities]),
  ];
  return totalAmenities.map(amenityName => ({
    displayFieldName: amenityName,
    mcKey: amenityName,
    processedListingPath: `detailedInfo.amenities.${amenityName}`,
    valueToDisplay: (listing: ProcessedListing) => {
      const listingAmenities = listing?.detailedInfo?.amenities || [];
      return listingAmenities.indexOf(amenityName) > -1 ? 'Yes' : '-';
    },
    listingFieldSource: {
      source: ONLY_ON_COMPASS_SOURCE,
      sourceName: ONLY_ON_COMPASS_SOURCE_DISPLAY,
    },
  }));
};

export const getCustomizablePropertyFields = (
  subjectProperty: Partial<ProcessedListing>,
  comps: Partial<ProcessedListing>[],
) => [...getAllDisplayableFields(subjectProperty, comps)];
