import {color} from '@uc/cx-design-tokens';
import {ListingStatus} from '@uc/thrift2npme/dist/listing/listing_status';

import {PinType, mapPinTypeToColor} from '@/constants';
import {DisplayStatusValue} from '@/types/types';

export const statusOrder = ['Active', 'Pending', 'Sold', 'Other'];

export const displayStatuses: Record<string, DisplayStatusValue> = {
  active: {
    label: 'Active',
    color: mapPinTypeToColor[PinType.ACTIVE],
    textColor: color.white,
    sortOrder: 0,
  },
  pending: {
    label: 'Pending',
    color: mapPinTypeToColor[PinType.PENDING],
    textColor: color.white,
    trendable: true,
    sortOrder: 1,
  },
  sold: {
    label: 'Sold',
    color: mapPinTypeToColor[PinType.SOLD],
    textColor: color.grey20,
    trendable: true,
    sortOrder: 2,
  },
  other: {
    label: 'Other',
    footnoteSymbol: '*',
    footnote:
      'Other status includes cancelled, expired, and temporarily off market',
    sortOrder: 3,
    color: color.black,
    textColor: color.grey20,
  },
};

export const statusToDisplayStatus = Object.entries({
  ACTIVE: 'active',
  APPLICATION_PENDING: 'pending',
  LEASES_OUT: 'pending',
  EXCLUSIVE_EXPIRED: 'other',
  CONTRACT_OUT: 'pending',
  CONTRACT_SIGNED: 'pending',
  SOLD: 'sold',
  RENTED: 'sold',
  BOARD_PACKAGE_OUT: 'pending',
  BOARD_APPROVED: 'pending',
  CONTINGENCIES_DROPPED: 'pending',
  TEMPORARILY_OFF_MARKET: 'other',
  PERMANENTLY_OFF_MARKET: 'other',
  INCOMING: 'active',
  COMING_SOON: 'active',
}).reduce<Record<string, Omit<DisplayStatusValue, 'key'> & {key: string}>>(
  (acc, [status, key]) => ({
    ...acc,
    [ListingStatus[status as keyof typeof ListingStatus]]: {
      key,
      ...displayStatuses[key],
    },
  }),
  {},
);
