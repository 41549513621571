/* eslint-disable max-len */
import React from 'react';

export const SVG_LISTING_DETAILS = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g stroke="none" strokeWidth="1">
      <polygon points="0 8 4 8 4 14 0 14" />
      <polygon points="6 8 10 8 10 14 6 14" />
      <polygon points="12 8 16 8 16 14 12 14" />
      <polygon points="12 3 16 3 16 6 12 6" />
      <polygon points="6 3 10 3 10 6 6 6" />
      <polygon points="0 3 4 3 4 6 0 6" />
    </g>
  </svg>
);

export const SVG_BATH = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g stroke="none" strokeWidth="1">
      <path d="M4,4 L12,4 C13.1045695,4 14,4.8954305 14,6 L14,8 L2,8 L2,6 C2,4.8954305 2.8954305,4 4,4 Z" />
      <polygon points="7 1 9 1 9 6 7 6" />
      <polygon fillRule="nonzero" points="3 10 5 10 5 12 3 12" />
      <polygon fillRule="nonzero" points="7 10 9 10 9 12 7 12" />
      <polygon fillRule="nonzero" points="11 10 13 10 13 12 11 12" />
      <polygon fillRule="nonzero" points="11 14 13 14 13 16 11 16" />
      <polygon fillRule="nonzero" points="7 14 9 14 9 16 7 16" />
      <polygon fillRule="nonzero" points="3 14 5 14 5 16 3 16" />
    </g>
  </svg>
);

export const SVG_BED = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g stroke="none" strokeWidth="1">
      <path d="M13,2 C13.5522847,2 14,2.44771525 14,3 L14,6 L15,6 C15.5522847,6 16,6.44771525 16,7 L16,14 L14,14 L14,12 L2,12 L2,14 L0,14 L0,7 C0,6.44771525 0.44771525,6 1,6 L2,6 L2,3 C2,2.44771525 2.44771525,2 3,2 L13,2 Z M13,8 L3,8 C2.48716416,8 2.06449284,8.38604019 2.00672773,8.88337887 L2,9 L2,10 L14,10 L14,9 C14,8.48716416 13.6139598,8.06449284 13.1166211,8.00672773 L13,8 Z M6,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,6 L7,6 L7,5 C7,4.48716416 6.61395981,4.06449284 6.11662113,4.00672773 L6,4 Z M11,4 L10,4 C9.48716416,4 9.06449284,4.38604019 9.00672773,4.88337887 L9,5 L9,6 L12,6 L12,5 C12,4.48716416 11.6139598,4.06449284 11.1166211,4.00672773 L11,4 Z" />
    </g>
  </svg>
);

export const SVG_FLOORPLAN = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g stroke="none" strokeWidth="1">
      <g transform="translate(8.000000, 8.000000) rotate(180.000000) translate(-8.000000, -8.000000) translate(1.000000, 1.000000)">
        <polygon points="14 4 14 14 10 14 10 12 12 11.999 12 5.999 6 6 6 2 2 2 2 4 4 4 4 6 2 6 2 12 6 12 6 8 8 8 8 14 0 14 0 -9.9475983e-14 8 4.97379915e-14 8 3.999" />
      </g>
    </g>
  </svg>
);

export const SVG_KITCHEN = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g stroke="none" strokeWidth="1">
      <path d="M5,8 L5,15 C5,15.5522847 4.55228475,16 4,16 L3,16 C2.44771525,16 2,15.5522847 2,15 L2,8 L5,8 Z M13,8 L13,15 C13,15.5522847 12.5522847,16 12,16 L11,16 C10.4477153,16 10,15.5522847 10,15 L10,8 L13,8 Z M2,1.33226763e-15 L2.029,4 L2.987,4 L3,1.33226763e-15 L4,1.33226763e-15 L4.006,4 L5.022,4 L5.02264404,1.33226763e-15 L6,1.33226763e-15 L6,6 C6,6.55228475 5.55228475,7 5,7 L2,7 C1.44771525,7 1,6.55228475 1,6 L1,1.33226763e-15 L2,1.33226763e-15 Z M11.5,0 C13.4329966,0 15,1.56700338 15,3.5 C15,5.43299662 13.4329966,7 11.5,7 C9.56700338,7 8,5.43299662 8,3.5 C8,1.56700338 9.56700338,0 11.5,0 Z" />
    </g>
  </svg>
);

export const SVG_LIVING_ROOM = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g stroke="none" strokeWidth="1">
      <path d="M7,6 C7.55228475,6 8,6.44771525 8,7 L8,8 L9,8 C9.55228475,8 10,8.44771525 10,9 L10,15 C10,15.5522847 9.55228475,16 9,16 C8.44771525,16 8,15.5522847 8,15 L8,14 L2,14 L2,15 C2,15.5522847 1.55228475,16 1,16 C0.44771525,16 0,15.5522847 0,15 L0,9 C0,8.44771525 0.44771525,8 1,8 L2,8 L2,7 C2,6.44771525 2.44771525,6 3,6 L7,6 Z M10,4 L6,4 L6,1 C6,0.44771525 6.44771525,0 7,0 L9,0 C9.55228475,0 10,0.44771525 10,1 L14,1 C14.5128358,1 14.9355072,1.38604019 14.9932723,1.88337887 L15,2 L15,14 C15.5522847,14 16,14.4477153 16,15 L16,16 L11,16 L11,15 C11,14.4477153 11.4477153,14 12,14 L13,14 L13,3 L10,3 L10,4 Z M7,10 L7.11662113,10.0067277 C7.61395981,10.0644928 8,10.4871642 8,11 C8,11.5522847 7.55228475,12 7,12 L7,12 L3,12 L2.88337887,11.9932723 C2.38604019,11.9355072 2,11.5128358 2,11 C2,10.4477153 2.44771525,10 3,10 L3,10 L7,10 Z" />
    </g>
  </svg>
);

export const SVG_PHOTOS = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g stroke="none" strokeWidth="1">
      <circle cx="5.5" cy="5.5" r="1.5" />
      <path d="M15,1 L15,15 L1,15 L1,4 L1,1 L15,1 Z M13,11 L3,11 L3,13 L13,13 L13,11 Z M13,3 L3,3 L3,10 L5,8 L6.485,9.515 L10,6 L13,9 L13,3 Z" />
    </g>
  </svg>
);
