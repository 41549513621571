import type {State} from './store';

export const SET_LAYOUT_KEY = 'SET_LAYOUT_KEY';
type SET_LAYOUT_KEY = typeof SET_LAYOUT_KEY;

export const setComparablesLayoutKey = (
  comparablesLayoutKey: string,
): {type: SET_LAYOUT_KEY; payload: {comparablesLayoutKey: string}} => ({
  type: SET_LAYOUT_KEY,
  payload: {comparablesLayoutKey},
});

export type Action = ReturnType<typeof setComparablesLayoutKey>;

export const compSectionReducer = (state: State, action: Action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_LAYOUT_KEY: {
      const {comparablesLayoutKey} = payload;
      return {
        ...state,
        comparablesLayoutKey,
      };
    }
    default:
      throw `action type not found: [${type}]`;
  }
};
