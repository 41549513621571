import produce from 'immer';

import {convertDisplayListingToProcessedListing} from '@uc/cma-adapters';
import ucFetch from '@uc/fetch';
import {getListingPrice} from '@uc/format';
import {Cma} from '@uc/thrift2npme/dist/cma/cma_models';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import {SearchQuery as ThriftSearchQuery} from '@uc/thrift2npme/dist/search/search';

import {CUSTOMIZE_FIELDS_SUBJECT_OVERRIDE_VALUE_KEY} from '@/constants';
import paths from '@/paths';
import {ApiError} from '@/types/ApiError';
import {
  getDisplayFieldName,
  stringifiedFieldValue,
} from '@/utils/customize-fields';
import {getAdjustedPrice} from '@/utils/display-helpers';
import {createReducer} from '../createReducer';
import type {ThunkFunction} from '../types/Store';
import IPublish from './types/Publish';
import PropertyField from './types/PropertyField';
import {trackCmaPublishError} from '@/utils/analyticTracks';

export const publishInitalState: IPublish = {
  hasPublished: false,
  isPublishing: false,
  error: null,
};

export const PUBLISH_ACTION_TYPES = {
  CMA_PUBLISH: 'CMA_PUBLISH',
  CMA_PUBLISH_FAILURE: 'CMA_PUBLISH_FAILURE',
  CMA_PUBLISH_SUCCESS: 'CMA_PUBLISH_SUCCESS',
  CMA_PUBLISH_RESET_ERROR: 'CMA_PUBLISH_RESET_ERROR',
} as const;

// ACTION HELPERS
const shouldPublishCma = (state: IPublish) =>
  !state.hasPublished && !state.isPublishing;

// ACTION CREATORS
const publishCmaAction = () => ({
  type: PUBLISH_ACTION_TYPES.CMA_PUBLISH,
});

const publishCmaSuccess = () => ({
  type: PUBLISH_ACTION_TYPES.CMA_PUBLISH_SUCCESS,
});

const publishCmaFailure = (error: Error & {response: Response}) => ({
  type: PUBLISH_ACTION_TYPES.CMA_PUBLISH_FAILURE,
  payload: {error},
});

export const publishCma = (): ThunkFunction => (dispatch, state, getState) => {
  const latestState = getState();
  const shouldPublish = shouldPublishCma(latestState.publish);
  if (shouldPublish) {
    dispatch(publishCmaAction());
    let subjectProperty =
      latestState.cma.cma?.agentInputs?.subjectProperty ?? {};
    const lotSizeUnit = latestState.cma.cma?.agentInputs?.lotSizeUnit;
    const subjectListingIdSHA = subjectProperty?.listingIdSHA;
    if (!subjectListingIdSHA) {
      const {listingIdSHA, ...restSubjectProperty} = subjectProperty;
      subjectProperty = restSubjectProperty;
    }
    // TODO: Remove the map function when below is satisfied
    // Temporarily continue setting overrideValue to persist edits until
    // Digital CMA starts using fields from cma-adapters
    const editedDisplayListings =
      latestState.cma.cma.agentInputs?.editedDisplayListings;
    const comps = latestState.comps.compListings.map(
      (comp: ProcessedListing) => {
        const compListingIdSHA = comp.listingIdSHA as string;
        const edits = editedDisplayListings?.[compListingIdSHA];
        return edits
          ? convertDisplayListingToProcessedListing(edits, comp, {
              lotSizeUnit,
            })
          : comp;
      },
    ) as ProcessedListing[];
    const adjustments = latestState.ai.appliedAdjustments;
    // Need to fill in override values for each subject/comp in each field
    // so DLP/MC can read these values into their property column display.
    // These values should reflect the latest listing data as shown in the workflow
    const fieldsWithOverrideValue = latestState.propertyFields.map<
      Omit<PropertyField, 'displayFieldName'> & {
        overrideValue: Record<string, string>;
        displayFieldName: string;
      }
    >(propertyField => {
      const fieldName = getDisplayFieldName(
        subjectProperty,
        propertyField.displayFieldName,
      );
      const subjectFieldValue = stringifiedFieldValue(
        propertyField.valueToDisplay?.(subjectProperty),
      );
      let newReducedOverrideValue: {[key: string]: string} = {};
      newReducedOverrideValue[CUSTOMIZE_FIELDS_SUBJECT_OVERRIDE_VALUE_KEY] =
        subjectFieldValue;
      if (
        propertyField.processedListingPath.indexOf('detailedInfo.amenities') >
        -1
      ) {
        newReducedOverrideValue[CUSTOMIZE_FIELDS_SUBJECT_OVERRIDE_VALUE_KEY] =
          stringifiedFieldValue(
            propertyField.valueToDisplay?.(
              subjectProperty,
              editedDisplayListings?.[
                CUSTOMIZE_FIELDS_SUBJECT_OVERRIDE_VALUE_KEY
              ],
            ),
          );
      }
      if (propertyField.displayFieldName === 'Lot Size') {
        newReducedOverrideValue[CUSTOMIZE_FIELDS_SUBJECT_OVERRIDE_VALUE_KEY] =
          stringifiedFieldValue(
            propertyField.valueToDisplay?.(subjectProperty, lotSizeUnit),
          );
      }
      newReducedOverrideValue = comps.reduce(
        (acc: {[key: string]: string}, comp: ProcessedListing) => {
          const compId = comp.listingIdSHA as string;
          const compFieldValue = stringifiedFieldValue(
            propertyField.valueToDisplay?.(comp),
          );
          acc[compId] = compFieldValue;
          if (
            propertyField.processedListingPath.indexOf(
              'detailedInfo.amenities',
            ) > -1
          ) {
            acc[compId] = stringifiedFieldValue(
              propertyField.valueToDisplay?.(
                comp,
                editedDisplayListings?.[compId],
              ),
            );
          }
          if (propertyField.displayFieldName === 'PPSF') {
            const originalPrice = getListingPrice(comp);
            const adjustedPrice = getAdjustedPrice(
              originalPrice,
              adjustments[compId],
            );
            acc[compId] = stringifiedFieldValue(
              propertyField.valueToDisplay?.(comp, adjustedPrice),
            );
          } else if (propertyField.displayFieldName === 'Lot Size') {
            acc[compId] = stringifiedFieldValue(
              propertyField.valueToDisplay?.(comp, lotSizeUnit),
            );
          }
          return acc;
        },
        newReducedOverrideValue,
      );
      return {
        ...propertyField,
        displayFieldName: fieldName,
        overrideValue: newReducedOverrideValue,
      };
    });

    const cma = produce(latestState.cma.cma, (draftState: Cma) => {
      if (draftState.agentInputs) {
        draftState.agentInputs.appliedAdjustments =
          latestState.ai.appliedAdjustments;
        draftState.agentInputs.adjustmentFeedback = latestState.ai.feedback;
        draftState.agentInputs.compSearchQuery =
          latestState.searchQuery as ThriftSearchQuery;
        draftState.agentInputs.assets = latestState.subjectAssets?.assets;
        draftState.agentInputs.subjectProperty = subjectProperty;
        draftState.agentInputs.selectListingAttributes =
          fieldsWithOverrideValue;
        draftState.agentInputs.presentationalSettings =
          latestState.previewSettings.presentationalSettings;
      }
    });
    const publishEndpoint = `${paths.base.pattern}${paths.publishCMA.pattern}`;
    return ucFetch
      .post(publishEndpoint, {cma})
      .then(() => dispatch(publishCmaSuccess()))
      .catch(e => {
        trackCmaPublishError(cma.cmaId || '', e);
        dispatch(publishCmaFailure(e));
        throw e;
      });
  }
};

// REDUCERS
const publishCmaReducer = (state: IPublish) =>
  produce(state, (draftState: IPublish) => {
    draftState.hasPublished = false;
    draftState.isPublishing = true;
    draftState.error = null;
  });

const publishCmaSuccessReducer = (state: IPublish) =>
  produce(state, (draftState: IPublish) => {
    draftState.hasPublished = true;
    draftState.isPublishing = false;
    draftState.error = null;
  });

const publishCmaFailureReducer = (
  state: IPublish,
  payload: {error: ApiError | null},
) =>
  produce(state, (draftState: IPublish) => {
    draftState.hasPublished = false;
    draftState.isPublishing = false;
    draftState.error = payload.error;
  });

const publishCmaResetErrorReducer = (state: IPublish) =>
  produce(state, (draftState: IPublish) => {
    draftState.error = null;
  });

export default createReducer<IPublish>({
  [PUBLISH_ACTION_TYPES.CMA_PUBLISH]: publishCmaReducer,
  [PUBLISH_ACTION_TYPES.CMA_PUBLISH_SUCCESS]: publishCmaSuccessReducer,
  [PUBLISH_ACTION_TYPES.CMA_PUBLISH_FAILURE]: publishCmaFailureReducer,
  [PUBLISH_ACTION_TYPES.CMA_PUBLISH_RESET_ERROR]: publishCmaResetErrorReducer,
});
