import styled from '@emotion/styled';
import React, {FC} from 'react';

import {color} from '@uc/cx-design-tokens';

const GoogleMapCustomControl = styled.div`
  z-index: var(--cx-zIndex-2);
  background-color: ${color.background};
  border: 2px solid ${color.white};
  border-radius: 2px;
  box-shadow: ${color.grey60} 0px 1px 4px -1px;
  cursor: pointer;
  color: ${color.grey100};
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  height: 40px;
  line-height: 18px;
  padding: 10px;
  position: absolute;
  right: 20px;
  bottom: 40px;
`;

const ArrowsSvg = styled.svg`
  vertical-align: top;
  transform: rotate(45deg);
  margin-right: 10px;
`;

const LockSvg = styled.svg`
  vertical-align: top;
  margin-right: 10px;
`;

type Props = {
  mapIsLocked: boolean;
  onClick: () => void;
};

export const MapLockToggleButton: FC<Props> = ({mapIsLocked, onClick}) => (
  <GoogleMapCustomControl onClick={onClick}>
    {mapIsLocked ? (
      <>
        <ArrowsSvg className="cx-icon" width="16" height="16">
          <use xlinkHref="/ucfe-assets/cx-icons/4/cx-icons.cdn.svg#cx-icon-arrowsExpand_16x16" />
        </ArrowsSvg>
        <span>Move Map</span>
      </>
    ) : (
      <>
        <LockSvg className="cx-icon" width="16" height="16">
          <use xlinkHref="/ucfe-assets/cx-icons/4/cx-icons.cdn.svg#cx-icon-lock_16x16" />
        </LockSvg>
        <span>Lock Map</span>
      </>
    )}
  </GoogleMapCustomControl>
);
