import produce from 'immer';

import {getListingPrice} from '@uc/format';
import {
  PriceEstimateSources,
  PriceEstimation,
} from '@uc/thrift2npme/dist/cma/cma_models';
import {estimatePrice} from '@uc/thrift2npme/dist/cma/cma_service.ucfetch';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {ApiError} from '@/types/ApiError';
import {createReducer} from '../createReducer';
import type {ThunkFunction} from '../types/Store';
import IEstimatePrice from './types/EstimatePrice';
import {trackGetEstimatePriceError} from '@/utils/analyticTracks';

export const estimatePriceInitalState: IEstimatePrice = {
  isFetching: false,
  error: undefined,
  priceEstimation: undefined,
  isFetchingSnapshot: false,
  snapshotError: undefined,
  snapshot: undefined,
};

export const ESTIMATE_PRICE_ACTION_TYPES = {
  FETCH_ESTIMATE_PRICE: 'FETCH_ESTIMATE_PRICE',
  FETCH_ESTIMATE_PRICE_SUCCESS: 'FETCH_ESTIMATE_PRICE_SUCCESS',
  FETCH_ESTIMATE_PRICE_FAILURE: 'FETCH_ESTIMATE_PRICE_FAILURE',
} as const;

// ACTIONS
const fetchEstimatePrice = () => ({
  type: ESTIMATE_PRICE_ACTION_TYPES.FETCH_ESTIMATE_PRICE,
});

const fetchEstimatePriceSuccess = (
  priceEstimation: PriceEstimation | undefined,
) => ({
  type: ESTIMATE_PRICE_ACTION_TYPES.FETCH_ESTIMATE_PRICE_SUCCESS,
  payload: {priceEstimation},
});

const fetchEstimatePriceFailure = (error: ApiError | undefined) => ({
  type: ESTIMATE_PRICE_ACTION_TYPES.FETCH_ESTIMATE_PRICE_FAILURE,
  payload: {error},
});

// REDUCERS
const fetchEstimatePriceReducer = (state: IEstimatePrice) =>
  produce(state, draftState => {
    draftState.isFetching = true;
    draftState.error = undefined;
  });

const fetchEstimatePriceSuccessReducer = (
  state: IEstimatePrice,
  payload: {priceEstimation: PriceEstimation | undefined},
) =>
  produce(state, draftState => {
    draftState.priceEstimation = payload.priceEstimation;
    draftState.isFetching = false;
    draftState.error = undefined;
  });

const fetchEstimatePriceFailureReducer = (
  state: IEstimatePrice,
  payload: {error: ApiError | undefined},
) =>
  produce(state, draftState => {
    draftState.isFetching = false;
    draftState.error = payload.error;
  });

// THUNKS

const shouldFetchEstimatePrice = (
  subjectProperty: ProcessedListing,
  compIds: string[],
) => {
  return subjectProperty && compIds && compIds.length;
};

export const getEstimatePrice =
  (): ThunkFunction => async (dispatch, state, getState) => {
    const currentState = getState();
    const {cmaId, agentInputs} = currentState.cma.cma;
    const {compListings} = currentState.comps;
    const {subjectProperty, compIds} = agentInputs || {};
    const listingIdSHA = subjectProperty?.listingIdSHA;
    const compassPropertyId = subjectProperty?.compassPropertyId;

    if (
      !shouldFetchEstimatePrice(
        subjectProperty as ProcessedListing,
        compIds as string[],
      )
    ) {
      return;
    }
    dispatch(fetchEstimatePrice());
    try {
      const compPrices: {[key: string]: number} = {};
      compListings.forEach((comp: ProcessedListing) => {
        compPrices[comp.listingIdSHA as string] = getListingPrice(comp) || 0;
      });

      const req = {
        fetchers: [
          PriceEstimateSources.AGENT_ADJUSTMENTS,
          PriceEstimateSources.AI_ADJUSTMENTS,
          PriceEstimateSources.PROPERTY_DATA_AVM,
          PriceEstimateSources.CMA_COMPASS_VALUATION,
        ],
        compassPropertyId:
          compassPropertyId ||
          (typeof compassPropertyId === 'string' &&
            compassPropertyId === 'undefined')
            ? `${compassPropertyId}`
            : '-1',
        compIds,
        listingIdSHA,
        compPrices,
      };
      const resp = await estimatePrice(cmaId as string, req);
      dispatch(fetchEstimatePriceSuccess(resp.priceEstimation ?? undefined));
    } catch (error) {
      trackGetEstimatePriceError({
        cmaId: cmaId || '',
        subjectProperty: subjectProperty || {},
        error,
      });
      dispatch(fetchEstimatePriceFailure(error));
    }
  };

export default createReducer<IEstimatePrice>({
  [ESTIMATE_PRICE_ACTION_TYPES.FETCH_ESTIMATE_PRICE]: fetchEstimatePriceReducer,
  [ESTIMATE_PRICE_ACTION_TYPES.FETCH_ESTIMATE_PRICE_SUCCESS]:
    fetchEstimatePriceSuccessReducer,
  [ESTIMATE_PRICE_ACTION_TYPES.FETCH_ESTIMATE_PRICE_FAILURE]:
    fetchEstimatePriceFailureReducer,
});
