import {CompsOrder, Cma} from '@uc/thrift2npme/dist/cma/cma_models';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import type {
  Adjustment,
  AdjustmentFeedback,
} from '@uc/thrift2npme/dist/valuation/ai_valuation/adjustments';
import {type DisplayField} from '@uc/cma-adapters';
import {CustomListingAttribute} from '@uc/thrift2npme/dist/cma/cma_models';
import {SearchQuery} from '@uc/search-query';

import {
  compsSortOrder,
  mainListingStatuses,
  sortingListingStatusMap,
} from '@/constants';
import {CUSTOM_LISTING_PREFIX} from '@/constants';
import {getCustomizablePropertyFields} from '@/utils/customize-fields';
import {defaultState} from './store';
import IStoreState from './types/Store';
import {ListingWithAgent} from '@/utils/types';
import type PropertyField from './ducks/types/PropertyField';

export const getMergedInitialState = (
  initialState: Partial<IStoreState>,
): IStoreState => {
  const mergedInitialState = Object.assign({}, defaultState, initialState);

  if (mergedInitialState.cma?.cma?.agentInputs) {
    const {agentInputs} = mergedInitialState.cma.cma;
    mergedInitialState.searchQuery = agentInputs?.compSearchQuery || {};
    mergedInitialState.subjectAssets.assets = agentInputs?.assets || [];

    // Sometimes an appliedAdjustment is null which causes issues when publishing as the
    // MC canvas expects an array for each comp. This defaults those cases to an empty array.
    // mergedInitialState.ai.appliedAdjustments = agentInputs.appliedAdjustments || {};
    const appliedAdjustments = agentInputs.appliedAdjustments || {};
    mergedInitialState.ai.appliedAdjustments = Object.keys(
      appliedAdjustments,
    ).reduce<Record<string, Adjustment[]>>((adjustments, listingIdSHA) => {
      adjustments[listingIdSHA] = appliedAdjustments[listingIdSHA] || [];
      return adjustments;
    }, {});
    const adjustmentFeedback = agentInputs.adjustmentFeedback || {};
    mergedInitialState.ai.feedback = Object.keys(adjustmentFeedback).reduce<
      Record<string, AdjustmentFeedback>
    >((feedback, listingIdSHA) => {
      feedback[listingIdSHA] = adjustmentFeedback[listingIdSHA];
      return feedback;
    }, {});
    if (agentInputs?.selectListingAttributes) {
      // Add valueToDisplay so on initial CMA creation, the property column fields have an initial way to
      // populate the data to display on the Analyze page
      const subjectProperty = agentInputs?.subjectProperty || {};
      const comps = mergedInitialState?.comps?.compListings;
      const propertyFields = getCustomizablePropertyFields(
        subjectProperty,
        comps,
      );
      const selectListingAttributes =
        agentInputs.selectListingAttributes as Array<
          CustomListingAttribute & {
            listingFieldSource: DisplayField['listingFieldSource'];
          }
        >;
      // Please check a comment below, until listingAttribute.listingFieldSource is fixed
      mergedInitialState.propertyFields = selectListingAttributes.map(
        listingAttribute => {
          const matchedField = propertyFields.find(field => {
            /*
              Note: 
              Back end fixed a bug in the API https://github.com/UrbanCompass/urbancompass/pull/100040,
              before the fix, the listingAttribute.listingFieldSource data was not recorded,
              so the logic below is to make sure we are "backward" compatible for the old data 
              that were missing listingAttribute.listingFieldSource
              */
            const isListingFieldSourceAvailable =
              listingAttribute.listingFieldSource !== undefined;
            return isListingFieldSourceAvailable
              ? field.processedListingPath ===
                  listingAttribute.processedListingPath &&
                  field.listingFieldSource?.source ===
                    listingAttribute.listingFieldSource?.source
              : field.processedListingPath ===
                  listingAttribute.processedListingPath;
          });
          const valueToDisplay = matchedField?.valueToDisplay;
          const isAlwaysSelected = matchedField?.isAlwaysSelected;
          const mcKey = matchedField?.mcKey;
          const listingFieldSource = matchedField?.listingFieldSource;
          const isSubject = matchedField?.isSubject;
          const isCustomField = matchedField?.isCustomField;
          if (
            !listingAttribute.displayFieldName &&
            typeof matchedField?.displayFieldName === 'string'
          ) {
            listingAttribute.displayFieldName = matchedField.displayFieldName;
          }
          return {
            ...listingAttribute,
            ...matchedField,
            valueToDisplay,
            isAlwaysSelected: !!isAlwaysSelected,
            ...(mcKey && {mcKey}),
            ...(listingFieldSource && {listingFieldSource}),
            ...(isSubject && {isSubject}),
            ...(isCustomField && {isCustomField}),
          } as PropertyField;
        },
      );
    }
    const presentationalSettings = agentInputs?.presentationalSettings;
    if (presentationalSettings) {
      if (presentationalSettings?.visibleSections) {
        mergedInitialState.previewSettings.presentationalSettings.visibleSections =
          presentationalSettings.visibleSections;
      }
      if (presentationalSettings?.hiddenSections) {
        mergedInitialState.previewSettings.presentationalSettings.hiddenSections =
          presentationalSettings.hiddenSections;
      }
    }
  }
  mergedInitialState.cma.isSaving = false;
  mergedInitialState.cma.hasUnsavedChanges = false;

  return mergedInitialState;
};

export const orderCompsBySortOrder = (
  comps: ProcessedListing[] = [],
  sortPref?: CompsOrder,
) => {
  switch (sortPref) {
    case CompsOrder.MANUAL: {
      return comps;
    }
    case CompsOrder.SALE_STATUS: {
      const newCompsArr = [...comps];
      newCompsArr.sort(
        ({localizedStatus: first = ''}, {localizedStatus: second = ''}) => {
          const firstStatus =
            sortingListingStatusMap[first.toLowerCase()] ||
            mainListingStatuses.OTHER;
          const secondStatus =
            sortingListingStatusMap[second.toLowerCase()] ||
            mainListingStatuses.OTHER;
          return (
            compsSortOrder.indexOf(firstStatus) -
            compsSortOrder.indexOf(secondStatus)
          );
        },
      );
      return newCompsArr;
    }
    default: {
      return comps;
    }
  }
};

export const combineCustomListings = (
  compListings: ProcessedListing[] = [],
  customListings: ProcessedListing[] = [],
  compIds: string[] = [],
) => {
  const customListingMap = customListings.reduce<
    Record<string, ProcessedListing>
  >((map, customListing) => {
    if (customListing.listingIdSHA) {
      return {...map, [customListing.listingIdSHA]: customListing};
    }
    return map;
  }, {});

  const compListingMap = compListings.reduce<Record<string, ListingWithAgent>>(
    (map, compListing) => {
      if (compListing.listingIdSHA) {
        return {...map, [compListing.listingIdSHA]: compListing};
      }
      return map;
    },
    {},
  );

  return compIds.reduce<ProcessedListing[]>((listings, compId) => {
    if (compId.startsWith(CUSTOM_LISTING_PREFIX)) {
      listings.push(customListingMap[compId]);
    } else {
      listings.push(compListingMap[compId]);
    }
    return listings;
  }, []);
};

export const initialCma = (state: IStoreState = defaultState): Cma => {
  return {
    agentInputs: {
      appliedAdjustments: state.ai.appliedAdjustments,
      assets: state.subjectAssets.assets,
      selectListingAttributes: state.propertyFields as CustomListingAttribute[],
      presentationalSettings: state.previewSettings.presentationalSettings,
      compSearchQuery: state.searchQuery as SearchQuery,
    },
  };
};
