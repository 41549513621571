import React, {createContext, useCallback, useContext} from 'react';

import getDebouncedDispatch from './getDebouncedDispatch';
import {reducer} from './store';
import useEnhancedReducer from './useEnhancedReducer';
import IStoreState, {Dispatch} from './types/Store';
import {ActionWithGenericPayload} from './ducks/types/ActionWithGenericPayload';

export type StateContextValue = [
  IStoreState,
  Dispatch,
  () => IStoreState,
  (debounceMs: number) => (action: ActionWithGenericPayload) => void,
];

export const StateContext = createContext<StateContextValue>(
  [] as unknown as StateContextValue,
);

type StateProviderProps = {
  children: React.ReactNode;
  initialState: IStoreState;
};

export const StateProvider = ({initialState, children}: StateProviderProps) => {
  const [state, dispatch, getState] = useEnhancedReducer(reducer, initialState);

  const debouncedDispatchCallback = useCallback(
    getDebouncedDispatch(dispatch),
    [dispatch],
  );

  return (
    <StateContext.Provider
      value={[state, dispatch, getState, debouncedDispatchCallback]}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);
