import produce from 'immer';

import {createReducer} from '../createReducer';
import IPropertyEditedFields from './types/PropertyEditedFields';

export const propertyEditedFieldsInitialState: IPropertyEditedFields = {
  // save the fields' name edited by agents for tracking in Step 1
  // all fields' name in V1:
  // 1. automatically
  //  - propertyTypes, bedrooms, bathrooms, sizLot, sizeInterior,
  //  - coverPhoto, prettyAddress
  // 2. manually
  //  - based on above fields' name
  //  - unitNumber, zipCode
  // all fields' name in V2:
  // 1. automatically
  //  - propertyTypes, bedrooms, bathrooms, sizLot, lotSizeUnit,
  //  - sizeInterior, description, hoaFees, hoaFrequency, priceListed,
  //  - priceSold, yearBuilt, coverPhoto, prettyAddress
  // 2. manually
  //  - based on above fields' name
  //  - city, latitude, longitude, neighborhood, unitNumber, zipCode, state, streetNumber
  userEditedFieldsNameList: [],
  // save the property columns' name edited by agents for tracking in Step 3
  // all columns' name:
  // - Bedrooms, Baths, Square Feet, Original Listing Price, Last Asking Price, Closing Price,
  // - HOA Fees, Condo/Co-op Fees, Taxes, DOM - Days on Market, Lot Size, Property Type, Year Built
  userEditedColumnFieldsNameList: [],
  hideAdjustmentsCheckWarning: false,
};

export const PROPERTY_EDITED_FIELDS_ACTION_TYPES = {
  SET_PROPERTY_FIELDS_EDIT: 'SET_PROPERTY_FIELDS_EDIT',
  ADD_COLUMN_FIELD_EDIT: 'ADD_COLUMN_FIELD_EDIT',
  SET_COLUMN_FIELDS_EDIT: 'SET_COLUMN_FIELDS_EDIT',
} as const;

export const setPropertyFieldsEdit = (userEditedFieldsNameList: string[]) => ({
  type: PROPERTY_EDITED_FIELDS_ACTION_TYPES.SET_PROPERTY_FIELDS_EDIT,
  payload: {userEditedFieldsNameList},
});

export const addColumnFieldEdit = (fieldLabel: string) => ({
  type: PROPERTY_EDITED_FIELDS_ACTION_TYPES.ADD_COLUMN_FIELD_EDIT,
  payload: {fieldLabel},
});

export const setColumnFieldsEdit = (
  userEditedColumnFieldsNameList: string[],
) => ({
  type: PROPERTY_EDITED_FIELDS_ACTION_TYPES.SET_COLUMN_FIELDS_EDIT,
  payload: {userEditedColumnFieldsNameList},
});

const setPropertyFieldsEditReducer = (
  state: IPropertyEditedFields,
  payload: {userEditedFieldsNameList: string[]},
) =>
  produce(state, draftState => {
    draftState.userEditedFieldsNameList = payload.userEditedFieldsNameList;
  });

const addColumnFieldEditReducer = (
  state: IPropertyEditedFields,
  payload: {fieldLabel: string},
) =>
  produce(state, draftState => {
    const {fieldLabel} = payload;
    if (fieldLabel) {
      const userEditedColumnFieldsNameList = new Set(
        draftState.userEditedColumnFieldsNameList,
      );
      userEditedColumnFieldsNameList.add(fieldLabel);
      draftState.userEditedColumnFieldsNameList = Array.from(
        userEditedColumnFieldsNameList,
      );
    }
  });

const setColumnFieldsEditReducer = (
  state: IPropertyEditedFields,
  payload: {userEditedColumnFieldsNameList: string[]},
) =>
  produce(state, draftState => {
    draftState.userEditedColumnFieldsNameList =
      payload.userEditedColumnFieldsNameList;
  });

export default createReducer<IPropertyEditedFields>({
  [PROPERTY_EDITED_FIELDS_ACTION_TYPES.SET_PROPERTY_FIELDS_EDIT]:
    setPropertyFieldsEditReducer,
  [PROPERTY_EDITED_FIELDS_ACTION_TYPES.ADD_COLUMN_FIELD_EDIT]:
    addColumnFieldEditReducer,
  [PROPERTY_EDITED_FIELDS_ACTION_TYPES.SET_COLUMN_FIELDS_EDIT]:
    setColumnFieldsEditReducer,
});
