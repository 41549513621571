import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';
import {LineItem} from '@uc/thrift2npme/dist/apex/apex_service';
import {formatPrice} from '@uc/format';

import {
  GetAmountParam,
  NetItem,
  NetsheetsLineItemNumber,
  NetsheetsMembers,
  Value,
} from '@/types/netsheet';

const EMPTY = 'N/A';

export const commissions = [
  'Buyer agent commission',
  'Seller agent commission',
];

export const commissionsNameMap: Record<string, string> = {
  'Buyer agent commission': 'Agent commissions (buyer)',
  'Seller agent commission': 'Agent commissions (seller)',
};

// remove undefined items
export const merge = (
  unitNetSheetsLineList: NetsheetsMembers[],
): NetSheetsLineItem[] =>
  unitNetSheetsLineList
    .filter((item): item is NetSheetsLineItem | NetSheetsLineItem[] => !!item)
    .flat();

export function fillValue(
  lineItem?: NetSheetsLineItem,
): NetsheetsLineItemNumber {
  if (lineItem) {
    const {minValue, maxValue} = lineItem;
    const max = maxValue || minValue || 0;
    const min = minValue || maxValue || 0;
    return {...lineItem, minValue: Number(min), maxValue: Number(max)};
  }

  return {
    minValue: 0,
    maxValue: 0,
  };
}

export function valueToString(
  lineItem: NetsheetsLineItemNumber,
): NetSheetsLineItem {
  const {minValue, maxValue} = lineItem;

  return {
    ...lineItem,
    minValue: minValue.toString(),
    maxValue: maxValue.toString(),
  };
}

// Convert percentages to values
export function convertPercentToAmount(
  netSheetsLineItems: NetSheetsLineItem[],
  salesPrice?: NetSheetsLineItem,
): NetsheetsLineItemNumber[] {
  const salesPriceFillValue = fillValue(salesPrice);

  return netSheetsLineItems
    .map(fillValue)
    .map(item => {
      if (item.isPercentage) {
        item.minValue = Math.round(
          (salesPriceFillValue.minValue * item.minValue) / 100,
        );
        item.maxValue = Math.round(
          (salesPriceFillValue.maxValue * item.maxValue) / 100,
        );
      }
      return item;
    })
    .filter(v => !!v.name);
}

export function getNetEquity(
  salesPrice: NetsheetsLineItemNumber,
  mortgage: NetsheetsLineItemNumber,
): Value {
  const minValue = salesPrice.minValue - mortgage.minValue;
  const maxValue = salesPrice.maxValue - mortgage.maxValue;

  return {
    minValue: minValue || maxValue,
    maxValue: maxValue || minValue,
  };
}

export function getSubtotal(
  netSheetsLineItems: NetsheetsLineItemNumber[],
): NetsheetsLineItemNumber {
  return netSheetsLineItems.reduce(
    ({minValue, maxValue}, item) => ({
      minValue: minValue + item.minValue,
      maxValue: maxValue + item.maxValue,
    }),
    {
      minValue: 0,
      maxValue: 0,
    },
  );
}

export const getAmount = (param: GetAmountParam): string[] => {
  const {amount1, amount2, asterisk, percent1, percent2} = param;
  const asteriskStr = asterisk ? '*' : '';
  const percent1Str = percent1 ? ` (${percent1})` : '';
  const percent2Str = percent2 ? ` (${percent2})` : '';
  if (amount1 === undefined && amount2 === undefined) {
    return [EMPTY];
  } else if (amount1 === undefined) {
    return [`${formatPrice(amount2 || '')}${percent2Str}${asteriskStr}`];
  } else if (amount2 === undefined) {
    return [`${formatPrice(amount1 || '')}${percent1Str}${asteriskStr}`];
  } else {
    return [
      `${formatPrice(amount1 || '')}${percent1Str}`,
      `${formatPrice(amount2 || '')}${percent2Str}${asteriskStr}`,
    ];
  }
};

export const matchMinMax = (
  minItems?: LineItem[],
  maxItems?: LineItem[],
  salePrice?: (string | undefined)[],
): NetItem[] => {
  const items = new Map();
  if (minItems)
    for (const minItem of minItems) {
      const min: NetItem = {
        title: minItem.name ?? '',
        minValue: minItem.amount,
        description: minItem.description,
      };
      if (salePrice && salePrice[0] && minItem.amount) {
        min.minPercent = getPercent(minItem.amount, salePrice[0]);
      }
      items.set(minItem.name, min);
    }

  if (maxItems)
    for (const maxItem of maxItems) {
      const max: NetItem = Object.assign({}, items.get(maxItem.name), {
        title: maxItem.name,
        maxValue: maxItem.amount,
        description: maxItem.description,
      });
      if (salePrice && salePrice[1] && maxItem.amount) {
        max.maxPercent = getPercent(maxItem.amount, salePrice[1]);
      }
      items.set(maxItem.name, max);
    }

  return [...items.values()];
};

export const getPercent = (amount: string, salePrice: string): string => {
  const percent = (parseFloat(amount) / parseFloat(salePrice)) * 100;
  if (percent !== Math.trunc(percent)) {
    return `${percent.toFixed(1)}%`;
  }
  return `${percent}%`;
};
