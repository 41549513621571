import React, {useMemo, VFC} from 'react';

import {genericNetSheetsDataFilter} from '@/utils/netsheets/genericNetSheetsDataFilter';
import {NetsheetGenericPage} from './NetsheetGenericPage';
import {NetsheetProps} from '@/types/netsheet';

export const NetsheetGenericSection: VFC<NetsheetProps> = ({
  generic = {},
  isPreview,
}) => {
  // TODO: replace these logics with real data process
  const {
    salesPrice,
    mortgage,
    predefinedFees = [],
    additionalFees = [],
  } = generic;

  const {salesPriceAndMortgage, subTotal, total} = useMemo(
    () =>
      genericNetSheetsDataFilter({
        salesPrice,
        mortgage,
        predefinedFees,
        additionalFees,
      }),
    [salesPrice, mortgage, predefinedFees, additionalFees],
  );

  if (!salesPriceAndMortgage.length) {
    return null;
  }

  return (
    <NetsheetGenericPage
      total={total}
      subTotal={subTotal}
      isPreview={isPreview}
      author=""
      date=""
      layouts={[]}
    />
  );
};
