import React from 'react';
import styled from '@emotion/styled';

import {font, spacing, color} from '@uc/cx-design-tokens';

import {NetsheetAmount} from './NetsheetAmount';

const Wrapper = styled.div`
  font-family: ${font.familySans};
  padding: ${spacing['1x']} 0;
  margin-bottom: ${spacing['half']};
  border-bottom: 1px solid ${color.grey30};
  &:last-child {
    border-bottom: none;
  }
  &:nth-last-of-type(2) {
    border-color: ${color.grey60};
  }
  &:last-child [data-tn='value'] {
    font-weight: ${font.weightMedium};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
`;

const Title = styled.div`
  color: ${color.text};
  font-weight: ${font.weightMedium};
  font-size: ${font.sizeBody2};
  line-height: ${font.lineHeightBody};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Value = styled.div`
  color: ${color.grey100};
  font-size: ${font.sizeBody1};
  line-height: ${font.lineHeightBody};
  font-weight: ${font.weightMedium};
`;

const Description = styled.div`
  font-family: ${font.familySans};
  color: ${color.grey80};
  font-size: ${font.sizeCaption1};
  line-height: ${font.lineHeightCompact};
`;

export type Props = {
  title?: string;
  value?: string[];
  description?: string[];
};
export const NetsheetLineItem: React.FC<Props> = ({
  title,
  value,
  description,
}: Props) => {
  return (
    <Wrapper>
      <Row>
        <Title>{title}</Title>
        <Value data-tn="value">
          <NetsheetAmount amount={value} />
        </Value>
      </Row>
      {description &&
        description.map(d => <Description key={d}>{d}</Description>)}
    </Wrapper>
  );
};
