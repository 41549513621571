import React from 'react';
import styled from '@emotion/styled';

import {font, spacing, mq} from '@uc/cx-design-tokens';
import {
  CmaOutputSection,
  ThankYouSection as ThankYouSectionType,
} from '@uc/thrift2npme/dist/cma/cma_models';

import {SectionTitle} from '@/components/SectionTitle';
import {AgentCardList} from '@/components/AgentCard/AgentCardList';
import {getAgentInfoFromPerson} from '@/utils/display-helpers';
import {EditableText} from '@/components/EditableText';
import {CoverSummaryLogo} from '@/pages/PreviewPage/components/Preview/components/CoverSummaryLogo';
import type {SectionProps} from '@/pages/PreviewPage/types';

const MAX_TITLE_LENGTH = 50;
const MAX_NOTES_LENGTH = 160;
export const DEFAULT_TITLE = 'I would love to work with you!';
export const DEFAULT_NOTES =
  'Thank you for the opportunity to list your home - when you’re ready, we’ll send over a marketing plan and contract for you to sign. Talk soon!';

const Wrapper = styled.div<{isPreview?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing['4x']} 0;
  margin: 0 auto;
  max-width: 80%;
  ${(props: {isPreview?: boolean}) => props.isPreview && 'min-height: 50vh;'};
  @media ${mq.maxWidthMobileLandscape} {
    padding: 0 0 ${spacing['4x']} 0;
    max-width: calc(100% - 38px);
  }
`;

const Title = styled(EditableText)`
  display: block;
  padding: 0 ${spacing['3x']};
  max-height: 120px;
  overflow: hidden;
  line-height: 120%;
  font-size: ${font.sizeTitle1};
  font-family: ${font.familySerif};
  font-weight: ${font.weightRegular};
  text-align: center;
  word-break: break-word;
  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeTitle2};
    max-height: 150px;
    padding: 0;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: ${spacing['2x']};
`;

const Notes = styled(EditableText)`
  display: block;
  font-family: ${font.familySans};
  font-size: ${font.sizeBody1};
  text-align: center;
  margin-bottom: ${spacing['2x']};
  max-height: 120px;
  overflow: hidden;
`;

export const ThankYouSection: React.FC<SectionProps> = ({
  cma = {},
  sectionIndex = 0,
  isPreview,
  setSectionDetails = () => {},
}) => {
  const {agentInputs, owner} = cma;
  const {presentationalSettings} = agentInputs || {};
  const {visibleSections = []} = presentationalSettings || {};

  const thankYouSection = visibleSections.find(
    section => section.sectionType === CmaOutputSection.CONTACT,
  );
  const {
    title = '',
    notes = '',
    showTitle,
    showNotes,
  } = thankYouSection?.thankYouSection || {
    showTitle: true,
    showNotes: true,
    title: DEFAULT_TITLE,
    notes: DEFAULT_NOTES,
  };
  const coverSection = visibleSections.find(
    section => section.sectionType === CmaOutputSection.COVER,
  );
  const {agentsInfo, logoLink, showLogo} = coverSection?.coverSection || {};

  const agentList = Array.isArray(agentsInfo)
    ? agentsInfo
    : owner
      ? [getAgentInfoFromPerson(owner)]
      : [];

  const updateSectionInfo = (info: ThankYouSectionType) => {
    setSectionDetails('thankYouSection', {
      ...visibleSections[sectionIndex]?.thankYouSection,
      ...info,
    });
  };
  return (
    <>
      {!isPreview && <SectionTitle title="THANK YOU" />}
      <Wrapper isPreview={isPreview}>
        <CoverSummaryLogo
          isPreview={isPreview}
          logoLink={logoLink}
          showLogo={showLogo}
        />
        {showTitle ? (
          <TitleWrapper>
            <Title
              editable={false}
              onTextChange={title => {
                updateSectionInfo({title, notes, showTitle, showNotes});
              }}
              lengthLimit={MAX_TITLE_LENGTH}
              value={title || DEFAULT_TITLE}
              isMultiLine={true}
              shouldRestoreInitialValueWhenCleared={false}
            />
          </TitleWrapper>
        ) : null}
        {showNotes ? (
          <div>
            <Notes
              editable={false}
              onTextChange={notes => {
                updateSectionInfo({title, notes, showTitle, showNotes});
              }}
              lengthLimit={MAX_NOTES_LENGTH}
              value={notes || DEFAULT_NOTES}
              isMultiLine={true}
              shouldRestoreInitialValueWhenCleared={false}
            />
          </div>
        ) : null}
        <AgentCardList agentList={agentList} />
      </Wrapper>
    </>
  );
};
