import {RoomType} from '@uc/thrift2npme/dist/image/ai';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

/*
 * Input: array of listings. Each listing has media array. Each media item has roomTypes array.
 * Output: Set() of all roomTypes for all media items for all listings.
 */
export const getAllRoomTypes = (
  listings: ProcessedListing[],
): Set<RoomType> => {
  return new Set(
    listings.reduce<RoomType[]>((arr, listing) => {
      listing?.media?.forEach(mediaItem => {
        if (mediaItem?.roomTypes) {
          arr = arr.concat(mediaItem.roomTypes);
        }
      });
      return arr;
    }, []),
  );
};
