import produce from 'immer';

import {createReducer} from '../createReducer';
import type {IMaps} from './types/Maps';

export const mapsInitialState: IMaps = {
  hasLoaded: false,
};

export const MAPS_ACTION_TYPES = {
  MAPS_LOAD_SUCCESS: 'MAPS_LOAD_SUCCESS',
} as const;

export const setMapsLoadSuccess = () => ({
  type: MAPS_ACTION_TYPES.MAPS_LOAD_SUCCESS,
});

export const mapsReducer = createReducer<IMaps>({
  [MAPS_ACTION_TYPES.MAPS_LOAD_SUCCESS]: state =>
    produce(state, draftState => {
      draftState.hasLoaded = true;
    }),
});
