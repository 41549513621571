import aiReducer, {AI_ACTION_TYPES, aiInitalState} from './ducks/ai';
import cmaReducer, {CMA_ACTION_TYPES, cmaInitalState} from './ducks/cma';
import cmaTitleChangeReducer, {
  CMA_TITLE_CHANGE_ACTION_TYPES,
  isCmaTitleFromAddressInitialState,
} from './ducks/cmaTitleChange';
import cmaUpdatesReducer, {
  CMA_UPDATES_ACTION_TYPES,
  cmaUpdatesInitalState,
} from './ducks/cmaUpdates';
import compsReducer, {
  COMPS_ACTION_TYPES,
  compsInitalState,
} from './ducks/comps';
import estimatePriceReducer, {
  ESTIMATE_PRICE_ACTION_TYPES,
  estimatePriceInitalState,
} from './ducks/estimatePrice';
import hasAutoTriggeredMlsPanelReducer, {
  HAS_AUTO_TRIGGERED_MLS_PANEL_ACTION_TYPES,
  hasAutoTriggeredMlsPanelInitialState,
} from './ducks/hasAutoTriggeredMlsPanel';
import setMapSearchPropertiesReducer, {
  MAP_SEARCH_PROPERTIES_ACTION_TYPES,
  mapSearchPropertiesInitalState,
} from './ducks/mapSearchProperties';
import modalsReducer, {
  MODALS_ACTION_TYPES,
  modalsInitialState,
} from './ducks/modals';
import presentationalSettingsReducer, {
  PRESENTATIONAL_SETTINGS_ACTION_TYPES,
  presentationalSettingsInitialState,
} from './ducks/presentationalSettings';
import propertyEditedFieldsReducer, {
  PROPERTY_EDITED_FIELDS_ACTION_TYPES,
  propertyEditedFieldsInitialState,
} from './ducks/propertyEditedFields';
import propertyFieldsReducer, {
  PROPERTY_FIELDS_ACTION_TYPES,
  propertyFieldsInitialState,
} from './ducks/propertyFields';
import publishReducer, {
  PUBLISH_ACTION_TYPES,
  publishInitalState,
} from './ducks/publish';
import searchQueryReducer, {
  SEARCH_QUERY_ACTION_TYPES,
  searchQueryInitialState,
} from './ducks/searchQuery';
import searchQuerySourceReducer, {
  SEARCH_QUERY_SOURCE_ACTION_TYPES,
} from './ducks/searchQuerySource';
import searchResultsReducer, {
  SEARCH_RESULTS_ACTION_TYPES,
  searchResultsInitialState,
} from './ducks/searchResults';
import shouldQueryCompsBuilderReducer, {
  SHOULD_QUERY_COMPS_BUILDER_ACTION_TYPES,
} from './ducks/shouldQueryCompsBuilder';
import subjectAssetsReducer, {
  SUBJECT_ASSETS_ACTION_TYPES,
  subjectAssetsInitialState,
} from './ducks/subjectAssets';
import {ActionWithGenericPayload} from './ducks/types/ActionWithGenericPayload';
import lensSelectedImageItemsReducer, {
  LENS_SELECTION_ACTION_TYPES,
  lensSelectedImageItemsInitalState,
} from './lens/lensSelectedImageItems';
import {mapsReducer, mapsInitialState, MAPS_ACTION_TYPES} from './ducks/maps';
import {
  dealIdReducer,
  dealIdInitialState,
  DEAL_ID_ACTION_TYPES,
} from './ducks/dealId';
import {
  allDealIdsReducer,
  allDealIdsInitialState,
  ALL_DEAL_IDS_ACTION_TYPES,
} from './ducks/allDealIds';
import IStoreState from './types/Store';
import {
  partnerConfigReducer,
  partnerConfigInitialState,
  PARTNER_CONFIG_ACTION_TYPES,
} from './ducks/partnerConfig';

export type statePropType = Record<string, never>;

// We can piece the default state together from initial states from sub states so those
// substates coexist with their respective actions and reducers.
export const defaultState: IStoreState = {
  ai: aiInitalState,
  cma: cmaInitalState,
  cmaUpdates: cmaUpdatesInitalState,
  comps: compsInitalState,
  hasAutoTriggeredMlsPanel: hasAutoTriggeredMlsPanelInitialState,
  lensSelectedImageItems: lensSelectedImageItemsInitalState,
  mapSearchProperties: mapSearchPropertiesInitalState,
  modals: modalsInitialState,
  previewSettings: presentationalSettingsInitialState,
  propertyFields: propertyFieldsInitialState,
  propertyEditedFields: propertyEditedFieldsInitialState,
  publish: publishInitalState,
  searchQuery: searchQueryInitialState,
  searchQuerySource: null,
  searchResults: searchResultsInitialState,
  shouldQueryCompsBuilder: true,
  subjectAssets: subjectAssetsInitialState,
  isCmaTitleFromAddress: isCmaTitleFromAddressInitialState,
  estimatePrice: estimatePriceInitalState,
  maps: mapsInitialState,
  dealId: dealIdInitialState,
  allDealIds: allDealIdsInitialState,
  partnerConfig: partnerConfigInitialState,
};

const ALL_ACTIONS = {
  ...AI_ACTION_TYPES,
  ...CMA_ACTION_TYPES,
  ...CMA_TITLE_CHANGE_ACTION_TYPES,
  ...CMA_UPDATES_ACTION_TYPES,
  ...COMPS_ACTION_TYPES,
  ...HAS_AUTO_TRIGGERED_MLS_PANEL_ACTION_TYPES,
  ...LENS_SELECTION_ACTION_TYPES,
  ...MAP_SEARCH_PROPERTIES_ACTION_TYPES,
  ...MODALS_ACTION_TYPES,
  ...PRESENTATIONAL_SETTINGS_ACTION_TYPES,
  ...PROPERTY_FIELDS_ACTION_TYPES,
  ...PROPERTY_EDITED_FIELDS_ACTION_TYPES,
  ...PUBLISH_ACTION_TYPES,
  ...SEARCH_QUERY_ACTION_TYPES,
  ...SEARCH_QUERY_SOURCE_ACTION_TYPES,
  ...SEARCH_RESULTS_ACTION_TYPES,
  ...SHOULD_QUERY_COMPS_BUILDER_ACTION_TYPES,
  ...SUBJECT_ASSETS_ACTION_TYPES,
  ...ESTIMATE_PRICE_ACTION_TYPES,
  ...MAPS_ACTION_TYPES,
  ...DEAL_ID_ACTION_TYPES,
  ...ALL_DEAL_IDS_ACTION_TYPES,
  ...PARTNER_CONFIG_ACTION_TYPES,
};

// Root reducer.
export const reducer = (
  state = defaultState,
  // eslint-disable-next-line @typescript-eslint/ban-types
  action: Exclude<ActionWithGenericPayload, Function>,
) => {
  const {type} = action;
  if (!(type in ALL_ACTIONS)) {
    throw `action type not found: ${type}`;
  }

  const updatedState: IStoreState = {
    ai: aiReducer(state.ai, action),
    cma: cmaReducer(state.cma, action),
    isCmaTitleFromAddress: cmaTitleChangeReducer(
      state.isCmaTitleFromAddress,
      action,
    ),
    comps: compsReducer(state.comps, action),
    searchQuery: searchQueryReducer(state.searchQuery, action),
    hasAutoTriggeredMlsPanel: hasAutoTriggeredMlsPanelReducer(
      state.hasAutoTriggeredMlsPanel,
      action,
    ),
    lensSelectedImageItems: lensSelectedImageItemsReducer(
      state.lensSelectedImageItems,
      action,
    ),
    mapSearchProperties: setMapSearchPropertiesReducer(
      state.mapSearchProperties,
      action,
    ),
    modals: modalsReducer(state.modals, action),
    previewSettings: presentationalSettingsReducer(
      state.previewSettings,
      action,
    ),
    propertyFields: propertyFieldsReducer(state.propertyFields, action),
    propertyEditedFields: propertyEditedFieldsReducer(
      state.propertyEditedFields,
      action,
    ),
    publish: publishReducer(state.publish, action),
    searchResults: searchResultsReducer(state.searchResults, action),
    shouldQueryCompsBuilder: shouldQueryCompsBuilderReducer(
      state.shouldQueryCompsBuilder,
      action,
    ),
    searchQuerySource: searchQuerySourceReducer(
      state.searchQuerySource,
      action,
    ),
    subjectAssets: subjectAssetsReducer(state.subjectAssets, action),
    estimatePrice: estimatePriceReducer(state.estimatePrice, action),
    cmaUpdates: cmaUpdatesReducer(state.cmaUpdates, action),
    maps: mapsReducer(state.maps, action),
    dealId: dealIdReducer(state.dealId, action),
    allDealIds: allDealIdsReducer(state.allDealIds, action),
    partnerConfig: partnerConfigReducer(state.partnerConfig, action),
  };

  return updatedState;
};
