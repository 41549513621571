import React, {FC} from 'react';
import styled from '@emotion/styled';

import {mq} from '@uc/cx-design-tokens';

import {SectionTitle} from '@/components/SectionTitle';
import type {SectionProps} from '@/pages/PreviewPage/types';
import {CompListingsAverage} from '@/components/CompListingsAverage';

type Props = SectionProps & {
  showSectionTitle?: boolean;
};

export const WebListingAveragesSection: FC<Props> = ({
  cma,
  showSectionTitle = false,
}) => {
  const {compListings = [], agentInputs = {}} = cma;

  return (
    <>
      {showSectionTitle && <SectionTitle title="Listing Averages" />}
      <Container>
        <CompListingsAverage
          compListings={compListings}
          agentInputs={agentInputs}
          isForWebView={true}
          showPriceTitle={true}
          showStatusFilter={true}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: var(--cx-spacing-1x) 48px;

  @media ${mq.maxWidthMobileLandscape} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--cx-spacing-2x);
    padding-top: 0;
  }
`;
