import {
  compile,
  match,
  PathFunction,
  pathToRegexp,
  MatchFunction,
} from 'path-to-regexp';

export const patterns = {
  // relative to the hostname
  baseAfterFlow: '/:flowName/flow',
  // relative to the hostname
  base: '/app/cma',
  // relative to the base
  empty: '/',
  paths: '/paths',
  withComps: `/with-comps/:listingIdSHAs+`, // other apps depend on this path, so other apps must be updated if the path name or the parameter names are changed.
  withSubject: `/with-subject/:listingIdSHA`, // other apps depend on this path, so other apps must be updated if the path name or the parameter names are changed.
  subjectSelection: `/edit/:id/v/:version`,
  compsSearch: `/search/:id/v/:version`,
  analyzeResults: `/analyze/:id/v/:version`,
  estimate: `/estimate/:id/v/:version`,
  preview: `/preview/:id/v/:version`,
  publishedCMA: `/published/cma/:id/v/:version`,
  publishedCanvas: `/published/canvas/:canvasId`,
  publishCMA: '/publish_cma',
  netsheetCalculate: '/netsheet/calculate',
  netsheetLeadGeneration: '/netsheet/lead',
  apexGeo: '/apex/operations',
  apexCounties: '/apex/counties',
  // handled by the server only
  newCMA: '/new',
  // place urls that are external to this application below
  listingPresentation:
    '/listing-presentation/view/a/:agentId/s/:subjectId/c/:compIds+',
  marketingCenterFromBrief:
    '/app/marketing-center/editor/@/\\?briefId=:briefId\\&designId=cmareportv3',
  marketingCenterFromCanvas:
    '/app/marketing-center/editor/@/:canvasId\\?designId=cmareportv3',
  cmaHome: '/app/cma-home/',
  cmaHomeWithAddToDealModal: '/app/cma-home/chooseDealForCma/:cmaId',
  // migrate listing-presention route to here
  viewWebBase: '/listing-presentation',
  viewWeb: '/view/cma/:cmaId/v/:version',
  viewWebOld: '/view/cma/:cmaId/a/:agentId',
  businessTrackerFolder: '/app/lab/:dealId/overview',
};

export const anyEditorRoutePattern = [
  patterns.subjectSelection,
  patterns.compsSearch,
  patterns.analyzeResults,
  patterns.estimate,
  patterns.preview,
  patterns.publishedCMA,
];

export interface Path {
  pattern: string;
  toPath: PathFunction;
  regexp: RegExp;
}

const paths = Object.entries(patterns).reduce<
  Record<
    string,
    {
      pattern: string;
      toPath: PathFunction;
      regexp: RegExp;
      match: MatchFunction<Record<string, string>>;
    }
  >
>(
  (memo, [key, pattern]) => ({
    ...memo,
    [key]: {
      pattern,
      toPath: compile(pattern),
      regexp: pathToRegexp(pattern),
      match: match(pattern),
    },
  }),
  {},
);

export default paths;
