export const MAX_ROWS_NUMBER = 12;
export const MAX_LAST_ROWS_NUMBER = 16;

export const ErrorMessage = {
  percentages: 'Must be less than 100.',
  min: 'Must be smaller than maximum',
};

export const commissions = [
  'Buyer agent commission',
  'Seller agent commission',
];

export const commissionsNameMap: Record<string, string> = {
  'Buyer agent commission': 'Agent commissions (buyer)',
  'Seller agent commission': 'Agent commissions (seller)',
};
