import {BBox, ContainerSize} from '@uc-maps/geospatial';
import produce from 'immer';

import {createReducer} from '../createReducer';
import IMapSearchProperties from './types/MapSearchProperties';

export const mapSearchPropertiesInitalState: IMapSearchProperties = {
  lazyLoading: false, // todo: switch to true for max optimization
  showSoldsLayer: false,
  minAvgClusterSize: 7,
  mapContainerPixels: null,
  boundingBox: null,
};

// ACTION TYPES
export const MAP_SEARCH_PROPERTIES_ACTION_TYPES = {
  SET_MAP_CONTAINER_PIXELS: 'SET_MAP_CONTAINER_PIXELS',
  SET_MAP_BOUNDING_BOX: 'SET_MAP_BOUNDING_BOX',
} as const;

// ACTION CREATORS
export const setMapSearchProperties = (mapContainerPixels: {
  mapContainerPixels: ContainerSize;
  boundingBox: BBox | null;
}) => ({
  type: MAP_SEARCH_PROPERTIES_ACTION_TYPES.SET_MAP_CONTAINER_PIXELS,
  payload: mapContainerPixels,
});

// REDUCERS
const setMapSearchPropertiesReducer = (
  state: IMapSearchProperties,
  payload: {
    mapContainerPixels: ContainerSize;
    boundingBox: BBox;
  },
) =>
  produce(state, draftState => {
    draftState.mapContainerPixels = payload.mapContainerPixels;
    draftState.boundingBox = payload.boundingBox;
  });

// COMBINED REDUCER
export default createReducer<IMapSearchProperties>({
  [MAP_SEARCH_PROPERTIES_ACTION_TYPES.SET_MAP_CONTAINER_PIXELS]:
    setMapSearchPropertiesReducer,
});
