import styled from '@emotion/styled';
import React, {FC} from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {
  ALWAYS_ENABLED_VIEW_CONFIG,
  VIEW_CONFIG,
  ViewKeys,
  mapViewKeyToAnalyticTracker,
} from './constants';
import {getAllRoomTypes} from './utils/getAllRoomTypes';

type Props = {
  cmaId: string;
  subjectProperty: ProcessedListing;
  compListings: ProcessedListing[];
  columnView?: string;
  onViewChooserTokenClick?: () => void;
  setColumnView?: (viewKey: ViewKeys) => void;
  isPreview: boolean;
};

export const ViewChooser: FC<Props> = ({
  cmaId,
  subjectProperty,
  compListings,
  columnView,
  onViewChooserTokenClick,
  setColumnView,
  isPreview,
}) => {
  const roomTypesKeySet = getAllRoomTypes([...compListings, subjectProperty]);
  const onItemClick = (isEnabled: boolean, viewKey: ViewKeys) => {
    isEnabled && setColumnView?.(viewKey);
    onViewChooserTokenClick?.();

    const analyticTracker = mapViewKeyToAnalyticTracker[viewKey];
    analyticTracker(cmaId);
  };

  const viewConfigs = isPreview ? ALWAYS_ENABLED_VIEW_CONFIG : VIEW_CONFIG;

  return (
    <ViewChooserContainer>
      {viewConfigs.map(item => {
        const isActive = item.viewKey === columnView;
        const isEnabled =
          item.alwaysEnabled ||
          (typeof item.roomTypeKey === 'number'
            ? roomTypesKeySet.has(item.roomTypeKey)
            : false);
        return isEnabled ? (
          <ViewChooserItem
            data-tn={`viewChooserItem_${item.text}`}
            key={item.viewKey}
            isActive={isActive}
            isEnabled={isEnabled}
            onClick={() => onItemClick(isEnabled, item.viewKey)}
          >
            {item.svg}
            <ViewChooserItemLabel data-tn="viewChooserItemLabel">
              {item.text}
            </ViewChooserItemLabel>
          </ViewChooserItem>
        ) : null;
      })}
    </ViewChooserContainer>
  );
};

const ViewChooserContainer = styled.div`
  background-color: ${color.background};
  border: 1px solid ${color.black};
  border-radius: 18px;
  bottom: 30px;
  height: 34px;
  line-height: 32px;
  position: sticky;
  margin: 190px auto -46px;
  opacity: 0;
  transition: opacity 0.5s;
  opacity: 1;
  width: fit-content;
  @media ${mq.maxWidthMobileLandscape} {
    display: none;
  }
`;

const ViewChooserItem = styled.div<{isActive: boolean; isEnabled: boolean}>`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  border-left: 1px solid ${color.black};
  white-space: nowrap;
  padding: 0 ${spacing['2x']};
  font-size: ${font.sizeBody1};
  font-weight: ${font.weightMedium};
  &:first-of-type {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    border-left: none;
  }
  &:last-child {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
  }
  ${({isActive}) =>
    isActive &&
    `
    background: ${color.backgroundDark};
    color: ${color.white};
    fill: ${color.white};
  `}
  ${({isEnabled}) =>
    isEnabled
      ? ''
      : `
    background: ${color.grey40};
    color: ${color.grey70};
    fill: ${color.grey70};
  `}
  svg {
    vertical-align: middle;
    margin-right: ${spacing['1x']};
  }
`;

const ViewChooserItemLabel = styled.span`
  vertical-align: middle;
`;
