import produce from 'immer';

import setStoreField from '../setStoreField';
import {ISetCmaNetsheetsInputPayload} from './setCmaNetsheetsInput';
import {ICma} from '../types/Cma';

const setCmaNetsheetsInputReducer = (
  state: ICma,
  payload: ISetCmaNetsheetsInputPayload,
) =>
  produce(state, draftState => {
    setStoreField(
      draftState.cma.agentInputs?.netsheets,
      'input',
      payload.input,
    );

    setStoreField(
      draftState.cma.agentInputs?.netsheets,
      'inputModifiedMs',
      payload.inputModifiedMs,
    );
  });

export default setCmaNetsheetsInputReducer;
