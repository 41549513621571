import React, {type ComponentProps, type ComponentType} from 'react';
import {useIntersection} from '@shopify/react-intersection-observer';

import type {Cma, AgentInputs} from '@uc/thrift2npme/dist/cma/cma_models';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import type {Media} from '@uc/thrift2npme/dist/translation_common/media';
import {modal as customizeFieldsModal} from '@uc/modal--customize-fields.react';
import type {DisplayField} from '@uc/cma-adapters';
import {OptyState as Opty} from '@uc/opty';

import {CoverSection} from '@/components/CoverSection';
import {SideBySideComparison} from '@/components/SideBySideComparison';
import type PropertyField from '@/context/ducks/types/PropertyField';

export type DisplayStatusValue = {
  label: string;
  color: string;
  textColor: string;
  sortOrder: number;
  trendable?: boolean;
  footnoteSymbol?: string;
  footnote?: string;
  key?: string;
};

type SectionComponent =
  | ComponentType
  | typeof CoverSection
  | ComponentType<
      Pick<
        ComponentProps<typeof SideBySideComparison>,
        'sectionRef' | 'viewPageRef'
      >
    >;

export interface SectionConfig {
  intersection?: ReturnType<typeof useIntersection>;
  props: {
    key: string;
    name?: string;
    collapseBorder?: boolean;
    SectionComponent?: SectionComponent;
    margin?: string;
    sectionIndex?: number;
  };
}

export type SetFlowPropTypes = (props?: {
  label?: string;
  valid?: boolean;
  submitting?: boolean;
  onNext?: {fn: () => void};
}) => void;

export type CustomizeFieldsModalArgs = Parameters<
  typeof customizeFieldsModal
>[1];

export type PublishCmaRequest = {
  cma: Omit<Cma, 'agentInputs'> & {
    agentInputs: Omit<AgentInputs, 'selectListingAttributes'> & {
      selectListingAttributes: Array<
        Omit<PropertyField, 'displayFieldName'> & {
          displayFieldName: string;
          overrideValue: Record<string, string>;
        }
      >;
    };
  };
};

export type PhotoImageClickType = ({
  listing,
  media,
  mediaIndex,
}: {
  listing: ProcessedListing;
  media: Media[];
  mediaIndex: number;
}) => void;

export type AllDisplayFieldsType = Record<
  string,
  Pick<
    DisplayField,
    'displayFieldName' | 'valueToDisplay' | 'processedListingPath'
  >
>;

export type ListingPhotoClickType = ({
  isSubject,
  index,
}: {
  isSubject: boolean;
  index?: number;
}) => void;

type CompsListingType = {
  originalIndex: number;
  listing: ProcessedListing;
}[];

export type CompsGroupsType = [string, CompsListingType][];

const defaultState = {features: {}};

export const OptimizelyStateContext = React.createContext<Opty>(defaultState);
