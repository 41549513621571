import styled from '@emotion/styled';
import React from 'react';

import {color, font, spacing} from '@uc/cx-design-tokens';

type Size = 'md' | 'lg';

interface Props {
  logoLink?: string;
  showLogo?: boolean;
  isPreview?: boolean;
  size?: Size;
}

const mapSizeToValue: {[key in Size]: string} = {
  md: '96px',
  lg: '200px',
};

const SummaryLogo = styled.div<{size: Size; logoLink?: string}>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-height: ${({size}) => mapSizeToValue[size]};
  max-width: ${({size}) => mapSizeToValue[size]};
  width: auto;
  height: auto;
  color: ${color.textSubtle};
  font-size: ${font.sizeCaption1};
  margin-bottom: ${spacing['3x']};
  overflow: hidden;

  ${({logoLink}) =>
    !logoLink &&
    `
    border: 1px solid ${color.border};
    background: ${color.backgroundAccent};
  `}
`;

const CoverLogo = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
`;
CoverLogo.displayName = 'CoverLogo';

const CoverLogoPlaceholder = styled.div<{size: Size}>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: ${({size}) => mapSizeToValue[size]};
  width: ${({size}) => mapSizeToValue[size]};
`;

const ImageSvg = styled.svg`
  fill: ${color.textSubtle};
`;
ImageSvg.displayName = 'ImageSvg';

const Text = styled.div`
  padding-left: ${spacing['half']};
`;

export const CoverSummaryLogo: React.FC<Props> = ({
  logoLink,
  showLogo,
  isPreview,
  size = 'md',
}: Props) => {
  if (!showLogo || (!logoLink && !isPreview)) {
    return null;
  }

  return (
    <SummaryLogo size={size} logoLink={logoLink}>
      {logoLink ? (
        <CoverLogo src={logoLink} alt="cover-logo" />
      ) : isPreview ? (
        <CoverLogoPlaceholder size={size}>
          <ImageSvg
            data-tn="cover-summary-svg"
            className="cx-icon"
            width="24"
            height="24"
          >
            <use xlinkHref="/ucfe-assets/cx-icons/4/cx-icons.cdn.svg#cx-icon-Image_24x24" />
          </ImageSvg>
          <Text>Logo will auto-fill the box</Text>
        </CoverLogoPlaceholder>
      ) : null}
    </SummaryLogo>
  );
};
