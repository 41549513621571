import {ReactElement} from 'react';

import type {Media} from '@uc/thrift2npme/dist/translation_common/media';
import {RoomType} from '@uc/thrift2npme/dist/image/ai';

import {
  trackAllPhotosClicked,
  trackBathsPhotosClicked,
  trackBedsPhotosClicked,
  trackFloorplanClicked,
  trackKitchenPhotosClicked,
  trackListingDetailsClicked,
  trackLivingPhotosClicked,
} from '@/utils/analyticTracks';
import {
  SVG_BATH,
  SVG_BED,
  SVG_KITCHEN,
  SVG_LISTING_DETAILS,
  SVG_LIVING_ROOM,
  SVG_PHOTOS,
} from './svgs';

const AI_TEXT = 'Photos filtered using Compass AI image recognition technology';
const ALL_PHOTOS_TEXT =
  'Click the room buttons at the bottom of the screen to filter images ' +
  'using Compass AI image recognition technology';

export enum ViewKeys {
  LISTING_DETAILS = 'LISTING_DETAILS',
  ALL_PHOTOS = 'ALL_PHOTOS',
  KITCHEN = 'KITCHEN',
  BATHROOMS = 'BATHROOMS',
  BEDROOMS = 'BEDROOMS',
  LIVING_ROOM = 'LIVING_ROOM',
  FLOORPLAN = 'FLOORPLAN',
}

export const ANALYTICS_EVENTS: Record<string, string> = {
  LISTING_DETAILS: 'cma_listing_details_clicked',
  ALL_PHOTOS: 'cma_all_photos_clicked',
  BEDROOMS: 'cma_beds_photos_clicked',
  BATHROOMS: 'cma_baths_photos_clicked',
  KITCHEN: 'cma_kitchen_photos_clicked',
  LIVING_ROOM: 'cma_living_photos_clicked',
  FLOORPLAN: 'cma_floorplan_clicked',
};

// room type key is defined in '@uc/thrift2npme/dist/image/ai';
type ViewConfigType = {
  viewKey: ViewKeys;
  text: string;
  description: string;
  svg: ReactElement;
  alwaysEnabled?: boolean;
  photoFilter?: (mediaItem: Media) => boolean;
  noPhotosLabel?: string;
  header?: string;
  roomTypeKey?: RoomType;
};

export const ALWAYS_ENABLED_VIEW_CONFIG: Array<ViewConfigType> = [
  {
    viewKey: ViewKeys.LISTING_DETAILS,
    text: 'Listing Details',
    description: 'This is the full listing  details view.',
    svg: SVG_LISTING_DETAILS,
    alwaysEnabled: true,
  },
  {
    viewKey: ViewKeys.ALL_PHOTOS,
    text: 'All Photos',
    description: ALL_PHOTOS_TEXT,
    svg: SVG_PHOTOS,
    alwaysEnabled: true,
    photoFilter: () => true,
  },
];
export const VIEW_CONFIG: Array<ViewConfigType> = [
  ...ALWAYS_ENABLED_VIEW_CONFIG,
  {
    viewKey: ViewKeys.BEDROOMS,
    text: 'Beds',
    noPhotosLabel: 'bedroom',
    header: 'Bedrooms',
    description: AI_TEXT,
    svg: SVG_BED,
    roomTypeKey: RoomType.BEDROOM,
    photoFilter: (mediaItem: Media) =>
      mediaItem.roomTypes?.includes(3) ?? false,
  },
  {
    viewKey: ViewKeys.BATHROOMS,
    text: 'Baths',
    noPhotosLabel: 'bathroom',
    header: 'Bathrooms',
    description: AI_TEXT,
    svg: SVG_BATH,
    roomTypeKey: RoomType.BATHROOM,
    photoFilter: (mediaItem: Media) =>
      mediaItem.roomTypes?.includes(2) ?? false,
  },
  {
    viewKey: ViewKeys.LIVING_ROOM,
    text: 'Living',
    noPhotosLabel: 'living room',
    header: 'Living Rooms',
    description: AI_TEXT,
    svg: SVG_LIVING_ROOM,
    roomTypeKey: RoomType.LIVINGROOM,
    photoFilter: (mediaItem: Media) =>
      mediaItem.roomTypes?.includes(6) ?? false,
  },
  {
    viewKey: ViewKeys.KITCHEN,
    text: 'Kitchen',
    noPhotosLabel: 'kitchen',
    header: 'Kitchens',
    description: AI_TEXT,
    svg: SVG_KITCHEN,
    roomTypeKey: RoomType.KITCHEN,
    photoFilter: (mediaItem: Media) =>
      mediaItem.roomTypes?.includes(5) ?? false,
  },
  // floorplan is not defined in '@uc/thrift2npme/dist/image/ai', ai team might need to update this
  // {
  //   viewKey: VIEW_KEYS.FLOORPLAN,
  //   text: 'Floor Plan',
  //   header: 'Floor Plans',
  //   description: AI_TEXT,
  //   svg: SVG_FLOORPLAN,
  //   photoFilter: mediaItem => mediaItem.roomType === 'floorplan',
  // },
];

export const TOGGLE_ADJUSTMENTS_HEIGHT = 32;
export const COMP_COLUMN_WIDTH = 280;
export const COMP_COLUMN_WIDTH_MOBILE = 210;
export const PHOTO_ADDRESS_HEIGHT = 180;
// 34 is the height of Adjustment items
export const ADJUSTMENTS_ITEM_HEIGHT = 34;
// 40 is the height of adjusted price box
export const ADJUSTED_PRICE_BOX_HEIGHT = 40;

export const mapViewKeyToAnalyticTracker: {
  [key in ViewKeys]: (cmaId: string) => void;
} = {
  LISTING_DETAILS: trackListingDetailsClicked,
  ALL_PHOTOS: trackAllPhotosClicked,
  KITCHEN: trackKitchenPhotosClicked,
  BATHROOMS: trackBathsPhotosClicked,
  BEDROOMS: trackBedsPhotosClicked,
  LIVING_ROOM: trackLivingPhotosClicked,
  FLOORPLAN: trackFloorplanClicked,
};
