import styled from '@emotion/styled';
import React, {FC} from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';

import {setNavExpanded} from '../../context/navigation';
import {useStateValue} from '../../context/state';

type Props = {
  activeSectionIndex: number;
  className?: string;
};

export const PageHeader: FC<Props> = ({activeSectionIndex, className}) => {
  const [{agent, isNavExpanded, subjectProperty}, dispatch] = useStateValue();

  return (
    <Wrapper currentPageNumber={activeSectionIndex + 1} className={className}>
      <PageHeaderButton
        onClick={() => dispatch(setNavExpanded(!isNavExpanded))}
      >
        <PageHeaderButtonIcon className="cx-icon" width="16" height="16">
          <use xlinkHref="/ucfe-assets/cx-icons/4/cx-icons.cdn.svg#cx-icon-menu_16x16" />
        </PageHeaderButtonIcon>
      </PageHeaderButton>
      <Agent currentPageNumber={activeSectionIndex + 1}>
        {agent.avatarUrl ? (
          <AgentAvatar
            className={'cx-avatar cx-avatar--xxl cx-avatar--image'}
            avatarUrl={agent.avatarUrl}
          />
        ) : null}
        <AgentDetails>
          <AgentName>{agent.displayName}</AgentName>
          <AgentSubtitle>Agent</AgentSubtitle>
        </AgentDetails>
      </Agent>
      <PageHeaderTitleWrap currentPageNumber={activeSectionIndex + 1}>
        <PageHeaderTitle>Comparative Market Analysis</PageHeaderTitle>
        <PageHeaderSubtitle>
          {subjectProperty?.location?.prettyAddress}
        </PageHeaderSubtitle>
      </PageHeaderTitleWrap>
      <PageHeaderLogo currentPageNumber={activeSectionIndex + 1}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 16">
          <title>Compass</title>
          {/* eslint-disable-next-line max-len */}
          <path d="M53,.457,45,11.314,37,.457V15h2V6.543l6,8.143,6-8.143V15h2ZM60,15H58V1h6.5a4.5,4.5,0,0,1,0,9H60Zm0-7h4.5a2.5,2.5,0,0,0,0-5H60Zm22.863,7h2.275L77.5.9,69.863,15h2.275l1.625-3h7.475Zm-8.018-5L77.5,5.1,80.155,10ZM97,11.085c0,2.371-2.175,4.16-5.06,4.16a6.494,6.494,0,0,1-4.878-2.355l1.41-1.375A4.494,4.494,0,0,0,91.94,13.29c1.8,0,3.06-.906,3.06-2.2,0-1.11-.756-1.856-2.31-2.283L91,8.42c-3.6-.884-3.6-3.043-3.6-3.753,0-2.232,1.8-3.732,4.485-3.732a6.1,6.1,0,0,1,4.581,2.05l-1.41,1.378a4.629,4.629,0,0,0-3.171-1.472c-1.579,0-2.485.647-2.485,1.777,0,.337.128,1.462,1.773,1.816l1.533.345C95.516,7.487,97,8.96,97,11.085Zm14,0c0,2.371-2.175,4.16-5.06,4.16a6.494,6.494,0,0,1-4.878-2.355l1.41-1.375a4.494,4.494,0,0,0,3.468,1.775c1.8,0,3.06-.906,3.06-2.2,0-1.11-.756-1.856-2.31-2.283L105,8.42c-3.6-.884-3.6-3.043-3.6-3.753,0-2.232,1.8-3.732,4.485-3.732a6.1,6.1,0,0,1,4.581,2.05l-1.41,1.378a4.629,4.629,0,0,0-3.171-1.472c-1.579,0-2.485.647-2.485,1.777,0,.337.128,1.462,1.773,1.816l1.533.345C109.516,7.487,111,8.96,111,11.085Zm-98.611.8h0a5.5,5.5,0,1,1,0-7.778h0l.354.354L14.157,3.05,13.8,2.7h0a7.5,7.5,0,1,0,0,10.607l0,0h0l.354-.353-1.414-1.415ZM25.5.5A7.5,7.5,0,1,0,33,8,7.5,7.5,0,0,0,25.5.5Zm0,13A5.5,5.5,0,1,1,31,8,5.5,5.5,0,0,1,25.5,13.5Zm3.207-7.293L27.293,4.793l-5,5,1.414,1.414Z"></path>
        </svg>
      </PageHeaderLogo>
    </Wrapper>
  );
};

const Wrapper = styled.div<{currentPageNumber: number}>`
  display: flex;
  height: 67px;
  width: 100%;
  z-index: var(--cx-zIndex-8);
  justify-content: space-between;
  ${({currentPageNumber}) =>
    currentPageNumber > 1
      ? `box-shadow: 0px 0px 14px 0px ${color.dropShadow};`
      : ''};

  @media ${mq.maxWidthMobileLandscape} {
    position: sticky;
    height: 48px;
    align-items: center;
    padding: 0 ${spacing['2x']};
  }

  position: sticky;
  top: 0;
  background-color: ${color.background};
  max-width: 1279px;
  border-left: 1px solid ${color.grey40};
  border-right: 1px solid ${color.grey40};
  left: 0;
  right: 0;
`;

const PageHeaderButton = styled.div`
  padding-left: ${spacing['2x']};
  padding-top: ${spacing['2x']};
  cursor: pointer;

  @media ${mq.maxWidthMobileLandscape} {
    height: initial;
    padding: 0;
    width: auto;
    padding-top: ${spacing['1x']};
  }
`;

const PageHeaderButtonIcon = styled.svg`
  margin-top: 10px;
  margin-left: 12px;
  transform: scale(1.3);

  @media ${mq.maxWidthMobileLandscape} {
    margin: 0;
    transform: scale(1);
  }
`;

const Agent = styled.div<{currentPageNumber: number}>`
  display: flex;
  text-align: left;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 30px;
  opacity: ${({currentPageNumber}) => (currentPageNumber > 1 ? 1 : 0)};
  font-size: ${font.sizeBody1};
  color: ${color.text};
  font-weight: ${font.weightMedium};

  @media ${mq.maxWidthMobileLandscape} {
    display: none;
  }
`;

const AgentAvatar = styled.div<{avatarUrl: string}>`
  background-image: url(${({avatarUrl}) => avatarUrl});
  width: 50px;
  height: 50px;
  vertical-align: middle;
`;

const AgentDetails = styled.div`
  margin-left: 10px;
  margin-right: auto;

  @media ${mq.maxWidthMobileLandscape} {
    display: none;
  }
`;

const AgentName = styled.div`
  font-weight: ${font.weightMedium};
`;

const AgentSubtitle = styled.div`
  font-size: ${font.sizeCaption1};
  color: ${color.text};
  font-weight: ${font.weightRegular};
`;

const PageHeaderTitleWrap = styled.div<{currentPageNumber: number}>`
  margin: 10px auto;
  text-align: center;
  display: ${({currentPageNumber}) => currentPageNumber === 1 && 'none'};

  @media ${mq.maxWidthMobileLandscape} {
    margin: 0 auto;
  }
`;

const PageHeaderTitle = styled.div`
  color: ${color.text};
  font-size: ${font.sizeBody2};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeCaption1};
    font-weight: ${font.weightRegular};
  }
`;

const PageHeaderSubtitle = styled.div`
  font-size: ${font.sizeSubheader2};
  color: ${color.text};
  font-weight: ${font.weightMedium};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeBody2};
  }
`;

const PageHeaderLogo = styled.div<{currentPageNumber: number}>`
  width: 240px;
  height: 17px;
  margin-top: ${spacing['3x']};
  padding: 0 35px 0 81px;

  @media ${mq.maxWidthMobileLandscape} {
    display: ${({currentPageNumber}) => currentPageNumber > 1 && 'none'};
    width: 86px;
    height: 12px;
    padding: 0;
    margin-top: 0;
    margin-right: calc(50% - 43px);
  }
`;
