import formatNumber from 'format-number';

import {getListingPrice} from '@uc/format';
import {LotSizeUnit as CmaLotSizeUnit} from '@uc/thrift2npme/dist/cma/cma_models';
import {AgentInputs} from '@uc/thrift2npme/dist/cma/cma_models';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {formatPriceNumber} from '@/utils/getPriceUtils';
import {getBathrooms} from '@/utils/getBathrooms';
import {getBedrooms} from '@/utils/getBedrooms';
import {getPricePerSqft} from '@/utils/getPriceUtils';
import {getSquareFeet} from '@/utils/getSquareFeet';

type Props = {
  agentInputs: AgentInputs;
  listing: ProcessedListing;
  showPrice?: boolean;
  showLotSizeSuffix?: boolean;
  shouldFormatIntoString?: boolean;
  isMobile?: boolean;
};

type Info = {
  Bed: string;
  Bath: string;
  SqFt: string;
  'Lot Size': string | number;
  '$/SqFt'?: string | number;
  DOM?: string;
  Price?: string;
};

export const getGeneralInfo = ({
  agentInputs,
  listing,
  showPrice,
  showLotSizeSuffix,
  isMobile,
}: Props): Info => {
  const {lotSizeUnit} = agentInputs || {};
  const lotSizeInSqft = listing?.size?.lotSizeInSquareFeet;
  let lotSizeDisplay: string | number = lotSizeInSqft?.toLocaleString() || '-';

  if (lotSizeUnit === CmaLotSizeUnit.ACRES) {
    lotSizeDisplay =
      lotSizeInSqft !== undefined
        ? formatNumber({round: 2})(lotSizeInSqft / 43560)
        : '-';
  }
  if (!isMobile && showLotSizeSuffix && lotSizeDisplay !== '-') {
    lotSizeDisplay = `${lotSizeDisplay}${
      lotSizeUnit === CmaLotSizeUnit.ACRES ? ' AC' : ' SqFt'
    }`;
  }

  const info: Info = {
    Bed: getBedrooms(listing, true),
    Bath: getBathrooms(listing, true),
    SqFt: getSquareFeet(listing, true),
    'Lot Size': lotSizeDisplay,
  };

  if (isMobile) {
    info.DOM = listing?.date?.daysOnMarket?.toLocaleString() || '-';
  } else {
    if (showPrice) {
      const priceAmt = getListingPrice(listing);
      const priceDisplay =
        priceAmt === undefined || isNaN(priceAmt)
          ? '-'
          : formatPriceNumber(priceAmt);
      info.Price = priceDisplay;
    }
    info.DOM = listing?.date?.daysOnMarket?.toLocaleString() || '-';
    info['$/SqFt'] = getPricePerSqft(listing);
  }

  return info;
};
