import styled from '@emotion/styled';
import React, {FC} from 'react';

import {mq} from '@uc/cx-design-tokens';

type OwnProps = {
  avatarUrl: string;
  className?: string;
};

export const AgentAvatar: FC<OwnProps> = ({className = ''}) => (
  <div className={`${className} cx-avatar cx-avatar--xxl cx-avatar--image`} />
);

const StyledAgentAvatar = styled(AgentAvatar)`
  background-image: url(${props => props.avatarUrl});
  width: 192px;
  height: 192px;
  vertical-align: middle;
  border-radius: 0;
  margin-left: 40px;
  flex-shrink: 0;

  @media ${mq.maxWidthTabletLandscape} {
    width: 150px;
    height: 150px;
    margin-left: 0;
  }

  @media ${mq.maxWidthMobileLandscape} {
    margin-right: 0;
    width: 120px;
    height: 120px;
    margin-left: 0;
  }
`;

export default StyledAgentAvatar;
