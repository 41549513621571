const displayNumber = (n: number, decimals = 2) =>
  Number(n.toFixed(decimals)).toLocaleString();

export default (tick: number) => {
  if (tick >= 1000000) {
    return `$${displayNumber(tick / 1000000)}M`;
  }

  if (tick >= 100000) {
    return `$${displayNumber(tick / 1000, 0)}K`;
  }

  if (tick >= 10000) {
    return `$${displayNumber(tick / 1000, 1)}K`;
  }

  if (tick >= 1000) {
    return `$${displayNumber(tick / 1000)}K`;
  }

  return `$${displayNumber(tick)}`;
};
