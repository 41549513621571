import {debounce} from 'lodash';

import {ActionWithGenericPayload} from './ducks/types/ActionWithGenericPayload';
import type {Dispatch} from './types/Store';

/**
 * Will batch dispatches so that:
 * (1) the batch happens synchronously, without intermediate
 *     state updates
 * (2) if more than one update happens for a given type in the
 *     debounce window, the previous updates are thrown away
 * @param {*} dispatcher store dispatcher
 * @returns {*} debounced dispatcher
 */
const getDebouncedDispatch =
  (dispatcher: (action: Dispatch) => void) => (debounceMs: number) => {
    const actionMap = new Map();
    const debouncedDispatchAll = debounce(
      () => {
        for (const action of actionMap.values()) {
          dispatcher(action);
        }

        actionMap.clear();
      },
      debounceMs,
      {trailing: true},
    );

    return (action: ActionWithGenericPayload) => {
      if (typeof action !== 'function') {
        actionMap.set(action.type, action);
        debouncedDispatchAll();
      }
    };
  };

export default getDebouncedDispatch;
