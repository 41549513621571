import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import type {State} from './store';

export const SET_SUBJECT_PROPERTY = 'SET_SUBJECT_PROPERTY';
type SET_SUBJECT_PROPERTY = typeof SET_SUBJECT_PROPERTY;

export const setSubjectProperty = (
  subjectProperty: ProcessedListing,
): {
  type: SET_SUBJECT_PROPERTY;
  payload: {subjectProperty: ProcessedListing};
} => ({
  type: SET_SUBJECT_PROPERTY,
  payload: {subjectProperty},
});

export type Action = ReturnType<typeof setSubjectProperty>;

export const subjectPropertyReducer = (state: State, action: Action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_SUBJECT_PROPERTY: {
      const {subjectProperty} = payload;
      return {
        ...state,
        subjectProperty,
      };
    }
    default:
      console.log('action type not found: ', type);
      return state;
  }
};
