import styled from '@emotion/styled';
import get from 'lodash.get';
import React from 'react';
import {
  Area,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {getListingPrice} from '@uc/format';
import {color, font, mq, spacing} from '@uc/cx-design-tokens';

import {statusToDisplayStatus} from '@/utils/listingStatusMaps';
import {DisplayStatusValue} from '@/types/types';
import {ChartLegend} from '@/pages/Web/components/chartLegend/chartLegend';
import {ChartMarker} from '@/pages/Web/components/chartMarker/chartMarker';
import {ChartTooltip} from '@/pages/Web/components/chartTooltip/chartTooltip';
import {SectionTitle} from '@/pages/Web/components/sectionTitle/sectionTitle';
import {useStateValue} from '@/pages/Web/context/state';
import {getXAxisProps, getYAxisProps} from '../prop-controllers/axes';
import {
  getCoordinates,
  getMinMaxCoordinates,
} from '../utils/calculateMinMaxCoordinates';
import getCroppedRegressionPoints from '../utils/get-cropped-regression-points';
import {useResponsive} from '@/hooks';

type DataItem = {
  x?: number;
  size?: number;
  price: number;
  originalIndex: number;
  status: {
    key: string;
  } & DisplayStatusValue;
  address?: string;
  coverPhoto?: string;
};

const StyledScatterChart = styled(ScatterChart)`
  margin: var(--cx-spacing-3x) var(--cx-spacing-3x) 0 0;

  @media ${mq.minWidthTablet} {
    margin: var(--cx-spacing-4x) var(--cx-spacing-4x) 0 var(--cx-spacing-3x);
  }
`;

const PositionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  @media ${mq.maxWidthMobileLandscape} {
    justify-content: space-between;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  position: relative;
  width: calc(100% - ${spacing['8x']});
  height: 600px;
  padding: 0 ${spacing['4x']};
  margin: ${spacing['4x']};
  margin-bottom: 0;

  & {
    .recharts-surface {
      overflow: visible;
    }
  }

  @media ${mq.maxWidthMobileLandscape} {
    height: calc(100vh - 300px);
    width: calc(100% - ${spacing['4x']});
    margin: ${spacing['2x']};
    margin-bottom: 0;
    padding: 0;
`;

type XLabelProps = {
  offset?: number;
  viewBox?: {
    y?: number;
  };
};

const XLabel = ({viewBox: {y = 0} = {}, offset = 0}: XLabelProps) => (
  <text
    x="55%"
    y={y + offset}
    textAnchor="middle"
    fontSize={font.sizeBody1}
    fontWeight={font.weightRegular}
  >
    <tspan fontWeight={font.weightBold}>Square Feet</tspan>
  </text>
);

export const PriceVsSqFt = () => {
  const {isMobile} = useResponsive();
  const [{compListings, agentInputs: {appliedAdjustments = {}} = {}}] =
    useStateValue();

  const listingsWithExtraInfo = compListings.map((comp, index) => ({
    listing: comp,
    appliedAdjustments:
      comp.listingIdSHA && appliedAdjustments[comp.listingIdSHA],
    originalIndex: index + 1,
  }));

  const data = listingsWithExtraInfo
    .map<DataItem>(l => {
      const size = l.listing?.size?.squareFeet;
      const adjustedPrice = (l.appliedAdjustments || []).reduce(
        (final, adj) => final + (adj.value as number),
        getListingPrice(l?.listing) || 0,
      );

      return {
        x: size,
        size,
        price: adjustedPrice,
        originalIndex: l['originalIndex'],
        status: statusToDisplayStatus[l?.listing?.status || 0],
        address: get(l, 'listing.location.prettyAddress'),
        coverPhoto: get(l, 'listing.media[0].originalUrl'),
      };
    })
    .filter(
      (item): item is Omit<DataItem, 'size'> & {size: number} =>
        item.size !== undefined,
    )
    .sort((a, b) => a.size - b.size);

  if (!data.length) {
    return null;
  }

  const {xmin, xmax, ymin, ymax} = getMinMaxCoordinates(
    data.map(({size, price}) => ({size, price})),
    'size',
    'price',
  );
  const xAxisProps = getXAxisProps({min: xmin, max: xmax, isMobile});
  const yAxisProps = getYAxisProps({min: ymin, max: ymax, isMobile});

  const trendPoints = getCroppedRegressionPoints({
    data: getCoordinates(
      data
        .filter(({status}) => status.trendable)
        .map(({size, price}) => ({size, price})),
      'size',
      'price',
    ),
    minX: xmin,
    maxX: xmax,
    minY: ymin,
    maxY: ymax,
  });

  // add cushion space to the left and right of the chart
  const cushionedData: Array<{x: number; size: number}> = [];
  cushionedData.push({
    x: xmin,
    size: xmin,
  });
  cushionedData.concat(data.map(({x = 0, size = 0}) => ({x, size})));
  cushionedData.push({
    x: xmax,
    size: xmax,
  });

  return (
    <>
      <SectionTitle title="Price vs Sq Ft" />
      <PositionWrapper>
        <ChartContainer>
          <ResponsiveContainer>
            <StyledScatterChart data={cushionedData}>
              <Scatter
                key="trend-line"
                shape={() => null}
                line={true}
                fill="black"
                name="Trend Line"
                id="trendLine"
                data={trendPoints.map(([x, y]) => ({x, price: y}))}
              />
              <CartesianGrid key="grid" vertical={false} />
              {data.map(d => (
                <Scatter
                  key={d.originalIndex}
                  name="scatter"
                  data={[d]}
                  shape={({originalIndex, ...props}) => (
                    <ChartMarker
                      index={originalIndex}
                      {...props}
                      z={0}
                      key={originalIndex}
                      textColor={d.status.textColor}
                    />
                  )}
                  fill={d.status?.color || color.black}
                />
              ))}
              <Area type="linear" dataKey="price" />
              <Tooltip
                content={ChartTooltip}
                cursor={false}
                filterNull={true}
                isAnimationActive={false}
              />
              <XAxis
                {...xAxisProps}
                dataKey="x"
                type="number"
                domain={[xmin, xmax]}
              >
                <Label content={XLabel} offset={66} position="insideBottom" />
              </XAxis>
              <YAxis
                {...yAxisProps}
                dataKey="price"
                domain={[ymin, ymax]}
                label={
                  isMobile
                    ? {}
                    : {
                        fontWeight: 'bold',
                        value: 'Price',
                        position: 'insideLeft',
                        offset: -48,
                        angle: -90,
                      }
                }
              />
            </StyledScatterChart>
          </ResponsiveContainer>
        </ChartContainer>
        <ChartLegend data={data} />
      </PositionWrapper>
    </>
  );
};
