import {useCallback, useMemo, useReducer, useRef} from 'react';

import IStoreState, {Dispatch} from './types/Store';
import {ActionWithGenericPayload} from './ducks/types/ActionWithGenericPayload';

// the baseline dispatch. This allows thunks to fire other thunks.
const enhancedThunkDispatch =
  (dispatch: Dispatch, state: IStoreState, getState: () => IStoreState) =>
  (action: ActionWithGenericPayload) => {
    if (typeof action === 'function') {
      const enhancedDispatch = enhancedThunkDispatch(dispatch, state, getState);
      return action(enhancedDispatch, state, getState); // Dispatch thunk
    }
    return dispatch(action);
  };

export const useThunkMiddleware =
  (state: IStoreState) =>
  (getState: () => IStoreState) =>
  (dispatch: Dispatch) =>
  (action: ActionWithGenericPayload) => {
    if (typeof action === 'function') {
      const enhancedDispatch = enhancedThunkDispatch(dispatch, state, getState);
      return action(enhancedDispatch, state, getState); // Dispatch thunk
    }
    return dispatch(action);
  };

const middleware = [useThunkMiddleware];
export default (
  reducer: (
    state: IStoreState,
    // eslint-disable-next-line @typescript-eslint/ban-types
    action: Exclude<ActionWithGenericPayload, Function>,
  ) => IStoreState,
  initState: IStoreState,
): [IStoreState, Dispatch, () => IStoreState] => {
  const lastState = useRef(initState);
  const getState = useCallback(() => lastState.current, []);
  // to prevent reducer called twice, per: https://github.com/facebook/react/issues/16295
  const enhancedReducer = useRef(
    (state: IStoreState, action: ActionWithGenericPayload) =>
      (lastState.current = reducer(
        state,
        // eslint-disable-next-line @typescript-eslint/ban-types
        action as Exclude<ActionWithGenericPayload, Function>,
      )),
  ).current;
  const [state, dispatch] = useReducer(enhancedReducer, initState);
  const enhancedDispatch = useMemo(
    () =>
      middleware.reduceRight(
        (acc, mdw) => action => mdw(state)(getState)(acc)(action),
        dispatch,
      ),
    [getState, state],
  );
  return [state, enhancedDispatch, getState];
};
