import produce from 'immer';

import type {History, Location} from 'history';
import type {ThunkFunction} from '../types/Store';
import {createReducer} from '../createReducer';

export type IDealId = string | null;

export const DEAL_ID_ACTION_TYPES = {
  SET_DEAL_ID: 'SET_DEAL_ID',
} as const;

export const dealIdInitialState: IDealId = null;

// ACTIONS
export const setDealId = (dealId: IDealId) => ({
  type: DEAL_ID_ACTION_TYPES.SET_DEAL_ID,
  payload: dealId !== null ? dealId : undefined,
});

// REDUCER
export const dealIdReducer = createReducer<IDealId>({
  [DEAL_ID_ACTION_TYPES.SET_DEAL_ID]: (state, dealId: string) =>
    produce(state, () => dealId),
});

// THUNKS
export const setDealIdAndUpdateUrl =
  ({
    dealId,
    history,
    location,
  }: {
    dealId: IDealId;
    history: History;
    location: Location;
  }): ThunkFunction =>
  dispatch => {
    const params = new URLSearchParams(location.search);

    if (dealId !== null) {
      params.set('dealId', dealId);
    } else {
      params.delete('dealId');
    }

    dispatch(setDealId(dealId));
    history.replace({
      search: [...params].length ? `?${params.toString()}` : '',
    });
  };
