import {CmaOutputSection} from '@uc/thrift2npme/dist/cma/cma_models';

// TODO: Finish this when copy is finalized
export const getSectionName = (
  sectionType: CmaOutputSection | undefined,
  isTNEPartner?: boolean,
) => {
  switch (sectionType) {
    case CmaOutputSection.COVER:
      return 'Cover';
    case CmaOutputSection.SUBJECT_DETAILS:
      return 'Subject property';
    case CmaOutputSection.MAP:
      return 'Location of comparables';
    case CmaOutputSection.SIDE_BY_SIDE_COMPARISON:
      return 'Comparable properties';
    case CmaOutputSection.STATUS_COMPARISON:
      return 'Status comparison';
    case CmaOutputSection.CHARTS_PRICE_VS_SQFT:
      return 'Price & size';
    case CmaOutputSection.CHARTS_PRICE_VS_DOM:
      return 'Price & days on market';
    case CmaOutputSection.CHARTS_LISTING_AVERAGES:
      return 'Listing averages';
    case CmaOutputSection.ESTIMATE:
      return 'Suggested listing price';
    case CmaOutputSection.UPGRADE_SUGGESTIONS:
      return 'Suggested upgrades';
    case CmaOutputSection.CONTACT:
      return 'Personal message';
    case CmaOutputSection.MARKET_TREND_REPORT:
      return 'Market report';
    case CmaOutputSection.NETSHEETS:
      return isTNEPartner ? 'Seller net sheet' : 'Net seller sheet';
    // TODO: See if we can reuse file name for section title of custom PDF's
    case CmaOutputSection.CUSTOM_PDF:
      return 'Custom PDF';
    default:
      return '';
  }
};
