import produce from 'immer';

import {ICma} from '../types/Cma';

const deleteCmaNetsheetsReducer = (state: ICma) =>
  produce(state, draftState => {
    delete draftState.cma.agentInputs?.netsheets;
  });

export default deleteCmaNetsheetsReducer;
