import type {Cma} from '@uc/thrift2npme/dist/cma/cma_models';

const state = {
  de: 'DE',
  pa: 'PA',
  tx: 'TX',
  ca: 'CA',
};

export const getPriceAnalysisDisclaimer = (cma?: Cma): string => {
  if (!cma) {
    return disclaimer.default;
  }

  const subjectStateCode = cma.subjectProperty?.location?.state;
  const compsStateCodes =
    cma.compListings
      ?.map(listing => listing?.location?.state)
      .filter(Boolean) ?? [];
  const ownerStateCodes =
    cma.owner?.profile?.agent?.licenses
      ?.map(license => license.stateCode)
      .filter(Boolean) ?? [];
  const agentRoles = cma.owner?.roles;

  // If the user agent is licensed in the state of Delaware
  // If the user agent has an agent role of “Agent_dc”
  // If any listing (either subject or comp listings) have a listing property of state = delaware
  if (
    [subjectStateCode, ...compsStateCodes, ...ownerStateCodes]?.includes(
      state.de,
    ) ||
    agentRoles?.includes('Agent_dc')
  ) {
    return disclaimer.de;
  }

  // If the user agent is licensed in the state of Pennsylvania
  // If the user agent has an agent role of Agent_philadelphia
  // If any listing (either subject or comp listings) have a listing property of state = pennyslvania
  if (
    [subjectStateCode, ...compsStateCodes, ...ownerStateCodes]?.includes(
      state.pa,
    ) ||
    agentRoles?.includes('Agent_philadelphia')
  ) {
    return disclaimer.pa;
  }

  if (subjectStateCode === state.tx) {
    return disclaimer.tx;
  }

  if (subjectStateCode === state.ca) {
    return disclaimer.ca;
  }

  return disclaimer.default;
};

export const disclaimer = {
  de: `* This represents an estimated sale price for this property.
      It's not the same as opinion of value in appraisal developed by a
      licensed appraiser under the Uniform Standards of Professional Appraisal practice.
      The information contained herein is derived from the local MLS
      and does not purport to be complete nor, necessarily, accurate and should be independently verified;
      nor does it represent or constitute a legal analysis or financial advice.
      Changes in price, condition, sale, or withdrawal may be made without notice.
      All measurements and square footage are approximate.
      This is not intended to solicit property already listed.
      Compass is a licensed real estate broker. Equal Housing Opportunity.
      Compass and its agents disclaim any and all liability for representations and warranties,
      express or implied, contained in, or for omissions from,
      the information contained herein or any other written or oral
      communication transmitted or made available to the recipient.
      Notwithstanding any language to the contrary contained herein,
      this Competitive Market Analysis is NOT an appraisal of the market value for property
      and is not intended to be used for any legal purpose including approval of mortgage loan,
      modification of a mortgage loan, divorce/property separation, estate settlement,
      bankruptcy proceedings or any other purpose where real estate value is needed.
      If an appraisal is desired, the services of a licensed or certified appraiser must be obtained.
    `,
  pa: `* This represents an estimated sale price for this property.
      It's not the same as opinion of value in appraisal developed by a licensed
      appraiser under the Uniform Standards of Professional Appraisal practice.
      The information contained herein is derived from the local MLS and does not
      purport to be complete nor, necessarily, accurate and should be independently verified;
      nor does it represent or constitute a legal analysis or financial advice.
      Changes in price, condition, sale, or withdrawal may be made without notice.
      All measurements and square footage are approximate.
      This is not intended to solicit property already listed.
      Compass is a licensed real estate broker.
      Equal Housing Opportunity. Compass and its agents disclaim any and all
      liability for representations and warranties, express or implied,
      contained in, or for omissions from, the information contained herein or
      any other written or oral communication transmitted or made available to the recipient.
      Notwithstanding any language to the contrary contained herein, 
      this Competitive Market Analysis is NOT an appraisal of the market value for 
      property and is not intended to be used for any legal purpose including 
      approval of mortgage loan, modification of a mortgage loan, 
      divorce/property separation, estate settlement, bankruptcy proceedings or 
      any other purpose where real estate value is needed. 
      If an appraisal is desired, the services of a licensed or certified appraiser must be obtained.
      This analysis has not been performed in accordance with the Uniform Standards
      of Professional Appraisal Practice which require valuers to act as unbiased,
      disinterested third parties with impartiality, objectivity and independence
      and without accommodation of personal interest. It is not to be construed
      as an appraisal and may not be used as such for any purpose.
    `,
  tx: `* This represents an estimated sale price for this property. It's
      not the same as opinion of value in appraisal developed by a licensed appraiser
      under the Uniform Standards of Professional Appraisal practice. The information
      contained herein is derived from the local MLS and does not purport to be complete
      nor, necessarily, accurate and should be independently verified; nor does it represent
      or constitute a legal analysis or financial advice. Changes in price, condition, sale,
      or withdrawal may be made without notice. All measurements and square footage are
      approximate. This is not intended to solicit property already listed. Compass is a
      licensed real estate broker. Equal Housing Opportunity. Compass and its agents disclaim
      any and all liability for representations and warranties, express or implied, contained
      in, or for omissions from, the information contained herein or any other written or
      oral communication transmitted or made available to the recipient.
    `,
  ca: `* This represents an estimated sale price for this property. It's
      not the same as opinion of value in appraisal developed by a licensed appraiser
      under the Uniform Standards of Professional Appraisal practice. The information
      contained herein is derived from the local MLS and does not
      purport to be complete nor, necessarily, accurate and should be independently verified;
      nor does it represent or constitute a legal analysis or financial advice. Changes in
      price, condition, sale, or withdrawal may be made without notice. All measurements and
      square footage are approximate. Equal Housing Opportunity. Compass and its agents
      disclaim any and all liability for representations and warranties, express or implied,
      contained in, or for omissions from, the information contained herein or any other
      written or oral communication transmitted or made available to the recipient. This is
      not intended to solicit property already listed. Compass is a real estate broker
      licensed by the State of California operating under multiple entities. License Numbers
      01991628, 1527235, 1527365, 1356742, 1443761, 1997075, 1935359, 1961027, 1842987,
      1869607, 1866771, 1527205, 1079009, 1272467.
  
    `,
  default: `* This represents an estimated sale price for this property. It's
      not the same as opinion of value in appraisal developed by a licensed appraiser
      under the Uniform Standards of Professional Appraisal practice. The information
      contained herein is derived from the local MLS and does not
      purport to be complete nor, necessarily, accurate and should be independently
      verified; nor does it represent or constitute a legal analysis or financial advice.
      Changes in price, condition, sale, or withdrawal may be made without notice. All
      measurements and square footage are approximate. This is not intended to solicit
      property already listed. Compass is a licensed real estate broker. Equal Housing
      Opportunity. Compass and its agents disclaim any and all liability for
      representations and warranties, express or implied, contained in, or for omissions
      from, the information contained herein or any other written or oral communication
      transmitted or made available to the recipient.
    `,
};
