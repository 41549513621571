import {CmaOutputSection} from '@uc/thrift2npme/dist/cma/cma_models';

export const isInertSection = (sectionType?: CmaOutputSection) => {
  return !(
    sectionType !== undefined &&
    [
      CmaOutputSection.COVER,
      CmaOutputSection.CONTACT,
      CmaOutputSection.MARKET_TREND_REPORT,
      CmaOutputSection.CHARTS_LISTING_AVERAGES,
      CmaOutputSection.STATUS_COMPARISON,
      CmaOutputSection.SIDE_BY_SIDE_COMPARISON,
      CmaOutputSection.CHARTS_PRICE_VS_SQFT,
      CmaOutputSection.CHARTS_PRICE_VS_DOM,
    ].includes(sectionType)
  );
};
