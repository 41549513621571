import React, {FC} from 'react';
import styled from '@emotion/styled';

import {Icon} from '@uc/cx.react';
import {color, font, spacing} from '@uc/cx-design-tokens';
import ListingPhotoCard from '@uc/listing-photocard.react';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {CUSTOM_LISTING_PREFIX} from '@/constants';
import {getColorByStatusValue} from '@/utils/getColorByStatusValue';

type Props = {
  index?: number;
  listing: ProcessedListing;
  isSubject?: boolean;
  onPhotoClick?: () => void;
};

export const ListingPhotoWithPropertyStatus: FC<Props> = ({
  index,
  isSubject,
  listing,
  onPhotoClick,
}) => {
  const onlyMediaData = {
    media: listing?.media || [],
  };
  return (
    <Wrapper hasPhotos={!!onlyMediaData.media?.length} isSubject={isSubject}>
      <ListingPhotoCard
        data={onlyMediaData}
        flickityOptions={{
          draggable: false,
        }}
        openLinkInSameTab={true}
        displayCarousel={onlyMediaData.media?.length > 1}
        onListingClick={onPhotoClick}
      />
      <ListingPhotoGradientOverlay onClick={onPhotoClick} />
      {listing?.listingIdSHA?.startsWith(CUSTOM_LISTING_PREFIX) && (
        <StatusLabel>CUSTOM</StatusLabel>
      )}
      <StatusWrapper>
        {isSubject ? (
          <SubjectLabel>
            <StarSvg />
            <div data-tn="subject-title">Subject Property</div>
          </SubjectLabel>
        ) : (
          <>
            <ColorStripe status={listing.status!} />
            <Status>{listing?.localizedStatus}</Status>
            {index !== undefined && (
              <PropertyLabel>{`Comparable # ${index + 1}`}</PropertyLabel>
            )}
          </>
        )}
      </StatusWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{hasPhotos?: boolean; isSubject?: boolean}>`
  position: relative;
  height: 100%;
  width: 100%;
  font-family: ${font.familyLegible};

  .uc-listingPhotoCard {
    width: 100%;
    height: 100%;
    border: none;
  }
  .uc-listingPhotoCard.uc-listingCard {
    border-radius: 0;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: ${(props: {hasPhotos?: boolean}) =>
      props.hasPhotos ? 'cover' : 'fill'};

    &.uc-listingCard-imagePlaceholder {
      width: 48px;
      height: 48px;
    }
  }
  .uc-listingPhotoCard-body,
  .listingCard-carousel {
    height: 100%;
  }

  .uc-listingCard-carousel {
    height: 100%;
    padding-bottom: 0;
  }

  .uc-listingCard-caption {
    opacity: 0;
    visibility: hidden;
  }

  .uc-listingCard-image {
    padding: 0;
    height: 120px;
    width: ${(props: {isSubject?: boolean}) =>
      props.isSubject ? '220px' : '270px'};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uc-listingCard-image.uc-listingCard-image--invisible {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .photoProgress {
    display: none;
  }

  .flickity-prev-next-button {
    display: none;
    width: 27px;
    height: 27px;
    padding: 0;
    z-index: var(--cx-zIndex-2);

    .flickity-button-icon {
      position: static;
      width: 27px;
      height: 27px;
      filter: drop-shadow(-3px 0 5px ${color.lightBackdrop});

      .arrow {
        fill: var(--colorIntent-textBrightBackgroundDark);
      }
    }
  }

  .flickity-prev-next-button.next {
    right: ${spacing['half']};
    background: none;
  }

  .flickity-prev-next-button.previous {
    left: ${spacing['half']};
    background: none;
  }

  @media (hover: hover) {
    .uc-listingCard:hover {
      border: none;
    }
    .uc-listingCard:hover:after {
      border: none;
    }
    .flickity-prev-next-button {
      display: block;
    }
  }
`;

const ListingPhotoGradientOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.8));
`;

const StatusWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  display: flex;
  color: ${color.white};
  font-size: ${font.sizeCaption1};
  font-weight: ${font.weightBold};
  pointer-events: none;
  z-index: var(--cx-zIndex-2);
`;

const ColorStripe = styled.div<{status: number}>`
  height: 12px;
  width: ${spacing.half};
  margin-top: 2px;
  ${(props: {status: number}) =>
    `background-color: ${getColorByStatusValue(props.status)};`}
`;

const Status = styled.div`
  margin-left: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${font.weightBold};
`;

const PropertyLabel = styled.div`
  margin-left: auto;
  font-weight: ${font.weightSemiBold};
`;

const SubjectLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: ${font.sizeCaption1};
  font-weight: ${font.weightBold};
`;

const StarSvg = styled(Icon.Star)`
  margin-right: 3px;
  fill: ${color.white};
`;

const StatusLabel = styled.div`
  position: absolute;
  background: ${color.teal80};
  color: ${color.white};
  left: ${spacing['1x']};
  top: ${spacing['1x']};
  padding: ${spacing['half']} ${spacing['1x']};
  font-weight: ${font.weightBold};
  font-size: ${font.sizeCaption1};
  z-index: var(--cx-zIndex-2);
`;
